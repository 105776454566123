import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';

import Form from './Form';
import Table from '../../reused/Table';
import EvaluationActivation from '../../reused/EvaluationActivation';
import ExpertAssessment from '../../reused/ExpertAssessment';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { getEvalActivationInfo, getSimpleAssessment, getWebsiteInfoAccess, toggleTabState, updateEvalActivationInfo } from '../../../actions';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';

function Section23() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebsiteInfoAccess());
    dispatch(getEvalActivationInfo(16));
    dispatch(getSimpleAssessment(16));
  }, [dispatch]);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-aimk');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisible = isExpert && finishTimeExpert > dateNow;

  const accessData = useSelector((state) => state.sectionTwoWebsiteAccess.accessData, shallowEqual);

  const defaultValues = useMemo(() => accessData.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}), [accessData]);

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const assessment = useSelector((state) => state.simpleEvaluation.assessment, shallowEqual);

  const { requestState } = useSelector((state) => state.sectionTwoWebsiteAccessUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoWebsiteAccess.error, shallowEqual);

  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(16, data));
    dispatch(getSimpleAssessment(16));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };
  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <>
          <Table
            rows={accessData}
            title={tabs[selectedTabIndex]?.title}
            head={
              <>
                {isVisible && (
                  <Button onClick={handleOpen} color="inherit">
                    <CreateIcon />
                  </Button>
                )}
                <Dialog
                  title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
                  dialogState={dialogState}
                  onClose={handleClose}>
                  <Form defaultValues={defaultValues} submitAction={handleClose} />
                </Dialog>
              </>
            }
          />
          <EvaluationActivation isExpert={isVisible} onSubmit={updateEvalActivation} className="w30 mAuto mb4">
            <div className="w50 mAuto">
              <ExpertAssessment assessment={assessment} isExpert={false} showComment={false} enable_notAssessed={false} />
            </div>
          </EvaluationActivation>
        </>
      )}
    </>
  );
}

export default Section23;
