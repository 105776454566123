import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button } from '@material-ui/core';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';

import DatePicker from 'components/reused/DatePicker';
import Autocomplete from 'components/reused/Autocomplete';
import { convertDate } from 'common/utils';
import { generateExcel, getSearchProjects } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFiltrationFields } from 'actions/monitoring/search';

const SearchForm = ({ getFormValues, dictionaries, page, setDictionaries }) => {
  const dispatch = useDispatch();
  const fields = useSelector((state) => state.m_search.filter);
  const defaultValues = {
    start_date_to: null,
    start_date_from: null,
    end_date_to: null,
    end_date_from: null,
    organization_type: null,
    organization_id: null,
    document_id: null,
    roadmap_id: null,
    status_id: null,
    ...fields,
  };
  const { register, handleSubmit, watch, control, reset } = useForm({ defaultValues });
  const formFields = watch();
  const currentUser = useSelector((state) => state.profile.currentUser);
  const isOrganization = currentUser?.roles.includes('monitoring_supervisor');
  const { organizations, organizationType, documents, roadmaps, projectStatuses } = dictionaries;
  const [isGeneratingExcel, setIsGeneratingExcel] = useState(false);

  useEffect(() => {
    getFormValues(formFields);
  }, []);

  const isShowBtn =
    !!formFields.organization_id || !!formFields.document_id || !!formFields.roadmap_id || !!formFields.status_id;

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      roadmap_id: formFields?.roadmap_id?.id,
      document_id: formFields?.document_id?.id,
      status_id: formFields?.status_id?.id,
      organization_type: data?.organization_type?.title,
      organization_id: data?.organization_id?.id,
      start_date_to: convertDate(data?.start_date_to),
      start_date_from: convertDate(data?.start_date_from),
      end_date_to: convertDate(data?.end_date_to),
      end_date_from: convertDate(data?.end_date_from),
    };
    dispatch(getSearchProjects(finalData, page));
    dispatch(setSearchFiltrationFields({ filter: data, isExpanded: true }));
  };

  const excelDownload = (lang) => () => {
    const convertValues = {
      ...formFields,
      roadmap_id: formFields?.roadmap_id?.id,
      document_id: formFields?.document_id?.id,
      status_id: formFields?.status_id?.id,
      organization_type: formFields?.organization_type?.id,
      organization_id: formFields?.organization_id?.id,
      start_date_to: convertDate(formFields?.start_date_to),
      start_date_from: convertDate(formFields?.start_date_from),
      end_date_to: convertDate(formFields?.end_date_to),
      end_date_from: convertDate(formFields?.end_date_from),
    };
    const finalQueryData = Object.keys(convertValues)
      .filter((key) => (isOrganization ? key : !key.includes('organization_id')))
      .reduce((acc, key) => {
        return { ...acc, [key]: convertValues[key] };
      }, {});
    setIsGeneratingExcel(true);
    generateExcel(lang, finalQueryData).then((response) => {
      fileDownload(response, `${uuidv4()}.xlsx`);
      setIsGeneratingExcel(false);
    });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="dFlex alignItemsCenter">
        <Autocomplete
          control={control}
          options={organizations}
          name="organization_id"
          label="Организация"
          variant="outlined"
          className="search-field"
          isAutocomplete={false}
        />
        <Autocomplete
          control={control}
          options={documents}
          name="document_id"
          label="Документ"
          variant="outlined"
          className="search-field"
          isAutocomplete={false}
        />
        <Autocomplete
          control={control}
          options={roadmaps}
          name="roadmap_id"
          label="Дорожная карта"
          variant="outlined"
          className="search-field"
          isAutocomplete={false}
        />
        <Autocomplete
          control={control}
          options={projectStatuses}
          name="status_id"
          label="Cтатус"
          variant="outlined"
          className="search-field"
          isAutocomplete={false}
        />
        <TextField
          className="search-field"
          label="Название проекта"
          variant="outlined"
          name="project_name"
          inputRef={register()}
        />
        <TextField
          className="search-field"
          label="Номер проекта"
          variant="outlined"
          name="project_no"
          inputRef={register()}
        />
        <TextField className="search-field" label="Этап" variant="outlined" name="step_name" inputRef={register()} />
        <Autocomplete
          control={control}
          options={organizationType}
          name="organization_type"
          label="Тип организации"
          variant="outlined"
          className="search-field"
          isAutocomplete={false}
        />
        <TextField
          className="search-field"
          label="Действие"
          variant="outlined"
          name="action_description"
          inputRef={register()}
        />
        <DatePicker
          control={control}
          inputVariant="outlined"
          fullWidth={false}
          name="start_date_to"
          label="По дата нач."
          className="search-field"
        />
        <DatePicker
          control={control}
          inputVariant="outlined"
          fullWidth={false}
          name="start_date_from"
          label="С дата нач."
          className="search-field"
        />
        <DatePicker
          control={control}
          inputVariant="outlined"
          fullWidth={false}
          name="end_date_to"
          label="По дата кон."
          className="search-field"
        />
        <DatePicker
          control={control}
          inputVariant="outlined"
          fullWidth={false}
          name="end_date_from"
          label="С дата кон."
          className="search-field"
        />
      </div>
      <Button className="m" variant="contained" color="primary" type="submit">
        Фильтр
      </Button>
      <Button className="m" variant="contained" color="primary" onClick={() => reset()}>
        Сброс
      </Button>
      {isShowBtn && (
        <React.Fragment>
          <Button
            disabled={isGeneratingExcel}
            className="m"
            variant="contained"
            color="primary"
            onClick={excelDownload('ru')}>
            {isGeneratingExcel ? 'Подождите...' : 'Экспорт (рус)'}
          </Button>
          <Button
            disabled={isGeneratingExcel}
            className="m"
            variant="contained"
            color="primary"
            onClick={excelDownload('uz')}>
            {isGeneratingExcel ? 'Подождите...' : 'Экспорт (уз)'}
          </Button>
        </React.Fragment>
      )}
    </form>
  );
};

export default SearchForm;
