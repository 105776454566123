import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Divider, Drawer, IconButton, List, SvgIcon } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BusinessIcon from '@material-ui/icons/Business';
import AssessmentIcon from '@material-ui/icons/Assessment';
import WidgetsIcon from '@material-ui/icons/Widgets';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

import { DrawerItem, SimpleDrawerItem } from './DrawerItem';
import { FormattedMessage } from 'react-intl';

function MonitoringDrawer({ classes }) {
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const organizations = useSelector((state) => state.r_dictionary.organizations);
  const deadline = useSelector((state) => state.r_dictionary.deadline);
  const currentUser = useSelector((state) => state.profile.currentUser);
  const isMininfocom = currentUser?.roles.includes('region_mininfocom');
  const isObserver = currentUser?.roles.includes('region_observer');
  return (
    <Drawer
      className="region-drawer"
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}>
      <div className={classes.toolbarIcon}>
        {open ? (
          <div className="spaceBetween w100 p">
            <p className="drawer__title"></p>
            <IconButton onClick={handleDrawerClose} color="primary">
              <ChevronLeftIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton color="primary" aria-label="open drawer" onClick={handleDrawerOpen}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <List>
        <SimpleDrawerItem
          icon={<HourglassFullIcon />}
          text={
            <p className="time">
              {localStorage.lang === 'ru' ? (
                <React.Fragment>
                  <FormattedMessage id="homeHeader.time" defaultMessage="Срок до" /> {deadline}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {deadline} <FormattedMessage id="homeHeader.time" defaultMessage="Срок до" />
                </React.Fragment>
              )}
            </p>
          }
        />
        {organizations.map((el) => (
          <DrawerItem key={el.id} url={`/organization/${el.id}`} text={el.short_name} icon={<BusinessIcon />} />
        ))}

        {(isObserver || isMininfocom) && (
          <>
            <DrawerItem url="/assessment" text="Детальная оценка" icon={<AssessmentIcon />} />
            <DrawerItem url="/excluded-indicator" text="Исключенные индикаторы" icon={<WidgetsIcon />} />
            <DrawerItem url="/rating" text="Рейтинг" icon={<StarHalfIcon />} />
          </>
        )}
        <DrawerItem
          open={open}
          url="/support"
          icon={<ContactSupportIcon />}
          text={<FormattedMessage id="drawerList.support" defaultMessage="Справка" />}
          exact
        />
      </List>
    </Drawer>
  );
}

export default MonitoringDrawer;
