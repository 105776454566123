import axios from 'axios';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';
import { createAction } from 'redux-actions';

export const getProjectLogsRequest = createAction('LOGS_GET_REQUEST');
export const getProjectLogsSuccess = createAction('LOGS_GET_SUCCESS');
export const getProjectLogsFailure = createAction('LOGS_GET_FAILURE');

export const getProjectLogs = (projectId, page) => async (dispatch) => {
  dispatch(getProjectLogsRequest());

  try {
    const filter = `filter[project_id]`;
    const { data } = await axios.get(routes.logs(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { [filter]: projectId, sort: '-id', 'per-page': 5, page },
    });
    dispatch(getProjectLogsSuccess(data));
  } catch (e) {
    dispatch(getProjectLogsFailure());
    console.log(e);
  }
};

export const getStepsLogsRequest = createAction('STEPS_LOGS_GET_REQUEST');
export const getStepsLogsSuccess = createAction('STEPS_LOGS_GET_SUCCESS');
export const getStepsLogsFailure = createAction('STEPS_LOGS_GET_FAILURE');

export const getStepsLogs = (stepId) => (projectId, page) => async (dispatch) => {
  dispatch(getStepsLogsRequest());

  try {
    const filter = `filter[step_id]`;
    const { data } = await axios.get(routes.logs(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { [filter]: stepId, sort: '-id', 'per-page': 5, page },
    });
    dispatch(getStepsLogsSuccess(data));
  } catch (e) {
    dispatch(getStepsLogsFailure());
    console.log(e);
  }
};

export const updateStepLogRequest = createAction('STEPS_LOG_UPDATE_REQUEST');
export const updateStepLogSuccess = createAction('STEPS_LOG_UPDATE_SUCCESS');
export const updateStepLogFailure = createAction('STEPS_LOG_UPDATE_FAILURE');

export const updateStepLog = (id, updateData) => async (dispatch) => {
  dispatch(updateStepLogRequest());

  try {
    const { data } = await axios({
      url: `${routes.logs()}/${id}`,
      method: 'put',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: updateData,
    });
    dispatch(updateStepLogSuccess(data));
  } catch (e) {
    dispatch(updateStepLogFailure(Array(e.response.data)));
    console.log(Array(e.response.data));
  }
};

export const addCommentSuccess = createAction('ADD_COMMENT_SUCCESS');
export const addStepsCommentSuccess = createAction('ADD_STEPS_COMMENT_SUCCESS');
