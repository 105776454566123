import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setGovernmentProjectsError = createAction('GOVERNMENT_PROJECTS_SET_ERROR');

export const getGovernmentProjectsRequest = createAction('GOVERNMENT_PROJECTS_GET_REQUEST');
export const getGovernmentProjectsSuccess = createAction('GOVERNMENT_PROJECTS_GET_SUCCESS');
export const getGovernmentProjectsFailure = createAction('GOVERNMENT_PROJECTS_GET_FAILURE');

export const getGovernmentProjects = (page) => async (dispatch) => {
  dispatch(getGovernmentProjectsRequest());

  try {
    const { data } = await axios.get(routes.sectionSixInfoGovernmentProject(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page, 'per-page': 10 },
    });
    dispatch(getGovernmentProjectsSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(getGovernmentProjectsFailure());
  }
};

export const getGovernmentLabelsRequest = createAction('GOVERNMENT_LABELS_GET_REQUEST');
export const getGovernmentLabelsSuccess = createAction('GOVERNMENT_LABELS_GET_SUCCESS');
export const getGovernmentLabelsFailure = createAction('GOVERNMENT_LABELS_GET_FAILURE');

export const getGovernmentLabels = () => async (dispatch) => {
  dispatch(getGovernmentLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionSixInfoGovernmentProjectLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getGovernmentLabelsSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(getGovernmentLabelsFailure());
  }
};

export const getElementGovernmentRequest = createAction('GOVERNMENT_ELEMENT_GET_REQUEST');
export const getElementGovernmentSuccess = createAction('GOVERNMENT_ELEMENT_GET_SUCCESS');
export const getElementGovernmentFailure = createAction('GOVERNMENT_ELEMENT_GET_FAILURE');

export const getElementGovernment = (id) => async (dispatch) => {
  dispatch(getElementGovernmentRequest());

  try {
    const { data } = await axios.get(routes.sectionSixInfoGovernmentProjectOperations(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getElementGovernmentSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(getElementGovernmentFailure());
  }
};

export const deleteElementGovernmentRequest = createAction('ELEMENT_GOVERNMENT_DELETE_REQUEST');
export const deleteElementGovernmentSuccess = createAction('ELEMENT_GOVERNMENT_DELETE_SUCCESS');
export const deleteElementGovernmentFailure = createAction('ELEMENT_GOVERNMENT_DELETE_FAILURE');

export const deleteElementGovernment = (id) => async (dispatch) => {
  dispatch(deleteElementGovernmentRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionSixInfoGovernmentProjectOperations(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteElementGovernmentSuccess({ id }));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(deleteElementGovernmentFailure());
  }
};

export const addElementGovernmentRequest = createAction('ELEMENT_GOVERNMENT_ADD_REQUEST');
export const addElementGovernmentSuccess = createAction('ELEMENT_GOVERNMENT_ADD_SUCCESS');
export const addElementGovernmentFailure = createAction('ELEMENT_GOVERNMENT_ADD_FAILURE');

export const addElementGovernment = (formData) => async (dispatch) => {
  dispatch(addElementGovernmentRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionSixInfoGovernmentProject(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addElementGovernmentSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(addElementGovernmentFailure());
  }
};

export const editElementGovernmentRequest = createAction('ELEMENT_GOVERNMENT_EDIT_REQUEST');
export const editElementGovernmentSuccess = createAction('ELEMENT_GOVERNMENT_EDIT_SUCCESS');
export const editElementGovernmentFailure = createAction('ELEMENT_GOVERNMENT_EDIT_FAILURE');

export const editElementGovernment = (id, formData) => async (dispatch) => {
  dispatch(editElementGovernmentRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionSixInfoGovernmentProjectOperations(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editElementGovernmentSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(editElementGovernmentFailure());
  }
};

export const getRadioBtnRequest = createAction('RADIO_BTN_GET_REQUEST');
export const getRadioBtnSuccess = createAction('RADIO_BTN_GET_SUCCESS');
export const getRadioBtnFailure = createAction('RADIO_BTN_GET_FAILURE');

export const getRadioBtn = () => async (dispatch) => {
  dispatch(getRadioBtnRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSixInfoGovernmentProjectRadio()}view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getRadioBtnSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(getRadioBtnFailure());
  }
};

export const updateRadioBtnRequest = createAction('RADIO_BTN_UPDATE_REQUEST');
export const updateRadioBtnFailure = createAction('RADIO_BTN_UPDATE_FAILURE');

export const updateRadioBtn = (formValues) => async (dispatch) => {
  dispatch(updateRadioBtnRequest());

  try {
    await axios({
      method: 'post',
      url: `${routes.sectionSixInfoGovernmentProjectRadio()}update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getRadioBtn());
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(updateRadioBtnFailure());
  }
};

export const getGovProjectEvaluationRequest = createAction('GOV_PROJECT_EVALUATION_GET_REQUEST');
export const getGovProjectEvaluationSuccess = createAction('GOV_PROJECT_EVALUATION_GET_SUCCESS');
export const getGovProjectEvaluationFailure = createAction('GOV_PROJECT_EVALUATION_GET_FAILURE');

export const getGovProjectEvaluation = () => async (dispatch) => {
  dispatch(getGovProjectEvaluationRequest());

  try {
    const { data } = await axios.get(routes.governmentProjectEvaluation('project-point'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getGovProjectEvaluationSuccess(data));
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(getGovProjectEvaluationFailure());
  }
};

export const saveGovProjectEvaluationRequest = createAction('GOV_PROJECT_EVALUATION_SAVE_REQUEST');
export const saveGovProjectEvaluationSuccess = createAction('GOV_PROJECT_EVALUATION_SAVE_SUCCESS');
export const saveGovProjectEvaluationFailure = createAction('GOV_PROJECT_EVALUATION_SAVE_FAILURE');

export const saveGovProjectEvaluation = (formValues) => async (dispatch) => {
  dispatch(saveGovProjectEvaluationRequest());

  try {
    await axios({
      url: routes.governmentProjectEvaluation('update'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getGovProjectEvaluation());
  } catch (e) {
    dispatch(setGovernmentProjectsError(e?.response));
    dispatch(saveGovProjectEvaluationFailure());
  }
};
