import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const questionnaireContentManager = handleActions(
  {
    [actions.getQuestionnaireManagerInfoSuccess]: (state, { payload: { labels, data } }) => {
      return {
        ...state,
        manager: data ? Object.values(data) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key })),
        labels: labels,
      };
    },
    [actions.setQuestionnaireManagerInfoError]: (state, { payload }) => ({ ...state, error: { hasError: true, message: payload.message, status: payload.status } }),
  },
  {
    manager: [],
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const questionnaireContentManagerUI = handleActions(
  {
    [actions.getQuestionnaireManagerInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getQuestionnaireManagerInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getQuestionnaireManagerInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.editQuestionnaireManagerInfoRequest]: () => ({ requestState: 'request' }),
    [actions.editQuestionnaireManagerInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.editQuestionnaireManagerInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
