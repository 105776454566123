import { LOCALES } from '../locales';
export default {
  [LOCALES.RUS]: {
    'signIn.enterToSystem': 'Вход в систему',
    'signIn.nameSystem': 'Национальная информационная система',
    'signIn.buttonNameEnter': 'Войти',
    'signIn.signUpTitle': 'Логин',
    'signIn.passwordTitle': 'Пароль',
    'signIn.helpTitle': 'Помощь',
    'signIn.techniqueSupportTitle': 'Техническая поддержка',
    'signIn.singleIntegratorTitle': 'Единый интегратор',
    'signIn.checkboxRememberTitle': 'Запомнить',
    'signIn.languageUzbOptionTitle': 'Узбекский',
    'signIn.languageRusOptionTitle': 'Русский',
    'drawerList.generalInfoOrg': 'Общие сведения об организации',
    'drawerList.infrastructure': 'Инфраструктура',
    'drawerList.financing': 'Финансирование',
    'drawerList.organizationList': 'Список организаций',
    'drawerList.support': 'Справка',
    'drawerList.total': 'Итого',
    'drawerList.rating': 'Рейтинг',
    'drawerList.nameOrganization': 'Наименование организации',
    'drawerList.ratingOrganization': 'Оценка организации',
    'statistics.title': 'Статистика всех организаций ',
    'statistics.govOrg': 'Госорганы',
    'statistics.ecoOrg': 'Хозорганы',
    'statistics.allOrg': 'Все организации',
    'homeHeader.headerTitle':
      'Рейтинговая оценка широкого использования ИКТ в органах государственного и хозяйственного управления',
    'monitoringHomeHeader.headerTitle': 'МОНИТОРИНГ ПРОЕКТОВ СТРАТЕГИИ "ЦИФРОВОЙ УЗБЕКИСТАН - 2030"',
    'regionHomeHeader.headerTitle': 'РЕЙТИНГОВАЯ ОЦЕНКА ЦИФРОВИЗАЦИИ РЕГИОНОВ',
    'homeHeader.day': 'д',
    'homeHeader.hour': 'ч',
    'homeHeader.minutes': 'м',
    'homeHeader.seconds': 'с',
    'homeHeader.time': 'Срок до',
    'drawerFooter.footerTitle': 'Единая система уведомлений Республики Узбекстан || Единый интегратор',
    'intro.systemSupportUsed': 'Инструкция по использованию системы',
    'intro.downloadQuestionaire': 'Скачать вопросник',
    'intro.downloadMethodologyState': 'Скачать  методику госорган',
    'intro.downloadMethodologyEconomic': 'Скачать  методику хозорган',
    'intro.contacts': 'Контактная информация',
    'contact.generalConsultation': 'Общая консультация и поддержка по работе в системе – (55)-501-36-26',
    'contact.consultationForFillingSectionOpenData':
      'Консультация по заполнению раздела «Наличие наборов открытых данных на Портале открытых данных» – (71)-202-80-60',
    'contact.consultationForFillingSectionElectronServices':
      'Консультация по заполнению раздела «Электронные услуги» – (71)-207-00-66 (1080)',
    'contact.phoneCentralCyberHelpLine': 'ГУП "Центр Кибербезопасности" - (71)-203-00-26, (71)-203-00-54',
    'evalActivation.title': 'Сведения о наличии оценки в данном разделе',
    'expertMark.markItemTitle': 'Отметка о правильности или неправильности заполнения пункта',
    'expertMark.fillItem': 'Заполнено',
    'expertMark.commentItem': 'Комментарий',
    'expertMark.noMarkItem': 'Нет отметки',
    'expertMark.correctly': 'Правильно',
    'expertMark.notCorrectly': 'Неправильно',
    'expertAssessment.expertAssessmentTitle': 'Оценка эксперта',
    'expertAssessment.ballItem': 'Балл',
    'expertAssessment.commentItem': 'Комментарий',
    'expertAssessment.assesmentTitle': 'Оценка',
    'expertAssessment.noAssessment': 'Нет оценки',
    'section13.directorTitle': 'Руководитель организации',
    'section13.legalAddressTitle': 'Юридический адрес',
    'section13.reportExportTemplateFirst': 'Форма 1',
    'section13.reportExportTemplateSecond': 'Форма 2',
    'section17.indicatorsTitle': 'Показатели',
    'section171.headerTitle':
      'Перечень подведомственных организаций, территориальных и других подразделений, входящих в систему данной организации',
    'section18.errorComputationVacancy':
      'Внимание! При указании количества фактически работающих без учета вакансий указывается количество занятых должностей согласно штатного расписания. Например, если на одной должности работают 2 сотрудника по 0,5 ставки, в графе «Фактически работающих без учета вакансий» указывается 1 единица. Общее количество персонала организации по штатному расписанию в разрезе подразделений и в разрезе должностей, а также общее количество фактически работающих в разрезе подразделений и в разрезе должностей должно иметь одинаковое значение.',
    'section110.deleteFilledFieldsTitle': 'Если вы потвердите действие мы удалим введенные данные!',
    'section110.responsiblePerson': 'Ответственное лицо',
    'section24.partlyTitle': 'Частично',
    'section24.indicatorName': 'Наименование показателя',
    'section24.estimatedTitle': 'Оценивается',
    'section24.indicatorCritition': 'Критерий показателя',
    'section27.infoOpenDataTitle': '',
    'section28.title': 'Наименование',
    'section28.count': 'Кол-во',
    'section31.addServiceBtnTitle': 'Добавить электронную услугу',
    'section32.title': 'Наименование',
    'section32.count': 'Кол-во',
    'section33.empty': 'По вашей организации запросов не было',
    'section33.actualData': 'Данные актуальны',
    'section33.actualFrom': 'от',
    'section33.actualTo': 'до',
    'section40.questionsTitle': 'Вопросы',
    'section40.infoSecurity': 'Информационная безопасность',
    'section40.questionTitle': 'Наименование вопроса',
    'section40.inputData': 'Вводимые данные',
    'section40.link': 'Система мониторинга информационной и кибербезопасности',
    'section51.activeEvaluation': 'Учёт оценки',
    'section51.infoAboutInfoSystem': 'Сведения об информационной системе',
    'section51.notAssessed': 'Исключено',
    'section52.title': 'Наименование',
    'section63.enterNum': 'Введите число для подсчёта',
    'section81.title': 'Наименование',
    'organizationStat.formLabel': 'Введите год и квартал для получения статистики',
    'organizationStat.labelYear': 'Год',
    'organizationStat.labelQuarter': 'Квартал',
    'monitoring.project.statusForm': 'Изменить статус',
    'monitoring.project.organizationForm': 'Изменить организацию',
    'monitoring.project.forecastForm': 'Изменить объём прогнозных средств',
    'monitoring.project.costEffectivenessForm': 'Изменить экономическую эффективность проекта',
    'monitoring.project.history': 'История проекта',
    'monitoring.form.commentTitle': 'Комментарий',
    'monitoring.roadmaps.fullNameApp': 'Полное наименование приложения',
    'monitoring.roadmaps.shortNameApp': 'Сокращенное наименование приложения',
    'monitoring.roadmaps.yearOfExecution': 'Год исполнения',
    'monitoring.roadmaps.statusProjects': 'Статусы проектов',
    'monitoring.roadmaps.stepStatistic': 'Статистика по этапам',
    'monitoring.roadmaps.attachmentFiles': ' Вложение файлов к проекту',
    'monitoring.roadmaps.hasntFiles': ' Файлы отсутствуют...',
    'monitoring.roadmaps.stepsProject': ' Этапы проекта',
    'monitoring.roadmaps.historySteps': ' История этапа',
    'monitoring.step.comment': `
        <strong>Об этапе проекта необходимо внести содержательный комментарий!</strong>
        <br />В текстовом комментарии об исполнении этапа должна быть указана чёткая информация (в случае писем - автор
        письма, адрес и дата, регистрационный номер; в случае контракта - с кем был подписан контракт, предмет
        контракта, дата подписания; комментарии об объекте, юридическом и/или физическом лице, событии также должно быть
        описано в деталях, с указанием количества, качество и других относительных показателей).
        <strong>
          В противном случае введенные данные могут быть не приняты Модератором модуля мониторинга из-за недостаточной
          информации.
        </strong>`,
    'global.requiredFill': 'Поле обязательно для заполнения!',
    'global.addBtnTitle': 'Добавить',
    'global.deleteItem': 'Удалить',
    'global.sendBtnTitle': 'Отправить',
    'global.requireBtnTitle': 'Запрос',
    'global.changeBtnTitle': 'Изменить',
    'global.saveBtnTitle': 'Сохранить',
    'global.attachFileTitle': 'Вложить файл',
    'global.downloadFileTitle': 'Скачать файл',
    'global.stateReason': 'Укажите причину',
    'global.yesTitle': 'Да',
    'global.noTitle': 'Нет',
    'global.attachOrderAppoint': 'Вложить приказ о назначении',
    'global.logout': 'Выйти',
    'global.editBtnTitle': 'Редактировать',
    'global.showBtnTitle': 'Посмотреть',
    'global.fillFormTitle': 'Заполните форму',
    'global.chooseRadioTitle': 'Выберите значение',
    'global.countTitle': 'Количество',
    'global.errorDublicateNames': 'Наименование документа не должно повторяться!',
    'global.errorDublicateLinks': 'Ссылка не должна повторяться!',
    'global.errorDublicateServices': 'Наименование услуги не должно повторяться!',
    'global.errorDublicateOpenData': 'Наименование ОД не должно повторяться!',
    'global.errorDublicateInformationSystems': 'Наименование ИС не должно повторяться!',
    'global.errorDublicateProjects': 'Наименование проекта не должно повторяться!',
    'global.errorDublicateServers': 'Тип и марка сервера не должны повторятся!',
    'global.errorDataNotFound': 'Данные отсутствуют!',
    'global.commentExpert': 'Комментарий эксперта',
    'global.categoryName': 'Категория',
  },
};
