import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoServersError = createAction('INFO_SERVERS_SET_ERROR');

export const getInfoServersRequest = createAction('INFO_SERVERS_GET_REQUEST');
export const getInfoServersSuccess = createAction('INFO_SERVERS_GET_SUCCESS');
export const getInfoServersFailure = createAction('INFO_SERVERS_GET_FAILURE');

export const getInfoServers = (page) => async (dispatch) => {
  dispatch(getInfoServersRequest());

  try {
    const { data } = await axios.get(routes.sectionSevenOrganizationServers(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });
    dispatch(getInfoServersSuccess(data));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(getInfoServersFailure());
  }
};

export const getLabelsServersRequest = createAction('LABELS_SERVERS_GET_REQUEST');
export const getLabelsServersSuccess = createAction('LABELS_SERVERS_GET_SUCCESS');
export const getLabelsServersFailure = createAction('LABELS_SERVERS_GET_FAILURE');

export const getLabelsServers = () => async (dispatch) => {
  dispatch(getLabelsServersRequest());

  try {
    const { data } = await axios.get(routes.sectionSevenOrganizationServersLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsServersSuccess(data));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(getLabelsServersFailure());
  }
};

export const addItemServerRequest = createAction('SERVER_ITEM_ADD_REQUEST');
export const addItemServerSuccess = createAction('SERVER_ITEM_ADD_SUCCESS');
export const addItemServerFailure = createAction('SERVER_ITEM_ADD_FAILURE');

export const addItemServer = (formData) => async (dispatch) => {
  dispatch(addItemServerRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionSevenOrganizationServers(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addItemServerSuccess(data));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(addItemServerFailure());
  }
};

export const deleteItemServerRequest = createAction('SERVER_ITEM_DELETE_REQUEST');
export const deleteItemServerSuccess = createAction('SERVER_ITEM_DELETE_SUCCESS');
export const deleteItemServerFailure = createAction('SERVER_ITEM_DELETE_FAILURE');

export const deleteItemServer = (id) => async (dispatch) => {
  dispatch(deleteItemServerRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionSevenOrganizationServersOperations(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteItemServerSuccess({ id }));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(deleteItemServerFailure());
  }
};

export const editItemServerRequest = createAction('SERVER_ITEM_EDIT_REQUEST');
export const editItemServerSuccess = createAction('SERVER_ITEM_EDIT_SUCCESS');
export const editItemServerFailure = createAction('SERVER_ITEM_EDIT_FAILURE');

export const editItemServer = (id, formData) => async (dispatch) => {
  dispatch(editItemServerRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionSevenOrganizationServersOperations(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editItemServerSuccess(data));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(editItemServerFailure());
  }
};

export const getItemServerRequest = createAction('SERVER_ITEM_GET_REQUEST');
export const getItemServerSuccess = createAction('SERVER_ITEM_GET_SUCCESS');
export const getItemServerFailure = createAction('SERVER_ITEM_GET_FAILURE');

export const getItemServer = (id) => async (dispatch) => {
  dispatch(getItemServerRequest());

  try {
    const { data } = await axios.get(routes.sectionSevenOrganizationServersOperations(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getItemServerSuccess({ id, data }));
  } catch (e) {
    dispatch(setInfoServersError(e?.response));
    dispatch(getItemServerFailure());
  }
};

