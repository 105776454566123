import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setProcessingActsError = createAction('PROCESSING_ACTS_SET_ERROR');

export const getProcessingActsRequest = createAction('PROCESSING_ACTS_GET_REQUEST');
export const getProcessingActsSuccess = createAction('PROCESSING_ACTS_GET_SUCCESS');
export const getProcessingActsFailure = createAction('PROCESSING_ACTS_GET_FAILURE');

export const getProcessingActs = () => async (dispatch) => {
  dispatch(getProcessingActsRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoProcessingActs(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoProcessingActLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getProcessingActsSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setProcessingActsError(e?.response));
    dispatch(getProcessingActsFailure());
  }
};

export const editProcessingActsRequest = createAction('PROCESSING_ACTS_EDIT_REQUEST');
export const editProcessingActsSuccess = createAction('PROCESSING_ACTS_EDIT_SUCCESS');
export const editProcessingActsFailure = createAction('PROCESSING_ACTS_EDIT_FAILURE');

export const editProcessingActs = (formData) => async (dispatch) => {
  dispatch(editProcessingActsRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoProcessingActs(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getProcessingActs());
  } catch (e) {
    dispatch(setProcessingActsError(e?.response));
    dispatch(editProcessingActsFailure());
  }
};
