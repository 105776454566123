import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const computers = handleActions(
  {
    [actions.getComputersInfoSuccess]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      labels: Object.values(payload.labels),
    }),
    [actions.setComputersError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    labels: [],
    data: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const computersUI = handleActions(
  {
    [actions.getComputersInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getComputersInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getComputersInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.postComputersInfoRequest]: () => ({ requestState: 'request' }),
    [actions.postComputersInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.postComputersInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
