import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const infoSystems = handleActions(
  {
    [actions.getInfoSystemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: payload.items,
        metaData: payload._meta,
      };
    },
    [actions.getLabelsInfoSystemSuccess]: (state, { payload }) => ({ ...state, labels: payload }),
    [actions.getElementInfoSystemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        element: payload,
      };
    },
    [actions.addElementInfoSystemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: [...state.data, payload],
      };
    },
    [actions.deleteElementInfoSystemsSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.id !== id),
      };
    },
    [actions.editElementInfoSystemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((el) => (el.id === payload.id ? payload : el)),
      };
    },
    [actions.getInfoSystemsEvaluationSuccess]: (state, { payload }) => ({ ...state, evaluation: payload }),
    [actions.getInfoSystemsPointsSuccess]: (state, { payload }) => ({ ...state, points: payload }),
    [actions.saveInfoSystemsEvaluationSuccess]: (state, { payload }) => ({
      ...state,
      evaluation: state.evaluation.map((_eval) => {
        if (_eval.param_id === payload.param_id.value)
          return {
            point: payload.point.value,
            description: payload.description.value,
            param_id: payload.param_id.value,
            label: payload.param_id.translated_value,
          };
        return _eval;
      }),
    }),
    [actions.setInfoSystemsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    data: [],
    labels: {},
    element: {},
    evaluation: [],
    points: [],
    metaData: {
      pageCount: 1,
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const infoSystemsUI = handleActions(
  {
    [actions.getLabelsInfoSystemRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsInfoSystemSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsInfoSystemFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editElementInfoSystemsRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editElementInfoSystemsSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editElementInfoSystemsFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getElementInfoSystemsSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getElementInfoSystemsRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getElementInfoSystemsFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
