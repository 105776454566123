import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import routes from 'monitoring/routes';
import { useDispatch, useSelector } from 'react-redux';
import { generateHeaders, editStep, getStepsLogs } from 'actions';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'components/reused/DatePicker';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router';

function FundsForm({ labels, closeDialog }) {
  const step = useSelector((state) => state.m_steps.current);
  const [validation, setValidation] = useState([]);
  const { projectId } = useParams();

  const { handleSubmit, control, register } = useForm({
    defaultValues: {
      start_date: new Date(step.start_date),
      end_date: new Date(step.end_date),
    },
  });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.editStep('change-deadline', step.id),
      method: 'put',
      data: {
        start_date: data.start_date.toLocaleDateString('uz-UZ', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        end_date: data.end_date.toLocaleDateString('uz-UZ', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        comment: data.comment,
      },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editStep(data));
        dispatch(getStepsLogs(step.id)(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <DatePicker
        control={control}
        rules={{ validate: (value) => (value !== null ? undefined : 'not date') }}
        name="start_date"
        label={labels.start_date}
        className="mb2"
      />
      <DatePicker
        control={control}
        rules={{ validate: (value) => (value !== null ? undefined : 'not date') }}
        name="end_date"
        label={labels.end_date}
        className="mb"
      />
      <TextField
        name="comment"
        multiline
        rowsMax="4"
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
        inputRef={register}
        type="text"
        className="mb2"
        fullWidth
      />
      <Button color="primary" variant="contained" type="submit">
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        )}
      </Button>
    </form>
  );
}

export default FundsForm;
