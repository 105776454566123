import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import crmLogo from '../../assests/img/logo.svg';
import whiteCrmLogo from '../../assests/img/white-logo.svg';
import uzinfocomLogo from '../../assests/img/uzinfocom.svg';
import EgovLogoRu from '../../assests/img/egov-logoru.png';
import EgovLogoUz from '../../assests/img/egov-logouz.png';

import { LOCALES } from '../../lang/locales';
import { userLogin, changeLang, resetTabIndex, resetErrorMessage } from '../../actions';
import Alert from '../reused/Alert';

function SignIn(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);

  useEffect(() => {
    dispatch(resetTabIndex());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentUser) history.replace('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const requestingState = useSelector((state) => state.loginUI.requestingState, shallowEqual);
  const authError = useSelector((state) => state.login.errorMessage, shallowEqual);
  const [alertState, setAlertState] = useState(false);
  useEffect(() => {
    if (authError) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetErrorMessage());
      }, 4000);
    }
  }, [authError, dispatch]);
  const locale = useSelector((state) => state.language.locale, shallowEqual);

  const { register, handleSubmit, errors, reset } = useForm({ mode: 'onChange' });

  const onChange = (e) => {
    localStorage.setItem('lang', e.target.value);
    dispatch(changeLang(e.target.value));
  };

  const intl = useIntl();

  const onSubmit = async (data) => {
    dispatch(userLogin(data));

    reset({ password: '', username: data.username });
  };

  return (
    <>
      <div className="signInBg">
        <div className="content">
          <header className="header">
            <div className="header__container">
              <div className="logo">
                <img src={whiteCrmLogo} alt="logo" />
              </div>
              <div className="brand">
                <FormattedMessage defaultMessage="Национальная информационная система" id="signIn.nameSystem" />
              </div>
            </div>
            <select name="language" className="nativeSelect" onChange={onChange} value={locale}>
              <option value={LOCALES.RUS}>
                {intl.formatMessage({
                  id: 'signIn.languageRusOptionTitle',
                  defaultMessage: 'Русский',
                })}
              </option>
              <option value={LOCALES.UZB}>
                {intl.formatMessage({
                  id: 'signIn.languageUzbOptionTitle',
                  defaultMessage: 'Узбекский',
                })}
              </option>
            </select>
          </header>

          <div className="signIn">
            <img className="signIn__logo" src={crmLogo} alt="logo" />
            <h1 className="signIn__title">
              <FormattedMessage defaultMessage="Вход в систему" id="signIn.enterToSystem" />
            </h1>
            <form onSubmit={handleSubmit(onSubmit)} className="signIn__form">
              <input
                name="username"
                ref={register({ required: true })}
                className={`signIn__formField ${!!errors.username && 'error'}`}
                type="text"
                placeholder={intl.formatMessage({
                  id: 'signIn.signUpTitle',
                  defaultMessage: 'Логин',
                })}
              />
              {!!errors.username && (
                <div className="signIn__formError">
                  <FormattedMessage id="global.requiredFill" defaultMessage="Поле обязательно для заполнения!" />
                </div>
              )}
              <input
                name="password"
                ref={register({ required: true })}
                className={`signIn__formField ${!!errors.password && 'error'}`}
                type="password"
                placeholder={intl.formatMessage({
                  id: 'signIn.passwordTitle',
                  defaultMessage: 'Пароль',
                })}
              />
              {!!errors.password && (
                <div className="signIn__formError">
                  <FormattedMessage id="global.requiredFill" defaultMessage="Поле обязательно для заполнения!" />
                </div>
              )}
              <div className="signIn__formCheckbox">
                <input type="checkbox" id="checkbox" />
                <label htmlFor="checkbox">
                  <FormattedMessage defaultMessage="Запомнить" id="signIn.checkboxRememberTitle" />
                </label>
              </div>
              <button className="signIn__formBtn" type="submit" disabled={requestingState === 'request'}>
                {requestingState === 'request' ? (
                  <CircularProgress color="secondary" size={30} />
                ) : (
                  <FormattedMessage defaultMessage="Войти" id="signIn.buttonNameEnter" />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <footer className="footer">
        <a href="https://e-gov.uz" rel="noopener noreferrer" target="_blank" className="egov">
          {localStorage.lang === 'ru' ? (
            <img src={EgovLogoRu} alt="egovernment" className="logo" />
          ) : (
            <img src={EgovLogoUz} alt="egovernment" className="logo" />
          )}
        </a>
        <a href="tel:(55)-501-36-26" className="contact">
          <FormattedMessage
            id="contact.generalConsultation"
            defaultMessage="Общая консультация и поддержка по работе в системе – (55)-501-36-26"
          />
        </a>
        <a href="https://uzinfocom.uz" rel="noopener noreferrer" target="_blank" className="footer__copyright">
          <img src={uzinfocomLogo} alt="uzinfocom" className="logo" />
          {/* <p className="description">
            2020 || <FormattedMessage defaultMessage="Единый интегратор" id="signIn.singleIntegratorTitle" /> UZINFOCOM
          </p> */}
        </a>
      </footer>
      <Alert open={alertState} onClose={() => setAlertState(false)} message={authError} />
    </>
  );
}

export default SignIn;
