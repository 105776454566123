import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { editWebsiteInfoAccess, getSimpleAssessment } from '../../../actions';
import InputAdornment from '@material-ui/core/InputAdornment';

const Form = ({ defaultValues, submitAction }) => {
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues });
  const { labels } = useSelector((state) => state.sectionTwoWebsiteAccess, shallowEqual);
  const dispatch = useDispatch();
  const expertAccesseble = watch('percent_accessible_expert');
  const onSubmit = (data) => {
    const total = Object.values(data).reduce((acc, score) => acc + parseInt(score), 0) / 2;
    dispatch(editWebsiteInfoAccess({ ...data, percent_accessible_total: total }));
    dispatch(getSimpleAssessment(16));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        inputProps={{ min: 0, max: 100, readOnly: true }}
        name="percent_accessible_api"
        value={expertAccesseble}
        error={!!errors.percent_accessible_api}
        className="mb"
        type="number"
        label={labels.percent_accessible_api}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <i className="percent">%</i>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        inputProps={{ min: 0, max: 100 }}
        name="percent_accessible_expert"
        error={!!errors.percent_accessible_expert}
        className="mb"
        type="number"
        label={labels.percent_accessible_expert}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <i className="percent">%</i>
            </InputAdornment>
          ),
        }}
      />
      <Button className="mb" type="submit" color="primary" variant="contained">
        <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
      </Button>
    </form>
  );
};

export default Form;
