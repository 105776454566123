import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setChannelsError = createAction('CHANNELS_SET_ERROR');

export const getChannelsRequest = createAction('CHANNELS_GET_REQUEST');
export const getChannelsSuccess = createAction('CHANNELS_GET_SUCCESS');
export const getChannelsFailure = createAction('CHANNELS_GET_FAILURE');

export const getChannels = () => async (dispatch) => {
  dispatch(getChannelsRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksChannels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getChannelsSuccess(data.items));
  } catch (e) {
    dispatch(setChannelsError(e?.response));
    dispatch(getChannelsFailure());
  }
};

export const getChannelsLabelsRequest = createAction('CHANNELS_LABELS_GET_REQUEST');
export const getChannelsLabelsSuccess = createAction('CHANNELS_LABELS_GET_SUCCESS');
export const getChannelsLabelsFailure = createAction('CHANNELS_LABELS_GET_FAILURE');

export const getChannelsLabels = () => async (dispatch) => {
  dispatch(getChannelsLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksChannelsLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getChannelsLabelsSuccess(data));
  } catch (e) {
    dispatch(setChannelsError(e?.response));
    dispatch(getChannelsLabelsFailure());
  }
};

export const addChannelLinkRequest = createAction('CHANNEL_LINK_SEND_REQUEST');
export const addChannelLinkSuccess = createAction('CHANNEL_LINK_SEND_SUCCESS');
export const addChannelLinkFailure = createAction('CHANNEL_LINK_SEND_FAILURE');

export const addChannelLink = (formData) => async (dispatch) => {
  dispatch(addChannelLinkRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionTwoLinksChannels(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addChannelLinkSuccess(data));
  } catch (e) {
    dispatch(setChannelsError(e?.response));
    dispatch(addChannelLinkFailure());
  }
};

export const deleteChannelLinkRequest = createAction('CHANNEL_LINK_DELETE_REQUEST');
export const deleteChannelLinkSuccess = createAction('CHANNEL_LINK_DELETE_SUCCESS');
export const deleteChannelLinkFailure = createAction('CHANNEL_LINK_DELETE_FAILURE');

export const deleteChannelLink = (id) => async (dispatch) => {
  dispatch(deleteChannelLinkRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionTwoLinksChannelsOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteChannelLinkSuccess({ id }));
  } catch (e) {
    console.log(e);
    dispatch(setChannelsError(e?.response));
    dispatch(deleteChannelLinkFailure());
  }
};

export const editChannelLinkRequest = createAction('CHANNEL_LINK_EDIT_REQUEST');
export const editChannelLinkSuccess = createAction('CHANNEL_LINK_EDIT_SUCCESS');
export const editChannelLinkFailure = createAction('CHANNEL_LINK_EDIT_FAILURE');

export const editChannelLink = (id, formData) => async (dispatch) => {
  dispatch(editChannelLinkRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionTwoLinksChannelsOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editChannelLinkSuccess(data));
  } catch (e) {
    dispatch(setChannelsError(e?.response));
    dispatch(editChannelLinkFailure());
  }
};

export const getChannelLinkRequest = createAction('CHANNEL_LINK_GET_REQUEST');
export const getChannelLinkSuccess = createAction('CHANNEL_LINK_GET_SUCCESS');
export const getChannelLinkFailure = createAction('CHANNEL_LINK_GET_FAILURE');

export const getChannelLink = (id) => async (dispatch) => {
  dispatch(getChannelLinkRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksChannelsOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getChannelLinkSuccess(data));
  } catch (e) {
    dispatch(setChannelsError(e?.response));
    dispatch(getChannelLinkFailure());
  }
};
