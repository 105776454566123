import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { addDivisionItem } from '../../../actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { editDivisionItem } from '../../../actions/sectionOne/orgSubordinateDivision';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const { register, handleSubmit, errors } = useForm({ defaultValues, mode: 'onChange' });
  const dispatch = useDispatch();
  const intl = useIntl();

  const { id, labels, items } = useSelector((state) => state.sectionOneSubordinateDivision, shallowEqual);
  const titles = items.map((document) => document.title);

  const onSubmit = (data) => {
    if (isEditableMode) dispatch(editDivisionItem(id, data));
    else dispatch(addDivisionItem(data));
    if (submitAction) submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="title"
        className="mb"
        label={labels.title}
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = titles.includes(value);
            return !isValid;
          },
        })}
        error={!!errors.title}
        helperText={
          errors.title?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateNames',
            defaultMessage: 'Наименование документа не должно повторяться!',
          })
        }
      />
      <TextField fullWidth name="ceo_info" className="mb" label={labels.ceo_info} inputRef={register({ required: true })} error={!!errors.ceo_info} />
      <TextField
        fullWidth
        name="form_ownership"
        className="mb"
        label={labels.form_ownership}
        inputRef={register({ required: true })}
        error={!!errors.form_ownership}
      />
      <TextField
        fullWidth
        name="website"
        className="mb"
        label={labels.website}
        defaultValue="https://"
        inputRef={register({ required: true })}
        error={!!errors.website}
        type="url"
      />
      <TextField fullWidth name="contacts" className="mb" label={labels.contacts} inputRef={register({ required: true })} error={!!errors.contacts} />
      <Button variant="outlined" color="primary" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
