import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

import { useStyles, StyledTableCell, StyledTableRow } from '../reused/Table';
import { FormattedMessage } from 'react-intl';

export default function CustomizedTables({ rows, onEdit, onOpen }) {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">№</StyledTableCell>
            <StyledTableCell>
              <FormattedMessage id="section40.questionsTitle" defaultMessage="Вопросы" />
            </StyledTableCell>
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell align="center">{row.short_title}</StyledTableCell>
              <StyledTableCell>{row.title}</StyledTableCell>
              <StyledTableCell align="center">
                {isVisibleOrg && (
                  <IconButton onClick={onEdit(row.id)} color="primary" disabled>
                    <CreateIcon />
                  </IconButton>
                )}

                <IconButton onClick={onOpen(row.id)} color="primary">
                  <VisibilityIcon />
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
