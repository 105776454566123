import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import Pagination from '@material-ui/lab/Pagination';

import Dialog from '../../reused/SimpleDialog';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import { useDialog } from '../../../common/hooks';
import { getDivisionItems, getDivisionLabels, resetError } from '../../../actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteDivisionItem, getDivisionItem } from '../../../actions/sectionOne/orgSubordinateDivision';
import { ButtonGroup } from '@material-ui/core';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import Form from './Form';

export default function Section171() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const { labels, items, itemDoc, metaData } = useSelector((state) => state.sectionOneSubordinateDivision, shallowEqual);
  const finalLabels = useMemo(() => Object.values(labels), [labels]);

  const item = Object.values(itemDoc);
  const defaultValues = item.reduce((acc, el) => {
    return { ...acc, [el.code_name]: el.value };
  }, {});

  useEffect(() => {
    dispatch(getDivisionLabels());
    dispatch(getDivisionItems(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  const handleEditElement = (id) => async () => {
    handleOpenEdit();
    await dispatch(getDivisionItem(id));
  };
  const handleDeleteElement = (id) => () => dispatch(deleteDivisionItem(id));

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestItemState, requestLabelState } = useSelector((state) => state.sectionOneSubordinateDivisionUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneSubordinateDivision.error, shallowEqual);

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getDivisionItems(value));
  };

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);
  
      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer marginsTitle">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>
      <TableContainer component={Paper} className={classes.container}>
        {requestLabelState === 'request' ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {isVisible && <StyledTableCell />}
                  <StyledTableCell>№</StyledTableCell>
                {finalLabels.map((label) => (
                  <StyledTableCell key={label}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TransitionGroup component={TableBody}>
              {items.map((item, ind) => (
                <CSSTransition key={item.id} timeout={1200} classNames="listItemUI">
                  <StyledTableRow>
                    {isVisible && (
                      <StyledTableCell>
                        <ButtonGroup orientation="vertical" variant="contained" color="primary">
                          <Button onClick={handleEditElement(item.id)}>
                            <CreateIcon />
                          </Button>
                          <Button onClick={handleDeleteElement(item.id)} color="secondary">
                            <Delete />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>{(page - 1) * 20 + ind + 1}</StyledTableCell>
                    <StyledTableCell>{item.title}</StyledTableCell>
                    <StyledTableCell>{item.ceo_info}</StyledTableCell>
                    <StyledTableCell>{item.form_ownership}</StyledTableCell>
                    <StyledTableCell>{item.website}</StyledTableCell>
                    <StyledTableCell>{item.contacts}</StyledTableCell>
                  </StyledTableRow>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Table>
        )}
      </TableContainer>
      {isVisible && (
        <Button onClick={handleOpenAdd} variant="contained" color="primary" className="m0Auto">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}
      {metaData.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt4">
          <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
        </div>
      )}
      <Dialog
        dialogState={dialogStateAdd}
        onClose={handleCloseAdd}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleCloseAdd} />
      </Dialog>
      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        {requestItemState === 'request' ? <Loader /> : <Form defaultValues={defaultValues} submitAction={handleCloseEdit} isEditableMode />}
      </Dialog>
      <Alert open={alertState} onClose={() => setAlertState(false)} message={intl.formatMessage({
          id: 'global.errorDublicateNames',
          defaultMessage: 'Наименование документа не должно повторяться!',
        })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
    </>
  );
}
