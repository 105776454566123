import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { editEpiguStats } from '../../../actions';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues });
  const labels = useSelector((state) => state.sectionThreeEpiguStats.labels, shallowEqual);
  const allRequests = watch('all_requests');
  const onSubmit = (data) => {
    dispatch(editEpiguStats({ ...data, organization_id: localStorage.orgId, section_id: 33 }));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="point_epigu"
        inputRef={register({ required: true })}
        inputProps={{ max: 5, min: 0 }}
        error={!!errors.point_epigu}
        type="number"
        className="mb"
        label={labels.point_epigu}
      />
      <TextField
        fullWidth
        name="all_requests"
        inputRef={register({ required: true })}
        error={!!errors.all_requests}
        type="number"
        className="mb"
        label={labels.all_requests}
      />
      <TextField
        fullWidth
        name="overdue_requests"
        inputRef={register({ required: true })}
        inputProps={{ min: 0, max: parseInt(allRequests) }}
        error={!!errors.overdue_requests}
        type="number"
        className="mb"
        label={labels.overdue_requests}
      />
      <Button variant="contained" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
