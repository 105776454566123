import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const profile = handleActions(
  {
    [actions.getProfileSuccess]: (_, { payload }) => ({ currentUser: payload }),
    [actions.getProfileFailure]: (state, { payload }) => {
      if (payload?.status === 401) {
        localStorage.removeItem('token');
        return { currentUser: null };
      } else return state;
    },
    [actions.logoutRequest]: () => {
      return { currentUser: null };
    },
  },
  {
    currentUser: null,
  }
);

export const profileUI = handleActions(
  {
    [actions.getProfileRequest]: () => ({ requestingState: 'request' }),
    [actions.getProfileSuccess]: () => ({ requestingState: 'success' }),
    [actions.getProfileFailure]: () => ({ requestingState: 'failure' }),
  },
  {}
);
