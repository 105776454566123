/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TextField } from '@material-ui/core';

import { useStyles, StyledTableRow, StyledTableCell } from '../reused/Table';
import Loader from '../reused/Loader';
import Alert from '../reused/Alert';
import { getEmployeesStat, postEmployeesStat } from '../../actions';
import { isEmpty } from '../../common/utils';
import Error from '../reused/Error';

export default function Section18() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { labels, data } = useSelector((state) => state.sectionOneEmployeesStat, shallowEqual);
  useEffect(() => {
    dispatch(getEmployeesStat());
  }, []);

  useEffect(() => {
    if (!isEmpty(defaultValues)) reset(defaultValues);
  }, [data]);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const defaultValues = useMemo(
    () =>
      data[0]?.children.concat(data[1]?.children).reduce(
        (acc, el) => ({
          ...acc,
          [el.meta_label_id]: { count_by_plan: el.count_by_plan, count_by_fact: el.count_by_fact, count_vacancy: el.count_vacancy },
        }),
        {}
      ),
    [data[0]]
  );

  const { register, handleSubmit, errors, reset, watch } = useForm({ defaultValues });
  const [isEditMode, setIsEditMode] = useState(false);
  const classes = useStyles();

  const fields = watch();
  const [alertState, setAlertState] = useState(false);

  const hasWarn = Object.keys(fields)
    .map((id) => {
      const isLess = parseInt(fields[id].count_vacancy) < 0;
      return isLess ? true : false;
    })
    .find((el) => el);
  useEffect(() => {
    if (isEditMode) {
      setAlertState(hasWarn);

      setTimeout(() => {
        setAlertState(false);
      }, 4000);
    }
  }, [hasWarn]);

  useEffect(() => {
    if (isEditMode) setAlertState(hasWarn);
  }, [fields]);

  const onSubmit = (data) => {
    const finalData = Object.keys(data).map((id) => ({
      meta_label_id: id,
      ...data[id],
    }));
    dispatch(postEmployeesStat(finalData));
    setIsEditMode(false);
  };

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestState } = useSelector((state) => state.sectionOneEmployeesStatUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneEmployeesStat.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="spaceBetween mb3">
        <div className="titleContainer">
          <h2 className="title" style={{ margin: 0, padding: '15px' }}>
            {sectionTabs[selectedTabIndex]?.title}
          </h2>
        </div>
        {isVisible && isEditMode && (
          <Button variant="contained" color="primary" type="submit">
            <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
          </Button>
        )}
        {isVisible && !isEditMode && (
          <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
            <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
          </Button>
        )}
      </div>
      <TableContainer component={Paper} className={classes.container}>
        {requestState === 'request' ? (
          <Loader />
        ) : (
          <>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  {labels.map((label) => (
                    <StyledTableCell key={label} align="center">
                      {label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => {
                  return (
                    <React.Fragment key={row.meta_label_id}>
                      <StyledTableRow>
                        <StyledTableCell align="center" colSpan="4">
                          {row.title}
                        </StyledTableCell>
                      </StyledTableRow>
                      {row.children.map((row) => {
                        if (isEditMode) {
                          const error = !!errors[row.meta_label_id]?.count_by_plan;
                          const error2 = !!errors[row.meta_label_id]?.count_by_fact;
                          return (
                            <StyledTableRow key={row.meta_label_id}>
                              <StyledTableCell align="justify">{row.title}</StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  inputRef={register({ required: true })}
                                  inputProps={{ min: 0 }}
                                  type="number"
                                  name={`${row.meta_label_id}[count_by_plan]`}
                                  error={error}
                                  variant="outlined"
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  inputRef={register({ required: true })}
                                  inputProps={{ min: 0 }}
                                  type="number"
                                  name={`${row.meta_label_id}[count_by_fact]`}
                                  error={error2}
                                  variant="outlined"
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <TextField
                                  inputRef={register({ required: true })}
                                  inputProps={{ readOnly: true }}
                                  type="number"
                                  name={`${row.meta_label_id}[count_vacancy]`}
                                  value={fields[row.meta_label_id]?.count_by_plan - fields[row.meta_label_id]?.count_by_fact}
                                />
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }

                        return (
                          <StyledTableRow key={row.meta_label_id}>
                            <StyledTableCell align="justify">{row.title}</StyledTableCell>
                            <StyledTableCell align="center">{row.count_by_plan}</StyledTableCell>
                            <StyledTableCell align="center">{row.count_by_fact}</StyledTableCell>
                            <StyledTableCell align="center">{row.count_vacancy}</StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </>
        )}
      </TableContainer>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'section18.errorComputationVacancy',
          defaultMessage: 'Сообщение',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </form>
  );
}
