import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormattedMessage } from 'react-intl';
import { host } from 'routes';

function Download({ fileName, className }) {
  return (
    <a href={`${host}/${fileName}`} className="btn mt">
      <div className={`${className} btn__fileUpload`}>
        <div className="btn-wrap">
          <label className="btn mb">
            <FormattedMessage id="global.downloadFileTitle" defaultMessage="Скачать файл" />
            &nbsp;
            <GetAppIcon />
          </label>
        </div>
      </div>
    </a>
  );
}

export default Download;
