import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const sectionSixEvaluation = handleActions(
  {
    [actions.getSectionSixSimpleMarkSuccess]: (state, { payload }) => {
      return {
        ...state,
        mark: payload.mark,
        markLabels: payload.labels,
      };
    },
    [actions.saveSectionSixSimpleMarkSuccess]: (state, { payload }) => ({ ...state, mark: payload }),
    [actions.deleteSectionSixSimpleMarkSuccess]: (state, { payload }) => ({ ...state, mark: null }),
    [actions.getSectionSixSimpleAssessmentSuccess]: (state, { payload }) => ({
      ...state,
      assessment: payload.assessment,
      assessmentLabels: payload.labels,
    }),
    [actions.deleteSectionSixSimpleAssessmentSuccess]: (state) => ({ ...state, assessment: [null] }),
    [actions.getSectionSixEvaluationSuccess]: (state, { payload }) => ({ ...state, firstEval: payload.firstEval, secondEval: payload.secondEval }),
  },
  {
    mark: null,
    markLabels: {},
    assessment: [null],
    assessmentLabels: {},
    firstEval: [],
    secondEval: [],
  }
);
