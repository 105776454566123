import React from 'react';
import NoData from 'assests/img/no-data.svg';

function Empty({ title = 'No Data' }) {
  return (
    <div className="noData">
      <img src={NoData} alt="noData" className="noData-thumb" />
      <p className="noData-title">{title}</p>
    </div>
  );
}

export default Empty;
