import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';
import { postSpendingStatSuccess } from '../sectionEight/spendingStat';

export const setComputersError = createAction('COMPUTERS_SET_ERROR');

export const getComputersInfoRequest = createAction('COMPUTERS_INFO_GET_REQUEST');
export const getComputersInfoSuccess = createAction('COMPUTERS_INFO_GET_SUCCESS');
export const getComputersInfoFailure = createAction('COMPUTERS_INFO_GET_FAILURE');

export const getComputersInfo = () => async (dispatch) => {
  dispatch(getComputersInfoRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSevenComputersInfo()}/view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(`${routes.sectionSevenComputersInfo()}/labels`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    dispatch(getComputersInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setComputersError(e?.response));
  }
};

export const postComputersInfoRequest = createAction('COMPUTERS_INFO_POST_REQUEST');
export const postComputersInfoSuccess = createAction('COMPUTERS_INFO_POST_SUCCESS');
export const postComputersInfoFailure = createAction('COMPUTERS_INFO_POST_FAILURE');

export const postComputersInfo = (formValues) => async (dispatch) => {
  dispatch(postComputersInfoRequest());
  try {
    await axios({
      method: 'post',
      url: `${routes.sectionSevenComputersInfo()}/update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getComputersInfo());
    dispatch(postSpendingStatSuccess());
  } catch (e) {
    dispatch(setComputersError(e?.response));
    dispatch(postComputersInfoFailure());
  }
};
