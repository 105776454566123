import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setDivisionError = createAction('DIVISION_SET_ERROR');

export const getDivisionLabelsRequest = createAction('DIVISION_LABELS_GET_REQUEST');
export const getDivisionLabelsSuccess = createAction('DIVISION_LABELS_GET_SUCCESS');
export const getDivisionLabelsFailure = createAction('DIVISION_LABELS_GET_FAILURE');

export const getDivisionLabels = () => async (dispatch) => {
  dispatch(getDivisionLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionOneSubordinateDivisionLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDivisionLabelsSuccess(data));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};

export const getDivisionItemsRequest = createAction('DIVISION_ITEMS_GET_REQUEST');
export const getDivisionItemsSuccess = createAction('DIVISION_ITEMS_GET_SUCCESS');
export const getDivisionItemsFailure = createAction('DIVISION_ITEMS_GET_FAILURE');

export const getDivisionItems = (page) => async (dispatch) => {
  dispatch(getDivisionItemsRequest());

  try {
    const { data } = await axios.get(routes.sectionOneSubordinateDivisionData(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });
    dispatch(getDivisionItemsSuccess(data));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};

export const addDivisionItemRequest = createAction('DIVISION_ITEM_ADD_REQUEST');
export const addDivisionItemSuccess = createAction('DIVISION_ITEM_ADD_SUCCESS');
export const addDivisionItemFailure = createAction('DIVISION_ITEM_ADD_FAILURE');

export const addDivisionItem = (formData) => async (dispatch) => {
  dispatch(addDivisionItemRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionOneSubordinateDivisionData(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addDivisionItemSuccess(data));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};

export const deleteDivisionItemRequest = createAction('DIVISION_ITEM_DELETE_REQUEST');
export const deleteDivisionItemSuccess = createAction('DIVISION_ITEM_DELETE_SUCCESS');
export const deleteDivisionItemFailure = createAction('DIVISION_ITEM_DELETE_FAILURE');

export const deleteDivisionItem = (id) => async (dispatch) => {
  dispatch(deleteDivisionItemRequest());

  try {
    await axios({
      method: 'delete',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      url: routes.sectionOneSubordinateDivisionDataOperation(id),
    });
    dispatch(deleteDivisionItemSuccess({ id }));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};

export const editDivisionItemRequest = createAction('DIVISION_ITEM_EDIT_REQUEST');
export const editDivisionItemSuccess = createAction('DIVISION_ITEM_EDIT_SUCCESS');
export const editDivisionItemFailure = createAction('DIVISION_ITEM_EDIT_FAILURE');

export const editDivisionItem = (id, formData) => async (dispatch) => {
  dispatch(editDivisionItemRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionOneSubordinateDivisionDataOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editDivisionItemSuccess(data));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};

export const getDivisionItemRequest = createAction('DIVISION_ITEM_GET_REQUEST');
export const getDivisionItemSuccess = createAction('DIVISION_ITEM_GET_SUCCESS');
export const getDivisionItemFailure = createAction('DIVISION_ITEM_GET_FAILURE');

export const getDivisionItem = (id) => async (dispatch) => {
  dispatch(getDivisionItemRequest());

  try {
    const { data } = await axios.get(routes.sectionOneSubordinateDivisionDataOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDivisionItemSuccess({ id, data }));
  } catch (e) {
    dispatch(setDivisionError(e?.response));
    console.log(e);
  }
};
