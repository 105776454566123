import Layout from 'components/reused/Layout';
import Support from 'components/reused/Support';
import Archive from 'components/pages/Archive';
import OrganizationList from 'components/pages/OrganizationList';
import NotFound from 'components/pages/NotFound';
import Home from 'monitoring/Layout';
import Statistics from '../components/pages/Statistics';

import Section13 from 'components/sections/section13';
import Section16 from 'components/sections/section16';
import Section17 from 'components/sections/section17';
import Section18 from 'components/sections/Section18';
import Section19 from 'components/sections/section19';
import Section110 from 'components/sections/section110';
import Section21 from 'components/sections/section21';
import Section22 from 'components/sections/section22';
import Section23 from 'components/sections/section23';
import Section24 from 'components/sections/Section24';
import Section25 from 'components/sections/section25';
import Section26 from 'components/sections/section26';
import Section27 from 'components/sections/section27';
import Section28 from 'components/sections/section28';
import Section29 from 'components/sections/section29';
import Section210 from 'components/sections/section210';
import Section211 from 'components/sections/section211';
import Section31 from 'components/sections/section31';
import Section32 from 'components/sections/section32';
import Section33 from 'components/sections/section33';
import { Section41, Section42, Section43 } from 'components/sections/Section40';
import Section51 from 'components/sections/section51';
import Section52 from 'components/sections/section52';
import Section61 from 'components/sections/section61';
import Section62 from 'components/sections/section62';
import Section63 from 'components/sections/section63';
import Section64 from 'components/sections/section64';
import Section71 from 'components/sections/Section71';
import Section72 from 'components/sections/section72';
import Section81 from 'components/sections/Section81';
import Section82 from 'components/sections/Section82';

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/organizations',
    component: OrganizationList,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '/archive',
    component: Archive,
  },
  {
    path: '/statistics',
    component: Statistics,
  },
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/section-id-1',
    component: Layout,
    routes: [
      {
        path: '/section-id-1/section13',
        component: Section13,
      },
      {
        path: '/section-id-1/section16',
        component: Section16,
      },
      {
        path: '/section-id-1/section17',
        component: Section17,
      },
      {
        path: '/section-id-1/section18',
        component: Section18,
      },
      {
        path: '/section-id-1/section19',
        component: Section19,
      },
      {
        path: '/section-id-1/section110',
        component: Section110,
      },
    ],
  },
  {
    path: '/section-id-13',
    component: Layout,
    routes: [
      {
        path: '/section-id-13/section21',
        component: Section21,
      },
      {
        path: '/section-id-13/section22',
        component: Section22,
      },
      {
        path: '/section-id-13/section23',
        component: Section23,
      },
      {
        path: '/section-id-13/section24',
        component: Section24,
      },
      {
        path: '/section-id-13/section25',
        component: Section25,
      },
      {
        path: '/section-id-13/section26',
        component: Section26,
      },
      {
        path: '/section-id-13/section27',
        component: Section27,
      },
      {
        path: '/section-id-13/section28',
        component: Section28,
      },
      {
        path: '/section-id-13/section29',
        component: Section29,
      },
      {
        path: '/section-id-13/section210',
        component: Section210,
      },
      {
        path: '/section-id-13/section211',
        component: Section211,
      },
    ],
  },
  {
    path: '/section-id-30',
    component: Layout,
    routes: [
      {
        path: '/section-id-30/section31',
        component: Section31,
      },
      {
        path: '/section-id-30/section32',
        component: Section32,
      },
      {
        path: '/section-id-30/section33',
        component: Section33,
      },
    ],
  },
  {
    path: '/section-id-34',
    component: Layout,
    routes: [
      {
        path: '/section-id-34/section41',
        component: Section41,
      },
      {
        path: '/section-id-34/section42',
        component: Section42,
      },
      {
        path: '/section-id-34/section43',
        component: Section43,
      },
    ],
  },
  {
    path: '/section-id-167',
    component: Layout,
    routes: [
      {
        path: '/section-id-167/section51',
        component: Section51,
      },
      {
        path: '/section-id-167/section52',
        component: Section52,
      },
    ],
  },
  {
    path: '/section-id-169',
    component: Layout,
    routes: [
      {
        path: '/section-id-169/section61',
        component: Section61,
      },
      {
        path: '/section-id-169/section62',
        component: Section62,
      },
      {
        path: '/section-id-169/section63',
        component: Section63,
      },
      {
        path: '/section-id-169/section64',
        component: Section64,
      },
    ],
  },
  {
    path: '/section-id-174',
    component: Layout,
    routes: [
      {
        path: '/section-id-174/section71',
        component: Section71,
      },
      {
        path: '/section-id-174/section72',
        component: Section72,
      },
    ],
  },
  {
    path: '/section-id-177',
    component: Layout,
    routes: [
      {
        path: '/section-id-177/section81',
        component: Section81,
      },
      {
        path: '/section-id-177/section82',
        component: Section82,
      },
    ],
  },
];
