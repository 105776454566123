import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const websiteApps = handleActions(
  {
    [actions.getWebsiteAppsInfoSuccess]: (state, { payload }) => ({
      ...state,
      websiteApps: payload.data,
      labels: payload.labels,
    }),
    [actions.setWebsiteAppsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    websiteApps: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const websiteAppsUI = handleActions(
  {
    [actions.getWebsiteAppsInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getWebsiteAppsInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getWebsiteAppsInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.editWebsiteAppsInfoRequest]: () => ({ requestState: 'request' }),
    [actions.editWebsiteAppsInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.editWebsiteAppsInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
