import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, FormControlLabel, FormHelperText, Radio, RadioGroup, TextField } from '@material-ui/core';

import { editInfoDivision, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues }) => {
  const _defaultValues = defaultValues.ict_structure_id
    ? { ...defaultValues, ...fileDefaultValues }
    : { ...defaultValues, ict_structure_id: 5 };

  const intl = useIntl();

  const dispatch = useDispatch();
  const labels = useSelector((state) => state.sectionSixInfoIctDivisions.labels, shallowEqual);
  const { divisionTypes, orgFormTypes } = useSelector((state) => state.dictionary, shallowEqual);

  const { register, handleSubmit, errors, control, watch } = useForm({ defaultValues: _defaultValues });
  const watchChangeValues = watch();
  const totalCount =
    parseInt(watchChangeValues.count_staff_central) +
    parseInt(watchChangeValues.count_staff_territorial) +
    parseInt(watchChangeValues.count_staff_subdivision);

  const getFile = async (data, fileName) => {
    if (data[fileName] === undefined) return '';
    return defaultValues[fileName] && data[fileName].length === 0
      ? `${defaultValues[fileName]}`
      : await uploadFile(data[fileName], 6.3, fileName);
  };

  const onSubmit = async (data) => {
    if (data.ict_structure_id === '5') {
      const resetValues = reset(labels);
      dispatch(
        editInfoDivision({
          ...resetValues,
          reason_text: data.reason_text,
          ict_structure_id: data.ict_structure_id,
        })
      );
      return submitAction();
    }

    const finalData = {
      ...data,
      organization_id: localStorage.orgId,
      count_staff_total:
        parseInt(data.count_staff_central) +
        parseInt(data.count_staff_subdivision) +
        parseInt(data.count_staff_territorial),
      outsourcing_reports_file: await getFile(data, 'outsourcing_reports_file'),
      outsourcing_work_plan_file: await getFile(data, 'outsourcing_work_plan_file'),
      outsourcing_services_document_file: await getFile(data, 'outsourcing_services_document_file'),
      financial_motivations_file: await getFile(data, 'financial_motivations_file'),
      work_plan_file: await getFile(data, 'work_plan_file'),
      staff_certificates_file: await getFile(data, 'staff_certificates_file'),
      staff_placement_specialists_file: await getFile(data, 'staff_placement_specialists_file'),
      divisions_structure_file: await getFile(data, 'divisions_structure_file'),
      normative_document_file: await getFile(data, 'normative_document_file'),
    };

    submitAction();
    dispatch(editInfoDivision(finalData));
  };
  return (
    <form className="outsourcingForm w80 mAuto mt10" onSubmit={handleSubmit(onSubmit)}>
      <div className="w50">
        <div className="outsourcingForm__field mb">
          <FormControl fullWidth>
            <Select
              inputProps={{ ref: register({ required: true }) }}
              error={!!errors.ict_structure_id}
              name="ict_structure_id"
              native>
              {divisionTypes.map((el) => (
                <option key={el.id} value={el.id}>
                  {el.title}
                </option>
              ))}
            </Select>
            <FormHelperText error={!!errors.ict_structure_id}>{labels.ict_structure_id}</FormHelperText>
          </FormControl>
        </div>
        {`${watchChangeValues?.ict_structure_id}` === '5' && (
          <>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                name="reason_text"
                inputRef={register({ required: true })}
                error={!!errors.reason_text}
                helperText={labels.reason_text}
              />
            </div>
          </>
        )}

        {`${watchChangeValues.ict_structure_id}` !== '5' && (
          <>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                name="title"
                inputRef={register({ required: true })}
                error={!!errors.title}
                helperText={labels.title}
              />
            </div>
            <div className="outsourcingForm__field mb">
              <FormControl fullWidth>
                <Select
                  inputProps={{ ref: register({ required: true }) }}
                  error={!!errors.organization_form_id}
                  name="organization_form_id"
                  native>
                  {orgFormTypes.map((el) => (
                    <option key={el.id} value={el.id}>
                      {el.title}
                    </option>
                  ))}
                </Select>
                <FormHelperText error={!!errors.organization_id}>{labels.organization_form_id}</FormHelperText>
              </FormControl>
            </div>

            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="full_name"
                error={!!errors.full_name}
                helperText={labels.full_name}
              />
            </div>

            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="position_name"
                error={!!errors.position_name}
                helperText={labels.position_name}
              />
            </div>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="office_phone"
                error={!!errors.office_phone}
                helperText={labels.office_phone}
              />
            </div>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="mobile_phone"
                error={!!errors.mobile_phone}
                helperText={labels.mobile_phone}
              />
            </div>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="email"
                error={!!errors.email}
                helperText={labels.email}
              />
            </div>
            <div className="outsourcingForm__field mb">
              <label htmlFor="has_approved_head" className={`nativeLabel ${!!errors.has_approved_head && 'error'}`}>
                {labels.has_approved_head}
              </label>
              <Controller
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessaage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessaage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="has_approved_head"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
            </div>
            <div className="outsourcingForm__field mb">
              <label
                htmlFor="has_normative_document"
                className={`nativeLabel ${!!errors.has_normative_document && 'error'}`}>
                {labels.has_normative_document}
              </label>
              <Controller
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessaage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessaage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="has_normative_document"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_normative_document === '1' && (
                <div>
                  <p className="nativeLabel">{labels.normative_document_file}</p>
                  <UploadFile
                    error={!!errors.normative_document_file}
                    register={register({ required: !defaultValues.normative_document_file })}
                    name="normative_document_file"
                    id="upload_normative_document_file"
                  />
                </div>
              )}
            </div>
            <div className="outsourcingForm__field mb">
              <label
                htmlFor="is_approved_document"
                className={`nativeLabel ${!!errors.is_approved_document && 'error'}`}>
                {labels.is_approved_document}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="is_approved_document"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
            </div>
            <div className="outsourcingForm__field mb">
              <TextField
                fullWidth
                inputRef={register}
                inputProps={{ readOnly: true }}
                name="count_staff_total"
                className="outsourcingForm__field mb"
                helperText={labels.count_staff_total}
                value={
                  totalCount
                    ? totalCount
                    : intl.formatMessage({
                        id: 'section63.enterNum',
                        defaultMessage: 'Введите число для подсчёта',
                      })
                }
              />
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_staff_central"
                error={!!errors.count_staff_central}
                className="outsourcingForm__field mb"
                helperText={labels.count_staff_central}
                type="number"
              />
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_staff_territorial"
                error={!!errors.count_staff_territorial}
                className="outsourcingForm__field mb"
                helperText={labels.count_staff_territorial}
                type="number"
              />
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_staff_subdivision"
                error={!!errors.count_staff_subdivision}
                className="outsourcingForm__field mb"
                helperText={labels.count_staff_subdivision}
                type="number"
              />
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_staff_security_info"
                error={!!errors.count_staff_security_info}
                className="outsourcingForm__field mb"
                helperText={labels.count_staff_security_info}
                type="number"
              />
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_stuff_db"
                error={!!errors.count_stuff_db}
                className="outsourcingForm__field mb"
                helperText={labels.count_stuff_db}
                type="number"
              />
            </div>
            <div className="outsourcingForm__field mb">
              <FormControl>
                <label
                  htmlFor="divisions_structure_file"
                  className={`nativeLabel ${!!errors.divisions_structure_file && 'error'}`}>
                  {labels.divisions_structure_file}
                </label>
                <UploadFile
                  error={!!errors.divisions_structure_file}
                  register={register({ required: !defaultValues.divisions_structure_file })}
                  name="divisions_structure_file"
                  id="upload_divisions_structure_file"
                  classNames={['mt']}
                />
              </FormControl>
            </div>
          </>
        )}
      </div>
      {`${watchChangeValues.ict_structure_id}` !== '5' && (
        <>
          <div className="w50">
            <div className="outsourcingForm__field mb">
              <FormControl>
                <label
                  htmlFor="staff_placement_specialists_file"
                  className={`nativeLabel ${!!errors.staff_placement_specialists_file && 'error'}`}>
                  {labels.staff_placement_specialists_file}
                </label>
                <UploadFile
                  error={!!errors.staff_placement_specialists_file}
                  register={register({ required: !defaultValues.staff_placement_specialists_file })}
                  name="staff_placement_specialists_file"
                  id="upload_staff_placement_specialists_file"
                  classNames={['mt']}
                />
              </FormControl>
            </div>
            <div className="outsourcingForm__field mb">
              <FormControl>
                <label
                  htmlFor="staff_certificates_file"
                  className={`nativeLabel ${!!errors.staff_certificates_file && 'error'}`}>
                  {labels.staff_certificates_file}
                </label>
                <UploadFile
                  error={!!errors.staff_certificates_file}
                  register={register({ required: !defaultValues.staff_certificates_file })}
                  name="staff_certificates_file"
                  id="upload_staff_certificates_file"
                  classNames={['mt']}
                />
              </FormControl>
            </div>
            <div className="mb">
              <label
                htmlFor="is_send_staff_objectives"
                className={`nativeLabel ${!!errors.is_send_staff_objectives && 'error'}`}>
                {labels.is_send_staff_objectives}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="is_send_staff_objectives"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
            </div>
            <div className="mb">
              <label htmlFor="has_work_plan" className={`nativeLabel ${!!errors.has_work_plan && 'error'}`}>
                {labels.has_work_plan}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="has_work_plan"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_work_plan === '1' && (
                <UploadFile
                  error={!!errors.work_plan_file}
                  register={register({ required: !defaultValues.work_plan_file })}
                  name="work_plan_file"
                  id="upload_work_plan_file"
                />
              )}
            </div>
            <div className="mb">
              <label
                htmlFor="has_financial_motivations_doc"
                className={`nativeLabel ${!!errors.has_financial_motivations_doc && 'error'}`}>
                {labels.has_financial_motivations_doc}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="has_financial_motivations_doc"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_financial_motivations_doc === '1' && (
                <UploadFile
                  error={!!errors.financial_motivations_file}
                  register={register({ required: !defaultValues.financial_motivations_file })}
                  name="financial_motivations_file"
                  id="upload_financial_motivations_file"
                />
              )}
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register}
                inputProps={{ readOnly: true }}
                name="amount_funds_provided"
                type="number"
                helperText={labels.amount_funds_provided}
              />
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="amount_funds_disbursed_old"
                error={!!errors.amount_funds_disbursed_old}
                type="number"
                helperText={labels.amount_funds_disbursed_old}
              />
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register}
                inputProps={{ readOnly: true }}
                name="amount_funds_allocated"
                type="number"
                helperText={labels.amount_funds_allocated}
              />
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register}
                inputProps={{ readOnly: true }}
                name="amount_funds_disbursed"
                type="number"
                helperText={labels.amount_funds_disbursed}
              />
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="amount_funds_plan"
                error={!!errors.amount_funds_plan}
                type="number"
                helperText={labels.amount_funds_plan}
              />
            </div>

            {`${watchChangeValues?.ict_structure_id}` === '8' && (
              <div className="mb">
                <TextField
                  fullWidth
                  inputRef={register}
                  inputProps={{ readOnly: true }}
                  name="amount_outsourcing_funds_allocated"
                  helperText={labels.amount_outsourcing_funds_allocated}
                />
              </div>
            )}

            <div className="mb">
              <label
                htmlFor="has_outsourcing_services_doc"
                className={`nativeLabel ${!!errors.has_outsourcing_services_doc && 'error'}`}>
                {labels.has_outsourcing_services_doc}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="has_outsourcing_services_doc"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_outsourcing_services_doc === '1' && (
                <UploadFile
                  error={!!errors.outsourcing_services_document_file}
                  register={register({ required: !defaultValues.outsourcing_services_document_file })}
                  name="outsourcing_services_document_file"
                  id="upload_outsourcing_services_document_file"
                />
              )}
            </div>
            <div className="mb">
              <TextField
                fullWidth
                inputRef={register({ required: true })}
                name="count_outsourcing_staff"
                error={!!errors.count_outsourcing_staff}
                helperText={labels.count_outsourcing_staff}
              />
            </div>
            <div className="mb">
              <label
                htmlFor="has_outsourcing_work_plan"
                className={`nativeLabel ${!!errors.has_outsourcing_work_plan && 'error'}`}>
                {labels.has_outsourcing_work_plan}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="has_outsourcing_work_plan"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_outsourcing_work_plan === '1' && (
                <UploadFile
                  error={!!errors.outsourcing_work_plan_file}
                  register={register({ required: !defaultValues.outsourcing_work_plan_file })}
                  name="outsourcing_work_plan_file"
                  id="upload_outsourcing_work_plan_file"
                />
              )}
            </div>
            <div className="mb">
              <label
                htmlFor="has_outsourcing_reports"
                className={`nativeLabel ${!!errors.has_outsourcing_reports && 'error'}`}>
                {labels.has_outsourcing_reports}
              </label>
              <Controller
                as={<RadioGroup row>{yesNo}</RadioGroup>}
                name="has_outsourcing_reports"
                control={control}
                rules={{ required: true }}
                defaultValue=""
              />
              {watchChangeValues.has_outsourcing_reports === '1' && (
                <UploadFile
                  error={!!errors.outsourcing_reports_file}
                  register={register({ required: !defaultValues.outsourcing_reports_file })}
                  name="outsourcing_reports_file"
                  id="upload_outsourcing_reports_file"
                />
              )}
            </div>
          </div>
        </>
      )}
      <Button variant="contained" color="primary" className="m0Auto outsourcingForm__submitBtn mb" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;

const yesNo = (
  <>
    <FormControlLabel
      value="1"
      control={<Radio color="primary" />}
      label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
    />
    <FormControlLabel
      value="0"
      control={<Radio color="primary" />}
      label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
    />
  </>
);

const reset = (data) =>
  Object.keys(data)
    .filter((key) => !['is_correctly', 'comment', 'ict_structure_id', 'reason_text', 'point'].includes(key))
    .reduce(
      (acc, el) => {
        if (conditionFields.includes(el)) return { ...acc, [el]: null };
        return { ...acc, [el]: '' };
      },
      { organization_id: localStorage.orgId }
    );

const fileDefaultValues = {
  outsourcing_reports_file: '',
  outsourcing_work_plan_file: '',
  outsourcing_services_document_file: '',
  financial_motivations_file: '',
  work_plan_file: '',
  staff_certificates_file: '',
  staff_placement_specialists_file: '',
  divisions_structure_file: '',
  normative_document_file: '',
};

const conditionFields = [
  'has_approved_head',
  'has_normative_document',
  'is_approved_document',
  'is_send_staff_objectives',
  'has_work_plan',
  'has_financial_motivations_doc',
  'has_outsourcing_services_doc',
  'has_outsourcing_work_plan',
];
