import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import fileDownload from 'js-file-download';
import axios from 'axios';
import HistoryIcon from '@material-ui/icons/History';
import BusinessIcon from '@material-ui/icons/Business';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CreateIcon from '@material-ui/icons/Create';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableContainer from '@material-ui/core/TableContainer';
import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core';

import { StyledTableRow, useStyles } from 'components/reused/Table';
import Dialog from 'components/reused/SimpleDialog';
import { useDialog } from 'common/hooks';
import { getProject, getLabels, get, addDrawerItem, removeDrawerItem, getRoadmap } from 'actions';
import Loader from 'components/reused/Loader';
import FileUploader from 'monitoring/FileUploader';
import StatusForm from './DialogForms/StatusForm';
import OrganizationForm from './DialogForms/OrganizationForm';
import ForecastForm from './DialogForms/ForecastForm';
import CostEffectivenessForm from './DialogForms/CostEffectivenessForm';
import ProblemsForm from './DialogForms/ProblemsForm';
import routes from '../routes';
import ModeratorForm from './DialogForms/ModeratorForm';
import ProjectForm from './DialogForms/ProjectForm';
import StepList from 'monitoring/Step/StepList';
import Log from 'monitoring/Lists/Logs';
import { getProjectLogs } from 'actions/monitoring';
import { generateHeaders } from '../../actions';

function Project() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [labels, setLabels] = useState({});
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    dispatch(getProject(projectId));
    let active = true;
    get('organizations').then((data) => {
      if (active) {
        setOrganizations(data);
      }
    });
    getLabels('project').then((data) => {
      if (active) setLabels(data);
    });
    return () => (active = false);
  }, []);

  const [statusForm, openStatusForm, closeStatusForm] = useDialog();
  const [orgForm, openOrgForm, closeOrgForm] = useDialog();
  const [forecastForm, openForecastForm, closeForecastForm] = useDialog();
  const [effectivenessForm, openEffectivenessForm, closeEffectivenessForm] = useDialog();
  const [problemsForm, openProblemsForm, closeProblemsForm] = useDialog();
  const [cancelForm, openCancelForm, closeCancelForm] = useDialog();
  const [approvalForm, openApprovalForm, closeApprovalForm] = useDialog();
  const [refuseForm, openRefuseForm, closeRefuseForm] = useDialog();
  const [projectForm, openProjectForm, closeProjectForm] = useDialog();

  const currentUser = useSelector((state) => state.profile.currentUser);

  const isSupervisor = currentUser?.roles.includes('monitoring_supervisor');
  const isModerator = currentUser?.roles.includes('monitoring_moderator');
  const isBothSuperRole = isSupervisor || isModerator;

  const isOrganization = currentUser?.roles.includes('monitoring_organization');
  const orgId =
    currentUser && currentUser.roles.includes('monitoring_organization') ? currentUser.organization.id : undefined;
  const project = useSelector((state) => state.m_projects.current);
  const steps = useSelector((state) => state.m_steps.list);
  const loading = useSelector((state) => state.m_documentsUI.loading);
  const classes = useStyles();
  const defaultValues = { ...project };
  defaultValues.co_executors = defaultValues.co_executors_names;
  defaultValues.financial_sources = defaultValues.financial_source_names;

  const performedStep = !steps.find((el) => parseInt(el.status_id) !== 1);
  const { list, config } = useSelector((state) => state.m_log);

  const { url } = useRouteMatch();
  const { roadmaps } = useSelector((state) => state.m_documents);
  const roadmap = roadmaps.find((_roadmap) => _roadmap.id === parseInt(project.roadmap_id));
  const unlessChapterIndexUrl = url.indexOf(`/projects/${projectId}`);

  useEffect(() => {
    dispatch(removeDrawerItem(`${url.substring(0, unlessChapterIndexUrl)}`));
    if (roadmap) {
      dispatch(addDrawerItem({ path: `${url.substring(0, unlessChapterIndexUrl)}`, title: roadmap?.short_title }));
    } else {
      if (project.roadmap_id) {
        dispatch(getRoadmap(project.roadmap_id));
      }
    }
  }, [project, roadmap]);

  const handleDownload = () =>
    axios({
      url: routes.projectPassport(),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      responseType: 'blob',
      data: { project_id: projectId },
    }).then((res) => {
      fileDownload(res.data, `${project.title}.xlsx`);
    });

  const isPerformed = project.status_id === 2;
  const isDone = project.status_id === 1;
  const isExecutor = isOrganization && project.organization_id !== orgId;

  return (
    <div className="dFlex">
      <div className="w70">
        <TableContainer component={Paper} className={classes.container}>
          {loading ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow className={classes.headRow}>
                  <StyledTableCell>{project.title}</StyledTableCell>
                  <StyledTableCell align="right">
                    {isBothSuperRole && (
                      <ButtonGroup variant="outlined">
                        <Button title={'Скачать пасспорт проекта'} color="primary" onClick={handleDownload}>
                          <AssignmentIcon />
                        </Button>
                        <Button
                          disabled={isExecutor}
                          title={'Изменить проект'}
                          color="primary"
                          onClick={openProjectForm}>
                          <CreateIcon />
                        </Button>
                        {isSupervisor && (
                          <Button
                            disabled={isExecutor}
                            title={intl.formatMessage({
                              id: 'monitoring.project.statusForm',
                              defaultMessage: 'Изменить статус',
                            })}
                            color="primary"
                            onClick={openStatusForm}>
                            <DonutLargeIcon />
                          </Button>
                        )}
                        {isSupervisor && (
                          <Button
                            title={intl.formatMessage({
                              id: 'monitoring.project.organizationForm',
                              defaultMessage: 'Изменить организацию',
                            })}
                            color="primary"
                            onClick={openOrgForm}>
                            <BusinessIcon />
                          </Button>
                        )}
                        <Button
                          title={intl.formatMessage({
                            id: 'monitoring.project.forecastForm',
                            defaultMessage: 'Изменить прогнозных средств',
                          })}
                          color="primary"
                          onClick={openForecastForm}>
                          <TrendingUpIcon />
                        </Button>
                        {!(project.status_id === 5) && (
                          <Button
                            disabled={!performedStep}
                            title={'Отправить проект на утверждение'}
                            color="primary"
                            onClick={openApprovalForm}>
                            <DoneAllIcon />
                          </Button>
                        )}
                        {isSupervisor && !(project.status_id === 2) && (
                          <Button title={'Отклонить утверждение проекта'} color="primary" onClick={openRefuseForm}>
                            <HistoryIcon />
                          </Button>
                        )}
                        {isSupervisor && (
                          <Button title={'Отменить проект'} color="primary" onClick={openCancelForm}>
                            <CancelPresentationIcon />
                          </Button>
                        )}
                      </ButtonGroup>
                    )}
                    {isOrganization && (
                      <ButtonGroup variant="outlined">
                        <Button title={'Скачать пасспорт проекта'} color="primary" onClick={handleDownload}>
                          <AssignmentIcon />
                        </Button>
                        {isPerformed && (
                          <Button
                            disabled={isExecutor}
                            title={intl.formatMessage({
                              id: 'monitoring.project.forecastForm',
                              defaultMessage: 'Изменить прогнозных средств',
                            })}
                            color="primary"
                            onClick={openForecastForm}>
                            <TrendingUpIcon />
                          </Button>
                        )}

                        {(isPerformed || isDone) && (
                          <Button
                            disabled={isExecutor}
                            title={intl.formatMessage({
                              id: 'monitoring.project.costEffectivenessForm',
                              defaultMessage: 'Изменить экономическую эффективность проекта',
                            })}
                            color="primary"
                            onClick={openEffectivenessForm}>
                            <AttachMoneyIcon />
                          </Button>
                        )}
                        {(isPerformed || isDone) && (
                          <Button
                            disabled={isExecutor}
                            title={'Изменить проблемы при реализации проекта'}
                            color="primary"
                            onClick={openProblemsForm}>
                            <ErrorOutlineIcon />
                          </Button>
                        )}
                      </ButtonGroup>
                    )}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell>{labels.status_id}</StyledTableCell>
                  <StyledTableCell align="right">{project.status_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.direction_type_id}</StyledTableCell>
                  <StyledTableCell align="right">{project.direction_type_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.financial_sources}</StyledTableCell>
                  <StyledTableCell align="right">
                    {project.financial_source_names.map((el) => el.title).join(', ')}
                  </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.cost_effectiveness}</StyledTableCell>
                  <StyledTableCell align="right">{project.cost_effectiveness}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.problems}</StyledTableCell>
                  <StyledTableCell align="right">{project.problems}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.start_date}</StyledTableCell>
                  <StyledTableCell align="right">{project.start_date}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.end_date}</StyledTableCell>
                  <StyledTableCell align="right">{project.end_date}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.amount_forecast_funds}</StyledTableCell>
                  <StyledTableCell align="right">{project.amount_forecast_funds}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.amount_involved_funds}</StyledTableCell>
                  <StyledTableCell align="right">{project.amount_involved_funds}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.amount_used_funds}</StyledTableCell>
                  <StyledTableCell align="right">{project.amount_used_funds}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.count_steps}</StyledTableCell>
                  <StyledTableCell align="right">{steps.length}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.organization_id}</StyledTableCell>
                  <StyledTableCell align="right">{project.organization_name}</StyledTableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell>{labels.co_executors}</StyledTableCell>
                  <StyledTableCell align="right">
                    {project.co_executors_names.map((el) => el.title).join(', ')}
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>

      <FileUploader isExecutor={isExecutor} />

      <StepList isExecutor={isExecutor} />

      <Log
        isExecutor={isExecutor}
        getLogs={getProjectLogs}
        logList={list}
        configLogs={config}
        title={<FormattedMessage id="monitoring.project.history" defaultMessage="История проекта" />}
        showComment={false}
      />

      <Dialog dialogState={projectForm} onClose={closeProjectForm} title={'Форма проекта'}>
        <ProjectForm labels={labels} orgId={orgId} closeDialog={closeProjectForm} defaultValues={defaultValues} />
      </Dialog>

      <Dialog
        dialogState={statusForm}
        onClose={closeStatusForm}
        title={intl.formatMessage({
          id: 'monitoring.project.statusForm',
          defaultMessage: 'Изменить статус',
        })}>
        <StatusForm labels={labels} closeDialog={closeStatusForm} defaultStatus={project.status_id} />
      </Dialog>

      <Dialog
        dialogState={orgForm}
        onClose={closeOrgForm}
        title={intl.formatMessage({
          id: 'monitoring.project.organizationForm',
          defaultMessage: 'Изменить организацию',
        })}>
        <OrganizationForm
          labels={labels}
          closeDialog={closeOrgForm}
          defaultValue={organizations.find((el) => el.id === project.organization_id)}
          organizationList={organizations}
        />
      </Dialog>

      <Dialog
        dialogState={forecastForm}
        onClose={closeForecastForm}
        title={intl.formatMessage({
          id: 'monitoring.project.forecastForm',
          defaultMessage: 'Изменить прогнозных средств',
        })}>
        <ForecastForm labels={labels} closeDialog={closeForecastForm} defaultValue={project.amount_forecast_funds} />
      </Dialog>

      <Dialog
        dialogState={effectivenessForm}
        onClose={closeEffectivenessForm}
        title={intl.formatMessage({
          id: 'monitoring.project.costEffectivenessForm',
          defaultMessage: 'Изменить экономическую эффективность проекта',
        })}>
        <CostEffectivenessForm
          labels={labels}
          closeDialog={closeEffectivenessForm}
          defaultValue={project.cost_effectiveness}
        />
      </Dialog>
      <Dialog dialogState={problemsForm} onClose={closeProblemsForm} title={'Проблемы'}>
        <ProblemsForm label={labels.problems} defaultValue={project.problems} closeDialog={closeProblemsForm} />
      </Dialog>
      <Dialog dialogState={cancelForm} onClose={closeCancelForm} title={'Отменить проект'}>
        <ModeratorForm
          label={labels.status_id}
          url={routes.editProject('cancel', projectId)}
          value={7}
          closeDialog={closeCancelForm}
        />
      </Dialog>
      <Dialog dialogState={approvalForm} onClose={closeApprovalForm} title={'Отправить проект на утверждение'}>
        <ModeratorForm
          label={labels.status_id}
          url={routes.editProject('send-to-checking', projectId)}
          value={5}
          closeDialog={closeApprovalForm}
        />
      </Dialog>
      <Dialog dialogState={refuseForm} onClose={closeRefuseForm} title={'Отклонить утверждение проекта'}>
        <ModeratorForm
          label={labels.status_id}
          url={routes.editProject('refuse', projectId)}
          value={2}
          closeDialog={closeRefuseForm}
        />
      </Dialog>
    </div>
  );
}

export default Project;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    padding: '16px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    '&:first-child': {
      borderRadius: '25px 0 0 25px',
    },
    '&:last-child': {
      borderRadius: '0 25px 25px 0',
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '16px 40px',
    borderBottom: '1px solid #F3F3F3',
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);
