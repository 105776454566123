import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Divider } from '@material-ui/core';

import { getDataSubdivision } from '../../../actions';
import { useDialog } from '../../../common/hooks';
import Dialog from '../../reused/SimpleDialog';
import Table from '../../reused/Table';
import Section171 from '../section171';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';
import Form from './Form';

function Section17() {
  const dispatch = useDispatch();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  useEffect(() => {
    dispatch(getDataSubdivision());
  }, [dispatch]);

  const { data, labels } = useSelector((state) => state.sectionOneSubdivision, shallowEqual);
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const dataValues = useMemo(() => (data ? Object.values(data) : Object.keys(labels).map((key) => ({ label: labels[key] }))), [data, labels]);

  const defaultValues = useMemo(
    () =>
      dataValues.reduce((acc, el) => {
        return { ...acc, [el.code_name]: el.value };
      }, {}),
    [dataValues]
  );

  const { requestState, requestItemState } = useSelector((state) => state.sectionOneSubdivisionUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneSubdivision.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;
  return (
    <>
      <div className="mb4">
        <div className="mb spaceBetween mb3">
          <div className="titleContainer">
            <h2 className="title">
              <FormattedMessage
                id="section171.headerTitle"
                defaultMessage="Перечень подведомственных организаций, территориальных и других подразделений, входящих в систему данной организации"
              />
            </h2>
          </div>
          {isVisible && (
            <Button variant="contained" color="primary" onClick={handleOpenEdit}>
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          )}
        </div>
        {requestState === 'request' ? (
          <Loader />
        ) : (
          <Table
            title={<FormattedMessage id="section17.indicatorsTitle" defaultMessage="Показатели" />}
            head={<FormattedMessage id="global.countTitle" defaultMessage="Количество" />}
            rows={dataValues}
          />
        )}

        <Dialog
          dialogState={dialogStateEdit}
          onClose={handleCloseEdit}
          title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
          {requestItemState === 'request' ? <Loader /> : <Form submitAction={handleCloseEdit} defaultValues={defaultValues} />}
        </Dialog>
      </div>
      <Divider />
      <Section171 />
    </>
  );
}

export default Section17;
