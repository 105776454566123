import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const questionnaire = handleActions(
  {
    [actions.getQuestionnaireSuccess]: (state, { payload }) => ({ ...state, questionnaire: payload }),
    [actions.setCurrentForm]: (state, { payload }) => ({ ...state, current: payload }),
    [actions.getDetailedEvaluationSuccess]: (state, { payload }) => ({ ...state, detailedEvaluation: payload }),
    [actions.fetchRatingSuccess]: (state, { payload }) => {
      return { ...state, rating: payload };
    },
  },
  {
    questionnaire: {
      formLabels: [],
      data: [],
    },
    current: [
      {
        id: null,
        title: '',
      },
    ],
    detailedEvaluation: [],
    rating: {
      categories: [],
      regions: [],
      i18n: {},
    },
  }
);

export const questionnaireUI = handleActions(
  {
    [actions.getQuestionnaireRequest]: () => ({ fetchingQuestionnaire: 'request' }),
    [actions.getQuestionnaireSuccess]: () => ({ fetchingQuestionnaire: 'success' }),
    [actions.getQuestionnaireFailure]: () => ({ fetchingQuestionnaire: 'failure' }),
    [actions.getDetailedEvaluationRequest]: () => ({ evaluation: true }),
    [actions.getDetailedEvaluationSuccess]: () => ({ evaluation: false }),
    [actions.getDetailedEvaluationFailure]: () => ({ evaluation: false }),
    [actions.fetchRatingRequest]: () => ({ rating: true }),
    [actions.fetchRatingSuccess]: () => ({ rating: false }),
    [actions.fetchRatingFailure]: () => ({ rating: false }),
  },
  {
    fetchingQuestionnaire: '',
    evaluation: false,
    rating: false,
  }
);
