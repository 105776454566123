import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setSourceStatError = createAction('SOURCE_STAT_SET_ERROR');

export const getSourceStatRequest = createAction('SOURCE_STAT_GET_REQUEST');
export const getSourceStatSuccess = createAction('SOURCE_STAT_GET_SUCCESS');
export const getSourceStatFailure = createAction('SOURCE_STAT_GET_FAILURE');

export const getSourceStat = () => async (dispatch) => {
  dispatch(getSourceStatRequest());

  try {
    const { data } = await axios.get(`${routes.sectionEightSourceStat()}/view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(`${routes.sectionEightSourceStat()}/labels`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    dispatch(getSourceStatSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setSourceStatError(e?.response));
  }
};

export const postSourceStatRequest = createAction('SOURCE_STAT_POST_REQUEST');
export const postSourceStatSuccess = createAction('SOURCE_STAT_POST_SUCCESS');
export const postSourceStatFailure = createAction('SOURCE_STAT_POST_FAILURE');

export const postSourceStat = (formValues) => async (dispatch) => {
  dispatch(postSourceStatRequest());
  try {
    await axios({
      method: 'post',
      url: `${routes.sectionEightSourceStat()}/update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getSourceStat());
    dispatch(postSourceStatSuccess());
  } catch (e) {
    dispatch(setSourceStatError(e?.response));
    dispatch(postSourceStatFailure());
  }
};
