import React, { useState } from 'react';
import clsx from 'clsx';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DrawerList from './DrawerList';
import { shallowEqual, useSelector } from 'react-redux';

function NISDrawer({ classes, title }) {
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');
  const OrgShortTitle = isOrganization ? currentUser?.organization.short_title : localStorage.orgTitle;
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}>
      <div className={classes.toolbarIcon}>
        {open ? (
          <div className="spaceBetween w100 p">
            <p className="drawer__title">{OrgShortTitle}</p>
            <IconButton onClick={handleDrawerClose} color="primary">
              <ChevronLeftIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton color="primary" aria-label="open drawer" onClick={handleDrawerOpen}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <DrawerList open={open} />
    </Drawer>
  );
}

export default NISDrawer;
