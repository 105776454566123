import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, FormHelperText, TextField } from '@material-ui/core';

import { generateHeaders, addCommentSuccess, addStepsCommentSuccess } from 'actions';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';

function CommentForm({ closeDialog, url, stepId }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();
  const { formatMessage } = useIntl();

  const onSubmit = (data) => {
    setLoading(true);
    const formData = { ...data, project_id: projectId };
    if (stepId) {
      formData.step_id = stepId;
    }

    axios({
      url,
      method: 'post',
      data: formData,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        if (stepId) {
          dispatch(addStepsCommentSuccess(data));
        } else {
          dispatch(addCommentSuccess(data));
        }

        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const { handleSubmit, register, errors } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mb" style={{ maxWidth: 700 }}>
      <TextField
        fullWidth
        multiline
        rows="8"
        rowsMax="10"
        name="comment"
        style={{ marginBottom: 10 }}
        error={!!errors.comment}
        inputRef={register({ required: true, minLength: 100 })}
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
      />
      <FormHelperText
        className="mb2"
        error={!!errors.comment}
        dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'monitoring.step.comment' }) }}
      />
      <Button color="primary" variant="contained" type="submit" style={{ display: 'block', marginLeft: 'auto' }}>
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        )}
      </Button>
    </form>
  );
}

export default CommentForm;
