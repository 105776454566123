import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const websiteOpenData = handleActions(
  {
    [actions.getLabelsOpenDataSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.getOpenDatasSuccess]: (state, { payload }) => {
      return {
        ...state,
        datas: payload.items,
        metaData: payload._meta,
      };
    },
    [actions.getElementOpenDataSuccess]: (state, { payload }) => {
      return {
        ...state,
        element: payload,
      };
    },
    [actions.addElementOpenDataSuccess]: (state, { payload }) => {
      return {
        ...state,
        datas: [...state.datas, payload],
      };
    },
    [actions.deleteElementOpenDataSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        datas: state.datas.filter((element) => element.id !== id),
      };
    },
    [actions.editElementOpenDataSuccess]: (state, { payload }) => {
      return {
        ...state,
        datas: state.datas.map((element) => (element.id === payload.id ? payload : element)),
      };
    },
    [actions.setOpenDataError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    labels: {},
    datas: [],
    element: {},
    metaData: {
      pageCount: 1,
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const websiteOpenDataUI = handleActions(
  {
    [actions.getLabelsOpenDataRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsOpenDataSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsOpenDataFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editElementOpenDataRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editElementOpenDataSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editElementOpenDataFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getElementOpenDataRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getElementOpenDataSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getElementOpenDataFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
