import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const organizationHackathon = handleActions(
  {
    [actions.getHackathonsListSuccess]: (state, { payload }) => {
      return {
        ...state,
        list: payload,
      };
    },
    [actions.getLabelsHackathonSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.getHackathonSuccess]: (state, { payload: { event, id } }) => ({ ...state, event, id }),
    [actions.deleteHackathonSuccess]: (state, { payload: { id } }) => ({ ...state, list: state.list.filter((event) => event.id !== id)}),
    [actions.addHackathonSuccess]: (state, { payload }) => ({ ...state, list: [...state.list, payload] }),
    [actions.editHackathonSuccess]: (state, { payload }) => ({ ...state, list: state.list.map((el) => el.id === payload.id ? payload : el)}),
    [actions.getHackathonRadioBtnSuccess]: (state, { payload }) => ({ ...state, radio: payload }),
    [actions.setHackathonError]: (state, { payload }) => ({ ...state, error: { hasError: true, status: payload.status, message: payload.message } }),
  },
  {
    list: [],
    event: {},
    labels: {},
    id: null,
    radio: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const organizationHackathonUI = handleActions(
  {
    [actions.getHackathonRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getHackathonSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getHackathonFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getLabelsHackathonRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsHackathonSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsHackathonFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.addHackathonRequest]: (state) => ({ ...state, requestState: 'request' }),
    [actions.addHackathonSuccess]: (state) => ({ ...state, requestState: 'success' }),
    [actions.addHackathonFailure]: (state) => ({ ...state, requestState: 'failure' }),
    [actions.editHackathonRequest]: (state) => ({ ...state, requestState: 'request' }),
    [actions.editHackathonSuccess]: (state) => ({ ...state, requestState: 'success' }),
    [actions.editHackathonFailure]: (state) => ({ ...state, requestState: 'failure' }),

  },
  {
    requestState: '',
    requestItemState: '',
    requestLabelState: '',
  }
);
