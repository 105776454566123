import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setWebsiteComplianceRequirementsError = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_SET_ERROR');

export const getWebsiteComplianceRequirementsRequest = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_GET_REQUEST');
export const getWebsiteComplianceRequirementsSuccess = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_GET_SUCCESS');
export const getWebsiteComplianceRequirementsFailure = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_GET_FAILURE');

export const getWebsiteComplianceRequirements = () => async (dispatch) => {
  dispatch(getWebsiteComplianceRequirementsRequest());

  try {
    const response = await axios.get(routes.sectionTwoWebsiteComplianceRequirements(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const data = response.data;
    dispatch(getWebsiteComplianceRequirementsSuccess(data));
  } catch (e) {
    dispatch(setWebsiteComplianceRequirementsError(e?.response));
    dispatch(getWebsiteComplianceRequirementsFailure());
  }
};

export const editWebsiteComplianceRequirementsRequest = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_EDIT_REQUEST');
export const editWebsiteComplianceRequirementsSuccess = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_EDIT_SUCCESS');
export const editWebsiteComplianceRequirementsFailure = createAction('WEBSITE_COMPLIANCE_REQUIREMENTS_EDIT_FAILURE');

export const editWebsiteComplianceRequirements = (formValues) => async (dispatch) => {
  dispatch(editWebsiteComplianceRequirementsRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoUpdateWebsiteCompliance(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(editWebsiteComplianceRequirementsSuccess());
    dispatch(getWebsiteComplianceRequirements());
  } catch (e) {
    dispatch(setWebsiteComplianceRequirementsError(e?.response));
    dispatch(editWebsiteComplianceRequirementsFailure());
  }
};
