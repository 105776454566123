import React from 'react';
import { NavLink } from 'react-router-dom';

function ProjectStats({ stats, isBtns = false, callback, url }) {
  return (
    <div className="dFlex dFJustifyFxSpaceBtwn w100">
      {stats.map((status) => {
        let color;
        let statusProj = parseInt(status.status_id);
        switch (statusProj) {
          case 0:
            color = '#7eeae5';
            break;
          case 1:
            color = '#00ff0060';
            break;
          case 2:
            color = '#8500ff8c';
            break;
          case 3:
            color = 'orange';
            break;
          case 4:
            color = '#ffff00';
            break;
          case 5:
            color = '#f360f3';
            break;
          case 6:
            color = '#87d0fb';
            break;
          case 7:
            color = 'red';
            break;
          default:
            color = '#fff';
        }
        return isBtns ? (
          <div
            onClick={callback(status.status_id)}
            key={status.status_id}
            className="statusProjects mb mr ml"
            style={{ border: `2px solid ${color}` }}>
            <div className="status_link" style={{ height: 100 }}>
              <p className="title">{status.title}:</p>
              <p className="value">{status.count}</p>
            </div>
          </div>
        ) : (
          <div key={status.status_id} className="statusProjects mb mr ml" style={{ border: `2px solid ${color}` }}>
            <NavLink
              className="status_link"
              style={{ height: 100 }}
              onClick={callback}
              to={`${url}/${status.status_id}`}
              title={status.title}>
              <p className="title status_link">{status.title}:</p>
              <p className="value status_link">{status.count}</p>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}

export default ProjectStats;
