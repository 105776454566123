import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow, makeStyles } from '@material-ui/core';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';

import { StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Title from 'components/reused/Title';
import Loader from 'components/reused/Spinner';
import { addDrawerItem, resetDrawer, getRoadmaps, getAct, getStatisticsAllProjects } from 'actions';
import { Pagination } from '@material-ui/lab';
import Empty from 'components/reused/Empty';
import ProjectStats from '../reused/ProjectStats';
import { FormattedMessage } from 'react-intl';
import CounterPage from 'components/reused/CounterPage';

const styles = makeStyles({
  headCell: {
    padding: 20,
  },
});
function Roadmaps() {
  const { id } = useParams();
  const { url } = useRouteMatch();

  const [statistics, setStatistics] = useState([]);
  useEffect(() => {
    dispatch(getRoadmaps(id, page));

    let active = true;
    getStatisticsAllProjects(id).then((data) => {
      if (active) setStatistics(data);
    });
    if (sessionStorage.getItem('projectPaginationPage')) {
      sessionStorage.removeItem('projectPaginationPage');
    }

    return () => (active = false);
  }, []);

  const classes = useStyles();
  const customClasses = styles();
  const dispatch = useDispatch();
  const { acts, roadmaps, configRoadmaps } = useSelector((state) => state.m_documents);
  const loading = useSelector((state) => state.m_documentsUI.loading);

  const act = acts.find((_act) => _act.id === parseInt(id));

  useEffect(() => {
    if (!act) {
      dispatch(getAct(id));
    }
  }, [act]);

  const [page, setPage] = useState(parseInt(sessionStorage.getItem('roadmapsPaginationPage')) || 1);
  const handleChange = (event, value) => {
    setPage(value);
    sessionStorage.setItem('roadmapsPaginationPage', value);
    dispatch(getRoadmaps(id, value));
  };

  const handleAdd = () => {
    dispatch(resetDrawer());
    dispatch(addDrawerItem({ path: `/acts/${id}`, title: act?.short_title }));
  };

  const resultStatistics = statistics
    .map((el) =>
      el.is_expire
        ? { status_id: 4, title: el[`title_${localStorage.lang || 'ru'}`], count: el.cnt }
        : { status_id: el.status_id, title: el[`title_${localStorage.lang || 'ru'}`], count: el.cnt }
    )
    .sort((a, b) => a.status_id - b.status_id);
  const allStatusesProject = statistics.reduce((acc, stat) => (stat.is_expire ? acc : acc + stat.cnt), 0);
  resultStatistics.push({ status_id: 0, title: 'Общее кол-во проектов', count: allStatusesProject });

  return (
    <>
      <Title title={act?.short_title} className="mb3" />
      <ProjectStats stats={resultStatistics} url={`${url}/status`} callback={handleAdd} />
      {roadmaps.length !== 0 && <CounterPage config={configRoadmaps} page={page} length={roadmaps.length} />}
      <TableContainer component={Paper} className={classes.container}>
        {loading ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.headRow}>
                <StyledTableCell className={customClasses.headCell}>
                  <FormattedMessage
                    id="monitoring.roadmaps.fullNameApp"
                    defaultMessage="Полное наименование приложения"
                  />
                </StyledTableCell>
                <StyledTableCell className={customClasses.headCell}>
                  <FormattedMessage
                    id="monitoring.roadmaps.shortNameApp"
                    defaultMessage="Сокращенное наименование приложения"
                  />
                </StyledTableCell>
                <StyledTableCell className={customClasses.headCell}>
                  <FormattedMessage id="monitoring.roadmaps.yearOfExecution" defaultMessage="Год исполнения" />
                </StyledTableCell>
                <StyledTableCell className={customClasses.headCell}>
                  <FormattedMessage id="monitoring.roadmaps.statusProjects" defaultMessage="Статусы проектов" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roadmaps.map((roadmap) => {
                const countAllProjects = roadmap.project_stat.reduce(
                  (acc, stat) => (stat.is_expire ? acc : acc + parseInt(stat.cnt)),
                  0
                );
                const otherStatusesProject = roadmap.project_stat
                  .sort((a, b) => a.status_id - b.status_id)
                  .map((stat, ind) => {
                    if (ind === 2) {
                      return { status_id: 4, title: stat.title, cnt: stat.cnt };
                    } else {
                      return stat;
                    }
                  })
                  .sort((a, b) => a.status_id - b.status_id)
                  .map((stat) => {
                    let color;
                    let status = parseInt(stat.status_id);
                    switch (status) {
                      case 1:
                        color = '#00ff0060';
                        break;
                      case 2:
                        color = '#8500ff8c';
                        break;
                      case 3:
                        color = 'orange';
                        break;
                      case 4:
                        color = '#ffff00';
                        break;
                      case 5:
                        color = '#f360f3';
                        break;
                      case 6:
                        color = '#87d0fb';
                        break;
                      case 7:
                        color = 'red';
                        break;
                      default:
                        color = '#fff';
                    }
                    return (
                      <div key={stat.status_id} style={{ border: `2px solid ${color}`, margin: 2, borderRadius: 25 }}>
                        <NavLink
                          className="status_link"
                          onClick={handleAdd}
                          to={`${url}/roadmaps/${roadmap.id}/status/${stat.status_id}`}
                          title={stat.title}>
                          {stat.cnt}
                        </NavLink>
                      </div>
                    );
                  });
                otherStatusesProject.push(
                  <div key="0" style={{ border: `2px solid #7eeae5`, margin: 2, borderRadius: 25 }}>
                    <NavLink
                      className="status_link m_link"
                      title="Общее кол-во проектов"
                      onClick={handleAdd}
                      to={`${url}/roadmaps/${roadmap.id}`}>
                      {countAllProjects}
                    </NavLink>
                  </div>
                );
                return (
                  <StyledTableRow key={roadmap.id}>
                    <StyledTableCell>
                      <NavLink className="m_link" onClick={handleAdd} to={`${url}/roadmaps/${roadmap.id}`}>
                        {roadmap.title}
                      </NavLink>
                    </StyledTableCell>
                    <StyledTableCell>{roadmap.short_title}</StyledTableCell>
                    <StyledTableCell>{roadmap.implementation_year}</StyledTableCell>
                    <StyledTableCell>
                      <div className="container-statuses">{otherStatusesProject}</div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

              {roadmaps.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan="4">
                    <Empty title="Данных нет" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {configRoadmaps.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt2">
          <Pagination
            count={configRoadmaps.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
    </>
  );
}

export default Roadmaps;
