import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Checkbox, CircularProgress, FormLabel, NativeSelect } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { generateHeaders } from 'actions/dictionary';
import { get, addStep, getProjectLogs, getProject, getSteps } from 'actions';
import routes from 'monitoring/routes';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'components/reused/DatePicker';

function Form({ labels, closeDialog }) {
  const dispatch = useDispatch();
  const { id, roadmapId, projectId, stepStatusId } = useParams();
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const project = useSelector((state) => state.m_projects.current);

  useEffect(() => {
    let active = true;
    get('step-status-types').then((data) => {
      if (active) setStatuses(data.filter((el) => el.id !== 4));
    });
    return () => (active = false);
  }, []);

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      start_date: null,
      end_date: null,
      is_start: false,
      is_finish: false,
    },
  });
  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.steps(),
      method: 'post',
      data: {
        ...data,
        document_id: id,
        roadmap_id: project.roadmap_id,
        project_id: projectId,
        is_start: data.is_start ? 1 : 0,
        is_finish: data.is_finish ? 1 : 0,
        start_date: data.start_date.toLocaleDateString('uz-UZ', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        end_date: data.end_date.toLocaleDateString('uz-UZ', { year: 'numeric', month: '2-digit', day: '2-digit' }),
      },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(() => {
        setValidation([]);
        if (stepStatusId) {
          dispatch(getSteps({ project_id: projectId, status_id: stepStatusId }, 1));
        } else {
          dispatch(getSteps({ project_id: projectId }, 1));
        }
        dispatch(getProjectLogs(projectId, 1));
        dispatch(getProject(projectId));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
      });
  };

  return (
    <>
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <form onSubmit={handleSubmit(onSubmit)} className="p">
        <TextField
          inputRef={register}
          name="step_no"
          error={!!errors.step_no}
          className="mb"
          label={'№'}
          type="number"
          fullWidth
        />
        <TextField
          inputRef={register({ required: true })}
          name="title_uz"
          error={!!errors.title_uz}
          className="mb"
          label={labels.title_uz}
          fullWidth
        />
        <TextField
          inputRef={register({ required: true })}
          name="title_ru"
          error={!!errors.title_ru}
          className="mb2"
          label={labels.title_ru}
          fullWidth
        />

        <FormLabel component="legend">{labels.status_id}</FormLabel>
        <NativeSelect
          inputProps={{ ref: register({ required: true }) }}
          error={!!errors.status_id}
          name="status_id"
          className="w100 mb">
          <option />
          {statuses.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </NativeSelect>
        <DatePicker
          control={control}
          rules={{ validate: (value) => (value !== null ? undefined : 'not date') }}
          name="start_date"
          label={labels.start_date}
          className="mb2"
        />
        <DatePicker
          control={control}
          rules={{ validate: (value) => (value !== null ? undefined : 'not date') }}
          name="end_date"
          label={labels.end_date}
          className="mb"
        />

        <div className="spaceBetween mt mb2">
          <div className="alignItemCenter">
            <Controller
              name="is_start"
              control={control}
              render={(props) => (
                <Checkbox onChange={(e) => props.onChange(e.target.checked)} checked={props.value} color="primary" />
              )}
            />
            <FormLabel component="legend">{labels.is_start}</FormLabel>
          </div>

          <div className="alignItemCenter">
            <Controller
              name="is_finish"
              control={control}
              render={(props) => (
                <Checkbox onChange={(e) => props.onChange(e.target.checked)} checked={props.value} color="primary" />
              )}
            />
            <FormLabel component="legend">{labels.is_finish}</FormLabel>
          </div>
        </div>

        <TextField
          name="comment"
          multiline
          rowsMax="4"
          label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
          inputRef={register}
          type="text"
          className="mb"
          fullWidth
        />
        <Button type="submit" color="primary" variant="contained" className="m0Auto" style={{ minWidth: 110 }}>
          {loading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          )}
        </Button>
      </form>
    </>
  );
}

export default Form;
