import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const drawer = handleActions(
  {
    [actions.addDrawerItem]: (state, { payload }) => {
      return { items: [...state.items, payload] };
    },
    [actions.removeDrawerItem]: (state, { payload }) => {
      return { items: state.items.filter((item) => item.path !== payload) };
    },
    [actions.resetDrawer]: () => ({ items: [] }),
  },
  {
    items: JSON.parse(sessionStorage.getItem('drawerList')) || [],
  }
);
