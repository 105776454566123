import axios from 'axios';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export const get = async (handbook) => {
  try {
    const { data } = await axios.get(routes.dictionary(handbook), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getLabels = async (entity) => {
  try {
    const { data } = await axios.get(routes.labels(entity), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};
