import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoDigitalStrategyError = createAction('INFO_DIGITAL_STRATEGY_SET_ERROR');

export const getInfoDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_INFO_GET_REQUEST');
export const getInfoDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_INFO_GET_SUCCESS');
export const getInfoDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_INFO_GET_FAILURE');

export const getInfoDigitalStrategy = () => async (dispatch) => {
  dispatch(getInfoDigitalStrategyRequest());

  try {
    const { data } = await axios.get(routes.sectionSixOrgDigitalStrategy(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getInfoDigitalStrategySuccess(data.items));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(getInfoDigitalStrategyFailure());
  }
};

export const getLabelsDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_LABELS_GET_REQUEST');
export const getLabelsDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_LABELS_GET_SUCCESS');
export const getLabelsDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_LABELS_GET_FAILURE');

export const getLabelsDigitalStrategy = () => async (dispatch) => {
  dispatch(getLabelsDigitalStrategyRequest());

  try {
    const { data } = await axios.get(routes.sectionSixOrgDigitalStrategyLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsDigitalStrategySuccess(data));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(getLabelsDigitalStrategyFailure());
  }
};

export const addElementDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_ELEMENT_ADD_REQUEST');
export const addElementDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_ELEMENT_ADD_SUCCESS');
export const addElementDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_ELEMENT_ADD_FAILURE');

export const addElementDigitalStrategy = (formData) => async (dispatch) => {
  dispatch(addElementDigitalStrategyRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionSixOrgDigitalStrategyOperations(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addElementDigitalStrategySuccess(data));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(addElementDigitalStrategyFailure());
  }
};

export const deleteElementDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_ELEMENT_DELETE_REQUEST');
export const deleteElementDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_ELEMENT_DELETE_SUCCESS');
export const deleteElementDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_ELEMENT_DELETE_FAILURE');

export const deleteElementDigitalStrategy = (id) => async (dispatch) => {
  dispatch(deleteElementDigitalStrategyRequest());

  try {
    await axios({
      method: 'delete',
      url: `${routes.sectionSixOrgDigitalStrategyOperations()}/${id}`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteElementDigitalStrategySuccess({ id }));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(deleteElementDigitalStrategyFailure());
  }
};

export const editElementDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_ELEMENT_EDIT_REQUEST');
export const editElementDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_ELEMENT_EDIT_SUCCESS');
export const editElementDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_ELEMENT_EDIT_FAILURE');

export const editElementDigitalStrategy = (id, formData) => async (dispatch) => {
  dispatch(editElementDigitalStrategyRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: `${routes.sectionSixOrgDigitalStrategyOperations()}/${id}`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editElementDigitalStrategySuccess(data));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(editElementDigitalStrategyFailure());
  }
};

export const getElementDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_ELEMENT_GET_REQUEST');
export const getElementDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_ELEMENT_GET_SUCCESS');
export const getElementDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_ELEMENT_GET_FAILURE');

export const getElementDigitalStrategy = (id) => async (dispatch) => {
  dispatch(getElementDigitalStrategyRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSixOrgDigitalStrategyOperations()}/${id}`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getElementDigitalStrategySuccess(data));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(getElementDigitalStrategyFailure());
  }
};

export const getCheckboxDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_CHECKBOX_GET_REQUEST');
export const getCheckboxDigitalStrategySuccess = createAction('DIGITAL_STRATEGY_CHECKBOX_GET_SUCCESS');
export const getCheckboxDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_CHECKBOX_GET_FAILURE');

export const getCheckboxDigitalStrategy = () => async (dispatch) => {
  dispatch(getCheckboxDigitalStrategyRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSixOrgDigitalStrategyCheckbox()}view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getCheckboxDigitalStrategySuccess(data));
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(getCheckboxDigitalStrategyFailure());
  }
};

export const updateCheckboxDigitalStrategyRequest = createAction('DIGITAL_STRATEGY_CHECKBOX_UPDATE_REQUEST');
export const updateCheckboxDigitalStrategyFailure = createAction('DIGITAL_STRATEGY_CHECKBOX_UPDATE_FAILURE');

export const updateCheckboxDigitalStrategy = (formValues) => async (dispatch) => {
  dispatch(updateCheckboxDigitalStrategyRequest());

  try {
    await axios({
      method: 'post',
      url: `${routes.sectionSixOrgDigitalStrategyCheckbox()}update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getCheckboxDigitalStrategy());
  } catch (e) {
    dispatch(setInfoDigitalStrategyError(e?.response));
    dispatch(updateCheckboxDigitalStrategyFailure());
  }
};
