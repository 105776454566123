import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { CircularProgress, FormLabel, NativeSelect } from '@material-ui/core';

import Autocomplete from 'components/reused/Autocomplete';
import { generateHeaders } from 'actions/dictionary';
import { get, addProject } from 'actions';
import routes from 'monitoring/routes';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';

function Form({ labels, closeDialog }) {
  const dispatch = useDispatch();
  const { roadmapId, id } = useParams();
  const [organizations, setOrganizations] = useState([]);
  const [financingSources, setFinancingSources] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [directionTypes, setDirectionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  useEffect(() => {
    let active = true;
    get('organizations').then((data) => {
      if (active) setOrganizations(data);
    });
    get('financing-sources').then((data) => {
      if (active) setFinancingSources(data);
    });
    get('project-status-types').then((data) => {
      if (active) setStatuses(data.filter((el) => el.id !== 5 && el.id !== 7));
    });
    get('direction-types').then((data) => {
      if (active) setDirectionTypes(data);
    });
    return () => (active = false);
  }, []);

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      financial_sources: [],
      co_executors: [],
    },
  });
  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.projects(),
      method: 'post',
      data: {
        ...data,
        organization_id: data.organization_id.id,
        co_executors: data.co_executors.map((el) => el.id),
        financial_sources: data.financial_sources.map((el) => el.id),
        roadmap_id: roadmapId,
        document_id: id,
      },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        setValidation([]);
        dispatch(addProject(data));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
      });
    console.log(data);
  };

  return (
    <>
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <form onSubmit={handleSubmit(onSubmit)} className="dFlex">
        <div className="dFlex w100 pt3">
          <div className="w50 pr3 pl3">
            {/* <TextField
          inputRef={register({ required: true })}
          name="project_no"
          error={!!errors.project_no}
          className="mb"
          label={'№'}
          type="number"
          fullWidth
        /> */}
            <TextField
              inputRef={register({ required: true })}
              name="title_uz"
              error={!!errors.title_uz}
              className="mb"
              label={labels.title_uz}
              fullWidth
            />
            <TextField
              inputRef={register({ required: true })}
              name="title_ru"
              error={!!errors.title_ru}
              className="mb"
              label={labels.title_ru}
              fullWidth
            />

            <Autocomplete
              control={control}
              options={financingSources}
              name="financial_sources"
              label={labels.financial_sources}
            />

            <Autocomplete
              control={control}
              options={organizations}
              name="organization_id"
              label={labels.organization_id}
              error={!!errors.organization_id}
              rules={{ validate: (value) => (value.id !== null ? undefined : 'value') }}
              defaultValue={{ id: null, title: '' }}
              isAutocomplete={false}
            />
          </div>

          <div className="w50 pr3 pl3">
            <Autocomplete
              control={control}
              name="co_executors"
              className="mb"
              options={organizations}
              label={labels.co_executors}
            />
            <FormLabel component="legend">{labels.status_id}</FormLabel>
            <NativeSelect inputProps={{ ref: register() }} name="status_id" className="w100 mb">
              <option />
              {statuses.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </NativeSelect>
            <FormLabel component="legend">{labels.direction_type_id}</FormLabel>
            <NativeSelect
              inputProps={{ ref: register({ required: true }) }}
              name="direction_type_id"
              error={!!errors.direction_type_id}
              className="w100 mb">
              <option />
              {directionTypes.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.title}
                </option>
              ))}
            </NativeSelect>
            <TextField
              name="amount_forecast_funds"
              label={labels.amount_forecast_funds}
              inputRef={register}
              type="number"
              className="mb"
              inputProps={{ min: 0, step: 0.01 }}
              fullWidth
            />
          </div>

          <div className="w100 pr3 pl3">
            <TextField
              name="comment"
              multiline
              rowsMax="4"
              label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
              inputRef={register}
              type="text"
              className="mb"
              fullWidth
            />
          </div>
        </div>

        <Button type="submit" color="primary" variant="contained" className="m0Auto" style={{ minWidth: 110 }}>
          {loading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          )}
        </Button>
      </form>
    </>
  );
}

export default Form;
