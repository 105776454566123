import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export const getStepsRequest = createAction('STEPS_GET_REQUEST');
export const getStepsSuccess = createAction('STEPS_GET_SUCCESS');
export const getStepsFailure = createAction('STEPS_GET_FAILURE');

export const getSteps = (filtration, page) => async (dispatch) => {
  dispatch(getStepsRequest());

  try {
    const filter = Object.keys(filtration).reduce((acc, el) => ({ ...acc, [`filter[${el}]`]: filtration[el] }), {});

    const { data } = await axios.get(routes.steps(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { ...filter, page, 'per-page': 10 },
    });
    dispatch(getStepsSuccess(data));
  } catch (e) {
    dispatch(getStepsFailure());
    console.log(e);
  }
};

export const deleteStepSuccess = createAction('STEP_DELETE_SUCCESS');
export const deleteStepFailure = createAction('STEP_DELETE_FAILURE');
export const deleteStepRequest = createAction('STEP_DELETE_REQUEST');

export const deleteStep = (id) => async (dispatch) => {
  dispatch(deleteStepRequest());

  try {
    const res = await axios.delete(routes.step(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    if (res.status === 204) {
      dispatch(deleteStepSuccess(id));
    }
  } catch (e) {
    dispatch(deleteStepFailure());
    console.log(e);
  }
};

export const editStep = createAction('STEP_EDIT');
export const setCurrentStep = createAction('CURRENT_STEP_SET)');
