import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgStructuralSubdivision = handleActions(
  {
    [actions.getDataSubdivisionSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: payload.data,
        labels: payload.labels,
      };
    },
    [actions.setSubdivisionError]: (state, { payload }) => {
      return {
        ...state,
        error: {
          hasError: true,
          status: payload.status,
          message: payload.message,
        },
      };
    },
  },
  {
    data: [],
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgStructuralSubdivisionUI = handleActions(
  {
    [actions.getDataSubdivisionRequest]: () => ({ requestState: 'request' }),
    [actions.getDataSubdivisionSuccess]: () => ({ requestState: 'success' }),
    [actions.getDataSubdivisionFailure]: () => ({ requestState: 'failure' }),
    [actions.editSubdivisionDataRequest]: () => ({ requestState: 'request' }),
    [actions.editSubdivisionDataSuccess]: () => ({ requestState: 'success' }),
    [actions.editSubdivisionDataFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
