import React from 'react';
import { Container } from '@material-ui/core';

import UzinfocomLogo from '../../assests/img/uzinfocom.svg';
import EgovLogoRu from '../../assests/img/egov-logoru.png';
import EgovLogoUz from '../../assests/img/egov-logouz.png';

function Footer({ classes }) {
  return (
    <Container maxWidth="xl" className={classes.footer}>
      <footer className="homeFooter">
        <a href="https://e-gov.uz" rel="noopener noreferrer" target="_blank" className="egov">
          {localStorage.lang === 'ru' ? (
            <img src={EgovLogoRu} alt="egovernment" className="logo" />
          ) : (
            <img src={EgovLogoUz} alt="egovernment" className="logo" />
          )}
        </a>
        <a href="https://uzinfocom.uz" rel="noopener noreferrer" target="_blank" className="logo">
          <img src={UzinfocomLogo} alt="uzinfocom" />
        </a>
      </footer>
    </Container>
  );
}

export default Footer;
