import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { generateHeaders } from './dictionary';

export const getEvaluationListRequest = createAction('EVALUATION_LIST_GET_REQUEST');
export const getEvaluationListSuccess = createAction('EVALUATION_LIST_GET_SUCCESS');
export const getEvaluationListFailure = createAction('EVALUATION_LIST_GET_FAILURE');

export const getEvaluationList = (id) => async (dispatch) => {
  dispatch(getEvaluationListRequest());

  try {
    const { data } = await axios.get(routes.complexEvaluationList(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });

    dispatch(getEvaluationListSuccess(data));
  } catch (e) {
    //dispatch(getEvaluationListFailure(e?.response));
    console.log(e);
  }
};

export const getComplexMarkRequest = createAction('COMPLEX_MARK_GET_REQUEST');
export const getComplexMarkSuccess = createAction('COMPLEX_MARK_GET_SUCCESS');
export const getComplexMarkFailure = createAction('COMPLEX_MARK_GET_FAILURE');

export const getComplexMark = (id, sectionId) => async (dispatch) => {
  dispatch(getComplexMarkRequest());

  try {
    const { data } = await axios.get(routes.complexEvaluationView('-correctly'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { id, section_id: sectionId },
    });
    dispatch(getComplexMarkSuccess(data));
    if (data) dispatch(getComplexAssessment(id, sectionId));
  } catch (e) {
    // dispatch(getComplexMarkFailure(e?.response));
    console.log(e);
  }
};
export const saveComplexMarkRequest = createAction('COMPLEX_MARK_SAVE_REQUEST');
export const saveComplexMarkSuccess = createAction('COMPLEX_MARK_SAVE_SUCCESS');
export const saveComplexMarkFailure = createAction('COMPLEX_MARK_SAVE_FAILURE');

export const saveComplexMark = (id, sectionId, formValues) => async (dispatch) => {
  dispatch(saveComplexMarkRequest());

  try {
    const { data } = await axios({
      url: routes.complexMarkCreate(),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: sectionId, item_id: id },
    });
    dispatch(saveComplexMarkSuccess(data));
    dispatch(getComplexAssessment(id, sectionId));
  } catch (e) {
    dispatch(saveComplexMarkFailure(e?.response));
  }
};

export const deleteСomplexMarkRequest = createAction('COMPLEX_MARK_DELETE_REQUEST');
export const deleteСomplexMarkSuccess = createAction('COMPLEX_MARK_DELETE_SUCCESS');
export const deleteСomplexMarkFailure = createAction('COMPLEX_MARK_DELETE_FAILURE');

export const deleteСomplexMark = (id, sectionId) => async (dispatch) => {
  dispatch(deleteСomplexMarkRequest());

  try {
    await axios.delete(routes.complexMarkDelete(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: sectionId, id },
    });
    dispatch(deleteСomplexMarkSuccess());
  } catch (e) {
    dispatch(deleteСomplexMarkFailure(e?.response));
  }
};

export const getComplexAssessmentRequest = createAction('COMPLEX_ASSESSMENT_GET_REQUEST');
export const getComplexAssessmentSuccess = createAction('COMPLEX_ASSESSMENT_GET_SUCCESS');
export const getComplexAssessmentFailure = createAction('COMPLEX_ASSESSMENT_GET_FAILURE');

export const getComplexAssessment = (id, sectionId) => async (dispatch) => {
  dispatch(getComplexAssessmentRequest());

  try {
    const { data } = await axios.get(routes.complexEvaluationView(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { id, section_id: sectionId },
    });

    dispatch(getComplexAssessmentSuccess(data));
  } catch (e) {
    //dispatch(getComplexAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const saveComplexAssessmentRequest = createAction('COMPLEX_ASSESSMENT_SAVE_REQUEST');
export const saveComplexAssessmentSuccess = createAction('COMPLEX_ASSESSMENT_SAVE_SUCCESS');
export const saveComplexAssessmentFailure = createAction('COMPLEX_ASSESSMENT_SAVE_FAILURE');

export const saveComplexAssessment = (id, sectionId, paramId, formValues) => async (dispatch) => {
  dispatch(saveComplexAssessmentRequest());

  try {
    await axios({
      url: routes.complexAssessmentCreate(),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: sectionId, param_id: paramId, item_id: id },
    });
    dispatch(getComplexAssessment(id, sectionId));
  } catch (e) {
    dispatch(saveComplexAssessmentFailure(e?.response));
  }
};

export const deleteComplexAssessmentRequest = createAction('COMPLEX_ASSESSMENT_DELETE_REQUEST');
export const deleteComplexAssessmentSuccess = createAction('COMPLEX_ASSESSMENT_DELETE_SUCCESS');
export const deleteComplexAssessmentFailure = createAction('COMPLEX_ASSESSMENT_DELETE_FAILURE');

export const deleteComplexAssessment = (id, sectionId, paramId) => async (dispatch) => {
  dispatch(deleteComplexAssessmentRequest());

  try {
    await axios.delete(routes.complexAssessmentDelete(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: sectionId, p_id: paramId, id },
    });
    dispatch(deleteComplexAssessmentSuccess());
    dispatch(getComplexAssessment(id, sectionId));
  } catch (e) {
    dispatch(deleteComplexAssessmentFailure(e?.response));
  }
};
