/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CreateIcon from '@material-ui/icons/Create';
import { Button, IconButton, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import fileDownload from 'js-file-download';

import CEOForm from './Form';
import CustomTable, { DashedTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Dialog from 'components/reused/FullScreenDialog';
import Spinner from 'components/reused/Spinner';
import Error from 'components/reused/Error';
import { useDialog } from 'common/hooks';
import { isEmpty } from 'common/utils';
import { getOrganizations, getOrgInfo, getPositions, getRegions, generateHeaders, getExcelFileLink } from 'actions';
import routes from '../../../routes';

const OrgInfo = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  useEffect(() => {
    if (localStorage.orgId) {
      dispatch(getOrgInfo(localStorage.orgId));
      dispatch(getOrganizations(paramsOrganizationFetch));
      dispatch(getExcelFileLink());
    }
  }, []);

  const requestState = useSelector((state) => state.sectionOneOrgInfoUI.requestState, shallowEqual);

  const orgInfo = useSelector((state) => state.sectionOneOrgInfo.data, shallowEqual);
  const { statOrganizations } = useSelector((state) => state.dictionary, shallowEqual);
  const link = useSelector((state) => state.dictionary.link, shallowEqual);

  const paramsOrganizationFetch = {
    org_category_id: null,
    max_section_level: '1',
    organization_id: localStorage.getItem('orgId'),
  };

  const dataStatOrganization = statOrganizations.orgStat;
  const labelsStatOrganization = statOrganizations.sections;

  const orgInfoValues = Object.values(orgInfo);
  const defaultValues = orgInfoValues.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {});
  const labels = orgInfoValues.reduce((acc, el) => ({ ...acc, [el.code_name]: el.label }), {});

  const [dialogState, handleOpen, handleClose] = useDialog();

  useEffect(() => {
    if (!isEmpty(orgInfo)) {
      localStorage.setItem('orgCategoryId', orgInfo.category_id.value);
    }
  }, [orgInfo]);

  const finalInfo = orgInfoValues
    .filter(
      (el) =>
        ![
          'app_type',
          'house_no_ceo',
          'street_name_ceo',
          'category_id',
          'deadline_evaluations',
          'cbs_id',
          'my_gov_id',
          'regulation_id',
          'max_point_2',
          'max_point_3',
          'max_point_4',
          'max_point_5',
          'max_point_6',
        ].includes(el.code_name)
    )
    .reduce((acc, el) => {
      if (el.code_name === 'short_title')
        return [
          ...acc,
          el,
          {
            label: <FormattedMessage id="section13.directorTitle" defaultMessage="Руководитель организации:" />,
            value: '',
            code_name: 'director',
          },
        ];
      if (el.code_name === 'phone_ceo')
        return [
          ...acc,
          el,
          {
            label: <FormattedMessage id="section13.legalAddressTitle" defaultMessage="Юридический адрес:" />,
            value: '',
            code_name: 'legal_address',
          },
          orgInfo?.house_no_ceo,
          orgInfo?.street_name_ceo,
        ];
      if (el.code_name === 'website') {
        let finalLink = el?.value?.includes('http' || 'https') ? el.value : `http://${el.value}`;
        return [
          ...acc,
          {
            ...el,
            value: (
              <a href={finalLink} target="_blank" rel="noopener noreferrer" style={{ color: '#1976d2' }}>
                {el.value}
              </a>
            ),
          },
        ];
      }
      return [...acc, el];
    }, []);
  const handleEdit = async () => {
    await dispatch(getRegions());
    await dispatch(getPositions());
    handleOpen();
  };

  const handleDownload = () => {
    let reportTemplate = null;
    const orgCategoryId = localStorage.getItem('orgCategoryId');
    let translatedValue;
    switch (orgCategoryId) {
      case '1':
        reportTemplate = 'FirstTemplate';
        translatedValue = intl.formatMessage({
          id: 'section13.reportExportTemplateFirst',
          defaultMessage: 'Форма 1',
        });
        break;
      case '2':
        reportTemplate = 'SecondTemplate';
        translatedValue = intl.formatMessage({
          id: 'section13.reportExportTemplateSecond',
          defaultMessage: 'Форма 2',
        });
        break;
      default:
        reportTemplate = null;
    }
    const paramsReportTemplate = {
      org_category_id: orgCategoryId,
      report_template_type: reportTemplate,
    };
    axios({
      url: routes.reportRating(),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 26),
      responseType: 'blob',
      data: paramsReportTemplate,
    }).then((res) => {
      fileDownload(res.data, `${translatedValue}.xlsx`);
    });
  };

  const handlePushArchive = () => {
    history.push('/rating');
  };

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneOrgInfo.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;
  return requestState === 'request' ? (
    <Spinner />
  ) : (
    <>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <DashedTableCell align="center">{labelsStatOrganization[2]?.title}</DashedTableCell>
              <DashedTableCell align="center">{labelsStatOrganization[3]?.title}</DashedTableCell>
              <DashedTableCell align="center">{labelsStatOrganization[4]?.title}</DashedTableCell>
              <DashedTableCell align="center">{labelsStatOrganization[5]?.title}</DashedTableCell>
              <DashedTableCell align="center">{labelsStatOrganization[6]?.title}</DashedTableCell>
              <DashedTableCell align="center">
                <FormattedMessage id="drawerList.total" defaultMessage="Итого" />
              </DashedTableCell>
              <DashedTableCell align="center">%</DashedTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataStatOrganization.map((org) => (
              <StyledTableRow key={org.id}>
                <DashedTableCell align="center">{org.points[2]?.point ? org.points[2]?.point : 0}</DashedTableCell>
                <DashedTableCell align="center">{org.points[3]?.point ? org.points[3]?.point : 0}</DashedTableCell>
                <DashedTableCell align="center">{org.points[4]?.point ? org.points[4]?.point : 0}</DashedTableCell>
                <DashedTableCell align="center">{org.points[5]?.point ? org.points[5]?.point : 0}</DashedTableCell>
                <DashedTableCell align="center">{org.points[6]?.point ? org.points[6]?.point : 0}</DashedTableCell>
                <DashedTableCell align="center">{org.points.TOTAL?.point.toFixed(2)}</DashedTableCell>
                <DashedTableCell align="center">
                  {isNaN(org.points.TOTAL_PERCENTAGE) ? 0 : org.points.TOTAL_PERCENTAGE?.toFixed(2)}
                </DashedTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="dFlex dFJustifyFxEnd mb">
        <Button variant="contained" color="primary" onClick={handlePushArchive} className="mr">
          Рейтинг
        </Button>
        <Button variant="contained" color="primary" onClick={handleDownload} className="mr">
          Экспорт
        </Button>
        <a href={`https://api.nis.uz/${link}`} className="nonUnderline">
          <Button variant="contained" color="primary">
            Анкета
          </Button>
        </a>
      </div>
      <CustomTable
        rows={finalInfo}
        title={sectionTabs[selectedTabIndex]?.title}
        head={
          <>
            {isVisible && (
              <IconButton onClick={handleEdit} color="inherit">
                <CreateIcon />
              </IconButton>
            )}
            <Dialog
              dialogState={dialogState}
              onClose={handleClose}
              title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
              <CEOForm
                className="w80 mAuto mt10"
                defaultValues={defaultValues}
                labels={labels}
                submitAction={handleClose}
              />
            </Dialog>
          </>
        }
      />
    </>
  );
};

export default OrgInfo;
