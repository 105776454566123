import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import routes from 'monitoring/routes';
import { useDispatch, useSelector } from 'react-redux';
import { generateHeaders, editStep, editCurrentProject, getStepsLogs } from 'actions';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

function FundsForm({ labels, closeDialog }) {
  const step = useSelector((state) => state.m_steps.current);
  const { handleSubmit, register } = useForm({ defaultValues: step });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.editStep('change-funds', step.id),
      method: 'put',
      data: data,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editStep(data));
        dispatch(
          editCurrentProject({
            amount_involved_funds: data.amount_involved_funds,
            amount_used_funds: data.amount_used_funds,
          })
        );
        dispatch(getStepsLogs(step.id)(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      <TextField
        name="amount_involved_funds"
        label={labels.amount_involved_funds}
        inputRef={register}
        type="number"
        className="mb"
        inputProps={{ min: 0, step: 0.01 }}
        fullWidth
      />

      <TextField
        name="amount_used_funds"
        label={labels.amount_used_funds}
        inputRef={register}
        type="number"
        className="mb"
        inputProps={{ min: 0, step: 0.01 }}
        fullWidth
      />
      <TextField
        name="comment"
        multiline
        rowsMax="4"
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
        inputRef={register}
        type="text"
        className="mb2"
        fullWidth
      />
      <Button color="primary" variant="contained" type="submit">
        {loading ? <CircularProgress color="inherit" size={30} /> : <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />}
      </Button>
    </form>
  );
}

export default FundsForm;
