import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setOpenDataError = createAction('OPEN_DATA_SET_ERROR');

export const getLabelsOpenDataRequest = createAction('LABELS_OPEN_DATA_GET_REQUEST');
export const getLabelsOpenDataSuccess = createAction('LABELS_OPEN_DATA_GET_SUCCESS');
export const getLabelsOpenDataFailure = createAction('LABELS_OPEN_DATA_GET_FAILURE');

export const getLabelsOpenData = () => async (dispatch) => {
  dispatch(getLabelsOpenDataRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteOpenDataLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsOpenDataSuccess(data));
  } catch (e) {
    console.log(e.response);
    dispatch(setOpenDataError(e?.response));
    dispatch(getLabelsOpenDataFailure());
  }
};

export const getOpenDatasRequest = createAction('OPEN_DATAS_GET_REQUEST');
export const getOpenDatasSuccess = createAction('OPEN_DATAS_GET_SUCCESS');
export const getOpenDatasFailure = createAction('OPEN_DATAS_GET_FAILURE');

export const getOpenDatas = (page) => async (dispatch) => {
  dispatch(getOpenDatasRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteOpenDatas(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });
    dispatch(getOpenDatasSuccess(data));
  } catch (e) {
    dispatch(setOpenDataError(e?.response));
    dispatch(getOpenDatasFailure());
  }
};

export const getElementOpenDataRequest = createAction('ELEMENT_OPEN_DATA_GET_REQUEST');
export const getElementOpenDataSuccess = createAction('ELEMENT_OPEN_DATA_GET_SUCCESS');
export const getElementOpenDataFailure = createAction('ELEMENT_OPEN_DATA_GET_FAILURE');

export const getElementOpenData = (id) => async (dispatch) => {
  dispatch(getElementOpenDataRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteOpenDataOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getElementOpenDataSuccess(data));
  } catch (e) {
    dispatch(setOpenDataError(e?.response));
    dispatch(getElementOpenDataFailure());
  }
};

export const addElementOpenDataRequest = createAction('ELEMENT_OPEN_DATA_SEND_REQUEST');
export const addElementOpenDataSuccess = createAction('ELEMENT_OPEN_DATA_SEND_SUCCESS');
export const addElementOpenDataFailure = createAction('ELEMENT_OPEN_DATA_SEND_FAILURE');

export const addElementOpenData = (formData) => async (dispatch) => {
  dispatch(addElementOpenDataRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionTwoWebsiteOpenDatas(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addElementOpenDataSuccess(data));
  } catch (e) {
    dispatch(setOpenDataError(e?.response));
    dispatch(addElementOpenDataFailure());
  }
};

export const deleteElementOpenDataRequest = createAction('ELEMENT_OPEN_DATA_DELETE_REQUEST');
export const deleteElementOpenDataSuccess = createAction('ELEMENT_OPEN_DATA_DELETE_SUCCESS');
export const deleteElementOpenDataFailure = createAction('ELEMENT_OPEN_DATA_DELETE_FAILURE');

export const deleteElementOpenData = (id) => async (dispatch) => {
  dispatch(deleteElementOpenDataRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionTwoWebsiteOpenDataOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteElementOpenDataSuccess({ id }));
  } catch (e) {
    dispatch(setOpenDataError(e?.response));
    dispatch(deleteElementOpenDataFailure());
  }
};

export const editElementOpenDataRequest = createAction('ELEMENT_OPEN_DATA_EDIT_REQUEST');
export const editElementOpenDataSuccess = createAction('ELEMENT_OPEN_DATA_EDIT_SUCCESS');
export const editElementOpenDataFailure = createAction('ELEMENT_OPEN_DATA_EDIT_FAILURE');

export const editElementOpenData = (id, formData) => async (dispatch) => {
  dispatch(editElementOpenDataRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionTwoWebsiteOpenDataOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editElementOpenDataSuccess(data));
    dispatch(getElementOpenData(id));
  } catch (e) {
    dispatch(setOpenDataError(e?.response));
    dispatch(editElementOpenDataFailure());
  }
};
