import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import HistoryIcon from '@material-ui/icons/History';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CreateIcon from '@material-ui/icons/Create';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import { Button, ButtonGroup, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import { useDialog } from 'common/hooks';
import { StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Dialog from 'components/reused/SimpleDialog';
import FundsForm from './DialogForms/FundsForm';
import StatusForm from './DialogForms/StatusForm';
import DeadlineForm from './DialogForms/DeadlineForm';
import CheckingForm from './DialogForms/CheckingForm';
import UpdateForm from './DialogForms/UpdateForm';
import routes from 'monitoring/routes';
import FileUploader from 'monitoring/FileUploader';
import Log from 'monitoring/Lists/Logs';
import { getStepsLogs } from 'actions/monitoring';

function Step({ labels, isExecutor = false }) {
  const intl = useIntl();

  const [statusForm, openStatusForm, closeStatusForm] = useDialog();
  const [fundsForm, openFundsForm, closeFundsForm] = useDialog();

  const [deadlineForm, openDeadlineForm, closeDeadlineForm] = useDialog();
  const [approvalForm, openApprovalForm, closeApprovalForm] = useDialog();
  const [refuseForm, openRefuseForm, closeRefuseForm] = useDialog();
  const [stepForm, openStepForm, closeStepForm] = useDialog();

  const step = useSelector((state) => state.m_steps.current);
  const classes = useStyles();

  const currentUser = useSelector((state) => state.profile.currentUser);
  const isOrganization = currentUser?.roles.includes('monitoring_organization');
  const isSupervisor = currentUser?.roles.includes('monitoring_supervisor');
  const isModerator = currentUser?.roles.includes('monitoring_moderator');
  const isBothSuperRole = isSupervisor || isModerator;

  const { stepLogs, configStepsLogs } = useSelector((state) => state.m_log);

  const isPerformed = step.status_id === 2;
  return (
    <div className="dFlex ml2 mt4">
      <div className="w70">
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.headRow}>
                <StyledTableCell>
                  {step.title}
                  {step.is_start === 1 && ` (${labels.is_start})`}
                  {step.is_finish === 1 && ` (${labels.is_finish})`}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {isOrganization && isPerformed && (
                    <ButtonGroup disabled={isExecutor} variant="outlined">
                      <Button title="Изменить объем средств" color="primary" onClick={openFundsForm}>
                        <AttachMoneyIcon />
                      </Button>
                      <Button title={'Отправить этап на согласование'} color="primary" onClick={openApprovalForm}>
                        <DoneAllIcon />
                      </Button>
                    </ButtonGroup>
                  )}

                  {isBothSuperRole && (
                    <ButtonGroup disabled={isExecutor} variant="outlined">
                      <Button title={'Изменить этап'} color="primary" onClick={openStepForm}>
                        <CreateIcon />
                      </Button>
                      <Button
                        title={intl.formatMessage({
                          id: 'monitoring.project.statusForm',
                          defaultMessage: 'Изменить статус',
                        })}
                        color="primary"
                        onClick={openStatusForm}>
                        <DonutLargeIcon />
                      </Button>
                      {isSupervisor && (
                        <Button title={'Изменить проект'} color="primary" onClick={openDeadlineForm}>
                          <HourglassFullIcon />
                        </Button>
                      )}
                      <Button title={'Отклонить утверждение этапа'} color="primary" onClick={openRefuseForm}>
                        <HistoryIcon />
                      </Button>
                    </ButtonGroup>
                  )}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell>{labels.status_id}</StyledTableCell>
                <StyledTableCell align="right">{step.status_name}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell>{labels.start_date}</StyledTableCell>
                <StyledTableCell align="right">{step.start_date}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell>{labels.end_date}</StyledTableCell>
                <StyledTableCell align="right">{step.end_date}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell>{labels.amount_involved_funds}</StyledTableCell>
                <StyledTableCell align="right">{step.amount_involved_funds}</StyledTableCell>
              </StyledTableRow>

              <StyledTableRow>
                <StyledTableCell>{labels.amount_used_funds}</StyledTableCell>
                <StyledTableCell align="right">{step.amount_used_funds}</StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <FileUploader isExecutor={isExecutor} stepId={step.id} />
      <div className="w100 mb2 mr2">
        <Log
          isExecutor={isExecutor}
          getLogs={getStepsLogs(step.id)}
          logList={stepLogs}
          configLogs={configStepsLogs}
          stepId={step.id}
          title={<FormattedMessage id="monitoring.roadmaps.historySteps" defaultMessage="История этапов" />}
        />
      </div>
      <Dialog dialogState={fundsForm} onClose={closeFundsForm} title="Заполните форму">
        <FundsForm closeDialog={closeFundsForm} labels={labels} />
      </Dialog>
      <Dialog
        dialogState={statusForm}
        onClose={closeStatusForm}
        title={intl.formatMessage({
          id: 'monitoring.project.statusForm',
          defaultMessage: 'Изменить статус',
        })}>
        <StatusForm labels={labels} closeDialog={closeStatusForm} />
      </Dialog>

      <Dialog dialogState={deadlineForm} onClose={closeDeadlineForm} title={'Форма проекта'}>
        <DeadlineForm labels={labels} closeDialog={closeDeadlineForm} />
      </Dialog>
      <Dialog dialogState={refuseForm} onClose={closeRefuseForm} title={'Отклонить утверждение этапа'}>
        <CheckingForm
          label={labels.status_id}
          url={routes.editStep('refuse', step.id)}
          value={2}
          closeDialog={closeRefuseForm}
        />
      </Dialog>
      <Dialog dialogState={approvalForm} onClose={closeApprovalForm} title={'Отправить этап на согласование'}>
        <CheckingForm
          label={labels.status_id}
          url={routes.editStep('send-to-checking', step.id)}
          value={4}
          closeDialog={closeApprovalForm}
        />
      </Dialog>
      <Dialog dialogState={stepForm} onClose={closeStepForm} title={'Форма этапа'}>
        <UpdateForm labels={labels} closeDialog={closeStepForm} />
      </Dialog>
    </div>
  );
}

export default Step;
