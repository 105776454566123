import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { generateHeaders } from './dictionary';

export const getEvalActivationInfoRequest = createAction('EVAL_ACTIVATION_INFO_GET_REQUEST');
export const getEvalActivationInfoSuccess = createAction('EVAL_ACTIVATION_INFO_GET_SUCCESS');
export const getEvalActivationInfoFailure = createAction('EVAL_ACTIVATION_INFO_GET_FAILURE');

export const getEvalActivationInfo = (id) => async (dispatch) => {
  dispatch(getEvalActivationInfoRequest());

  try {
    const { data } = await axios.get(routes.evaluationActivation('view'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });

    dispatch(getEvalActivationInfoSuccess(data));
  } catch (e) {
    //dispatch(getEvalActivationInfoFailure(e?.response?.data));
    console.log(e);
  }
};

export const updateEvalActivationInfoRequest = createAction('EVAL_ACTIVATION_INFO_UPDATE_REQUEST');
export const updateEvalActivationInfoSuccess = createAction('EVAL_ACTIVATION_INFO_UPDATE_SUCCESS');
export const updateEvalActivationInfoFailure = createAction('EVAL_ACTIVATION_INFO_UPDATE_FAILURE');

export const updateEvalActivationInfo = (id, formValues) => async (dispatch) => {
  dispatch(updateEvalActivationInfoRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.evaluationActivation('create'),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: id },
    });

    dispatch(updateEvalActivationInfoSuccess(data));
  } catch (e) {
    //dispatch(updateEvalActivationInfoFailure(e?.response?.data));
    console.log(e);
  }
};
