import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoSystemsError = createAction('INFO_SYSTEMS_SET_ERROR');

export const getInfoSystemsRequest = createAction('INFO_SYSTEMS_GET_REQUEST');
export const getInfoSystemsSuccess = createAction('INFO_SYSTEMS_GET_SUCCESS');
export const getInfoSystemsFailure = createAction('INFO_SYSTEMS_GET_FAILURE');

export const getInfoSystems = (page) => async (dispatch) => {
  dispatch(getInfoSystemsRequest());

  try {
    const { data } = await axios.get(routes.sectionFiveInfoSystems(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });
    dispatch(getInfoSystemsSuccess(data));
  } catch (e) {
    dispatch(getInfoSystemsFailure());
    dispatch(setInfoSystemsError(e?.response));
  }
};

export const getLabelsInfoSystemRequest = createAction('LABELS_INFO_SYSTEM_GET_REQUEST');
export const getLabelsInfoSystemSuccess = createAction('LABELS_INFO_SYSTEM_GET_SUCCESS');
export const getLabelsInfoSystemFailure = createAction('LABELS_INFO_SYSTEM_GET_FAILURE');

export const getLabelsInfoSystem = () => async (dispatch) => {
  dispatch(getLabelsInfoSystemRequest());

  try {
    const { data } = await axios.get(routes.sectionFiveInfoSystemLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsInfoSystemSuccess(data));
  } catch(e) {
    dispatch(getLabelsInfoSystemFailure());
    dispatch(setInfoSystemsError(e?.response));
  }
}

export const getElementInfoSystemsRequest = createAction('INFO_SYSTEMS_ELEMENT_GET_REQUEST');
export const getElementInfoSystemsSuccess = createAction('INFO_SYSTEMS_ELEMENT_GET_SUCCESS');
export const getElementInfoSystemsFailure = createAction('INFO_SYSTEMS_ELEMENT_GET_FAILURE');

export const getElementInfoSystems = (id) => async (dispatch) => {
  dispatch(getElementInfoSystemsRequest());

  try {
    const { data } = await axios.get(routes.sectionFiveInfoSystemsOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getElementInfoSystemsSuccess(data));
  } catch (e) {
    dispatch(setInfoSystemsError(e?.response));
    dispatch(getElementInfoSystemsFailure());
  }
};

export const addElementInfoSystemsRequest = createAction('INFO_SYSTEMS_ELEMENT_ADD_REQUEST');
export const addElementInfoSystemsSuccess = createAction('INFO_SYSTEMS_ELEMENT_ADD_SUCCESS');
export const addElementInfoSystemsFailure = createAction('INFO_SYSTEMS_ELEMENT_ADD_FAILURE');

export const addElementInfoSystems = (formData) => async (dispatch) => {
  dispatch(addElementInfoSystemsRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionFiveInfoSystems(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addElementInfoSystemsSuccess(data));
  } catch (e) {
    dispatch(setInfoSystemsError(e?.response));
    dispatch(addElementInfoSystemsFailure());
  }
};

export const deleteElementInfoSystemsRequest = createAction('INFO_SYSTEMS_ELEMENT_DELETE_REQUEST');
export const deleteElementInfoSystemsSuccess = createAction('INFO_SYSTEMS_ELEMENT_DELETE_SUCCESS');
export const deleteElementInfoSystemsFailure = createAction('INFO_SYSTEMS_ELEMENT_DELETE_FAILURE');

export const deleteElementInfoSystems = (id) => async (dispatch) => {
  dispatch(deleteElementInfoSystemsRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionFiveInfoSystemsOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteElementInfoSystemsSuccess({ id }));
  } catch (e) {
    dispatch(setInfoSystemsError(e?.response));
    dispatch(deleteElementInfoSystemsFailure());
  }
};

export const editElementInfoSystemsRequest = createAction('INFO_SYSTEMS_ELEMENT_EDIT_REQUEST');
export const editElementInfoSystemsSuccess = createAction('INFO_SYSTEMS_ELEMENT_EDIT_SUCCESS');
export const editElementInfoSystemsFailure = createAction('INFO_SYSTEMS_ELEMENT_EDIT_FAILURE');

export const editElementInfoSystems = (id, formData) => async (dispatch) => {
  dispatch(editElementInfoSystemsRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionFiveInfoSystemsOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editElementInfoSystemsSuccess(data));
    dispatch(getElementInfoSystems(id));
  } catch (e) {
    dispatch(setInfoSystemsError(e?.response));
    dispatch(editElementInfoSystemsFailure());
  }
};

export const getInfoSystemsEvaluationRequest = createAction('INFO_SYSTEMS_EVALUATION_GET_REQUEST');
export const getInfoSystemsEvaluationSuccess = createAction('INFO_SYSTEMS_EVALUATION_GET_SUCCESS');
export const getInfoSystemsEvaluationFailure = createAction('INFO_SYSTEMS_EVALUATION_GET_FAILURE');

export const getInfoSystemsEvaluation = () => async (dispatch) => {
  dispatch(getInfoSystemsEvaluationRequest());

  try {
    const { data } = await axios.get(routes.infoSystemsEvaluation('view'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: 168 },
    });
    dispatch(getInfoSystemsEvaluationSuccess(data));
  } catch (e) {
    dispatch(setInfoSystemsError(e?.response));
    dispatch(getInfoSystemsEvaluationFailure());
  }
};

export const saveInfoSystemsEvaluationRequest = createAction('INFO_SYSTEMS_EVALUATION_SAVE_REQUEST');
export const saveInfoSystemsEvaluationSuccess = createAction('INFO_SYSTEMS_EVALUATION_SAVE_SUCCESS');
export const saveInfoSystemsEvaluationFailure = createAction('INFO_SYSTEMS_EVALUATION_SAVE_FAILURE');

export const saveInfoSystemsEvaluation = (paramId, formValues) => async (dispatch) => {
  dispatch(saveInfoSystemsEvaluationRequest());

  try {
    const { data } = await axios({
      url: routes.infoSystemsEvaluation('create'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: 168, param_id: paramId },
    });
    dispatch(saveInfoSystemsEvaluationSuccess(data));
  } catch (e) {
    //dispatch(saveInfoSystemsEvaluationFailure(e?.response));
    console.log(e);
  }
};

export const deleteInfoSystemsEvaluationRequest = createAction('INFO_SYSTEMS_EVALUATION_DELETE_REQUEST');
export const deleteInfoSystemsEvaluationSuccess = createAction('INFO_SYSTEMS_EVALUATION_DELETE_SUCCESS');
export const deleteInfoSystemsEvaluationFailure = createAction('INFO_SYSTEMS_EVALUATION_DELETE_FAILURE');

export const deleteInfoSystemsEvaluation = (paramId) => async (dispatch) => {
  dispatch(deleteInfoSystemsEvaluationRequest());

  try {
    await axios.delete(routes.infoSystemsEvaluation('delete'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: 168, param: paramId },
    });
    dispatch(getInfoSystemsEvaluation());
  } catch (e) {
    //dispatch(deleteInfoSystemsEvaluationFailure(e?.response));
    console.log(e);
  }
};

export const getInfoSystemsPointsSuccess = createAction('INFO_SYSTEM_POINTS_GET_SUCCESS');

export const getInfoSystemsPoints = (tabId, params) => async (dispatch) => {
  try {
    const promises = params.map(async (id) => {
      const { data } = await axios.get(routes.evaluations(), {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
        params: { id: tabId, param_id: id },
      });

      return data;
    });
    const data = await Promise.all(promises);
    dispatch(getInfoSystemsPointsSuccess(data));
  } catch (e) {
    // dispatch(setError(e.response));
  }
};
