import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';

import Dashboard from '../../Dashboard';
import Alert from '../../reused/Alert';
import { resetError } from '../../../actions';
import OrganizationHistory from './OrganizationHistory';
import { parse } from 'common/utils';

function Rating() {
  const dispatch = useDispatch();
  const intl = useIntl();

  const errorHandler = useSelector((state) => state.errorHandler, shallowEqual);
  const [alertState, setAlertState] = useState(errorHandler.hasError);

  const { orgAllSections, orgStatHistory } = useSelector((state) => state.dictionary, shallowEqual);
  const sortedOrganizations = orgStatHistory
    .map((org) => {
      const total =
        parse(org.total_s2?.toFixed(2)) +
        parse(org.total_s3?.toFixed(2)) +
        parse(org.s4) +
        parse(org.s5) +
        parse(org.s6);
      const percent = (total / parseInt(org.max_point)) * 100;
      return { ...org, percent };
    })
    .sort((a, b) => b.percent - a.percent);

  useEffect(() => {
    if (errorHandler.status === 404) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 4000);
    }
  }, [errorHandler, dispatch]);
  return (
    <div>
      <Dashboard>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="p2">
              <OrganizationHistory data={sortedOrganizations} rawData={orgAllSections} />
            </Paper>
          </Grid>
        </Grid>
      </Dashboard>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDataNotFound',
          defaultMessage: 'Данные отсутствуют!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}

export default Rating;
