import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Radio, RadioGroup } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { addElementInfoSystems, editElementInfoSystems } from '../../../actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Form = ({ submitAction, defaultValues, isEditableMode, defaultClassifiers = [], defaultAutoProcesses = [], defaultPurpose = [] }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { element, labels, data } = useSelector((state) => state.sectionFiveInfoSystems, shallowEqual);

  const titles = data.map(({ title }) => title);

  const { stageList, purposeTypes, classifierTypes, autoProcessTypes } = useSelector((state) => state.dictionary, shallowEqual);
  const _defaultValues = isEditableMode
    ? defaultValues
    : {
        is_used_classifiers: '',
        has_integration: '',
        has_payment_system: '',
        has_complex_system_integration: '',
        has_central_db_integration: '',
        has_classifier_integration: '',
        has_interdepartmental_integration: '',
      };
  const { register, handleSubmit, errors, control, watch } = useForm({ defaultValues: _defaultValues, mode: 'onChange' });
  const radioWatch = watch();

  const [purpose, setPurpose] = useState(defaultPurpose);
  const [classifier, setClassifier] = useState(defaultClassifiers);
  const [autoProcess, setAutoProcess] = useState(defaultAutoProcesses);

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());

  const onSubmit = (data) => {
    const resetValue = {};
    if (!classifier.find((value) => value.id === 6)) resetValue.other_classifier_name = '';
    if (isEditableMode)
      dispatch(
        editElementInfoSystems(element.id.value, {
          ...data,
          ...resetValue,
          organization_id: localStorage.orgId,
          classifiers: classifier.map((el) => el.id),
          auto_processes: autoProcess.map((el) => el.id),
          purpose: purpose.map((el) => el.id),
        })
      );
    else
      dispatch(
        addElementInfoSystems({
          ...data,
          ...resetValue,
          organization_id: localStorage.orgId,
          classifiers: classifier.map((el) => el.id),
          auto_processes: autoProcess.map((el) => el.id),
          purpose: purpose.map((el) => el.id),
        })
      );
    submitAction();
  };

  return (
    <form className="infoSystem__dialogFormEdit" onSubmit={handleSubmit(onSubmit)}>
      <div className="container">
        <div className="container__row">
          <div className="mb">
            <TextField
              inputRef={register({
                required: true,
                validate: (value) => {
                  let isValid = false;
                  if (!isEditableMode) isValid = titles.includes(value);
                  return !isValid;
                },
              })}
              error={!!errors.title}
              name="title"
              className="infoSystem__dialogFormEditTextField mb"
              label={labels.title}
              helperText={
                errors.title?.type === 'validate' &&
                intl.formatMessage({
                  id: 'global.errorDublicateInformationSystems',
                  defaultMessage: 'Наименование ИС не должно повторяться!',
                })
              }
            />
          </div>
          <div className="mb">
            <Autocomplete
              multiple
              value={purpose}
              onChange={(_, newValue) => setPurpose(newValue)}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option.id === value.id}
              options={purposeTypes}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color="primary" />
                  {option.title}
                </React.Fragment>
              )}
              renderInput={(params) => <TextField {...params} label={labels.purpose} className="mb2" required={purpose.length === 0} />}
            />
          </div>

          <div className="mb">
            <TextField
              name="registry_no"
              inputRef={register({ required: true })}
              error={!!errors.registry_no}
              className="infoSystem__dialogFormEditTextField mb"
              label="Регистрационный номер в Реестре ИС"
            />
          </div>

          <div className="mb">
            <FormControl className="infoSystem__dialogFormEditTextField mb">
              <FormLabel>{labels.state_id}</FormLabel>
              <NativeSelect inputProps={{ ref: register({ required: true }) }} error={!!errors.state_id} name="state_id">
                {stageList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>

          <div className="mb">
            <label htmlFor="commissioning_date" className={`nativeLabel ${!!errors.commissioning_date && 'error'}`}>
              {labels.commissioning_date}
            </label>
            <TextField
              inputRef={register({ required: true })}
              error={!!errors.commissioning_date}
              name="commissioning_date"
              className="infoSystem__dialogFormEditTextField mb"
              id="commissioning_date"
              type="date"
              defaultValue={defaultDate}
            />
          </div>

          <div className="mb">
            <label htmlFor="expert_opinion_date" className={`nativeLabel ${!!errors.expert_opinion_date && 'error'}`}>
              {labels.expert_opinion_date}
            </label>
            <TextField
              inputRef={register({ required: true })}
              error={!!errors.expert_opinion_date}
              name="expert_opinion_date"
              className="infoSystem__dialogFormEditTextField mb"
              id="expert_opinion_date"
              type="date"
              defaultValue={defaultDate}
            />
          </div>

          <div className="mb">
            <TextField
              inputRef={register({ required: true })}
              error={!!errors.expert_opinion_no}
              name="expert_opinion_no"
              className="infoSystem__dialogFormEditTextField"
              label={labels.expert_opinion_no}
            />
          </div>

          <div className="mb3">
            <textarea
              name="services_list"
              className="textarea"
              id="services_list"
              rows="4"
              ref={register({ required: true })}
              className={`infoSystem__dialogFormEditTextField ${!!errors.services_list && 'error'}`}></textarea>
            <label htmlFor="services_list" className={`nativeLabel w100 dBlock ${!!errors.services_list && 'error'}`}>
              {labels.services_list}
            </label>
          </div>

          <div className="mb">
            <TextField
              inputRef={register({ required: true })}
              type="number"
              error={!!errors.count_users}
              name="count_users"
              className="infoSystem__dialogFormEditTextField mb"
              label={labels.count_users}
            />
          </div>
        </div>

        <div className="container__row">
          <div className="mb">
            <label htmlFor="is_used_classifiers" className={`nativeLabel ${!!errors.is_used_classifiers && 'error'}`}>
              {labels.is_used_classifiers}
            </label>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                  />
                </RadioGroup>
              }
              name="is_used_classifiers"
              control={control}
              rules={{ required: true }}
            />
            {radioWatch.is_used_classifiers === '1' && (
              <>
                <Autocomplete
                  multiple
                  value={classifier}
                  onChange={(event, newValue) => setClassifier(newValue)}
                  getOptionLabel={(option) => option.title}
                  getOptionSelected={(option, value) => option.id === value.id}
                  options={classifierTypes}
                  disableCloseOnSelect
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color="primary" />
                      {option.title}
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label={labels.classifiers} className="mb2" required={classifier.length === 0} />}
                />
                {classifier.find((el) => el.id === 6) && (
                  <TextField
                    fullWidth
                    name="other_classifier_name"
                    inputRef={register({ required: true })}
                    error={!!errors.other_classifier_name}
                    className="mb2"
                    helperText={labels.other_classifier_name}
                  />
                )}
              </>
            )}
            {radioWatch.is_used_classifiers === '0' && (
              <TextField
                fullWidth
                name="reason_used_classifiers"
                inputRef={register({ required: true })}
                error={!!errors.reason_used_classifiers}
                className="mb2"
                helperText={labels.reason_used_classifiers}
              />
            )}
          </div>
          <div className="mb">
            <label htmlFor="has_integration" className={`nativeLabel ${!!errors.has_integration && 'error'}`}>
              {labels.has_integration}
            </label>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                  />
                </RadioGroup>
              }
              name="has_integration"
              control={control}
              rules={{ required: true }}
            />
            {radioWatch.has_integration === '0' && (
              <TextField
                fullWidth
                name="reason_integration"
                inputRef={register({ required: true })}
                helperText={labels.reason_integration}
                error={!!errors.reason_integration}
                className="mb"
              />
            )}
            {radioWatch.has_integration === '1' && (
              <div className="ml3">
                <div className="mb">
                  <label
                    htmlFor="has_interdepartmental_integration"
                    className={`nativeLabel ${!!errors.has_interdepartmental_integration && 'error'}`}>
                    {labels.has_interdepartmental_integration}
                  </label>
                  <Controller
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                        />
                      </RadioGroup>
                    }
                    name="has_interdepartmental_integration"
                    control={control}
                    rules={{ required: true }}
                  />
                  {radioWatch.has_interdepartmental_integration === '1' && (
                    <TextField
                      fullWidth
                      name="data_interdepartmental"
                      inputRef={register({ required: true })}
                      helperText={labels.data_interdepartmental}
                      error={!!errors.data_interdepartmental}
                      className="mb"
                    />
                  )}
                </div>
                <div className="mb">
                  <label htmlFor="has_classifier_integration" className={`nativeLabel ${!!errors.has_classifier_integration && 'error'}`}>
                    {labels.has_classifier_integration}
                  </label>
                  <Controller
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                        />
                      </RadioGroup>
                    }
                    name="has_classifier_integration"
                    control={control}
                    rules={{ required: true }}
                  />
                  {radioWatch.has_classifier_integration === '1' && (
                    <TextField
                      fullWidth
                      name="data_classifiers"
                      inputRef={register({ required: true })}
                      helperText={labels.data_classifiers}
                      error={!!errors.data_classifiers}
                      className="mb"
                    />
                  )}
                </div>
                <div className="mb">
                  <label htmlFor="has_central_db_integration" className={`nativeLabel ${!!errors.has_central_db_integration && 'error'}`}>
                    {labels.has_central_db_integration}
                  </label>
                  <Controller
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                        />
                      </RadioGroup>
                    }
                    name="has_central_db_integration"
                    control={control}
                    rules={{ required: true }}
                  />
                  {radioWatch.has_central_db_integration === '1' && (
                    <TextField
                      fullWidth
                      name="data_central_db"
                      inputRef={register({ required: true })}
                      helperText={labels.data_central_db}
                      error={!!errors.data_central_db}
                      className="mb"
                    />
                  )}
                </div>
                <div className="mb">
                  <label htmlFor="has_complex_system_integration" className={`nativeLabel ${!!errors.has_complex_system_integration && 'error'}`}>
                    {labels.has_complex_system_integration}
                  </label>
                  <Controller
                    as={
                      <RadioGroup row>
                        <FormControlLabel
                          value="1"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                        />
                        <FormControlLabel
                          value="0"
                          control={<Radio color="primary" />}
                          label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                        />
                      </RadioGroup>
                    }
                    name="has_complex_system_integration"
                    control={control}
                    rules={{ required: true }}
                  />
                  {radioWatch.has_complex_system_integration === '1' && (
                    <TextField
                      fullWidth
                      name="data_complex_system"
                      inputRef={register({ required: true })}
                      helperText={labels.data_complex_system}
                      error={!!errors.data_complex_system}
                      className="mb"
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="mb">
            <label htmlFor="has_payment_system" className={`nativeLabel ${!!errors.has_payment_system && 'error'}`}>
              {labels.has_payment_system}
            </label>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                  />
                </RadioGroup>
              }
              control={control}
              name="has_payment_system"
              rules={{ required: true }}
            />
            {radioWatch.has_payment_system === '0' && (
              <TextField
                fullWidth
                name="reason_payment_system"
                inputRef={register({ required: true })}
                helperText={labels.reason_payment_system}
                error={!!errors.reason_payment_system}
                className="mb"
              />
            )}
            {radioWatch.has_payment_system === '1' && (
              <TextField
                fullWidth
                name="payment_system_name"
                inputRef={register({ required: true })}
                helperText={labels.payment_system_name}
                error={!!errors.payment_system_name}
                className="mb"
              />
            )}
          </div>
          
          <div className="mb">
            <label htmlFor="has_payment_system" className={`nativeLabel ${!!errors.has_auto_service && 'error'}`}>
              {labels.has_auto_service}
            </label>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                  />
                </RadioGroup>
              }
              control={control}
              name="has_auto_service"
              rules={{ required: true }}
            />
          </div>

          <div className="mb">
            {purpose.find((value) => value.id === 1) && (
              <Autocomplete
                multiple
                value={autoProcess}
                onChange={(event, newValue) => {
                  setAutoProcess(newValue);
                }}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.title === value.title}
                options={autoProcessTypes}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} color="primary" />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => <TextField {...params} label={labels.auto_processes} className="mb2" required={autoProcess.length === 0} />}
              />
            )}
          </div>
        </div>
      </div>
      <Button color="primary" variant="contained" className="mb m0Auto" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
