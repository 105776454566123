import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Pagination } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';

import { StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Dialog from 'components/reused/SimpleDialog';
import FullDialog from 'components/reused/FullScreenDialog';
import Title from 'components/reused/Title';
import Loader from 'components/reused/Loader';
import { deleteStep, get, getSteps, setCurrentStep } from 'actions';
import { useDialog } from 'common/hooks';
import { getLabels } from 'actions';
import StepForm from './Form';
import Step from './Step';
import Empty from 'components/reused/Empty';
import CounterPage from 'components/reused/CounterPage';

function StepList({ isExecutor = false }) {
  const { projectId, stepStatusId } = useParams();
  const [labels, setLabels] = useState({});
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (stepStatusId) {
      dispatch(getSteps({ project_id: projectId, status_id: stepStatusId }, page));
    } else {
      dispatch(getSteps({ project_id: projectId }, page));
    }

    let active = true;
    get('step-status-types').then((data) => {
      if (active) setStatuses(data);
    });
    getLabels('step').then((data) => {
      if (active) setLabels(data);
    });
    return () => (active = false);
  }, []);

  const classes = useStyles();
  const dispatch = useDispatch();

  const steps = useSelector((state) => state.m_steps.list);
  const { loadingSteps } = useSelector((state) => state.m_documentsUI);

  const [open, handleOpen, handleClose] = useDialog();
  const [view, openView, closeView] = useDialog();

  const editStep = (step, openDialog) => (event) => {
    event.preventDefault();
    dispatch(setCurrentStep(step));
    openDialog();
  };

  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const [stepDeleteId, setStepDeleteId] = useState(null);
  const handleModalDelete = (id) => () => {
    handleOpenDelete();
    setStepDeleteId(id);
  };

  const handleDelete = () => {
    handleCloseDelete();
    dispatch(deleteStep(stepDeleteId));
  };

  const [status, setStatus] = useState(stepStatusId || 'all');
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    if (event.target.value === 'all') {
      dispatch(getSteps({ project_id: projectId }, page));
    } else {
      dispatch(getSteps({ project_id: projectId, status_id: event.target.value }, page));
    }
  };

  const pagination = useSelector((state) => state.m_steps.paginationConfig);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    if (status === 'all') {
      dispatch(getSteps({ project_id: projectId }, value));
    } else {
      dispatch(getSteps({ project_id: projectId, status_id: status }, value));
    }
  };

  const currentUser = useSelector((state) => state.profile.currentUser);
  const isModerator =
    currentUser?.roles.includes('monitoring_supervisor') || currentUser?.roles.includes('monitoring_moderator');

  return (
    <div className="w100">
      <div className="spaceBetween mb3 mt2">
        <Title
          title={<FormattedMessage id="monitoring.roadmaps.stepsProject" defaultMessage="Этапы проекта" />}
          disableBorder
        />
        {steps.length !== 0 && <CounterPage config={pagination} page={page} length={steps.length} />}
        {isModerator && (
          <Button disabled={isExecutor} variant="contained" color="primary" onClick={handleOpen}>
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          </Button>
        )}
      </div>
      <Dialog dialogState={open} onClose={handleClose} title="Заполните форму ">
        <StepForm labels={labels} closeDialog={handleClose} />
      </Dialog>
      <TableContainer component={Paper} className={classes.container}>
        {loadingSteps ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.headRow}>
                <StyledTableCell>№</StyledTableCell>
                <StyledTableCell>{labels[`title_${localStorage.lang || 'ru'}`]}</StyledTableCell>
                <StyledTableCell>
                  <FormControl style={{ width: 100 }}>
                    <InputLabel id="status-select-label">{labels.status_id}</InputLabel>
                    <Select
                      className="w100"
                      labelId="status-select-label"
                      id="status-select"
                      value={status}
                      onChange={handleChangeStatus}>
                      <MenuItem value="all">Все</MenuItem>
                      {statuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>

                {/* <StyledTableCell>{labels.amount_involved_funds}</StyledTableCell> */}
                <StyledTableCell>{labels.amount_used_funds}</StyledTableCell>
                <StyledTableCell>{labels.start_date}</StyledTableCell>
                <StyledTableCell>{labels.end_date}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {steps.map((step) => {
                let color = '';
                let statusId = step.status_id;
                switch (statusId) {
                  case 1:
                    color = '#00ff0060';
                    break;
                  case 2:
                    color = '#0000ff60';
                    break;
                  case 3:
                    color = 'red';
                    break;
                  case 4:
                    color = '#ffff00';
                    break;
                  default:
                    color = '#fff';
                }
                return (
                  <StyledTableRow key={step.id}>
                    <StyledTableCell>{step.step_no}</StyledTableCell>
                    <StyledTableCell>
                      <a href="#" className="m_link" onClick={editStep(step, openView)}>
                        {step.title}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: color }}>{step.status_name}</StyledTableCell>

                    {/* <StyledTableCell>{step.amount_involved_funds}</StyledTableCell> */}
                    <StyledTableCell>{step.amount_used_funds}</StyledTableCell>
                    <StyledTableCell>{step.start_date}</StyledTableCell>
                    <StyledTableCell>{step.end_date}</StyledTableCell>
                    <StyledTableCell>
                      {isModerator && (
                        <IconButton color="secondary" onClick={handleModalDelete(step.id)}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

              {steps.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan="7">
                    <Empty title="Данных нет" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {pagination.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt2">
          <Pagination
            count={pagination.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
      <FullDialog dialogState={view} onClose={closeView}>
        <Step isExecutor={isExecutor} labels={labels} />
      </FullDialog>

      <Dialog title="Подтвердить удаление" dialogState={openDelete} onClose={handleCloseDelete}>
        <Button className="mr ml mt mb" variant="contained" color="primary" onClick={handleCloseDelete}>
          Закрыть
        </Button>
        <Button className="mr ml mt mb" variant="contained" color="secondary" onClick={handleDelete}>
          Удалить?
        </Button>
      </Dialog>
    </div>
  );
}

export default StepList;
