import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const sourceStat = handleActions(
  {
    [actions.getSourceStatSuccess]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      labels: payload.labels,
    }),
    [actions.setSourceStatError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    labels: {
      quarters: [],
      titles: [],
    },
    data: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const sourceStatUI = handleActions(
  {
    [actions.getSourceStatRequest]: () => ({ requestState: 'request' }),
    [actions.getSourceStatSuccess]: () => ({ requestState: 'success' }),
    [actions.getSourceStatFailure]: () => ({ requestState: 'failure' }),
    [actions.postSourceStatRequest]: () => ({ requestState: 'request' }),
    [actions.postSourceStatSuccess]: () => ({ requestState: 'success' }),
    [actions.postSourceStatFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
