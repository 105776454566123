import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, ButtonGroup, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Pagination from '@material-ui/lab/Pagination';

import Form from './Form';
import { useStyles, StyledTableRow, StyledTableCell } from '../../reused/Table';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { deleteItemServer, getItemServer, getInfoServers, getLabelsServers, resetError } from '../../../actions';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';

function Section72() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  useEffect(() => {
    dispatch(getLabelsServers());
    dispatch(getInfoServers(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { servers, labels, element, metaData } = useSelector((state) => state.sectionSevenOrganizationServers, shallowEqual);

  const defaultValue = useMemo(() => Object.values(element).reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}), [element]);

  const onClickEdit = (id) => async () => {
    handleOpenEdit();
    await dispatch(getItemServer(id));
  };
  const onClickDelete = (id) => () => dispatch(deleteItemServer(id));

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestLabelState, requestItemState } = useSelector((state) => state.sectionSevenOrganizationServersUI, shallowEqual);
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getInfoServers(value));
  };
  
  const errorHandler = useSelector((state) => state.sectionSevenOrganizationServers.error, shallowEqual);
  const [alertState, setAlertState] = useState(false);

  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);
  
      setTimeout(() => {
        dispatch(resetError());
      }, 4000);
    }
  }, [errorHandler, dispatch]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;
  // if (errorHandler.status === 422) {
  //   alert('Тип и марка сервера не должны повторятся!');
  //   dispatch(resetError());
  // }
  return (
    <div>
      <div className="titleContainer mb4 mt">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <>
        <TableContainer component={Paper} className={classes.container}>
          {requestLabelState === 'request' ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">№</StyledTableCell>
                  <StyledTableCell align="center">{labels.server_type}</StyledTableCell>
                  <StyledTableCell align="center">{labels.server_brand_type}</StyledTableCell>
                  <StyledTableCell align="center">{labels.server_configuration}</StyledTableCell>
                  <StyledTableCell align="center">{labels.server_functions}</StyledTableCell>
                  <StyledTableCell align="center">{labels.servers_count}</StyledTableCell>
                  {isVisibleOrg && <StyledTableCell></StyledTableCell>}
                </TableRow>
              </TableHead>
              <TransitionGroup component={TableBody} className="addLinks__tableBody">
                {servers.map((element, ind) => (
                  <CSSTransition key={element.id} timeout={1200} classNames="listItemUI">
                    <StyledTableRow>
                      <StyledTableCell align="center">{(page - 1) * 20 + ind + 1}</StyledTableCell>
                      <StyledTableCell align="center">{element.server_type}</StyledTableCell>
                      <StyledTableCell align="center">{element.server_brand_type}</StyledTableCell>
                      <StyledTableCell align="center">{element.server_configuration}</StyledTableCell>
                      <StyledTableCell align="center">{element.server_functions}</StyledTableCell>
                      <StyledTableCell align="center">{element.servers_count}</StyledTableCell>
                      {isVisibleOrg && (
                        <StyledTableCell>
                          <ButtonGroup orientation="vertical" variant="contained" color="primary">
                            <Button aria-label="edit" onClick={onClickEdit(element.id)}>
                              <CreateIcon />
                            </Button>
                            <Button aria-label="show" color="secondary" onClick={onClickDelete(element.id)}>
                              <DeleteIcon />
                            </Button>
                          </ButtonGroup>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </Table>
          )}
        </TableContainer>
        {isVisibleOrg && (
          <Button onClick={handleOpenAdd} color="primary" variant="contained" className="m0Auto">
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          </Button>
        )}
        {metaData.pageCount > 1 && (
          <div className="dFJustifyFxCenter mt4">
            <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
          </div>
        )}
      </>

      <Dialog
        dialogState={dialogStateAdd}
        onClose={handleCloseAdd}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleCloseAdd} />
      </Dialog>
      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        {requestItemState === 'request' ? <Loader /> : <Form submitAction={handleCloseEdit} defaultValues={defaultValue} isEditableMode />}
      </Dialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateServers',
          defaultMessage: 'Тип и марка сервера не должны повторятся!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}

export default Section72;
