import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setHelplinesInfoError = createAction('HELPLINES_INFO_SET_ERROR');

export const getHelplinesInfoRequest = createAction('HELPLINES_INFO_GET_REQUEST');
export const getHelplinesInfoSuccess = createAction('HELPLINES_INFO_GET_SUCCESS');
export const getHelplinesInfoFailure = createAction('HELPLINES_INFO_GET_FAILURE');
export const getHelplinesInfo = () => async (dispatch) => {
  dispatch(getHelplinesInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteHelplineInfo(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoWebsiteHelplineInfoLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getHelplinesInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setHelplinesInfoError(e?.response));
    dispatch(getHelplinesInfoFailure());
  }
};

export const editHelplinesInfoRequest = createAction('HELPLINES_INFO_EDIT_REQUEST');
export const editHelplinesInfoSuccess = createAction('HELPLINES_INFO_EDIT_SUCCESS');
export const editHelplinesInfoFailure = createAction('HELPLINES_INFO_EDIT_FAILURE');

export const editHelplinesInfo = (formData) => async (dispatch) => {
  dispatch(editHelplinesInfoRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoWebsiteHelplineInfo(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editHelplinesInfoSuccess());
    dispatch(getHelplinesInfo());
  } catch (e) {
    dispatch(setHelplinesInfoError(e?.response));
    dispatch(editHelplinesInfoFailure());
  }
};
