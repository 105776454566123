import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgLegalBasis = handleActions(
  {
    [actions.getLabelsBasesSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.getDocumentsBasesSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },
    [actions.addElementBasesSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: [...state.data, payload],
      };
    },
    [actions.deleteElementBasesSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        data: state.data.filter((el) => el.id !== id),
      };
    },
    [actions.getElementBasesSuccess]: (state, { payload }) => {
      return {
        ...state,
        document: payload,
      };
    },
    [actions.editElementBasesSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: state.data.map((doc) => (doc.id === payload.id ? payload : doc)),
      };
    },
    [actions.setOrgBasesError]: (state, { payload }) => {
      return {
        ...state,
        error: {
          hasError: true,
          status: payload.status,
          message: payload.message,
        },
      };
    },
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    labels: {},
    data: [],
    document: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgLegalBasisUI = handleActions(
  {
    [actions.getLabelsBasesRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsBasesSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsBasesFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editElementBasesRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editElementBasesSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editElementBasesFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getElementBasesRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getElementBasesSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getElementBasesFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
