import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, TextField } from '@material-ui/core';

import { editProcessingActs, getSimpleAssessment } from '../../../actions';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues });
  const countOffers = watch('count_offers');
  const labels = useSelector((state) => state.sectionTwoProcessingActs.labels, shallowEqual);

  const onSubmit = (data) => {
    dispatch(editProcessingActs({ ...data, organization_id: localStorage.orgId, section_id: 27 }));
    if (localStorage.orgCategoryId === '1') dispatch(getSimpleAssessment(27));

    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="count_projects"
        inputRef={register({ required: true })}
        error={!!errors.count_projects}
        type="number"
        className="mb"
        helperText={labels.count_projects}
      />
      <TextField
        fullWidth
        name="count_offers"
        inputRef={register({ required: true })}
        error={!!errors.count_offers}
        type="number"
        className="mb"
        helperText={labels.count_offers}
      />
      <TextField
        fullWidth
        name="count_comments"
        inputRef={register({ required: true })}
        inputProps={{ max: parseInt(countOffers), min: 0 }}
        error={!!errors.count_comments}
        type="number"
        className="mb"
        helperText={labels.count_comments}
      />
      <Button className="mb" variant="contained" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
