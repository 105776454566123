import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Divider, Drawer, IconButton, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/HomeRounded';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SearchIcon from '@material-ui/icons/Search';
import { shallowEqual, useSelector } from 'react-redux';

import { DrawerItem } from './DrawerItem';
import { FormattedMessage } from 'react-intl';

function MonitoringDrawer({ classes }) {
  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const items = useSelector((state) => state.m_drawer.items, shallowEqual);
  useEffect(() => {
    window.onbeforeunload = () => {
      sessionStorage.setItem('drawerList', JSON.stringify(items));
    };
  }, [items]);
  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}>
      <div className={classes.toolbarIcon}>
        {open ? (
          <div className="spaceBetween w100 p">
            <p className="drawer__title"></p>
            <IconButton onClick={handleDrawerClose} color="primary">
              <ChevronLeftIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton color="primary" aria-label="open drawer" onClick={handleDrawerOpen}>
            <ChevronRightIcon />
          </IconButton>
        )}
      </div>
      <Divider />
      <List>
        <DrawerItem open={open} url="/search" icon={<SearchIcon />} text={'Поиск'} />
        <DrawerItem open={open} url="/" icon={<HomeIcon />} text={'Список НПА'} exact />
        {items.map((item) => (
          <DrawerItem open={open} url={item.path} text={item.title} icon={<AssignmentIcon />} key={item.path} />
        ))}
        <DrawerItem open={open} url="/support" icon={<ContactSupportIcon />} text={<FormattedMessage id="drawerList.support" defaultMessage="Справка" />} exact />
      </List>
    </Drawer>
  );
}

export default MonitoringDrawer;
