import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

import Form from './Form';
import Table from '../../reused/Table';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { getInfoIctLabels, getInfoIctManager, sendExistsData } from '../../../actions';
import Download from '../../reused/Download';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';

export default function Section110() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInfoIctManager());
    dispatch(getInfoIctLabels());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const { data, labels } = useSelector((state) => state.sectionOneInfoIctManager, shallowEqual);
  const finalData = useMemo(() => (data ? Object.values(data) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))), [
    data,
    labels,
  ]);
  const tableData = useMemo(
    () =>
      finalData
        .filter((el) => !['is_exists', 'point'].includes(el.code_name))
        .map((el) => {
          if (el.code_name === 'file') {
            return {
              ...el,
              value: el.value ? <Download className="end" fileName={el.value} /> : '',
            };
          }
          return el;
        }),
    [finalData]
  );
  const defaultValues = useMemo(
    () =>
      finalData.reduce((acc, el) => {
        return { ...acc, [el.code_name]: el.value };
      }, {}),
    [finalData]
  );

  const [value, setValue] = useState('');

  useEffect(() => {
    if (data?.is_exists) setValue(`${data.is_exists.value}`);
    else setValue('');
  }, [data]);

  const handleChange = (e) => {
    if (e.target.value === '1') {
      setValue(e.target.value);
      dispatch(sendExistsData({ is_exists: 1 }));
    }
    else handleOpenConfirmDialog();
  };

  const handleClickConfirm = () => {
    const data = {
      last_name: null,
      first_name: null,
      middle_name: null,
      position_name: null,
      phone: null,
      fax: null,
      email: null,
      file: null,
      is_exists: 0,
    };
    dispatch(sendExistsData(data));
    setValue('0');
    handleCloseConfirmDialog();
  };
  const [dialogState, handleOpen, handleClose] = useDialog();
  const [confirmDialog, handleOpenConfirmDialog, handleCloseConfirmDialog] = useDialog();

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestState } = useSelector((state) => state.sectionOneInfoIctManagerUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneInfoIctManager.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer marginsTitle">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <>
          <FormControl component="fieldset" disabled={!isVisible} className="mb">
            <FormLabel>{labels.is_exists}</FormLabel>

            <RadioGroup name="is_exists" value={value} onChange={handleChange} row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          </FormControl>

          {value === '1' && (
            <Table
              title={<FormattedMessage id="section110.responsiblePerson" defaultMessage="Ответственное лицо" />}
              rows={tableData}
              head={
                <>
                  {isVisible && (
                    <Button onClick={handleOpen} color="inherit">
                      <CreateIcon />
                    </Button>
                  )}
                  <Dialog
                    dialogState={dialogState}
                    onClose={handleClose}
                    title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
                    <Form defaultValues={defaultValues} submitAction={handleClose} />
                  </Dialog>
                </>
              }
            />
          )}
        </>
      )}

      <Dialog
        title={<FormattedMessage id="section110.deleteFilledFieldsTitle" defaultMessage="Если вы потвердите действие мы удалим введенные данные!" />}
        dialogState={confirmDialog}
        onClose={handleCloseConfirmDialog}>
        <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button color="primary" variant="outlined" onClick={handleCloseConfirmDialog} className="mr">
            <FormattedMessage id="global.noTitle" defaultMessage="Нет" />
          </Button>
          <Button color="primary" variant="outlined" onClick={handleClickConfirm}>
            <FormattedMessage id="global.yesTitle" defaultMessage="Да" />
          </Button>
        </div>
      </Dialog>
    </>
  );
}
