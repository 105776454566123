import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const language = handleActions(
  {
    [actions.changeLang](state, { payload }) {
      return {
        ...state,
        locale: payload,
      };
    },
  },
  {
    locale: localStorage.getItem('lang') || 'ru',
  }
);
