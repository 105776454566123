import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setStatsTechnoprocessError = createAction('STATS_TECHNOPROCESS_SET_ERROR');

export const getStatsTechnoprocessRequest = createAction('STATS_TECHNOPROCESS_GET_REQUEST');
export const getStatsTechnoprocessSuccess = createAction('STATS_TECHNOPROCESS_GET_SUCCESS');
export const getStatsTechnoprocessFailure = createAction('STATS_TECHNOPROCESS_GET_FAILURE');

export const getStatsTechnoprocess = () => async (dispatch) => {
  dispatch(getStatsTechnoprocessRequest());

  try {
    const { data } = await axios.get(routes.sectionFiveTechnoProccess(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionFiveTechnoProccessLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getStatsTechnoprocessSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setStatsTechnoprocessError(e?.response));
    dispatch(getStatsTechnoprocessFailure());
  }
};

export const editStatsTechnoprocessRequest = createAction('STATS_TECHNOPROCESS_EDIT_REQUEST');
export const editStatsTechnoprocessSuccess = createAction('STATS_TECHNOPROCESS_EDIT_SUCCESS');
export const editStatsTechnoprocessFailure = createAction('STATS_TECHNOPROCESS_EDIT_FAILURE');

export const editStatsTechnoprocess = (data) => async (dispatch) => {
  dispatch(editStatsTechnoprocessRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionFiveTechnoProccess(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data,
    });
    dispatch(getStatsTechnoprocess());
    dispatch(editStatsTechnoprocessSuccess());
  } catch (e) {
    dispatch(setStatsTechnoprocessError(e?.response));
    dispatch(editStatsTechnoprocessFailure());
  }
};
