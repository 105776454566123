import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const employeesStat = handleActions(
  {
    [actions.getEmployeesStatSuccess]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      labels: Object.values(payload.labels),
    }),
    [actions.setEmployeesStatError]: (state, { payload }) => (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    labels: [],
    data: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const employeesStatUI = handleActions(
  {
    [actions.getEmployeesStatRequest]: () => ({ requestState: 'request' }),
    [actions.getEmployeesStatSuccess]: () => ({ requestState: 'success' }),
    [actions.getEmployeesStatFailure]: () => ({ requestState: 'failure' }),
    [actions.postEmployeesStatRequest]: () => ({ requestState: 'request' }),
    [actions.postEmployeesStatSuccess]: () => ({ requestState: 'success' }),
    [actions.postEmployeesStatFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
