import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const infoIctDivision = handleActions(
  {
    [actions.getInfoDivisionSuccess]: (state, { payload: { data, labels } }) => {
      return {
        ...state,
        infoDivision: data,
        labels,
      };
    },
    [actions.setInfoIctDivisionError]: (state, { payload }) => ({
      ...state,
      error: { hasError: true, status: payload.status, message: payload.message },
    }),
  },
  {
    infoDivision: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const infoIctDivisionUI = handleActions(
  {
    [actions.getInfoDivisionRequest]: () => ({ requestState: 'request' }),
    [actions.getInfoDivisionSuccess]: () => ({ requestState: 'success' }),
    [actions.getInfoDivisionFailure]: () => ({ requestState: 'failure' }),
    [actions.editInfoDivisionRequest]: () => ({ requestState: 'request' }),
    [actions.editInfoDivisionSuccess]: () => ({ requestState: 'success' }),
    [actions.editInfoDivisionFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
