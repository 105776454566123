import React from 'react';
import { KeyboardDatePicker as MUDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from 'date-fns/locale/ru';
import uzLocale from 'date-fns/locale/uz';

const localeMap = {
  uz: uzLocale,
  ru: ruLocale,
};

function DatePicker({ label, className, inputVariant, fullWidth = true, ...props }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[localStorage.lang || 'ru']}>
      <Controller
        {...props}
        render={({ ref, value, onChange, ...rest }) => (
          <MUDatePicker
            label={label}
            value={value}
            inputVariant={inputVariant}
            className={className}
            onChange={onChange}
            format="dd/MM/yyyy"
            fullWidth={fullWidth}
            animateYearScrolling
            invalidDateMessage="Введите корректную дату"
            {...rest}
          />
        )}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DatePicker;
