import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgSubordinateDivision = handleActions(
  {
    [actions.getDivisionLabelsSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.getDivisionItemsSuccess]: (state, { payload }) => {
      return {
        ...state,
        items: payload.items,
        metaData: payload._meta,
      };
    },
    [actions.addDivisionItemSuccess]: (state, { payload }) => {
      return {
        ...state,
        items: [...state.items, payload],
      };
    },
    [actions.editDivisionItemSuccess]: (state, { payload }) => {
      return {
        ...state,
        items: state.items.map((item) => (item.id === payload.id ? payload : item)),
      };
    },
    [actions.deleteDivisionItemSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        items: state.items.filter((item) => (item.id !== id)),
      };
    },
    [actions.getDivisionItemSuccess]: (state, { payload: { id, data } }) => {
      return {
        ...state,
        itemDoc: data,
        id: id,
      };
    },
    [actions.setDivisionError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    labels: {},
    items: [],
    itemDoc: [],
    metaData: {
      pageCount: 1,
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgSubordinateDivisionUI = handleActions(
  {
    [actions.getDivisionLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getDivisionLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getDivisionLabelsFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editDivisionItemRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editDivisionItemSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editDivisionItemFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getDivisionItemRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getDivisionItemSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getDivisionItemFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
