/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
} from '@material-ui/core';

import { DashedTableCell, StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Spinner from 'components/reused/Spinner';
import { getOrgStat } from 'actions';
import ExportCSV from 'components/reused/ExportCSV';

const Form = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const tableFormClasses = tableFormStyles();

  const { errors, handleSubmit, register } = useForm();

  const fetchingOrganizationsStat = useSelector((state) => state.dictionaryUI.fetchingOrganizationsStat, shallowEqual);

  const onSubmit = (data) => {
    dispatch(getOrgStat(data));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="m0Auto" style={{ width: '40%' }}>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan="3">
                <FormattedMessage
                  id="organizationStat.formLabel"
                  defaultMessage="Введите год и квартал для получения статистики"
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="center" className={tableFormClasses.tableCell}>
                <TextField
                  inputRef={register({ required: true })}
                  inputProps={{ min: 2020, max: 2099 }}
                  className={tableFormClasses.textField}
                  error={!!errors.year}
                  variant="outlined"
                  name="year"
                  label={intl.formatMessage({
                    id: 'organizationStat.labelYear',
                  })}
                  type="number"
                />
              </StyledTableCell>
              <StyledTableCell align="center" className={tableFormClasses.tableCell}>
                <TextField
                  inputRef={register({ required: true })}
                  inputProps={{ min: 1, max: 4 }}
                  className={tableFormClasses.textField}
                  error={!!errors.quarter}
                  variant="outlined"
                  name="quarter"
                  label={intl.formatMessage({
                    id: 'organizationStat.labelQuarter',
                  })}
                  type="number"
                />
              </StyledTableCell>
              <StyledTableCell align="center" className={tableFormClasses.tableCell}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={fetchingOrganizationsStat === 'request'}>
                  {fetchingOrganizationsStat === 'request' ? (
                    <CircularProgress color="secondary" size={28} />
                  ) : (
                    <FormattedMessage id="global.requireBtnTitle" defaultMessage="Запрос" />
                  )}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </form>
  );
};

function OrganizationHistory({ data = [], rawData = [] }) {
  const classes = useStyles();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert');

  const fetchingOrganizationsStat = useSelector((state) => state.dictionaryUI.fetchingOrganizationsStat, shallowEqual);

  const fileName = new Intl.DateTimeFormat('uz', { dateStyle: 'short', timeStyle: 'long' }).format(Date.now());
  return (
    <>
      <Form />
      <TableContainer component={Paper} className={classes.container}>
        {fetchingOrganizationsStat === 'request' ? (
          <Spinner />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {isExpert && (
                  <>
                    <DashedTableCell align="center">№</DashedTableCell>
                    <DashedTableCell align="center">
                      <FormattedMessage id="drawerList.nameOrganization" defaultMessage="Наименование организации" />
                    </DashedTableCell>
                  </>
                )}
                <DashedTableCell align="center">
                  <FormattedMessage
                    id="drawerList.openessEparticipation"
                    defaultMessage="Открытость и электронное участие"
                  />
                </DashedTableCell>
                <DashedTableCell align="center">
                  <FormattedMessage id="drawerList.electronicServices" defaultMessage="Электронные услуги" />
                </DashedTableCell>
                <DashedTableCell align="center">
                  <FormattedMessage
                    id="drawerList.infoCyberSecurity"
                    defaultMessage="Информационная кибербезопасность"
                  />
                </DashedTableCell>
                <DashedTableCell align="center">
                  <FormattedMessage id="drawerList.informationalSystems" defaultMessage="Информационные системы" />
                </DashedTableCell>
                <DashedTableCell align="center">
                  <FormattedMessage id="drawerList.organizationQuestions" defaultMessage="Организационные вопросы" />
                </DashedTableCell>
                <DashedTableCell align="center">
                  <FormattedMessage id="drawerList.total" defaultMessage="Итого" />
                </DashedTableCell>
                <DashedTableCell align="center">%</DashedTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((org, ind) => (
                <StyledTableRow key={org.id}>
                  {isExpert && (
                    <>
                      <DashedTableCell align="center">{ind + 1}</DashedTableCell>
                      <DashedTableCell>{org.short_title}</DashedTableCell>
                    </>
                  )}
                  <DashedTableCell align="center">{org.s2}</DashedTableCell>
                  <DashedTableCell align="center">{org.s3}</DashedTableCell>
                  <DashedTableCell align="center">{org.s4}</DashedTableCell>
                  <DashedTableCell align="center">{org.s5}</DashedTableCell>
                  <DashedTableCell align="center">{org.s6}</DashedTableCell>
                  <DashedTableCell align="center">{org.total.toFixed(2)}</DashedTableCell>
                  <DashedTableCell align="center">{org.percent.toFixed(2)}</DashedTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <div className="dFlex dFJustifyFxEnd mb">
        {data.length > 0 && <ExportCSV csvData={rawData} fileName={fileName} />}
      </div>
    </>
  );
}

export default OrganizationHistory;

const tableFormStyles = makeStyles({
  textField: {
    width: '120px',
  },
  tableCell: {
    padding: '15px 20px',
  },
});
