import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

import Loader from 'components/reused/Loader';
import Empty from 'components/reused/Empty';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Error from '../../reused/Error';
import Title from '../../reused/Title';
import ExpertAssessment from '../../reused/ExpertAssessment';
import EvaluationActivation from '../../reused/EvaluationActivation';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import { getEpiguServicesInfo, getEvalActivationInfo, getSimpleAssessment, toggleTabState, getEvaluationList, updateEvalActivationInfo } from 'actions';
import Pagination from '@material-ui/lab/Pagination';
import routes from '../../../routes';
import { generateHeaders, updateEpiguServices } from 'actions';

export default function Section33() {
  const dispatch = useDispatch();
  useEffect(() => {    
    dispatch(getSimpleAssessment(187));
    dispatch(getEvalActivationInfo(187));
  }, [dispatch]);
  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const classes = useStyles();
  const errorHandler = useSelector((state) => state.sectionThreeEpiguStats.error, shallowEqual);
  const assessment = useSelector((state) => state.simpleEvaluation.assessment, shallowEqual);
  const { requestAssessmentState } = useSelector((state) => state.simpleEvaluationUI, shallowEqual);
  const currentUser = useSelector((state) => state.profile.currentUser);
  const isExpert = currentUser?.roles.includes('expert-agu');
  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();
  const isVisible = isExpert && finishTimeExpert > dateNow;


  const { data, metaData, labels, dates } = useSelector((state) => state.sectionThreeEpiguServicesInfo, shallowEqual);
  const fetchLoader = useSelector((state) => state.sectionThreeEpiguServicesInfoUI.fetchRequest, shallowEqual);

  useEffect(() => {
    dispatch(getEpiguServicesInfo(page));
    dispatch(getEvaluationList(187));
  }, [currentUser]);


  const handleChangeActiveRequest = (id) => async (e, checked) => {
    await axios({
      method: 'post',
      url: routes.sectionThreeEpiguStatsRequestsActivate(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: {
        id,
        is_active: Number(checked),
      },
    });
    dispatch(getEpiguServicesInfo(page));
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getEpiguServicesInfo(value));
  };
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(187, data));
    dispatch(getSimpleAssessment(187));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };
  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <Title className="mb2" title={tabs[selectedTabIndex]?.title} />
      <div className="date mb2">
        <p>
          <FormattedMessage id="section33.actualData" defaultMessage="Данные актуальны" />{' '}
          <FormattedMessage id="section33.actualFrom" defaultMessage="от" /> {dates.from} -{' '}
          <FormattedMessage id="section33.actualTo" defaultMessage="до" /> {dates.to}
        </p>
      </div>
      <TableContainer component={Paper} className={classes.container}>
        {fetchLoader === 'request' ? (
          <div style={{ height: 400, display: 'flex', alignItems: 'center' }}>
            <Loader />
          </div>
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">№</StyledTableCell>
                <StyledTableCell align="center">{labels.mygov_service_name}</StyledTableCell>
                <StyledTableCell align="center">{labels.status_200}</StyledTableCell>
                <StyledTableCell align="center">{labels.status_others}</StyledTableCell>
                <StyledTableCell align="center">{labels.cnt}</StyledTableCell>
                <StyledTableCell align="center">Активация</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length !== 0 ? (
                <>
                  {data.map((service, ind) => {
                    return (
                      <StyledTableRow key={ind}>
                        <StyledTableCell align="center">{ind + 1}</StyledTableCell>
                        <StyledTableCell align="center">{service.mygov_service_name}</StyledTableCell>
                        <StyledTableCell align="center">{service.status_200}</StyledTableCell>
                        <StyledTableCell align="center">{service.status_others}</StyledTableCell>
                        <StyledTableCell align="center">
                          <b>{service.cnt}</b>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Checkbox
                            disabled={!isExpert}
                            checked={Boolean(service.is_active)}
                            onChange={handleChangeActiveRequest(service.mygov_service_id)}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  <StyledTableRow>
                    <StyledTableCell />
                    <StyledTableCell align="center">
                      <b>
                        <FormattedMessage id="section33.all" defaultMessage="Всего" />:
                      </b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <b>{data.reduce((acc, service) => acc + service.status_200, 0)}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <b>{data.reduce((acc, service) => acc + service.status_others, 0)}</b>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <b>{data.reduce((acc, service) => acc + service.cnt, 0)}</b>
                    </StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} align="center">
                    <Empty
                      title={
                        <FormattedMessage id="section33.empty" defaultMessage="По вашей организации запросов не было" />
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {metaData.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt4">
          <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
        </div>
      )}
      <EvaluationActivation isExpert={isVisible} onSubmit={updateEvalActivation} className="w30 mAuto mb4">
        <div className="w70 m0Auto mt4">
          {requestAssessmentState === 'request' ? (
            <Loader />
          ) : (
            <ExpertAssessment assessment={assessment} isExpert={false} showComment={false} />
          )}
        </div>
      </EvaluationActivation>
      {/* {evaluation.length !== 0 && (
        <div className="evaluationContainer">
          {evaluation.map((evaluation, ind) => (
            <p className="evaluationLabel" key={ind}>
              <strong>{evaluation.label}</strong>: -{evaluation.point}
            </p>
          ))}
        </div>
      )} */}
    </>
  );
}
