import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoIctManagerError = createAction('INFO_ICT_MANAGER_SET_ERROR');

export const getInfoIctManagerRequest = createAction('INFO_ICT_MANAGER_GET_REQUEST');
export const getInfoIctManagerSuccess = createAction('INFO_ICT_MANAGER_GET_SUCCESS');
export const getInfoIctManagerFailure = createAction('INFO_ICT_MANAGER_GET_FAILURE');

export const getInfoIctManager = () => async (dispatch) => {
  dispatch(getInfoIctManagerRequest());

  try {
    const { data } = await axios.get(routes.sectionOneInfoIctManager(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getInfoIctManagerSuccess(data));
  } catch (e) {
    dispatch(setInfoIctManagerError(e?.response));
    dispatch(getInfoIctManagerFailure());
  }
};

export const getInfoIctLabelsRequest = createAction('INFO_ICT_LABELS_GET_REQUEST');
export const getInfoIctLabelsSuccess = createAction('INFO_ICT_LABELS_GET_SUCCESS');
export const getInfoIctLabelsFailure = createAction('INFO_ICT_LABELS_GET_FAILURE');

export const getInfoIctLabels = () => async (dispatch) => {
  dispatch(getInfoIctLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionOneInfoIctLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getInfoIctLabelsSuccess(data));
  } catch (e) {
    dispatch(setInfoIctManagerError(e?.response));
    dispatch(getInfoIctLabelsFailure());
  }
};

export const editInfoIctManagerRequest = createAction('INFO_ICT_MANAGER_ADD_REQUEST');
export const editInfoIctManagerSuccess = createAction('INFO_ICT_MANAGER_ADD_SUCCESS');
export const editInfoIctManagerFailure = createAction('INFO_ICT_MANAGER_ADD_FAILURE');

export const editInfoIctManager = (formData) => async (dispatch) => {
  dispatch(editInfoIctManagerRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionOneInfoIctManager(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getInfoIctManager());
    dispatch(editInfoIctManagerSuccess());
  } catch (e) {
    dispatch(setInfoIctManagerError(e?.response));
    dispatch(editInfoIctManagerFailure());
  }
};

export const sendExistsDataRequest = createAction('EXISTS_DATA_SEND_REQUEST');
export const sendExistsDataSuccess = createAction('EXISTS_DATA_SEND_SUCCESS');
export const sendExistsDataFailure = createAction('EXISTS_DATA_SEND_FAILURE');

export const sendExistsData = (yesOrNo) => async (dispatch) => {
  dispatch(sendExistsDataRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionOneInfoIctManager(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: yesOrNo,
    });
    dispatch(getInfoIctManager());
  } catch (e) {
    dispatch(setInfoIctManagerError(e?.response));
    dispatch(sendExistsDataFailure());
  }
};
