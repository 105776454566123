import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';

export default function Autocompleter({
  options,
  label,
  error,
  onSelect,
  isAutocomplete = true,
  variant = 'standard',
  className = 'mb',
  style,
  groupBy,
  ...custom
}) {
  return (
    <Controller
      render={({ onChange, ...props }) => (
        <Autocomplete
          multiple={isAutocomplete}
          className={className}
          style={style}
          options={options}
          groupBy={groupBy}
          getOptionLabel={(option) => option.title}
          getOptionSelected={(option, value) => option.id === value.id}
          noOptionsText="Нет подходящего элемента в списке"
          onChange={(e, value) => {
            onChange(value);
            if (onSelect) {
              onSelect(value);
            }
          }}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              {isAutocomplete && (
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  color="primary"
                />
              )}
              {option.title}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              variant={variant}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'disabled',
              }}
            />
          )}
          {...props}
        />
      )}
      {...custom}
    />
  );
}
