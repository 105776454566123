import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, TextField } from '@material-ui/core';

import Table from '../reused/Table';
import Dialog from '../reused/SimpleDialog';
import { useDialog } from '../../common/hooks';
import { editInfoIctFounds, getInfoIctFounds } from '../../actions';
import Loader from '../reused/Loader';
import Error from '../reused/Error';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const { labels } = useSelector((state) => state.sectionEightInfoIctFounds, shallowEqual);

  const onSubmit = (formData) => {
    dispatch(editInfoIctFounds({ ...formData, organization_id: localStorage.orgId, section_id: 27 }));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="total_amount"
        inputRef={register({ required: true })}
        inputProps={{ step: 0.01, min: 0 }}
        error={!!errors.total_amount}
        type="number"
        className="mb"
        helperText={labels.total_amount}
      />
      <TextField
        fullWidth
        name="percentage_ict_found"
        inputRef={register({ required: true })}
        inputProps={{ step: 0.01, max: 100, min: 0 }}
        error={!!errors.percentage_ict_found}
        type="number"
        className="mb"
        helperText={labels.percentage_ict_found}
      />
      <TextField
        fullWidth
        name="percentage_ict_digitalisation_found"
        inputRef={register({ required: true })}
        inputProps={{ step: 0.01, max: 100, min: 0 }}
        error={!!errors.percentage_ict_digitalisation_found}
        type="number"
        className="mb"
        helperText={labels.percentage_ict_digitalisation_found}
      />
      <Button variant="contained" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};
function Section81() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInfoIctFounds());
  }, [dispatch]);
  const { founds, labels } = useSelector((state) => state.sectionEightInfoIctFounds, shallowEqual);

  const foundsFields = useMemo(
    () => (founds ? Object.values(founds) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))),
    [founds, labels]
  );
  const defaultValues = useMemo(
    () => foundsFields.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}),
    [foundsFields]
  );

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const requestState = useSelector((state) => state.sectionEightInfoIctFoundsUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionEightInfoIctFounds.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <div className="mb">
        <div className="spaceBetween mb3">
          <div className="titleContainer">
            <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
          </div>
          {isVisibleOrg && (
            <Button onClick={handleOpen} variant="contained" color="primary">
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          )}
        </div>
      </div>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <Table
          title={<FormattedMessage id="section81.title" defaultMessage="Наименование" />}
          head={<FormattedMessage id="global.countTitle" defaultMessage="Количество" />}
          rows={foundsFields}
        />
      )}

      <Dialog
        dialogState={dialogState}
        onClose={handleClose}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleClose} defaultValues={defaultValues} />
      </Dialog>
    </>
  );
}

export default Section81;
