/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TextField } from '@material-ui/core';

import { useStyles, StyledTableRow, StyledTableCell } from '../reused/Table';
import { getComputersInfo, postComputersInfo } from '../../actions';
import { isEmpty } from '../../common/utils';
import Spinner from '../reused/Spinner';
import Error from '../reused/Error';

export default function Section71() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getComputersInfo());
  }, []);

  const { labels, data } = useSelector((state) => state.sectionSevenComputers, shallowEqual);
  const requestState = useSelector((state) => state.sectionSevenComputersUI.requestState, shallowEqual);
  useEffect(() => {
    if (!isEmpty(defaultValues)) reset(defaultValues);
  }, [data]);

  const defaultValues = useMemo(
    () =>
      data.reduce(
        (acc, el) => ({
          ...acc,
          [el.meta_label_id]: {
            count_main: el.count_main,
            count_by_office: el.count_by_office,
            count_by_territorial: el.count_by_territorial,
            count_by_subdivisions: el.count_by_subdivisions,
            count_by_other_divisions: el.count_by_other_divisions,
          },
        }),
        {}
      ),
    [data]
  );

  const { register, handleSubmit, errors, reset } = useForm({ defaultValues });
  const [isEditMode, setIsEditMode] = useState(false);
  const classes = useStyles();

  const onSubmit = (data) => {
    const finalData = Object.keys(data).map((id) => ({
      meta_label_id: id,
      ...data[id],
    }));
    dispatch(postComputersInfo(finalData));
    setIsEditMode(false);
  };

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionSevenComputers.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="spaceBetween mb3">
        <div className="titleContainer">
          <h2 className="title" style={{ margin: 0, padding: '15px' }}>
            {sectionTabs[selectedTabIndex]?.title}
          </h2>
        </div>
        {isVisibleOrg && (
          <>
            {isEditMode && (
              <Button variant="contained" color="primary" type="submit">
                <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
              </Button>
            )}
            {!isEditMode && (
              <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
                <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
              </Button>
            )}
          </>
        )}
      </div>
      <TableContainer component={Paper} className={classes.container}>
        {requestState === 'request' ? (
          <Spinner />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                {labels.map((label) => (
                  <StyledTableCell key={label} align="center">
                    {label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => {
                if (isEditMode) {
                  const error = !!errors[row.meta_label_id]?.count_main;
                  const error2 = !!errors[row.meta_label_id]?.count_by_office;
                  const error3 = !!errors[row.meta_label_id]?.count_by_territorial;
                  const error4 = !!errors[row.meta_label_id]?.count_by_subdivisions;
                  const error5 = !!errors[row.meta_label_id]?.count_by_other_divisions;
                  return (
                    <StyledTableRow key={row.meta_label_id}>
                      <StyledTableCell align="justify">{row.title}</StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0 }}
                          type="number"
                          name={`${row.meta_label_id}[count_main]`}
                          error={error}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0 }}
                          type="number"
                          name={`${row.meta_label_id}[count_by_office]`}
                          error={error2}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0 }}
                          type="number"
                          name={`${row.meta_label_id}[count_by_territorial]`}
                          error={error3}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0 }}
                          type="number"
                          name={`${row.meta_label_id}[count_by_subdivisions]`}
                          error={error4}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0 }}
                          type="number"
                          name={`${row.meta_label_id}[count_by_other_divisions]`}
                          error={error5}
                          variant="outlined"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }

                return (
                  <StyledTableRow key={row.meta_label_id}>
                    <StyledTableCell align="justify">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">{row.count_main}</StyledTableCell>
                    <StyledTableCell align="center">{row.count_by_office}</StyledTableCell>
                    <StyledTableCell align="center">{row.count_by_territorial}</StyledTableCell>
                    <StyledTableCell align="center">{row.count_by_subdivisions}</StyledTableCell>
                    <StyledTableCell align="center">{row.count_by_other_divisions}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </form>
  );
}
