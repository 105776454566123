import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, IconButton, ListItemSecondaryAction, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import { FileInput } from 'components/reused/FileInput';
import Title from 'components/reused/Title';
import { useParams } from 'react-router';
import axios from 'axios';
import routes from './routes';
import { generateHeaders, getFiles, getProjectLogs, getStepsLogs } from 'actions';
import { Alert } from '@material-ui/lab';
import { useDialog } from 'common/hooks';
import Dialog from 'components/reused/SimpleDialog';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';

function FileUploader({ stepId, isExecutor = false }) {
  const dispatch = useDispatch();
  const { projectId, id } = useParams();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    let active = true;

    if (stepId) {
      getFiles('step_id', stepId).then((data) => {
        if (active) setFiles(data.items);
      });
    } else {
      getFiles('project_id', projectId).then((data) => {
        if (active) setFiles(data.items);
      });
    }

    return () => {
      active = false;
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const onSubmit = (data) => {
    setLoading(true);
    const form = new FormData();
    form.append('attachment', data.file[0]);
    form.append('title', data.title);
    form.append('document_id', id);
    form.append('project_id', projectId);
    form.append('comment', data.comment);

    if (stepId) {
      form.append('step_id', stepId);
    }

    axios
      .post(routes.addFile(), form, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
        },
      })
      .then(({ data }) => {
        setValidation([]);
        setFiles(files.concat(data));
        setLoading(false);
        if (stepId) {
          dispatch(getStepsLogs(stepId)(projectId, 1));
        } else {
          dispatch(getProjectLogs(projectId, 1));
        }
        handleClose();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
        console.log(err);
      });
  };

  const { register, handleSubmit, control, errors } = useForm();
  const [open, handleOpen, handleClose] = useDialog();

  const handleDownload = (url, filename) => () => {
    axios
      .get(url, {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const [fileDeleteId, setFileDeleteId] = useState(null);
  const handleModalDelete = (id) => () => {
    handleOpenDelete();
    setFileDeleteId(id);
  };

  const handleDelete = () => {
    handleCloseDelete();
    axios
      .delete(routes.file(fileDeleteId), {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      })
      .then((res) => {
        if (res.status === 204) {
          setFiles(files.filter((f) => f.id !== fileDeleteId));
          if (stepId) {
            dispatch(getStepsLogs(stepId)(projectId, 1));
          } else {
            dispatch(getProjectLogs(projectId, 1));
          }
        }
      });
  };
  const currentUser = useSelector((state) => state.profile.currentUser);
  const isOrganization = currentUser?.roles.includes('monitoring_organization');

  return (
    <div className="w30">
      <Title title={<FormattedMessage id="monitoring.roadmaps.attachmentFiles" />} disableBorder />{' '}
      <Dialog
        dialogState={open}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
        onClose={handleClose}>
        {validation.length > 0 &&
          validation.map((err, i) => (
            <Alert key={i} severity="error" className="mt">
              {err.message}
            </Alert>
          ))}
        <form onSubmit={handleSubmit(onSubmit)} className="p2">
          <TextField
            name="title"
            inputRef={register({ required: true })}
            error={!!errors.title}
            label="Описание файла"
            className="mb2"
            fullWidth
          />
          <TextField
            name="comment"
            multiline
            rowsMax="4"
            label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
            inputRef={register}
            type="text"
            className="mb"
            fullWidth
          />
          <FileInput
            name="file"
            control={control}
            label="Перетащите файл сюда или вложите его, нажав на кнопку"
            rules={{
              validate: (val) => {
                return val.length === 0 ? 'required' : undefined;
              },
            }}
          />
          <Button type="submit" color="primary" variant="contained" className="m0Auto" style={{ minWidth: 110 }}>
            {loading ? (
              <CircularProgress color="inherit" size={30} />
            ) : (
              <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
            )}
          </Button>
        </form>
      </Dialog>
      <List className="dFlex" dense={true}>
        {files.length === 0 && (
          <h2 style={{ color: '#707070', margin: '20px auto 0px' }}>
            <FormattedMessage id="monitoring.roadmaps.hasntFiles" defaultMessage="Файлы отсутствуют..." />
          </h2>
        )}
        {files.map((file) => (
          <ListItem key={file.id}>
            <ListItemIcon>
              <InsertDriveFile />
            </ListItemIcon>
            <ListItemText primary={file.title} secondary={file.created_at} />
            <ListItemSecondaryAction>
              <IconButton
                disabled={isExecutor}
                edge="end"
                color="primary"
                onClick={handleDownload(file.link, file.filename)}>
                <GetAppIcon />
              </IconButton>
              {isOrganization && (
                <IconButton disabled={isExecutor} edge="end" color="secondary" onClick={handleModalDelete(file.id)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Dialog title="Подтвердить удаление" dialogState={openDelete} onClose={handleCloseDelete}>
        <Button className="mr ml mt mb" variant="contained" color="primary" onClick={handleCloseDelete}>
          Закрыть
        </Button>
        <Button className="mr ml mt mb" variant="contained" color="secondary" onClick={handleDelete}>
          Удалить?
        </Button>
      </Dialog>
      {isOrganization && (
        <Button disabled={isExecutor} onClick={handleOpen} color="primary" variant="contained" className="m0Auto">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}
    </div>
  );
}

export default FileUploader;
