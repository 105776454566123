import { host } from 'routes';

const url = host + '/monitoring';

export default {
  acts: () => [url, 'documents'].join('/'),
  act: (id) => [url, `documents/${id}`].join('/'),

  roadmaps: () => [url, 'roadmaps'].join('/'),
  roadmap: (id) => [url, `roadmaps/${id}`].join('/'),
  statistics: () => [url, 'roadmap/project-stat'].join('/'),

  projects: () => [url, 'projects'].join('/'),
  project: (id) => [url, `projects/${id}`].join('/'),
  editProject: (field, id) => [url, `project/${field}?id=${id}`].join('/'),
  search: () => [url, 'project/search-module'].join('/'),

  steps: () => [url, 'steps'].join('/'),
  step: (id) => [url, `steps/${id}`].join('/'),
  editStep: (field, id) => [url, `step/${field}?id=${id}`].join('/'),

  files: () => [url, 'files'].join('/'),
  file: (id) => [url, `files/${id}`].join('/'),
  addFile: () => [url, 'file/create'].join('/'),

  labels: (entity) => [url, `${entity}/labels`].join('/'),
  dictionary: (handbook) => [url, `dictionary/${handbook}`].join('/'),

  logs: () => [url, 'logs'].join('/'),

  excel: () => [url, 'project/excel-download'].join('/'),
  projectPassport: () => [url, 'project/project-pasport-excel'].join('/'),
};
