import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const simpleEvaluation = handleActions(
  {
    [actions.getSimpleMarkSuccess]: (state, { payload }) => ({
      ...state,
      mark: payload.mark,
      markLabels: payload.labels,
    }),
    [actions.saveSimpleMarkSuccess]: (state, { payload }) => ({ ...state, mark: payload }),
    [actions.deleteSimpleMarkSuccess]: (state, { payload }) => ({ ...state, mark: null }),
    [actions.getSimpleAssessmentSuccess]: (state, { payload }) => ({
      ...state,
      assessment: payload.assessment,
      assessmentLabels: payload.labels,
    }),
    [actions.saveSimpleAssessmentSuccess]: (state, { payload }) => ({ ...state, assessment: payload }),
    [actions.deleteSimpleAssessmentSuccess]: (state, { payload }) => ({ ...state, assessment: null }),
  },
  {
    mark: null,
    markLabels: {},
    assessment: null,
    assessmentLabels: {},
  }
);

export const simpleEvaluationUI = handleActions({
  [actions.getSimpleMarkRequest]: (state) => ({ ...state, requestMarkState: 'request' }),
  [actions.getSimpleMarkSuccess]: (state) => ({ ...state, requestMarkState: 'success' }),
  [actions.getSimpleMarkFailure]: (state) => ({ ...state, requestMarkState: 'failure' }),
  [actions.saveSimpleMarkRequest]: (state) => ({ ...state, requestMarkState: 'request' }),
  [actions.saveSimpleMarkSuccess]: (state) => ({ ...state, requestMarkState: 'success' }),
  [actions.saveSimpleMarkFailure]: (state) => ({ ...state, requestMarkState: 'failure' }),
  [actions.deleteSimpleMarkRequest]: (state) => ({ ...state, requestMarkState: 'request' }),
  [actions.deleteSimpleMarkSuccess]: (state) => ({ ...state, requestMarkState: 'success' }),
  [actions.deleteSimpleMarkFailure]: (state) => ({ ...state, requestMarkState: 'failure' }),
  [actions.getSimpleAssessmentRequest]: (state) => ({ ...state, requestAssessmentState: 'request' }),
  [actions.getSimpleAssessmentSuccess]: (state) => ({ ...state, requestAssessmentState: 'success' }),
  [actions.getSimpleAssessmentFailure]: (state) => ({ ...state, requestAssessmentState: 'failure' }),
  [actions.saveSimpleAssessmentRequest]: (state) => ({ ...state, requestAssessmentState: 'request' }),
  [actions.saveSimpleAssessmentSuccess]: (state) => ({ ...state, requestAssessmentState: 'success' }),
  [actions.saveSimpleAssessmentFailure]: (state) => ({ ...state, requestAssessmentState: 'failure' }),
  [actions.deleteSimpleAssessmentRequest]: (state) => ({ ...state, requestAssessmentState: 'request' }),
  [actions.deleteSimpleAssessmentSuccess]: (state) => ({ ...state, requestAssessmentState: 'success' }),
  [actions.deleteSimpleAssessmentFailure]: (state) => ({ ...state, requestAssessmentState: 'failure' }),
}, {
  requestMarkState: '',
  requestAssessmentState: '',
})