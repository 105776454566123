import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';

import Form from './Form';
import Table from '../../reused/Table';
import Loader from '../../reused/Loader';
import EvaluationActivation from '../../reused/EvaluationActivation';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getEpiguStats, getEvalActivationInfo, getSectionThreeAssessment, toggleTabState, updateEvalActivationInfo } from '../../../actions';
import Error from '../../reused/Error';

export default function Section32() {
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getEpiguStats());
    dispatch(getSectionThreeAssessment(33));
    dispatch(getEvalActivationInfo(33));
  }, [dispatch]);

  const { labels, stats, assessment } = useSelector((state) => state.sectionThreeEpiguStats, shallowEqual);
  const finalAssessment = assessment.reduce((acc, element) => [...acc, { ...element, value: element.point, code_name: element.param_id }], []);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-agu');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;

  const statsInfo = useMemo(() => (stats ? Object.values(stats) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))), [
    stats,
    labels,
  ]);

  const defaultValues = useMemo(
    () => statsInfo.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value === 0 || el.value ? `${el.value}` : '' }), {}),
    [statsInfo]
  );

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const requestState = useSelector((state) => state.sectionThreeEpiguStatsUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionThreeEpiguStats.error, shallowEqual);

  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(33, data));
    dispatch(getSectionThreeAssessment(33));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <div className="spaceBetween mb">
        <div className="titleContainer marginsTitle">
          <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
        </div>
        {isVisibleExpert && (
          <Button color="primary" variant="contained" onClick={handleOpenEdit}>
            <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
          </Button>
        )}
      </div>
      {requestState === 'request' ? <Loader /> : <Table title={intl.formatMessage({
        id: 'section32.title',
        defaultMessage: 'Наименование'
      })} head={intl.formatMessage({
        id: 'section32.count',
        defaultMessage: 'Кол-во'
      })} rows={statsInfo} />}
      <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="w30 mAuto mt4">
        <div className="w70 mt4 m0Auto">
          <Table title={<FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />} rows={finalAssessment} />
        </div>
      </EvaluationActivation>
      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleCloseEdit} defaultValues={defaultValues} />
      </Dialog>
    </>
  );
}
