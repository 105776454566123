import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { getProfile } from './index';

export const resetErrorMessage = createAction('ERROR_MESSAGE_RESET');

export const userLoginRequest = createAction('USER_LOGIN_REQUEST');
export const userLoginSuccess = createAction('USER_LOGIN_SUCCESS');
export const userLoginFailure = createAction('USER_LOGIN_FAILURE');

export const userLogin = (formValues) => async (dispatch) => {
  dispatch(userLoginRequest());
  try {
    const { data } = await axios.post(routes.login(), formValues);

    if (data.status) {
      localStorage.setItem('token', data.token);
      dispatch(userLoginSuccess());
      dispatch(getProfile());
    } else {
      console.log(data);
      dispatch(userLoginFailure({ message: data.message }));
    }
  } catch (err) {
    dispatch(userLoginFailure({ message: err.message }));
    console.log(err);
  }
};

export const logoutRequest = createAction('LOGOUT_REQUEST');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');

export const logoutSystem = () => async (dispatch) => {
  dispatch(logoutRequest());
  try {
    await axios.get(routes.logout(), {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    localStorage.removeItem('token');
    localStorage.removeItem('orgId');
    localStorage.removeItem('orgTitle');
    sessionStorage.removeItem('selectedTab');
    window.location.reload();
  } catch (e) {
    console.log(e);
  }
};
