import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Button, FormControl, FormHelperText, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';

import { editStatsTechnoprocess, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues: { ...defaultValues, file: '' } });

  const labels = useSelector((state) => state.sectionFiveStateTechnoprocess.labels, shallowEqual);
  const countMainProcess = watch('count_main_process');
  const isEditMode = defaultValues.file;
  const onSubmit = async (data) => {
    let file;
    if (isEditMode && data.file.length === 0) {
      file = defaultValues.file;
    } else {
      file = await uploadFile(data.file, 5.2);
    }

    dispatch(
      editStatsTechnoprocess({
        ...data,
        file,
        section_id: 27,
        organization_id: localStorage.orgId,
      })
    );

    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="count_main_process"
        inputRef={register({ required: true })}
        inputProps={{ min: 0 }}
        error={!!errors.count_main_process}
        type="number"
        className="mb"
        helperText={labels.count_main_process}
      />
      <TextField
        fullWidth
        name="count_ict_process"
        inputRef={register({ required: true })}
        inputProps={{ min: 0, max: parseInt(countMainProcess) }}
        error={!!errors.count_ict_process}
        type="number"
        className="mb"
        helperText={labels.count_ict_process}
      />
      <div className="mb">
        <UploadFile error={!!errors.file} register={register({ required: !isEditMode })} />
        <FormHelperText>{labels.file}</FormHelperText>
      </div>
      <Button variant="contained" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
