/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import NativeSelect from '@material-ui/core/NativeSelect';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { getDistricts, resetDistricts, editOrgInfo } from '../../../actions';

const CEOForm = ({ className, defaultValues, submitAction, labels }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);

  const { register, handleSubmit, watch, errors, setValue } = useForm({ defaultValues });
  const watchRegion = watch('region_id');

  useEffect(() => {
    if (watchRegion) dispatch(getDistricts(watchRegion));
    else dispatch(resetDistricts());
  }, [watchRegion]);

  const onSubmit = (data) => {
    dispatch(editOrgInfo(currentUser.organization.id, data));
    if (submitAction) submitAction();
  };

  const { regions, districts, positions } = useSelector((state) => state.dictionary, shallowEqual);

  if (defaultValues.district_id) setValue('district_id', defaultValues.district_id);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={`orgForm ${className}`}>
      <div className="w50">
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.last_name_ceo}
          name="last_name_ceo"
          className="orgForm__textField mb"
          label={labels.last_name_ceo}
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.first_name_ceo}
          name="first_name_ceo"
          className="orgForm__textField mb"
          label={labels.first_name_ceo}
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.middle_name_ceo}
          name="middle_name_ceo"
          className="orgForm__textField mb"
          label={labels.middle_name_ceo}
        />
        <FormControl className="orgForm__textField mb3">
          <FormHelperText>{labels.position_id}</FormHelperText>
          <NativeSelect
            inputProps={{ ref: register({ required: true }) }}
            error={!!errors.position_id}
            name="position_id">
            {positions.map((position) => (
              <option key={position.id} value={position.id}>
                {position.title}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.phone_ceo}
          name="phone_ceo"
          className="orgForm__textField mb"
          label={labels.phone_ceo}
          type="phone"
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.fax}
          name="fax"
          className="orgForm__textField mb"
          label={labels.fax}
          type="phone"
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.email_ceo}
          name="email_ceo"
          className="orgForm__textField mb"
          label={labels.email_ceo}
          type="email"
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.email}
          name="email"
          className="orgForm__textField mb"
          label={labels.email}
          type="email"
        />
        <TextField
          inputRef={register({
            required: true,
            pattern: {
              value: new RegExp(
                '^(https?:\\/\\/)?' + // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                  '(\\#[-a-z\\d_]*)?$',
                'i'
              ),
            },
          })}
          error={!!errors.website}
          name="website"
          type="url"
          className="orgForm__textField mb"
          label={labels.website}
        />
      </div>
      <div className="w50 orgForm__address">
        <h4 className="orgForm__addressTitle">Юридический адрес организации:</h4>

        <FormControl className="orgForm__addressTextField mb">
          <NativeSelect inputProps={{ ref: register({ required: true }) }} name="region_id" error={!!errors.region_id}>
            {regions.map((region) => (
              <option key={region.id} value={region.id}>
                {region.title}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText>{labels.region_id}</FormHelperText>
        </FormControl>
        <FormControl className="orgForm__addressTextField mb">
          <NativeSelect
            inputProps={{ ref: register({ required: true }) }}
            name="district_id"
            error={!!errors.district_id}>
            {districts.map((district) => (
              <option key={district.id} value={district.id}>
                {district.title}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText>{labels.district_id}</FormHelperText>
        </FormControl>
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.street_name_ceo}
          name="street_name_ceo"
          className="orgForm__addressTextField mb"
          label={labels.street_name_ceo}
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.house_no_ceo}
          name="house_no_ceo"
          className="orgForm__addressTextField mb"
          label={labels.house_no_ceo}
        />
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.post_index}
          name="post_index"
          className="orgForm__addressTextField mb"
          label={labels.post_index}
        />
      </div>
      <Button variant="contained" color="primary" type="submit" className="mb" style={{ margin: '30px auto 0' }}>
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default CEOForm;
