import React, { useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Dashboard from '../Dashboard';
import Tabs from './Tabs';
import { getSections } from '../../actions';
import RouteWithSubRoutes from './RouteWithSubRoutes';

function Layout({ routes }) {
  const dispatch = useDispatch();
  const sectionNum = useLocation().pathname.substring(12);
  useEffect(() => {
    if (localStorage.token) dispatch(getSections(sectionNum || '1'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionNum]);

  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">
            <Tabs tabs={sectionTabs} />
            <Switch>
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
              <Redirect to="/not-found" />
            </Switch>
          </Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default Layout;
