import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

import NISDrawer from './NISDrawer';
import MonitoringDrawer from './MonitoringDrawer';
import RegionsDrawer from './RegionsDrawer';
import Header from './Header';
import Footer from './Footer';
import { useStyles } from './styles';

export default function Dashboard(props) {
  const classes = useStyles();
  const currentApp = useSelector((state) => state.app.currentApp, shallowEqual);
  let App;
  switch (currentApp) {
    case 'nis':
      App = <NISDrawer classes={classes} />;
      break;
    case 'monitoring':
      App = <MonitoringDrawer classes={classes} />;
      break;
    case 'region':
      App = <RegionsDrawer classes={classes} />;
      break;
    default:
      App = null;
  }

  return (
    <div className={classes.root}>
      {App}
      <main className={classes.content}>
        <Header />
        <CssBaseline />
        <Container maxWidth="xl" className={classes.mainContainer}>
          {props.children}
        </Container>
        <Footer classes={classes} />
      </main>
    </div>
  );
}
