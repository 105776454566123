/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Pagination from '@material-ui/lab/Pagination';

import { useDialog } from '../../../common/hooks';
import Form from './Form';
import Dialog from '../../reused/SimpleDialog';
import Loader from '../../reused/Loader';
import FullDialog from '../../reused/FullScreenDialog';
import CustomTable from '../../reused/Table';
import Spinner from '../../reused/Spinner';
import ExpertAssessment from '../../reused/ExpertAssessment';
import ExpertMark from '../../reused/ExpertMark';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import {
  deleteElementOpenData,
  getElementOpenData,
  getEvaluations,
  getLabelsOpenData,
  getOpenDatas,
  saveComplexAssessment,
  deleteComplexAssessment,
  saveComplexMark,
  deleteСomplexMark,
  getComplexMark,
  getEvaluationList,
  resetError,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  toggleTabState,
} from '../../../actions';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import { useState } from 'react';
import EvaluationActivation from '../../reused/EvaluationActivation';

export default function Section27() {
  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateShow, handleOpenShow, handleCloseShow] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isStatExpert = currentUser?.roles.includes('expert-stat');
  const isOrganization = currentUser?.roles.includes('organization');
  const isExpert = currentUser?.roles.includes('expert');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleExpert = isStatExpert && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;
  const isVisibleBoth = isVisibleExpert || isVisibleOrg;

  useEffect(() => {
    dispatch(getLabelsOpenData());
    dispatch(getOpenDatas(page));
    dispatch(getEvaluations(26, 5));
    dispatch(getEvaluationList(25));
    dispatch(getEvalActivationInfo(25));
  }, []);
  const { labels, datas, element, metaData } = useSelector((state) => state.sectionTwoWebsiteOpenData, shallowEqual);

  const raw = element?.publication_forms?.value;
  let publicForm;
  let defaultFormatValue;
  if (raw) {
    publicForm = raw.map((el) => el.title).join(', ');
    defaultFormatValue = raw;
  }
  const elementFields = useMemo(
    () =>
      Object.values(element)
        .filter((el) => {
          if (el.code_name === 'reason_geolocation') return element.has_geolocation?.value === '0';
          if (el.code_name === 'reason_identifier') return element.has_identifier?.value === '0';
          if (el.code_name === 'reason_services') return element.has_services?.value === '0';
          if (el.code_name === 'services_info') return element.has_services?.value === '1';
          if (el.code_name === 'mark_geolocation' || el.code_name === 'mark_identifier') return isExpert;
          return !['id', 'com_geolocation', 'com_identifier', 'com_services'].includes(el.code_name);
        })
        .reduce((acc, el) => {
          if (el.code_name === 'reason_geolocation') {
            return [...acc, el, element?.com_geolocation];
          }
          if (el.code_name === 'reason_identifier') {
            return [...acc, el, element?.com_identifier];
          }
          if (el.code_name === 'reason_services') {
            return [...acc, el, element?.com_services];
          }
          return [...acc, el];
        }, [])
        .map((el) => {
          if (el.code_name === 'publication_forms') return { ...el, value: publicForm };
          if (el.code_name === 'mark_geolocation') return { ...el, value: generateAnswer(el.value) };
          if (el.code_name === 'mark_identifier') return { ...el, value: generateAnswer(el.value) };
          return el;
        })
        .filter((el) => {
          if (el.code_name === 'com_geolocation') return element.com_geolocation?.value?.length > 0;
          if (el.code_name === 'com_identifier') return element.com_identifier?.value?.length > 0;
          if (el.code_name === 'com_services') return element.com_services?.value?.length > 0;
          return true;
        }),
    [element, isExpert, publicForm]
  );

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: "short" }).format(Date.now());

  const defaultValues = useMemo(
    () =>
      elementFields.reduce((acc, el) => {
        if (el.code_name === 'mark_geolocation') return { ...acc, [el.code_name]: el.value ? `${element.mark_geolocation.value}` : '' };
        if (el.code_name === 'mark_identifier') return { ...acc, [el.code_name]: el.value ? `${element.mark_identifier.value}` : '' };
        if (el.code_name === 'provision_term') return { ...acc, [el.code_name]: el.value ? el.value : defaultDate };
        if (el.code_name === 'data_modified_date') return { ...acc, [el.code_name]: el.value ? el.value : defaultDate };
        return { ...acc, [el.code_name]: el.value || el.value === 0 ? `${el.value}` : '' };
      }, {}),
    [elementFields, element]
  );
  const onClickView = (id) => async () => {
    handleOpenShow();
    await dispatch(getElementOpenData(id));
    dispatch(getComplexMark(id, 26));
  };

  const onClickDelete = (id) => () => {
    dispatch(deleteElementOpenData(id, 26, 5));
    dispatch(getEvaluationList(25));
  };

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const { tabs, evaluations } = useSelector((state) => state.dictionary, shallowEqual);
  const language = useSelector((state) => state.language.locale, shallowEqual);

  const { requestLabelState, requestItemState } = useSelector((state) => state.sectionTwoWebsiteOpenDataUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoWebsiteOpenData.error, shallowEqual);
  const { mark, assessment, evaluationList } = useSelector((state) => state.complexEvaluation, shallowEqual);
  const _evaluationList = evaluationList.map((el) => ({ ...el, value: el.point, code_name: el.param_id }));
  const handleSubmitAssessment = (id) => async (data) => {
    await dispatch(saveComplexAssessment(id, 26, 5, data));
    dispatch(getOpenDatas(page));
    dispatch(getEvaluationList(25));
  };
  const handleDeleteAssessment = (id) => async () => {
    await dispatch(deleteComplexAssessment(id, 26, 5));
    dispatch(getOpenDatas(page));
    dispatch(getEvaluationList(25));
  };

  const handleSubmitMark = (id) => async (data) => {
    await dispatch(saveComplexMark(id, 26, data));
    dispatch(getOpenDatas(page));
    dispatch(getEvaluationList(25));
  };
  const handleDeleteMark = (id) => async () => {
    await dispatch(deleteСomplexMark(id, 26));
    await dispatch(deleteComplexAssessment(id, 26, 5));
    dispatch(getOpenDatas(page));
    dispatch(getEvaluationList(25));
  };

  const evalActivation = useSelector((state) => state.evaluationActivation, shallowEqual);
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(25, data));
    dispatch(getEvaluationList(25));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getOpenDatas(value));
  };

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 4000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <div>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>
      <TableContainer component={Paper} className={classes.container}>
        {requestLabelState === 'request' ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell align="center">№</StyledTableCell>
                <StyledTableCell align="center">{language === 'ru' ? labels.title_ru : labels.title_uz}</StyledTableCell>
                <StyledTableCell align="center">{labels.is_active}</StyledTableCell>
                <StyledTableCell align="center">{labels.has_geolocation}</StyledTableCell>
                <StyledTableCell align="center">{labels.has_identifier}</StyledTableCell>
                <StyledTableCell align="center">{labels.has_services}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TransitionGroup component={TableBody}>
              {datas.map((doc, ind) => {
                return (
                  <CSSTransition key={doc.id} timeout={1200} classNames="listItemUI">
                    <StyledTableRow>
                      <StyledTableCell>
                        <ButtonGroup orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="contained">
                          <Button onClick={onClickView(doc.id)}>
                            <VisibilityIcon />
                          </Button>
                          {isVisibleExpert && (
                            <Button onClick={onClickDelete(doc.id)} color="secondary">
                              <DeleteIcon />
                            </Button>
                          )}
                        </ButtonGroup>
                      </StyledTableCell>
                      <StyledTableCell align="center">{(page - 1) * 20 + ind + 1}</StyledTableCell>
                      <StyledTableCell align="center">{language === 'ru' ? doc.title_ru : doc.title_uz}</StyledTableCell>
                      <StyledTableCell align="center">{generateAnswer(doc.is_active)}</StyledTableCell>
                      <StyledTableCell align="center">{generateAnswer(doc.has_geolocation)}</StyledTableCell>
                      <StyledTableCell align="center">{generateAnswer(doc.has_identifier)}</StyledTableCell>
                      <StyledTableCell align="center">{generateAnswer(doc.has_services)}</StyledTableCell>
                    </StyledTableRow>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </Table>
        )}
      </TableContainer>
      {isVisibleExpert && (
        <Button type="submit" color="primary" variant="contained" onClick={handleOpenAdd} className="mb mt m0Auto">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}

      {metaData.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt4">
          <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
        </div>
      )}

      <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="w30 m0Auto">
        {_evaluationList.length !== 0 && (
          <div style={{ marginTop: 65 }}>
            <div className="w50 m0Auto mt4">
              <CustomTable
                rows={_evaluationList}
                title={<FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />}
              />
            </div>
          </div>
        )}
      </EvaluationActivation>

      <Dialog dialogState={dialogStateAdd} onClose={handleCloseAdd} title="Наличие наборов открытых данных">
        <Form submitAction={handleCloseAdd} />
      </Dialog>

      <FullDialog dialogState={dialogStateShow} onClose={handleCloseShow}>
        <div className="mt10 tableData">
          {requestItemState === 'request' ? (
            <Spinner />
          ) : (
            <>
              <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow className={classes.headRow}>
                      <StyledTableCell><FormattedMessage id="section27.infoOpenDataTitle" defaultMessage="Сведения о наборе открытых данных" /></StyledTableCell>
                      <StyledTableCell align="right">
                        {isVisibleBoth && (
                          <Button onClick={handleOpenEdit} color="inherit">
                            <CreateIcon />
                          </Button>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {elementFields.map((row) => {
                      let commentGeo;
                      let commentIdentifier;
                      let commentServices;
                      switch (row.code_name) {
                        case 'com_geolocation':
                          commentGeo = row.value?.length > 0;
                          break;
                        case 'com_identifier':
                          commentIdentifier = row.value?.length > 0;
                          break;
                        case 'com_services':
                          commentServices = row.value?.length > 0;
                          break;
                        default:
                      }
                      let commentStyle = commentGeo || commentIdentifier || commentServices;
                      return (
                        <TableRow key={row.code_name}>
                          <StyledTableCell component="th" scope="row" className={commentStyle && 'comment'}>
                            {row.label}
                          </StyledTableCell>
                          <StyledTableCell align="right" className={commentStyle && 'comment'}>
                            {row.translated_value || row.value}
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {evalActivation.is_active.value !== 0 && (
                <div className="w100 spaceBetween wrap mt3">
                  <div className="w50">
                    <ExpertMark
                      mark={mark}
                      isExpert={isVisibleExpert}
                      onSubmit={handleSubmitMark(element.id?.value)}
                      onDelete={handleDeleteMark(element.id?.value)}
                    />
                  </div>
                  <div className="w50">
                    <div className="mt2">
                      <ExpertAssessment
                        assessment={assessment[0]}
                        isExpert={isVisibleExpert}
                        evaluations={evaluations}
                        onSubmit={handleSubmitAssessment(element.id?.value)}
                        onDelete={handleDeleteAssessment(element.id?.value)}
                        isVisible={mark}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <Dialog dialogState={dialogStateEdit} onClose={handleCloseEdit} title="Наличие наборов открытых данных">
          <Form submitAction={handleCloseEdit} defaultValues={defaultValues} defaultFormatValue={defaultFormatValue} isEditableMode />
        </Dialog>
      </FullDialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateOpenData',
          defaultMessage: 'Наименование ОД не должно повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}

const generateAnswer = (answer) => {
  if (answer || answer === 0)
    return answer === 1 ? (
      <FormattedMessage id="global.yesTitle" defaultMessage="Да" />
    ) : (
      <FormattedMessage id="global.noTitle" defaultMessage="Нет" />
    );
  return null;
};
