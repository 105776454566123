import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import routes from 'monitoring/routes';
import { useDispatch } from 'react-redux';
import { editCurrentProject, generateHeaders, getProjectLogs } from 'actions';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

function ForecastForm({ labels, closeDialog, defaultValue }) {
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      amount_forecast_funds: defaultValue,
    },
  });
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.editProject('change-forecast', projectId),
      method: 'put',
      data: data,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editCurrentProject(data));
        dispatch(getProjectLogs(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      <TextField
        name="amount_forecast_funds"
        label={labels.amount_forecast_funds}
        inputRef={register({ required: true })}
        type="number"
        className="mb"
        inputProps={{ min: 0, step: 0.01 }}
        error={!!errors.amount_forecast_funds}
        fullWidth
      />
      <TextField
        name="comment"
        multiline
        rowsMax="4"
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
        inputRef={register}
        type="text"
        className="mb2"
        fullWidth
      />

      <Button color="primary" variant="contained" type="submit">
        {loading ? <CircularProgress color="inherit" size={30} /> : <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />}
      </Button>
    </form>
  );
}

export default ForecastForm;
