import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, CircularProgress, FormControl, InputLabel, Select } from '@material-ui/core';
import Autocomplete from 'components/reused/Autocomplete';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useStyles, DashedTableCell } from 'components/reused/Table';
import Title from 'components/reused/Title';
import Layout from 'regions/Layout';
import Spinner from 'components/reused/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { get, getRegions, getDetailedEvaluation, getRDistricts, reportApi } from 'actions/regions';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import clsx from 'classnames';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';

function DetailAssessment() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {
      district_ids: [],
    },
  });

  const [statisticsDate, setStatisticsDate] = useState('');
  const [chronologyFirstDate, setChronologyFirstDate] = useState([]);
  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDetailedEvaluation({ region_id: '', region_type: 'region' }));
    let active = true;
    get('statistics-date').then(({ result }) => {
      if (active) setStatisticsDate(result);
    });
    get('date-stat').then((data) => {
      if (active && data.hasOwnProperty('result')) setChronologyFirstDate(data.result);
    });
    return () => (active = false);
  }, []);

  const currentUser = useSelector((state) => state.profile.currentUser);
  const showExportBtn = currentUser?.permissions.includes('r_export_reports');

  const { regions, districts } = useSelector((state) => state.r_dictionary);
  const loading = useSelector((state) => state.r_questionnaire_ui.evaluation);
  const table = useSelector((state) => state.r_questionnaire.detailedEvaluation);
  const region = watch('region_id');
  const isAllRegions = region === 'allRegions' || region === 'allDistricts';

  useEffect(() => {
    setValue('district_ids', []);
    if (region !== undefined && region !== 'allRegions' && region !== 'allDistricts') {
      dispatch(getRDistricts(region));
    }
  }, [region, dispatch, setValue]);

  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  useEffect(() => {
    if (isAllRegions) {
      setAutocompleteOptions([]);
    } else {
      setAutocompleteOptions(districts);
    }
  }, [region, districts]);

  const firstChronologyDate = watch('chronology_first_date_id');
  const [chronologySecondDate, setChronologySecondDate] = useState([]);
  useEffect(() => {
    if (firstChronologyDate) {
      get(`date-stat?date_first_stat_id=${firstChronologyDate}`).then((data) => {
        if (data.hasOwnProperty('result')) {
          setChronologySecondDate(data.result);
        } else {
          setChronologySecondDate([]);
        }
      });
    }
  }, [firstChronologyDate]);

  const onSubmit = (data) => {
    const filterIds = data?.district_ids?.map((value) => value.id);
    const filterOtherValues = {};
    if (data?.chronology_first_date_id && data?.chronology_first_date_id)
      filterOtherValues.chronology_first_date_id = data.chronology_first_date_id;
    if (data?.chronology_second_date_id && data?.chronology_second_date_id)
      filterOtherValues.chronology_second_date_id = data.chronology_second_date_id;

    if (isAllRegions) {
      const region_type = data.region_id === 'allRegions' ? 'region' : 'district';
      dispatch(getDetailedEvaluation({ region_id: '', region_type, ...filterOtherValues }));
    } else {
      dispatch(
        getDetailedEvaluation({
          region_id: region,
          districts: filterIds,
          region_type: 'district',
          ...filterOtherValues,
        })
      );
    }
  };

  const [fetchingFile, setFetchingFile] = useState(false);
  const handleExportFile = () => {
    setFetchingFile(true);
    const formValues = watch();
    const params = {};

    if (formValues.chronology_first_date_id) params.chronology_first_date_id = formValues.chronology_first_date_id;
    if (formValues.chronology_second_date_id) params.chronology_second_date_id = formValues.chronology_second_date_id;

    if (isAllRegions) {
      params.region_id = '';
      params.region_type = formValues.region_id === 'allRegions' ? 'region' : 'district';
    } else {
      params.region_id = formValues.region_id;
      params.region_type = 'district';
      params.districts = formValues?.district_ids?.map((value) => value.id);
    }

    reportApi('detailed-evaluation-report/get', params).then((data) => {
      fileDownload(data, `${uuidv4()}.xlsx`);
      setFetchingFile(false);
    });
  };

  return (
    <Layout>
      <Title title="Детализированная оценка" />
      <form
        className="mt dFlex"
        style={{ alignItems: 'stretch', justifyContent: 'center' }}
        onSubmit={handleSubmit(onSubmit)}>
        <div className="filtrationContainer" style={{ width: 450 }}>
          <FormControl fullWidth>
            <InputLabel>Выберите регион</InputLabel>
            <Select label="" native name="region_id" inputRef={register()}>
              <option value="allRegions">Все регионы</option>
              <option value="allDistricts">Все районы и города</option>
              {regions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.title}
                </option>
              ))}
            </Select>
          </FormControl>
          {!isAllRegions && (
            <Autocomplete
              control={control}
              options={autocompleteOptions}
              name="district_ids"
              label="Выберите район или область"
              noOptionsText="Нет подходящего элемента в списке"
            />
          )}
        </div>
        {chronologyFirstDate.length !== 0 && (
          <div className="filtrationContainer">
            <FormControl className="mb" fullWidth>
              <InputLabel>Выберите дату</InputLabel>
              <Select native name="chronology_first_date_id" inputRef={register()}>
                <option value="" />
                {chronologyFirstDate.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.comment || option.date_on}
                  </option>
                ))}
              </Select>
            </FormControl>
            {chronologySecondDate.length !== 0 && (
              <FormControl fullWidth>
                <InputLabel>Выберите дату до</InputLabel>
                <Select native name="chronology_second_date_id" inputRef={register()}>
                  <option value="" />
                  {chronologySecondDate.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.comment || option.date_on}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
        <div className="dFlex flexDirectionCol ml">
          <Button variant="contained" color="primary" type="submit" className="mt mb">
            Фильтровать
          </Button>
          {showExportBtn && (
            <Button variant="contained" color="primary" onClick={handleExportFile} disabled={fetchingFile}>
              {fetchingFile ? <CircularProgress color="secondary" size="30px" /> : 'Экспорт'}
            </Button>
          )}
        </div>
        <Title className="mlAuto" title={statisticsDate} disableBorder />
      </form>
      {loading ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper} className={clsx(classes.container, 'tableScroll')}>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              {table.map((tr, i) => {
                let styles = {};

                if (tr.rowType === 'category') {
                  styles.background = '#F0F2FF';
                  styles.height = 80;
                }
                if (tr.rowType === 'header') {
                  styles.background = '#b1baf7';
                }
                if (i === 0) {
                  styles.top = 0;
                  styles.height = 60;
                }

                if (table[0].rowType === 'head') {
                  if (i === 0) {
                    styles.background = '#b1baf7';
                  }

                  if (i === 1) {
                    styles.top = 60;
                    styles.height = 60;
                  }
                  if (i === 2) {
                    styles.top = 121;
                    styles.height = 60;
                  }
                  if (i >= 3) {
                    styles.top = 210;
                  }
                } else {
                  if (i === 1 && (tr.rowType === 'category' || tr.rowType === 'header')) {
                    styles.top = 60;
                    styles.height = 100;
                  }
                  if (i >= 2) {
                    styles.top = 160;
                  }
                }
                return (
                  <TableRow
                    key={i}
                    style={styles}
                    className={clsx(
                      (tr.rowType === 'header' || tr.rowType === 'category' || tr.rowType === 'head') && 'stickyHead'
                    )}>
                    {tr.row.map((cell, index) => {
                      let rowspan = 1;
                      let colspan = 1;
                      if (cell.hasOwnProperty('rowspan')) rowspan = cell.rowspan;
                      if (cell.hasOwnProperty('colspan')) colspan = cell.colspan;
                      if (cell.type === 'float' && cell.value) cell.value = Number(cell.value.toFixed(2));

                      let align = 'center';
                      if (cell.style && cell.style.align) align = cell.style.align;

                      let style = {};
                      if (tr.rowType === 'category' || tr.rowType === 'header') {
                        style.fontWeight = 'bold';
                        style.color = '#707070';
                      }
                      if (cell.style) style = { ...style, ...cell.style };

                      let ProgressIcon = null;
                      let colorIcon = 'white';
                      if (cell.type === 'icon') {
                        switch (cell.value) {
                          case 'progress':
                            ProgressIcon = TrendingUpIcon;
                            colorIcon = 'green';
                            break;
                          case 'regress':
                            ProgressIcon = TrendingDownIcon;
                            colorIcon = 'red';
                            break;
                          case 'not_changed':
                            ProgressIcon = TrendingFlatIcon;
                            colorIcon = '#666';
                            break;
                          default:
                            ProgressIcon = null;
                        }
                        return (
                          <DashedTableCell align={align} style={style} key={index} colSpan={colspan} rowSpan={rowspan}>
                            <ProgressIcon style={{ color: colorIcon, fontWeight: 600 }} />
                          </DashedTableCell>
                        );
                      }

                      return (
                        <DashedTableCell
                          className={clsx({
                            white:
                              tr.rowType !== 'header' &&
                              tr.rowType !== 'category' &&
                              tr.rowType !== 'head' &&
                              !style.background,
                            sidebar:
                              (tr.rowType !== 'child-indicator' || index === 0) &&
                              !exclutions.includes(tr.row[0].value) &&
                              [0, 1, 2].includes(index) &&
                              tr.rowType !== 'head',
                            scoreSidebar:
                              ((exclutions.includes(tr.row[0].value) && index === 0) ||
                                (tr.rowType === 'child-indicator' && index === 1)) &&
                              tr.rowType !== 'head',
                          })}
                          align={align}
                          style={style}
                          key={index}
                          colSpan={colspan}
                          rowSpan={rowspan}>
                          {cell.value}
                        </DashedTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}

export default DetailAssessment;

const exclutions = ['Оценка', 'Баҳо', 'Количество', 'Миқдори', 'План', 'Режа', 'Amount', 'Evaluation', 'Plan'];
