import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { addElementDigitalStrategy, editElementDigitalStrategy, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...defaultValues, document_file: '' },
    mode: 'onChange',
  });
  const { labels, elementStrategy, data } = useSelector((state) => state.sectionSixOrgDigitalStrategy, shallowEqual);

  const titles = data.map(({ title }) => title);

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());

  const onSubmit = async (data) => {
    const formValues = {
      ...data,
      organization_id: localStorage.orgId,
      section_id: 171,
    };
    if (isEditableMode) {
      if (data.document_file.length === 0)
        dispatch(
          editElementDigitalStrategy(elementStrategy.id.value, {
            ...formValues,
            document_file: defaultValues.document_file,
          })
        );
      else {
        const document_file = await uploadFile(data.document_file, 6.2);
        dispatch(editElementDigitalStrategy(elementStrategy.id.value, { ...formValues, document_file }));
      }
    } else {
      const document_file = await uploadFile(data.document_file, 6.2);
      dispatch(addElementDigitalStrategy({ ...formValues, document_file }));
    }

    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="title"
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = titles.includes(value);
            return !isValid;
          },
        })}
        error={!!errors.title}
        className="mb"
        label={labels.title}
        helperText={
          errors.title?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateNames',
            defaultMessage: 'Наименование документа не должно повторяться!',
          })
        }
      />
      <TextField
        fullWidth
        name="document_no"
        inputRef={register({
          required: true,
        })}
        error={!!errors.document_no}
        className="mb"
        label={labels.document_no}
      />
      <label htmlFor="accepted_date" className={`nativeLabel ${!!errors.accepted_date && 'error'}`}>
        {labels.accepted_date}
      </label>
      <TextField
        fullWidth
        name="accepted_date"
        id="accepted_date"
        type="date"
        defaultValue={defaultDate}
        inputRef={register({ required: true })}
        error={!!errors.accepted_date}
        className="mb"
      />
      <UploadFile
        error={!!errors.document_file}
        register={register({ required: !isEditableMode })}
        name="document_file"
      />
      <Button color="primary" variant="contained" className="mb" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
