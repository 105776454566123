import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const searchProjects = handleActions(
  {
    [actions.getSearchProjectsSuccess]: (state, { payload }) => ({
      ...state,
      projects: payload.items,
      pagination: payload._meta,
    }),
    [actions.setSearchFiltrationFields]: (state, { payload }) => ({ ...state, filter: payload.filter, isExpanded: payload.isExpanded }),
  },
  {
    filter: {},
    isExpanded: false,
    projects: [],
    pagination: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
  }
);

export const searchProjectsUI = handleActions(
  {
    [actions.getSearchProjectsRequest]: (state) => ({ ...state, fetchingProjects: true }),
    [actions.getSearchProjectsSuccess]: (state) => ({ ...state, fetchingProjects: false }),
    [actions.getSearchProjectsFailure]: (state) => ({ ...state, fetchingProjects: false }),
  },
  {
    fetchingProjects: false,
  }
);
