import { handleActions } from 'redux-actions';
import { setApp, setAccessibleApps } from 'actions';

export const app = handleActions(
  {
    [setApp]: (state, { payload }) => {
      localStorage.setItem('app', payload);
      return { ...state, currentApp: payload };
    },
    [setAccessibleApps]: (state, { payload }) => ({ ...state, list: payload }),
  },
  {
    currentApp: localStorage.app,
    list: [],
  }
);
