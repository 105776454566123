import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const epiguStats = handleActions(
  {
    [actions.getEpiguStatsSuccess]: (state, { payload: { data, labels } }) => ({
      ...state,
      stats: data,
      labels,
    }),
    [actions.setEpiguStatsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.getSectionThreeAssessmentSuccess]: (state, { payload }) => ({ ...state, assessment: payload }),
  },
  {
    stats: {},
    labels: {},
    assessment: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const epiguStatsUI = handleActions(
  {
    [actions.getEpiguStatsRequest]: () => ({ requestState: 'request' }),
    [actions.getEpiguStatsSuccess]: () => ({ requestState: 'success' }),
    [actions.getEpiguStatsFailure]: () => ({ requestState: 'failure' }),
    [actions.editEpiguStatsRequest]: () => ({ requestState: 'request' }),
    [actions.editEpiguStatsSuccess]: () => ({ requestState: 'success' }),
    [actions.editEpiguStatsFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
