import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { editInfoIctManager, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ defaultValues: { ...defaultValues, file: '' } });

  const labels = useSelector((state) => state.sectionOneInfoIctManager.labels, shallowEqual);
  const isEditMode = defaultValues.file;

  const onSubmit = async (data) => {
    if (isEditMode && data.file.length === 0)
      dispatch(editInfoIctManager({ ...data, file: defaultValues.file, is_exists: 1 }));
    else {
      const file = await uploadFile(data.file, 1.1);

      dispatch(editInfoIctManager({ ...data, file, is_exists: 1 }));
    }
    submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        name="last_name"
        className="mb"
        label={labels.last_name}
        inputRef={register({ required: true })}
        error={!!errors.last_name}
        fullWidth
      />
      <TextField
        name="first_name"
        className="mb"
        label={labels.first_name}
        inputRef={register({ required: true })}
        error={!!errors.first_name}
        fullWidth
      />
      <TextField
        name="middle_name"
        className="mb"
        label={labels.middle_name}
        inputRef={register({ required: true })}
        error={!!errors.middle_name}
        fullWidth
      />
      <TextField
        name="position_name"
        className="mb"
        label={labels.position_name}
        inputRef={register({ required: true })}
        error={!!errors.position_name}
        fullWidth
      />
      <TextField
        name="phone"
        className="mb"
        label={labels.phone}
        inputRef={register({ required: true })}
        error={!!errors.phone}
        type="phone"
        fullWidth
      />
      <TextField
        name="fax"
        className="mb"
        label={labels.fax}
        inputRef={register({ required: true })}
        error={!!errors.fax}
        type="phone"
        fullWidth
      />
      <TextField
        name="email"
        className="mb"
        label={labels.email}
        inputRef={register({ required: true })}
        error={!!errors.email}
        type="email"
        fullWidth
      />
      <label className="nativeLabel">{labels.file}</label>
      <UploadFile error={!!errors.file} register={register({ required: !isEditMode })} />
      <Button variant="contained" className="m0Auto mt mb" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
