import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const websiteAccess = handleActions(
  {
    [actions.getWebsiteInfoAccessSuccess]: (state, { payload: { data, labels } }) => {
      return {
        ...state,
        accessData: data
          ? Object.values(data)
          : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key })),
        labels,
      };
    },
    [actions.setWebsiteAccessError]: (state, { payload }) => ({ ...state, error: { hasError: true, message: payload.message, status: payload.status }})
  },
  {
    accessData: [],
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const websiteAccessUI = handleActions({
  [actions.getWebsiteInfoAccessRequest]: () => ({ requestState: 'request' }),
  [actions.getWebsiteInfoAccessSuccess]: () => ({ requestState: 'success' }),
  [actions.getWebsiteInfoAccessFailure]: () => ({ requestState: 'failure' }),
  [actions.editWebsiteInfoAccessRequest]: () => ({ requestState: 'request' }),
  [actions.editWebsiteInfoAccessSuccess]: () => ({ requestState: 'success' }),
  [actions.editWebsiteInfoAccessFailure]: () => ({ requestState: 'failure' }),
}, {})