import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const getSectionSixSimpleMarkRequest = createAction('SECTION_SIX_SIMPLE_MARK_GET_REQUEST');
export const getSectionSixSimpleMarkSuccess = createAction('SECTION_SIX_SIMPLE_MARK_GET_SUCCESS');
export const getSectionSixSimpleMarkFailure = createAction('SECTION_SIX_SIMPLE_MARK_GET_FAILURE');

export const getSectionSixSimpleMark = (id) => async (dispatch) => {
  dispatch(getSectionSixSimpleMarkRequest());

  try {
    const headers = generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId);

    const labels = await axios.get(routes.simpleMark('labels'), { headers });
    const mark = await axios.get(routes.simpleMark('view'), { headers, params: { section_id: id } });

    dispatch(getSectionSixSimpleMarkSuccess({ mark: mark.data, labels: labels.data }));
  } catch (e) {
    //dispatch(getSectionSixSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const saveSectionSixSimpleMarkRequest = createAction('SECTION_SIX_SIMPLE_MARK_SAVE_REQUEST');
export const saveSectionSixSimpleMarkSuccess = createAction('SECTION_SIX_SIMPLE_MARK_SAVE_SUCCESS');
export const saveSectionSixSimpleMarkFailure = createAction('SECTION_SIX_SIMPLE_MARK_SAVE_FAILURE');

export const saveSectionSixSimpleMark = (id, formValues) => async (dispatch) => {
  dispatch(saveSectionSixSimpleMarkRequest());

  try {
    const { data } = await axios({
      url: routes.simpleMark('create'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: id },
    });
    dispatch(saveSectionSixSimpleMarkSuccess(data));
  } catch (e) {
    //dispatch(saveSectionSixSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const deleteSectionSixSimpleMarkRequest = createAction('SECTION_SIX_SIMPLE_MARK_DELETE_REQUEST');
export const deleteSectionSixSimpleMarkSuccess = createAction('SECTION_SIX_SIMPLE_MARK_DELETE_SUCCESS');
export const deleteSectionSixSimpleMarkFailure = createAction('SECTION_SIX_SIMPLE_MARK_DELETE_FAILURE');

export const deleteSectionSixSimpleMark = (id) => async (dispatch) => {
  dispatch(deleteSectionSixSimpleMarkRequest());

  try {
    await axios.delete(routes.simpleMark('delete'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });
    dispatch(deleteSectionSixSimpleMarkSuccess());
    dispatch(deleteSectionSixSimpleAssessment(id, 15));
    dispatch(deleteSectionSixSimpleAssessment(id, 16));
  } catch (e) {
    //dispatch(deleteSectionSixSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const getSectionSixSimpleAssessmentRequest = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_GET_REQUEST');
export const getSectionSixSimpleAssessmentSuccess = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_GET_SUCCESS');
export const getSectionSixSimpleAssessmentFailure = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_GET_FAILURE');

export const getSectionSixSimpleAssessment = (id) => async (dispatch) => {
  dispatch(getSectionSixSimpleAssessmentRequest());

  try {
    const headers = generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId);

    const assessment = await axios.get(routes.sectionSixAssessment('view'), { headers, params: { section_id: id } });
    dispatch(getSectionSixSimpleAssessmentSuccess({ assessment: assessment.data }));
  } catch (e) {
    //dispatch(getSectionSixSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const saveSectionSixSimpleAssessmentRequest = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_SAVE_REQUEST');
export const saveSectionSixSimpleAssessmentSuccess = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_SAVE_SUCCESS');
export const saveSectionSixSimpleAssessmentFailure = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_SAVE_FAILURE');

export const saveSectionSixSimpleAssessment = (id, paramId = 1, formValues) => async (dispatch) => {
  dispatch(saveSectionSixSimpleAssessmentRequest());

  try {
    await axios({
      url: routes.sectionSixAssessment('create'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: id, param_id: paramId },
    });
    dispatch(getSectionSixSimpleAssessment(id));
  } catch (e) {
    //dispatch(saveSectionSixSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const deleteSectionSixSimpleAssessmentRequest = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_DELETE_REQUEST');
export const deleteSectionSixSimpleAssessmentSuccess = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_DELETE_SUCCESS');
export const deleteSectionSixSimpleAssessmentFailure = createAction('SECTION_SIX_SIMPLE_ASSESSMENT_DELETE_FAILURE');

export const deleteSectionSixSimpleAssessment = (id, paramId) => async (dispatch) => {
  dispatch(deleteSectionSixSimpleAssessmentRequest());

  try {
    await axios.delete(routes.sectionSixAssessment('delete'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id, param: paramId },
    });
    dispatch(getSectionSixSimpleAssessment(id));
  } catch (e) {
    //dispatch(deleteSectionSixSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const getSectionSixEvaluationRequest = createAction('SECTION_SIX_EVALUATIONS_GET_REQUEST');
export const getSectionSixEvaluationSuccess = createAction('SECTION_SIX_EVALUATIONS_GET_SUCCESS');
export const getSectionSixEvaluationFailure = createAction('SECTION_SIX_EVALUATIONS_GET_FAILURE');

export const getSectionSixEvaluations = (sectionId, firstParamId, secondParamId) => async (dispatch) => {
  dispatch(getSectionSixEvaluationRequest());

  try {
    const firstEval = await axios.get(routes.evaluations(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { id: sectionId, param_id: firstParamId },
    });
    const secondEval = await axios.get(routes.evaluations(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { id: sectionId, param_id: secondParamId },
    });
    dispatch(getSectionSixEvaluationSuccess({ firstEval: firstEval.data, secondEval: secondEval.data }));
  } catch (e) {
    // dispatch(getSectionSixEvaluationFailure());
    console.log(e);
  }
};
