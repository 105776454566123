import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { editWebsiteAppsInfo } from '../../../actions';

const Form = ({ defaultValues, submitAction }) => {
  const dispatch = useDispatch();

  const labels = useSelector((state) => state.sectionTwoWebsiteApps.labels, shallowEqual);
  const { register, handleSubmit, control, watch, errors } = useForm({ defaultValues });
  const radios = watch();
  const onSubmit = (data) => {
    let linksReset = {};
    if (data.has_android_app === '0') linksReset.link_android_app = '';
    if (data.has_ios_app === '0') linksReset.link_ios_app = '';
    if (data.has_another_app === '0') linksReset.link_another_app = '';
    dispatch(editWebsiteAppsInfo({ ...data, ...linksReset }));
    submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt2" style={{ width: 500 }}>
      <div className="mb2">
        <label htmlFor="has_android_app" className={`nativeLabel ${!!errors.has_android_app && 'error'}`}>
          {labels.has_android_app}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_android_app"
          rules={{ required: true }}
          control={control}
        />
        {radios.has_android_app === '1' && (
          <TextField
            fullWidth
            name="link_android_app"
            label={labels.link_android_app}
            inputRef={register({ required: true })}
            error={!!errors.link_android_app}
          />
        )}
      </div>

      <div className="mb2">
        <label htmlFor="has_ios_app" className={`nativeLabel ${!!errors.has_ios_app && 'error'}`}>
          {labels.has_ios_app}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_ios_app"
          rules={{ required: true }}
          control={control}
        />
        {radios.has_ios_app === '1' && (
          <TextField inputRef={register({ required: true })} error={!!errors.link_ios_app} name="link_ios_app" label={labels.has_ios_app} fullWidth />
        )}
      </div>

      <div className="mb2">
        <label htmlFor="has_another_app" className={`nativeLabel ${!!errors.has_another_app && 'error'}`}>
          {labels.has_another_app}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_another_app"
          rules={{ required: true }}
          control={control}
        />
        {radios.has_another_app === '1' && (
          <TextField
            inputRef={register({ required: true })}
            error={!!errors.link_another_app}
            name="link_another_app"
            label={labels.link_another_app}
            fullWidth
          />
        )}
      </div>

      <div className="mb2">
        <label htmlFor="is_adaptive" className={`nativeLabel ${!!errors.is_adaptive && 'error'}`}>
          {labels.is_adaptive}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="is_adaptive"
          rules={{ required: true }}
          control={control}
        />
      </div>

      <Button variant="outlined" color="primary" className="m0Auto" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;