import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export function DrawerItem({ url, icon, text, open, ...rest }) {
  const listStyles = {
    height: open ? 'min-content' : 55,
    borderBottom: '1px solid rgba(0,0,0, 0.07)',
  };
  return (
    <NavLink to={url} className="drawerLink" {...rest}>
      <ListItem button dense style={listStyles}>
        <ListItemIcon color="primary">{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </NavLink>
  );
}

export function SimpleDrawerItem({ icon, text, open }) {
  const listStyles = {
    height: open ? 'min-content' : 55,
    borderBottom: '1px solid rgba(0,0,0, 0.07)',
  };
  return (
    <ListItem button dense style={listStyles}>
      <ListItemIcon color="primary">{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
}
