import { handleActions } from 'redux-actions';
import { errorFileUpload } from 'actions';

export const fileUpload = handleActions(
  {
    [errorFileUpload]: (_, { payload }) => ({ errorData: payload.error, fileName: payload.fileName }),
  },
  {
    errorData: [],
  }
);
