import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { generateHeaders } from './dictionary';

export const setCyberSecurityError = createAction('CYBER_SECURITY_SET_ERROR');

export const getCyberSecurityInfoRequest = createAction('CYBER_SECURITY_INFO_GET_REQUEST');
export const getCyberSecurityInfoSuccess = createAction('CYBER_SECURITY_INFO_GET_SUCCESS');
export const getCyberSecurityInfoFailure = createAction('CYBER_SECURITY_INFO_GET_FAILURE');

export const getCyberSecurityInfo = (id) => async (dispatch) => {
  dispatch(getCyberSecurityInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionFourCyberSecurityView(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });
    dispatch(getCyberSecurityInfoSuccess(data));
  } catch (e) {
    dispatch(setCyberSecurityError(e?.response));
    dispatch(getCyberSecurityInfoFailure());
  }
};

export const postCyberSecurityInfoRequest = createAction('CYBER_SECURITY_INFO_POST_REQUEST');
export const postCyberSecurityInfoSuccess = createAction('CYBER_SECURITY_INFO_POST_SUCCESS');
export const postCyberSecurityInfoFailure = createAction('CYBER_SECURITY_INFO_POST_FAILURE');

export const postCyberSecurityInfo = (formValues) => async (dispatch) => {
  dispatch(postCyberSecurityInfoRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionFourCyberSecurityUpdate(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(postCyberSecurityInfoSuccess(data));
  } catch (e) {
    dispatch(setCyberSecurityError(e?.response));
    dispatch(postCyberSecurityInfoFailure());
  }
};

export const getCyberSecurityEvaluationRequest = createAction('CYBER_SECURITY_EVALUATION_GET_REQUEST');
export const getCyberSecurityEvaluationSuccess = createAction('CYBER_SECURITY_EVALUATION_GET_SUCCESS');
export const getCyberSecurityEvaluationFailure = createAction('CYBER_SECURITY_EVALUATION_GET_FAILURE');

export const getCyberSecurityEvaluation = (sectionId) => async (dispatch) => {
  dispatch(getCyberSecurityEvaluationRequest());

  try {
    const requestConfig = {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: sectionId },
    };
    const evaluation = await axios.get(routes.cyberSecurityEvaluation('view'), requestConfig);
    const alert = await axios.get(routes.cyberSecurityEvaluation('alert'), requestConfig);

    dispatch(getCyberSecurityEvaluationSuccess({ evaluation: evaluation.data, alert: alert.data }));
  } catch (e) {
    //dispatch(getCyberSecurityEvaluationFailure(e?.response));
    console.log(e);
  }
};
