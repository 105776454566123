import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setManagerInfoError = createAction('MANAGER_INGO_SET_ERROR');

export const getManagerInfoRequest = createAction('MANAGER_INFO_GET_REQUEST');
export const getManagerInfoSuccess = createAction('MANAGER_INFO_GET_SUCCESS');
export const getManagerInfoFailure = createAction('MANAGER_INFO_GET_FAILURE');

export const getManagerInfo = () => async (dispatch) => {
  dispatch(getManagerInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteManager(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoWebsiteManagerLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getManagerInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    console.log('e', e);
    dispatch(setManagerInfoError(e?.response));
  }
};

export const editManagerInfoRequest = createAction('MANAGER_INFO_EDIT_REQUEST');
export const editManagerInfoSuccess = createAction('MANAGER_INFO_EDIT_SUCCESS');
export const editManagerInfoFailure = createAction('MANAGER_INFO_EDIT_FAILURE');

export const editManagerInfo = (formData) => async (dispatch) => {
  dispatch(editManagerInfoRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoWebsiteManager(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getManagerInfo());
    dispatch(editManagerInfoSuccess());
  } catch (e) {
    dispatch(setManagerInfoError(e?.response));
    dispatch(editManagerInfoFailure());
  }
};
