import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

import Form from './Form';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import ExpertMark from '../../reused/ExpertMark';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import ExpertAssessment from '../../reused/ExpertAssessment';
import EvaluationActivation from '../../reused/EvaluationActivation';
import {
  deleteElementDigitalStrategy,
  deleteSectionSixSimpleAssessment,
  deleteSectionSixSimpleMark,
  getCheckboxDigitalStrategy,
  getElementDigitalStrategy,
  getInfoDigitalStrategy,
  getLabelsDigitalStrategy,
  getSectionSixSimpleAssessment,
  getSectionSixEvaluations,
  getSectionSixSimpleMark,
  saveSectionSixSimpleAssessment,
  saveSectionSixSimpleMark,
  updateCheckboxDigitalStrategy,
  resetError,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  toggleTabState,
} from '../../../actions';
import Download from '../../reused/Download';

function Section62() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();
  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  useEffect(() => {
    dispatch(getInfoDigitalStrategy());
    dispatch(getLabelsDigitalStrategy());
    dispatch(getCheckboxDigitalStrategy());
    dispatch(getEvalActivationInfo(171));
    dispatch(getSectionSixSimpleAssessment(171));
    dispatch(getSectionSixSimpleMark(171));
    dispatch(getSectionSixEvaluations(171, 15, 16));
  }, [dispatch]);

  const { data, labels, elementStrategy, checkbox } = useSelector(
    (state) => state.sectionSixOrgDigitalStrategy,
    shallowEqual
  );
  const { assessment, mark, firstEval, secondEval } = useSelector((state) => state.sectionSixEvaluation, shallowEqual);

  const { requestAssessmentState } = useSelector((state) => state.simpleEvaluationUI, shallowEqual);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpertCep = currentUser?.roles.includes('expert-cep');
  const isOrganization = currentUser?.roles.includes('organization-xoz');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const isVisible = isExpertCep && finishTimeExpert > dateNow;

  const isBothRole = isExpertCep || isOrganization;

  const isVisibleExpert = isExpertCep && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const defaultValues = useMemo(
    () =>
      Object.values(elementStrategy).reduce((acc, el) => ({ ...acc, [el.code_name]: el.value ? el.value : '' }), {}),
    [elementStrategy]
  );

  const onClickEdit = (id) => async () => {
    await dispatch(getElementDigitalStrategy(id));
    handleOpenEdit();
  };

  const onClickDelete = (id) => () => dispatch(deleteElementDigitalStrategy(id));

  useEffect(() => {
    if (checkbox.checkbox_value) {
      setValue(checkbox.checkbox_value);
      setComment(checkbox.comment_value);
      if (!checkbox.comment_value?.trim()) setIsEditMode(true);
    } else {
      setValue('');
      setComment('');
      setIsEditMode(false);
    }
  }, [checkbox]);

  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const handleChange = (e) => {
    if (e.target.value === '1') {
      dispatch(
        updateCheckboxDigitalStrategy({
          ...checkbox,
          checkbox_value: +e.target.value,
          comment_value: '',
        })
      );
    }
    setValue(e.target.value);
    setIsEditMode(true);
  };
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      updateCheckboxDigitalStrategy({
        ...checkbox,
        checkbox_value: value,
        comment_value: comment,
      })
    );
    setIsEditMode(false);
  };
  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestLabelState, requestItemState } = useSelector(
    (state) => state.sectionSixOrgDigitalStrategyUI,
    shallowEqual
  );
  const errorHandler = useSelector((state) => state.sectionSixOrgDigitalStrategy.error, shallowEqual);

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(171, data));
    dispatch(getSectionSixSimpleAssessment(171));
    dispatch(getSectionSixSimpleMark(171));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const handleSubmitMark = (data) => dispatch(saveSectionSixSimpleMark(171, data));
  const handleDeleteMark = () => dispatch(deleteSectionSixSimpleMark(171));
  const handleSubmitAssessment = (id) => (data) => dispatch(saveSectionSixSimpleAssessment(171, id, data));
  const handleDeleteAssessment = (id) => () => dispatch(deleteSectionSixSimpleAssessment(171, id));

  return (
    <div>
      <div className="titleContainer marginsTitle">
        <div className="titleBorder"></div>
        <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <FormControl component="fieldset" disabled={!isVisibleOrg}>
        <FormLabel component="legend">{checkbox.checkbox_title}</FormLabel>
        <RadioGroup value={value} onChange={handleChange} row>
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
          />
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
          />
        </RadioGroup>
      </FormControl>
      {value === '0' && isVisibleOrg && (
        <>
          {isEditMode && isVisibleOrg ? (
            <form>
              <TextField
                required
                className="w50"
                label={checkbox.comment_title}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <Button variant="contained" color="primary" type="submit" onClick={handleClick} className="dBlock mt2">
                <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
              </Button>
            </form>
          ) : (
            <div>
              <p className="assessment__score mb">{checkbox.comment_title}</p>
              <p className="assessment__score mb">{comment}</p>
              {isVisibleOrg && (
                <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
                  <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
                </Button>
              )}
            </div>
          )}
        </>
      )}

      {value === '1' && (
        <div className="mb">
          <TableContainer component={Paper} className={classes.container}>
            {requestLabelState === 'request' ? (
              <Loader />
            ) : (
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {isVisibleOrg && <StyledTableCell align="right"></StyledTableCell>}
                    <StyledTableCell>№</StyledTableCell>
                    <StyledTableCell>{labels.title}</StyledTableCell>
                    <StyledTableCell>{labels.document_no}</StyledTableCell>
                    <StyledTableCell>{labels.accepted_date}</StyledTableCell>
                    <StyledTableCell>{labels.document_file}</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TransitionGroup component={TableBody}>
                  {data.map((currentInfo, ind) => (
                    <CSSTransition key={currentInfo.id} timeout={1200} classNames="listItemUI">
                      <StyledTableRow>
                        {isVisibleOrg && (
                          <StyledTableCell align="center">
                            <ButtonGroup orientation="vertical" variant="contained" color="primary">
                              <Button aria-label="edit" onClick={onClickEdit(currentInfo.id)}>
                                <CreateIcon />
                              </Button>
                              <Button color="secondary" aria-label="edit" onClick={onClickDelete(currentInfo.id)}>
                                <DeleteIcon />
                              </Button>
                            </ButtonGroup>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>{ind + 1}</StyledTableCell>
                        <StyledTableCell>{currentInfo.title}</StyledTableCell>
                        <StyledTableCell>{currentInfo.document_no}</StyledTableCell>
                        <StyledTableCell>{currentInfo.accepted_date}</StyledTableCell>
                        <StyledTableCell>
                          <Download fileName={currentInfo.document_file} />
                        </StyledTableCell>
                      </StyledTableRow>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Table>
            )}
          </TableContainer>
          {value === '1' && isVisibleOrg && (
            <Button color="primary" variant="contained" className="m0Auto" onClick={handleOpenAdd}>
              <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
            </Button>
          )}
        </div>
      )}
      <EvaluationActivation isExpert={isVisible} onSubmit={updateEvalActivation} className="w30 mAuto mt4">
        {/* {(isBothRole && localStorage.orgCategoryId === '2') && (
              <> */}
        <div className="w50 m0Auto">
          <ExpertMark mark={mark} isExpert={isVisible} onSubmit={handleSubmitMark} onDelete={handleDeleteMark} />
        </div>
        <div className="w50 m0Auto">
          <div className="mt2">
            <ExpertAssessment
              assessment={assessment[0]}
              isExpert={isVisibleExpert}
              evaluations={firstEval}
              onSubmit={handleSubmitAssessment(assessment[0]?.param_id)}
              onDelete={handleDeleteAssessment(assessment[0]?.param_id)}
              isVisible={mark}
            />
          </div>
        </div>
        <div className="w50 m0Auto">
          <div className="mt2">
            <ExpertAssessment
              assessment={assessment[1]}
              isExpert={isVisibleExpert}
              evaluations={secondEval}
              onSubmit={handleSubmitAssessment(assessment[1]?.param_id)}
              onDelete={handleDeleteAssessment(assessment[1]?.param_id)}
              isVisible={mark}
            />
          </div>
        </div>
      </EvaluationActivation>

      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        {requestItemState === 'request' ? (
          <Loader />
        ) : (
          <Form submitAction={handleCloseEdit} defaultValues={defaultValues} isEditableMode />
        )}
      </Dialog>
      <Dialog
        dialogState={dialogStateAdd}
        onClose={handleCloseAdd}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleCloseAdd} />
      </Dialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateNames',
          defaultMessage: 'Наименование документа не должно повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}

export default Section62;
