import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const getEpiguServicesInfoRequest = createAction('EPIGU_SERVICES_INFO_GET_REQUEST');
export const getEpiguServicesInfoSuccess = createAction('EPIGU_SERVICES_INFO_GET_SUCCESS');
export const getEpiguServicesInfoFailure = createAction('EPIGU_SERVICES_INFOGEET_FAILURE');

export const getEpiguServicesInfo = (page) => async (dispatch) => {
  dispatch(getEpiguServicesInfoRequest());

  try {
    const { data } = await axios({
      url: routes.sectionThreeEpiguStatsRequests(),
      method: 'get',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });

    const labels = await axios.get(routes.sectionThreeEpiguStatsRequestsLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getEpiguServicesInfoSuccess({data, labels: labels.data}));
  } catch (e) {
    dispatch(getEpiguServicesInfoFailure());
    console.log(e);
  }
};
