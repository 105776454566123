import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';

export const getProfileRequest = createAction('PROFILE_GET_REQUEST');
export const getProfileSuccess = createAction('PROFILE_GET_SUCCESS');
export const getProfileFailure = createAction('PROFILE_GET_FAILURE');

export const getProfile = () => async (dispatch) => {
  dispatch(getProfileRequest());

  try {
    if (localStorage.token) {
      const { data } = await axios.get(routes.profile(), {
        headers: {
          'organization-id': localStorage.orgId,
          Authorization: `Bearer ${localStorage.token}`,
          language: localStorage.lang || 'ru',
        },
      });

      if (data.status) {
        dispatch(getProfileSuccess(data));
      } else {
        localStorage.removeItem('token');
        dispatch(getProfileFailure({ payload: { status: 401 } }));
      }
    } else {
      dispatch(getProfileFailure({ payload: { status: 401 } }));
    }
  } catch (e) {
    dispatch(getProfileFailure(e.response));
  }
};
