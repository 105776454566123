import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setOrgBasesError = createAction('ORG_BASES_SET_ERROR');

export const getLabelsBasesRequest = createAction('LABELS_BASES_GET_REQUEST');
export const getLabelsBasesSuccess = createAction('LABELS_BASES_GET_SUCCESS');
export const getLabelsBasesFailure = createAction('LABELS_BASES_GET_FAILURE');

export const getLabelsBases = () => async (dispatch) => {
  dispatch(getLabelsBasesRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgLegalBasisLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsBasesSuccess(data));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(getLabelsBasesFailure());
    console.log(e);
  }
};

export const getDocumentsBasesRequest = createAction('DOCUMENTS_BASES_GET_REQUEST');
export const getDocumentsBasesSuccess = createAction('DOCUMENTS_BASES_GET_SUCCESS');
export const getDocumentsBasesFailure = createAction('DOCUMENTS_BASES_GET_FAILURE');

export const getDocumentsBases = () => async (dispatch) => {
  dispatch(getDocumentsBasesRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgLegalBasisData(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDocumentsBasesSuccess(data.items));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(getDocumentsBasesFailure());
    console.log(e.response);
  }
};

export const addElementBasesRequest = createAction('ELEMENT_ADD_REQUEST');
export const addElementBasesSuccess = createAction('ELEMENT_ADD_SUCCESS');
export const addElementBasesFailure = createAction('ELEMENT_ADD_FAILURE');

export const addElementBases = (formData) => async (dispatch) => {
  dispatch(addElementBasesRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionOneOrgLegalBasisData(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addElementBasesSuccess(data));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(addElementBasesFailure());
    console.log(e.request.response);
  }
};

export const deleteElementBasesRequest = createAction('ELEMENT_BASES_DELETE_REQUEST');
export const deleteElementBasesSuccess = createAction('ELEMENT_BASES_DELETE_SUCCESS');
export const deleteElementBasesFailure = createAction('ELEMENT_BASES_DELETE_FAILURE');

export const deleteElementBases = (id) => async (dispatch) => {
  dispatch(deleteElementBasesRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionOneOrgLegalBasisOperationInfo(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteElementBasesSuccess({ id }));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(deleteElementBasesFailure());
    console.log(e);
  }
};

export const getElementBasesRequest = createAction('ELEMENT_BASES_GET_REQUEST');
export const getElementBasesSuccess = createAction('ELEMENT_BASES_GET_SUCCESS');
export const getElementBasesFailure = createAction('ELEMENT_BASES_GET_FAILURE');

export const getElementBases = (id) => async (dispatch) => {
  dispatch(getElementBasesRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgLegalBasisOperationInfo(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getElementBasesSuccess(data));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(getElementBasesFailure());
    console.log(e);
  }
};

export const editElementBasesRequest = createAction('ELEMENT_BASES_EDIT_REQUEST');
export const editElementBasesSuccess = createAction('ELEMENT_BASES_EDIT_SUCCESS');
export const editElementBasesFailure = createAction('ELEMENT_BASES_EDIT_FAILURE');

export const editElementBases = (id, formData) => async (dispatch) => {
  dispatch(editElementBasesRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionOneOrgLegalBasisOperationInfo(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editElementBasesSuccess(data));
  } catch (e) {
    dispatch(setOrgBasesError(e?.response));
    dispatch(editElementBasesFailure());
  }
};
