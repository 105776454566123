import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';

import Form from './Form';
import { useDialog } from '../../../common/hooks';
import Dialog from '../../reused/SimpleDialog';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import { getLabelsBases, getDocumentsBases, deleteElementBases, getDocumentTypes, getAcceptedDocuments, getElementBases, resetError } from '../../../actions';
import Download from '../../reused/Download';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';

export default function Section16() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [dialogState, handleOpen, handleClose] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  useEffect(() => {
    dispatch(getDocumentsBases());
    dispatch(getLabelsBases());
    dispatch(getDocumentTypes());
    dispatch(getAcceptedDocuments());
  }, [dispatch]);

  const { labels, data, document } = useSelector((state) => state.sectionOneOrgLegalBasis, shallowEqual);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const finalLabels = useMemo(() => Object.values(labels), [labels]);

  const finalDocument = useMemo(() => Object.values(document), [document]);
  const defaultValues = useMemo(
    () =>
      finalDocument.reduce((acc, el) => {
        return { ...acc, [el.code_name]: el.value };
      }, {}),
    [finalDocument]
  );

  const handleEditElement = (id) => async () => {
    handleOpenEdit();
    await dispatch(getElementBases(id));
  };

  const handleDeleteElement = (id) => () => dispatch(deleteElementBases(id));

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestLabelState, requestItemState } = useSelector((state) => state.sectionOneOrgLegalBasisUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneOrgLegalBasis.error, shallowEqual);
  
  const [alertState, setAlertState] = React.useState(false);

  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);
  
      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <TableContainer component={Paper} className={classes.container}>
        {requestLabelState === 'request' ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {isVisible && <StyledTableCell />}
                <StyledTableCell>№</StyledTableCell>
                {finalLabels.map((label) => (
                  <StyledTableCell key={label}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>

            <TransitionGroup component={TableBody}>
              {data.map((row, ind) => (
                <CSSTransition key={row.id} timeout={1200} classNames="listItemUI">
                  <StyledTableRow>
                    {isVisible && (
                      <StyledTableCell>
                        <ButtonGroup orientation="vertical" variant="contained" color="primary">
                          <Button onClick={handleEditElement(row.id)}>
                            <CreateIcon />
                          </Button>
                          <Button color="secondary" onClick={handleDeleteElement(row.id)}>
                            <Delete />
                          </Button>
                        </ButtonGroup>
                      </StyledTableCell>
                    )}

                    <StyledTableCell align="center">{ind + 1}</StyledTableCell>
                    <StyledTableCell align="center">{row.document_no}</StyledTableCell>
                    <StyledTableCell align="center">{row.document_date}</StyledTableCell>
                    <StyledTableCell align="center">{row.document_type_name}</StyledTableCell>
                    <StyledTableCell align="center">{row.accepted_authority_name}</StyledTableCell>
                    <StyledTableCell align="center">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">
                      <Download fileName={row.file} />
                    </StyledTableCell>
                  </StyledTableRow>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Table>
        )}
      </TableContainer>
      {isVisible && (
        <Button variant="contained" color="primary" type="submit" onClick={handleOpen} className="m0Auto">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}
      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        {requestItemState === 'request' ? <Loader /> : <Form submitAction={handleCloseEdit} defaultValues={defaultValues} isEditableMode />}
      </Dialog>
      <Dialog dialogState={dialogState} onClose={handleClose} title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleClose} />
      </Dialog>
      <Alert open={alertState} onClose={() => setAlertState(false)} message={intl.formatMessage({
          id: 'global.errorDublicateNames',
          defaultMessage: 'Наименование документа не должно повторяться!',
        })} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} />
    </>
  );
}
