import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function CustomizedTables({ rows, title, head }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow className={classes.headRow}>
            <StyledTableCell>{title}</StyledTableCell>
            <StyledTableCell align="right">{head}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.code_name}>
              <StyledTableCell component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell align="right">{row.translated_value || row.value}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const useStyles = makeStyles({
  container: {
    marginRight: '20px',
    marginBottom: '20px',
    borderRadius: 25,
  },
  table: {
    minWidth: 'auto',
  },
  link: {
    textDecoration: 'none',
    color: '#337ab7',
  },
});

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    padding: '16px 40px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    '&:first-child': {
      borderRadius: '25px 0 0 25px',
    },
    '&:last-child': {
      borderRadius: '0 25px 25px 0',
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '16px 40px',
    borderBottom: '1px solid #F3F3F3',
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);

export const DashedTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    '&:first-child': {
      borderRadius: '25px 0 0 25px',
    },
    '&:last-child': {
      borderRadius: '0 25px 25px 0',
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '10px',
    borderBottom: '1px dashed #E4E4E4',
    borderRight: '1px dashed #E4E4E4',
    '&:last-child': {
      borderRight: '0px',
    },
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    // '&:nth-of-type(odd)': {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}))(TableRow);
