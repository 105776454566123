import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const projects = handleActions(
  {
    [actions.getProjectsSuccess]: (state, { payload }) => ({
      ...state,
      list: payload.items,
      pagination: payload._meta,
    }),
    [actions.addProject]: (state, { payload }) => ({ ...state, list: state.list.concat(payload) }),
    [actions.getProjectSuccess]: (state, { payload }) => ({ ...state, current: payload }),
    [actions.deleteProjectSuccess]: (state, { payload: id }) => ({
      ...state,
      list: state.list.filter((project) => project.id !== id),
    }),
    [actions.editCurrentProject]: (state, { payload }) => ({
      ...state,
      current: { ...state.current, ...payload },
    }),
  },
  {
    list: [],
    pagination: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
    current: {
      co_executors: [],
      co_executors_names: [],
      financial_sources: [],
      financial_source_names: [],
    },
  }
);
