import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setHackathonError = createAction('HACKATHON_SET_ERROR');

export const getHackathonsListRequest = createAction('LIST_HACKATHONS_GET_REQUEST');
export const getHackathonsListSuccess = createAction('LIST_HACKATHONS_GET_SUCCESS');
export const getHackathonsListFailure = createAction('LIST_HACKATHONS_GET_FAILURE');

export const getHackathonsList = () => async (dispatch) => {
  dispatch(getHackathonsListRequest());

  try {
    const { data } = await axios.get(routes.sectionSixOrgHackathon(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getHackathonsListSuccess(data.items));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(getHackathonsListFailure());
  }
};

export const getLabelsHackathonRequest = createAction('LABELS_HACKATHON_GET_REQUEST');
export const getLabelsHackathonSuccess = createAction('LABELS_HACKATHON_GET_SUCCESS');
export const getLabelsHackathonFailure = createAction('LABELS_HACKATHON_GET_FAILURE');

export const getLabelsHackathon = () => async (dispatch) => {
  dispatch(getLabelsHackathonRequest());

  try {
    const { data } = await axios.get(routes.sectionSixOrgHackathonLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getLabelsHackathonSuccess(data));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(getLabelsHackathonFailure());
  }
};

export const getHackathonRequest = createAction('HACKATHON_GET_REQUEST');
export const getHackathonSuccess = createAction('HACKATHON_GET_SUCCESS');
export const getHackathonFailure = createAction('HACKATHON_GET_FAILURE');

export const getHackathon = (id) => async (dispatch) => {
  dispatch(getHackathonRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSixOrgHackathonOperations()}/${id}`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getHackathonSuccess({ event: data, id }));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(getHackathonFailure());
  }
};

export const deleteHackathonRequest = createAction('HACKATHON_DELETE_REQUEST');
export const deleteHackathonSuccess = createAction('HACKATHON_DELETE_SUCCESS');
export const deleteHackathonFailure = createAction('HACKATHON_DELETE_FAILURE');

export const deleteHackathon = (id) => async (dispatch) => {
  dispatch(deleteHackathonRequest());

  try {
    await axios({
      method: 'delete',
      url: `${routes.sectionSixOrgHackathonOperations()}/${id}`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteHackathonSuccess({ id }));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(deleteHackathonFailure());
  }
};

export const addHackathonRequest = createAction('HACKATHON_ADD_REQUEST');
export const addHackathonSuccess = createAction('HACKATHON_ADD_SUCCESS');
export const addHackathonFailure = createAction('HACKATHON_ADD_FAILURE');

export const addHackathon = (formData) => async (dispatch) => {
  dispatch(addHackathonRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionSixOrgHackathon(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addHackathonSuccess(data));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(addHackathonFailure());
  }
};

export const editHackathonRequest = createAction('HACKATHON_EDIT_REQUEST');
export const editHackathonSuccess = createAction('HACKATHON_EDIT_SUCCESS');
export const editHackathonFailure = createAction('HACKATHON_EDIT_FAILURE');

export const editHackathon = (id, formData) => async (dispatch) => {
  dispatch(editHackathonRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: `${routes.sectionSixOrgHackathon()}/${id}`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editHackathonSuccess(data));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(editHackathonFailure());
  }
};

export const getHackathonRadioBtnRequest = createAction('HACKATHON_RADIO_BTN_GET_REQUEST');
export const getHackathonRadioBtnSuccess = createAction('HACKATHON_RADIO_BTN_GET_SUCCESS');
export const getHackathonRadioBtnFailure = createAction('HACKATHON_RADIO_BTN_GET_FAILURE');

export const getHackathonRadioBtn = () => async (dispatch) => {
  dispatch(getHackathonRadioBtnRequest());

  try {
    const { data } = await axios.get(`${routes.sectionSixOrgHackathonRadio()}view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getHackathonRadioBtnSuccess(data));
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(getHackathonRadioBtnFailure());
  }
};

export const updateHackathonRadioBtnRequest = createAction('HACKATHON_RADIO_BTN_UPDATE_REQUEST');
export const updateHackathonRadioBtnFailure = createAction('HACKATHON_RADIO_BTN_UPDATE_FAILURE');

export const updateHackathonRadioBtn = (formValues) => async (dispatch) => {
  dispatch(updateHackathonRadioBtnRequest());

  try {
    await axios({
      method: 'post',
      url: `${routes.sectionSixOrgHackathonRadio()}update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getHackathonRadioBtn());
  } catch (e) {
    dispatch(setHackathonError(e?.response));
    dispatch(updateHackathonRadioBtnFailure());
  }
};