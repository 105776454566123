import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableContainer, TableHead, Paper, Button, Checkbox } from '@material-ui/core';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { DashedTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import { Alert, Pagination, Skeleton } from '@material-ui/lab';

import { getLabels } from 'actions';
import { useDialog } from 'common/hooks';
import Dialog from 'components/reused/SimpleDialog';
import Empty from 'components/reused/Empty';
import Title from 'components/reused/Title';
import CommentForm from 'monitoring/Lists/Logs/CommentForm';
import routes from 'monitoring/routes';
import { updateStepLog } from 'actions/monitoring/log';
import CounterPage from 'components/reused/CounterPage';
import { FormattedMessage } from 'react-intl';

export default function DenseTable({
  getLogs,
  stepId,
  logList = [],
  configLogs,
  title = '',
  showComment = true,
  isExecutor = false,
}) {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [labels, setLabels] = useState({});
  const [projectCommentForm, openProjectCommentForm, closeProjectCommentForm] = useDialog();

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getLogs(projectId, value));
  };

  useEffect(() => {
    dispatch(getLogs(projectId, page));

    let active = true;
    getLabels('log').then((data) => {
      if (active) setLabels(data);
    });
    return () => (active = false);
  }, []);

  const classes = useStyles();
  const { loading, errors } = useSelector((state) => state.m_log);

  const ui = (text) => (loading ? <Skeleton className="m0Auto" animation="pulse" width={200} /> : text);

  const handleUpdateLogActive = (id) => (event) => {
    const isChecked = event.target.checked;
    dispatch(updateStepLog(id, { to_excel: isChecked }));
  };

  return (
    <>
      {errors.length > 0 &&
        errors.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <div className="dFJustifyFxSpaceBtwn mt3 w100">
        <Title title={title} disableBorder />
        {logList.length !== 0 && <CounterPage config={configLogs} page={page} length={logList.length} />}
      </div>
      <TableContainer component={Paper} className="mt2">
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <DashedTableCell align="center" style={{ width: 100 }}>
                Включение в историю
              </DashedTableCell>
              <DashedTableCell align="center">{labels.role_name}</DashedTableCell>
              <DashedTableCell align="center">{labels.action_name}</DashedTableCell>
              <DashedTableCell align="center">{labels.created_at}</DashedTableCell>
              {showComment && (
                <DashedTableCell align="center">
                  {labels.description}
                  <Button
                    disabled={isExecutor}
                    variant="outlined"
                    title={'Добавить комментарий'}
                    color="primary"
                    className="ml2"
                    onClick={openProjectCommentForm}>
                    <AddCommentIcon />
                  </Button>
                </DashedTableCell>
              )}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {logList.map((log) => (
              <StyledTableRow key={log.id}>
                <DashedTableCell align="center">
                  <Checkbox
                    disabled={isExecutor}
                    color="primary"
                    checked={log.to_excel}
                    onClick={handleUpdateLogActive(log.id)}
                  />
                </DashedTableCell>
                <DashedTableCell align="center">{ui(log.role_name)}</DashedTableCell>
                <DashedTableCell align="center">{ui(log.action_name)}</DashedTableCell>
                <DashedTableCell align="center">{ui(log.created_at)}</DashedTableCell>
                {showComment && (
                  <DashedTableCell>
                    <p style={{ whiteSpace: 'pre-wrap' }}>{ui(log.description)}</p>
                  </DashedTableCell>
                )}
              </StyledTableRow>
            ))}

            {logList.length === 0 && (
              <StyledTableRow>
                <DashedTableCell colSpan="4">
                  <Empty title="Данных нет" />
                </DashedTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        dialogState={projectCommentForm}
        onClose={closeProjectCommentForm}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
        maxWidth="md">
        <CommentForm url={routes.logs()} closeDialog={closeProjectCommentForm} stepId={stepId} />
      </Dialog>
      {configLogs.pageCount > 1 && (
        <div className="dFJustifyFxCenter w100">
          <Pagination
            count={configLogs.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            variant="outlined"
            color="primary"
            className="mt2 m0Auto"
          />
        </div>
      )}
    </>
  );
}
