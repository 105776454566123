import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const getOrgInfoRequest = createAction('ORG_INFO_GET_REQUEST');
export const getOrgInfoSuccess = createAction('ORG_INFO_GET_SUCCESS');
export const getOrgInfoFailure = createAction('ORG_INFO_GET_FAILURE');

export const getOrgInfo = (orgId) => async (dispatch) => {
  dispatch(getOrgInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgInfo(orgId), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getOrgInfoSuccess(data));
  } catch (e) {
    dispatch(getOrgInfoFailure(e?.response));
  }
};

export const editOrgInfoRequest = createAction('ORG_INFO_EDIT_REQUEST');
export const editOrgInfoSuccess = createAction('ORG_INFO_EDIT_SUCCESS');
export const editOrgInfoFailure = createAction('ORG_INFO_EDIT_FAILURE');

export const editOrgInfo = (orgId, formValues) => async (dispatch) => {
  dispatch(editOrgInfoRequest());
  try {
    await axios({
      method: 'put',
      url: routes.sectionOneOrgInfo(orgId),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getOrgInfo(orgId));
  } catch (e) {
    dispatch(getOrgInfoFailure(e?.response));
  }
};

export const getCurrentOrgEvaluationsRequest = createAction('ORG_EVALUATIONS_GET_REQUEST');
export const getCurrentOrgEvaluationsSuccess = createAction('ORG_EVALUATIONS_GET_SUCCESS');

export const getCurrentOrgEvaluations = () => async (dispatch) => {
  dispatch(getCurrentOrgEvaluationsRequest());

  try {
    const { data } = await axios.get(routes.orgEvaluation(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    dispatch(getCurrentOrgEvaluationsSuccess({ data }));
  } catch (e) {
    console.log(e);
    dispatch(getOrgInfoFailure(e?.response));
  }
};

export const getPercentRequest = createAction('PERCENT_FILLED_SECTIONS_GET_REQUEST');
export const getPercentSuccess = createAction('PERCENT_FILLED_SECTIONS_GET_SUCCESS');

export const getPercent = () => async (dispatch) => {
  dispatch(getPercentRequest());

  try {
    const { data } = await axios.get(routes.percentData(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getPercentSuccess(data));
  } catch (e) {
    dispatch(getOrgInfoFailure(e?.response));
  }
};
