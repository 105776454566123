import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setOnlineConsultantError = createAction('ONLINE_CONSULTANT_SET_ERROR');

export const getOnlineConsultantInfoRequest = createAction('ONLINE_CONSULTANT_INFO_GET_REQUEST');
export const getOnlineConsultantInfoSuccess = createAction('ONLINE_CONSULTANT_INFO_GET_SUCCESS');
export const getOnlineConsultantInfoFailure = createAction('ONLINE_CONSULTANT_INFO_GET_FAILURE');

export const getOnlineConsultantInfo = () => async (dispatch) => {
  dispatch(getOnlineConsultantInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoOnlineConsultant(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoOnlineConsultantLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getOnlineConsultantInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setOnlineConsultantError(e?.response));
    dispatch(getOnlineConsultantInfoFailure());
  }
};

export const editOnlineConsultantInfoRequest = createAction('ONLINE_CONSULTANT_EDIT_REQUEST');
export const editOnlineConsultantInfoSuccess = createAction('ONLINE_CONSULTANT_EDIT_SUCCESS');
export const editOnlineConsultantInfoFailure = createAction('ONLINE_CONSULTANT_EDIT_FAILURE');

export const editOnlineConsultantInfo = (formData) => async (dispatch) => {
  dispatch(editOnlineConsultantInfoRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoOnlineConsultant(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getOnlineConsultantInfo());
    dispatch(editOnlineConsultantInfoSuccess());
  } catch (e) {
    dispatch(setOnlineConsultantError(e?.response));
    dispatch(editOnlineConsultantInfoFailure());
  }
};
