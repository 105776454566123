import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { editSubdivisionData } from '../../../actions';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { labels } = useSelector((state) => state.sectionOneSubdivision, shallowEqual);

  const { register, handleSubmit, errors } = useForm({ defaultValues });
  const onSubmit = (data) => {
    const totalCount = Object.values(data).reduce((acc, count) => acc + parseInt(count), 0);
    dispatch(editSubdivisionData({ ...data, count_all: totalCount }));
    if (submitAction) submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label={labels.count_central}
        name="count_central"
        inputRef={register({ required: true })}
        error={!!errors.count_central}
        type="number"
        fullWidth
        className="mb"
      />
      <TextField
        label={labels.count_territorial}
        name="count_territorial"
        inputRef={register({ required: true })}
        error={!!errors.count_territorial}
        type="number"
        fullWidth
        className="mb"
      />
      <TextField
        label={labels.count_subdivisions}
        name="count_subdivisions"
        inputRef={register({ required: true })}
        error={!!errors.count_subdivisions}
        type="number"
        fullWidth
        className="mb"
      />
      <TextField
        label={labels.count_other}
        name="count_other"
        inputRef={register({ required: true })}
        error={!!errors.count_other}
        type="number"
        fullWidth
        className="mb"
      />
      <Button variant="outlined" color="primary" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
