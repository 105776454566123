import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const tabs = handleActions(
  {
    [actions.selectTabIndex]: (state, { payload }) => ({ ...state, selectedTabIndex: payload }),
    [actions.resetTabIndex]: (state) => ({ ...state, selectedTabIndex: 0 }),
  },
  {
    selectedTabIndex: +sessionStorage.getItem('selectedTab') || 0,
  }
);
