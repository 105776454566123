import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgActs = handleActions(
  {
    [actions.getOrgActsSuccess]: (state, { payload }) => ({ ...state, orgActs: payload }),
    [actions.getOrgActsLabelsSuccess]: (state, { payload }) => ({
      ...state,
      labels: payload,
    }),
    [actions.getCurrentActSuccess]: (state, { payload }) => ({ ...state, currentAct: payload }),
    [actions.addActSuccess]: (state, { payload }) => ({
      ...state,
      orgActs: [...state.orgActs, payload],
    }),
    [actions.editActSuccess]: (state, { payload }) => ({
      ...state,
      orgActs: state.orgActs.map((act) => (act.id === payload.id ? payload : act)),
    }),
    [actions.deleteActSuccess]: (state, { payload: { id } }) => ({
      ...state,
      orgActs: state.orgActs.filter((act) => (act.id !== id)),
    }),
    [actions.getCheckboxSuccess]: (state, { payload }) => ({ ...state, checkbox: payload }),
    [actions.setOrgActError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    orgActs: [],
    currentAct: {},
    labels: [],
    checkbox: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgActsUI = handleActions(
  {
    [actions.getOrgActsLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getOrgActsLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.editActRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editActSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editActFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getCurrentActSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getCurrentActRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getCurrentActFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
