import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import { Button, TextField } from '@material-ui/core';

import { useStyles, StyledTableRow } from '../reused/Table';
import { StyledTableCell } from './Section82';
import { getSourceStat, postSourceStat } from '../../actions';
import { isEmpty, sum, parse } from '../../common/utils';
import Spinner from '../reused/Spinner';
import Error from '../reused/Error';

export default function Section18() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSourceStat());
  }, [dispatch]);

  const { labels, data } = useSelector((state) => state.sectionEightSourceStat, shallowEqual);
  const requestState = useSelector((state) => state.sectionEightSourceStatUI.requestState, shallowEqual);

  useEffect(() => {
    if (!isEmpty(defaultValues)) reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const defaultValues = data.reduce(
    (acc, el) => ({
      ...acc,
      [el.meta_label_id]: {
        quarter1_plan: el.quarter1_plan,
        quarter1_fact: el.quarter1_fact,
        quarter2_plan: el.quarter2_plan,
        quarter2_fact: el.quarter2_fact,
        quarter3_plan: el.quarter3_plan,
        quarter3_fact: el.quarter3_fact,
        quarter4_plan: el.quarter4_plan,
        quarter4_fact: el.quarter4_fact,
        total_plan: el.total_plan,
        total_fact: el.total_fact,
      },
    }),
    {}
  );

  const { register, handleSubmit, errors, reset, watch } = useForm({ defaultValues });
  const [isEditMode, setIsEditMode] = useState(false);
  const classes = useStyles();
  const allFields = watch();

  const onSubmit = (data) => {
    const finalData = Object.keys(data).map((id) => ({
      meta_label_id: id,
      ...data[id],
    }));
    dispatch(postSourceStat(finalData));
    setIsEditMode(false);
  };
  const errorHandler = useSelector((state) => state.sectionEightSourceStat.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="spaceBetween mb3">
        <div />
        {isVisibleOrg && (
          <>
            {isEditMode && (
              <Button variant="contained" color="primary" type="submit">
                <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
              </Button>
            )}
            {!isEditMode && (
              <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
                <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
              </Button>
            )}
          </>
        )}
      </div>
      <p className="dFlex dFJustifyFxEnd mainFont" style={{ padding: '10px 30px' }}>
        млн. сум
      </p>
      <TableContainer component={Paper} className={classes.container}>
        {requestState === 'request' ? (
          <Spinner />
        ) : (
          <Table className={classes.table}>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell rowSpan="2">{labels.quarters && labels.quarters[0]}</StyledTableCell>

                {labels.quarters?.map((label, i) => {
                  if (i === 0) return null;
                  if (i === labels.quarters.length - 1)
                    return (
                      <StyledTableCell key={i} style={{ borderRadius: 0 }} align="center" colSpan={2}>
                        {label}
                      </StyledTableCell>
                    );

                  return (
                    <StyledTableCell key={i} align="center" colSpan={2}>
                      {label}
                    </StyledTableCell>
                  );
                })}
              </StyledTableRow>
              <StyledTableRow>
                {labels.titles.map((label, i) => {
                  if (i === 0)
                    return (
                      <StyledTableCell key={i} style={{ borderRadius: 0 }}>
                        {label}
                      </StyledTableCell>
                    );
                  if (i === labels.titles.length - 1)
                    return (
                      <StyledTableCell key={i} style={{ borderRadius: '0 0 25px 0' }}>
                        {label}
                      </StyledTableCell>
                    );
                  return <StyledTableCell key={i}>{label}</StyledTableCell>;
                })}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => {
                if (isEditMode && i === data.length - 1) {
                  delete allFields[row.meta_label_id];
                  const values = sum(allFields);

                  const total_fact =
                    parse(values.quarter1_fact) +
                    parse(values.quarter2_fact) +
                    parse(values.quarter3_fact) +
                    parse(values.quarter4_fact);
                  const total_plan =
                    parse(values.quarter1_plan) +
                    parse(values.quarter2_plan) +
                    parse(values.quarter3_plan) +
                    parse(values.quarter4_plan);
                  return (
                    <StyledTableRow key={row.meta_label_id}>
                      <StyledTableCell align="justify">{row.title}</StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter1_plan}
                          type="number"
                          name={`${row.meta_label_id}[quarter1_plan]`}
                          // error={error}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter1_fact}
                          type="number"
                          name={`${row.meta_label_id}[quarter1_fact]`}
                          // error={error2}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter2_plan}
                          type="number"
                          name={`${row.meta_label_id}[quarter2_plan]`}
                          // error={error3}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter2_fact}
                          type="number"
                          name={`${row.meta_label_id}[quarter2_fact]`}
                          // error={error4}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter3_plan}
                          type="number"
                          name={`${row.meta_label_id}[quarter3_plan]`}
                          // error={error5}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter3_fact}
                          type="number"
                          name={`${row.meta_label_id}[quarter3_fact]`}
                          // error={error6}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter4_plan}
                          type="number"
                          name={`${row.meta_label_id}[quarter4_plan]`}
                          // error={error7}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={values.quarter4_fact}
                          type="number"
                          name={`${row.meta_label_id}[quarter4_fact]`}
                          // error={error8}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          autoFocus
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={total_plan}
                          type="number"
                          name={`${row.meta_label_id}[total_plan]`}
                          // error={error9}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          autoFocus
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={total_fact}
                          type="number"
                          name={`${row.meta_label_id}[total_fact]`}
                          // error={error10}
                          variant="outlined"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }
                if (isEditMode) {
                  const error = !!errors[row.meta_label_id]?.quarter1_plan;
                  const error2 = !!errors[row.meta_label_id]?.quarter1_fact;
                  const error3 = !!errors[row.meta_label_id]?.quarter2_plan;
                  const error4 = !!errors[row.meta_label_id]?.quarter2_fact;
                  const error5 = !!errors[row.meta_label_id]?.quarter3_plan;
                  const error6 = !!errors[row.meta_label_id]?.quarter3_fact;
                  const error7 = !!errors[row.meta_label_id]?.quarter4_plan;
                  const error8 = !!errors[row.meta_label_id]?.quarter4_fact;
                  const error9 = !!errors[row.meta_label_id]?.total_plan;
                  const error10 = !!errors[row.meta_label_id]?.total_fact;
                  const values = allFields[row.meta_label_id];

                  const total_fact =
                    parse(values.quarter1_fact) +
                    parse(values.quarter2_fact) +
                    parse(values.quarter3_fact) +
                    parse(values.quarter4_fact);
                  const total_plan =
                    parse(values.quarter1_plan) +
                    parse(values.quarter2_plan) +
                    parse(values.quarter3_plan) +
                    parse(values.quarter4_plan);
                  return (
                    <StyledTableRow key={row.meta_label_id}>
                      <StyledTableCell align="justify">{row.title}</StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter1_plan]`}
                          error={error}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter1_fact]`}
                          error={error2}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter2_plan]`}
                          error={error3}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter2_fact]`}
                          error={error4}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter3_plan]`}
                          error={error5}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter3_fact]`}
                          error={error6}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter4_plan]`}
                          error={error7}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01 }}
                          type="number"
                          name={`${row.meta_label_id}[quarter4_fact]`}
                          error={error8}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={total_plan}
                          type="number"
                          name={`${row.meta_label_id}[total_plan]`}
                          error={error9}
                          variant="outlined"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TextField
                          inputRef={register({ required: true })}
                          inputProps={{ min: 0, step: 0.01, readOnly: true }}
                          value={total_fact}
                          type="number"
                          name={`${row.meta_label_id}[total_fact]`}
                          error={error10}
                          variant="outlined"
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                }

                return (
                  <StyledTableRow key={row.meta_label_id}>
                    <StyledTableCell align="justify">{row.title}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter1_plan}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter1_fact}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter2_plan}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter2_fact}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter3_plan}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter3_fact}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter4_plan}</StyledTableCell>
                    <StyledTableCell align="center">{row.quarter4_fact}</StyledTableCell>
                    <StyledTableCell align="center">{row.total_plan}</StyledTableCell>
                    <StyledTableCell align="center">{row.total_fact}</StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </form>
  );
}
