import React, { useEffect, useState } from 'react';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../../actions';
import clsx from 'classnames';
import { FormattedMessage } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Alert } from '@material-ui/lab';
import { useSelector } from 'react-redux';

const UploadFile = ({ error, register, classNames = [], label = '', name = 'file', id = 'upload' }) => {
  const [params, setParams] = useState({});
  const [files, setFiles] = useState([]);
  const [title, setTitle] = useState(label);
  const { errorData, fileName = null } = useSelector((state) => state.fileUpload);

  useEffect(() => {
    axios
      .get(routes.paramsUploadFile(), {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      })
      .then(({ data }) => setParams(data));
    if (!title) {
      setTitle(<FormattedMessage id="global.attachFileTitle" defaultMessage="Вложить файл" />);
    }
  }, []);

  const validation = errorData.map((error) => (
    <Alert key={error.field} className={clsx('mt', 'mb')} severity="error">
      {error.message}
    </Alert>
  ));

  return (
    <React.Fragment>
      <div className={clsx('spaceBetween', ...classNames)}>
        <div className="btn">
          <div className="btn__fileUpload">
            <div className="btn-wrap">
              <label className={clsx('btn', error && 'error')} htmlFor={id}>
                {title}
                &nbsp;
                <GetAppIcon />
              </label>
              <input
                id={id}
                name={name}
                type="file"
                ref={register}
                multiple
                onChange={(e) => {
                  setFiles([...e.target.files]);
                }}
              />
            </div>
          </div>
        </div>
        <div className="fileContainerList">
          {files.map((file, key) => (
            <p key={key} className="fileItem">
              {file.name}
            </p>
          ))}
        </div>
      </div>
      <Alert className="mt mb" severity="info">
        {params.hint}
      </Alert>
      {fileName ? name === fileName && validation : validation}
    </React.Fragment>
  );
};

export default UploadFile;
