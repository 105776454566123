import React, { useState } from 'react';
import axios from 'axios';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Checkbox, CircularProgress, FormLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { generateHeaders } from 'actions/dictionary';
import { editStep, getStepsLogs, getProject } from 'actions';
import routes from 'monitoring/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

function UpdateForm({ labels, closeDialog }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);
  const { projectId } = useParams();

  const step = useSelector((state) => state.m_steps.current);

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      ...step,

      is_start: parseInt(step.is_start) === 1,
      is_finish: parseInt(step.is_finish) === 1,
    },
  });

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.step(step.id),
      method: 'put',
      data: {
        ...data,
        is_start: data.is_start ? 1 : 0,
        is_finish: data.is_finish ? 1 : 0,
      },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        setValidation([]);
        dispatch(editStep(data));
        dispatch(getStepsLogs(step.id)(projectId, 1));
        dispatch(getProject(projectId));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
      });
  };

  return (
    <>
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
        <TextField inputRef={register} name="step_no" className="mb" label="№" type="number" fullWidth />

        <TextField
          inputRef={register({ required: true })}
          name="title_uz"
          error={!!errors.title_uz}
          className="mb"
          label={labels.title_uz}
          fullWidth
        />

        <TextField
          inputRef={register({ required: true })}
          name="title_ru"
          error={!!errors.title_ru}
          className="mb2"
          label={labels.title_ru}
          fullWidth
        />

        <div className="spaceBetween mt mb2">
          <div className="alignItemCenter">
            <Controller
              name="is_start"
              control={control}
              render={(props) => (
                <Checkbox onChange={(e) => props.onChange(e.target.checked)} checked={props.value} color="primary" />
              )}
            />
            <FormLabel component="legend">{labels.is_start}</FormLabel>
          </div>

          <div className="alignItemCenter">
            <Controller
              name="is_finish"
              control={control}
              render={(props) => (
                <Checkbox onChange={(e) => props.onChange(e.target.checked)} checked={props.value} color="primary" />
              )}
            />
            <FormLabel component="legend">{labels.is_finish}</FormLabel>
          </div>
        </div>
        <TextField
          name="comment"
          multiline
          rowsMax="4"
          label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
          inputRef={register}
          type="text"
          className="mb"
          fullWidth
        />

        <Button type="submit" color="primary" variant="contained" className="m0Auto" style={{ minWidth: 110 }}>
          {loading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          )}
        </Button>
      </form>
    </>
  );
}

export default UpdateForm;
