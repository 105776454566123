import List from '@material-ui/core/List';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import ListIcon from '@material-ui/icons/List';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { resetTabIndex } from '../../actions';
import CoinsIcon from '../../assests/icons/coins.svg';
import DoorIcon from '../../assests/icons/door.svg';
import InfoIcon from '../../assests/icons/info.svg';
import InfrastructureIcon from '../../assests/icons/infrastructure .svg';
import SecurityIcon from '../../assests/icons/security.svg';
import ServicesIcon from '../../assests/icons/services.svg';
import SystemIcon from '../../assests/icons/system.svg';
import TodoIcon from '../../assests/icons/todo.svg';
import { DrawerItem, SimpleDrawerItem } from './DrawerItem';

export default function DrawerList({ open }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const reset = () => dispatch(resetTabIndex());

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');
  const isExpert = currentUser?.roles.includes('expert');

  const orgDeadline = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? // при перезагрузке в переменной orgDeadline пусто, и данные приходит в объекте  currentUser?.organization
        currentUser?.organization?.deadline_evaluations
      : // когда эксперт заходит в систему ему показывается общий срок оценивания для всех экспертов
        currentUser?.deadline_evaluation;

  const deadline = isOrganization ? currentUser?.organization?.deadline_questionnaire : orgDeadline || timeProfile;

  return (
    <List>
      <SimpleDrawerItem
        open={open}
        icon={<HourglassFullIcon />}
        text={
          <p className="time">
            {localStorage.lang === 'ru' ? (
              <Fragment>
                <FormattedMessage id="homeHeader.time" defaultMessage="Срок до" /> {deadline}
              </Fragment>
            ) : (
              <Fragment>
                {deadline} <FormattedMessage id="homeHeader.time" defaultMessage="Срок до" />
              </Fragment>
            )}
          </p>
        }
      />

      {isExpert && (
        <>
          <DrawerItem
            open={open}
            url="/organizations"
            icon={<ListIcon />}
            text={intl.formatMessage({
              id: 'drawerList.organizationList',
              defaultMessage: 'Список организаций',
            })}
          />

          {!localStorage.orgId && (
            <>
              <DrawerItem open={open} url="/statistics" icon={<AssessmentIcon />} text={'Диаграмма'} />

              <DrawerItem
                open={open}
                url="/support"
                icon={<ContactSupportIcon />}
                text={<FormattedMessage id="drawerList.support" defaultMessage="Справка" />}
                exact
              />
            </>
          )}
        </>
      )}
      {localStorage.orgId && (
        <>
          <DrawerItem
            open={open}
            url="/section-id-1/section13"
            icon={<img src={InfoIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.generalInfoOrg',
              defaultMessage: 'Общие сведения об организации',
            })}
          />
          <DrawerItem
            open={open}
            url="/section-id-13/section21"
            icon={<img src={DoorIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.openessEparticipation',
              defaultMessage: 'Открытость и электронное участие',
            })}
          />
          <DrawerItem
            open={open}
            url="/section-id-30/section31"
            icon={<img src={ServicesIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.electronicServices',
              defaultMessage: 'Электронные услуги',
            })}
          />

          <DrawerItem
            open={open}
            url="/section-id-34/section41"
            icon={<img src={SecurityIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.infoCyberSecurity',
              defaultMessage: 'Информационная и кибербезопасность',
            })}
          />

          <DrawerItem
            open={open}
            url="/section-id-167/section51"
            icon={<img src={SystemIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.informationalSystems',
              defaultMessage: 'Информационные системы',
            })}
          />

          <DrawerItem
            open={open}
            url="/section-id-169/section61"
            icon={<img src={TodoIcon} alt="" />}
            onClick={reset}
            text={intl.formatMessage({
              id: 'drawerList.organizationQuestions',
              defaultMessage: 'Организационные вопросы',
            })}
          />

          <div className="drawerBg">
            <DrawerItem
              open={open}
              url="/section-id-174/section71"
              icon={<img src={InfrastructureIcon} alt="" />}
              onClick={reset}
              text={intl.formatMessage({
                id: 'drawerList.infrastructure',
                defaultMessage: 'Инфраструктура',
              })}
            />
            <DrawerItem
              open={open}
              url="/section-id-177/section81"
              icon={<img src={CoinsIcon} alt="" />}
              onClick={reset}
              text={intl.formatMessage({
                id: 'drawerList.financing',
                defaultMessage: 'Финансирование',
              })}
            />
            {!isOrganization && (
              <DrawerItem open={open} url="/statistics" icon={<AssessmentIcon />} text={'Диаграмма'} />
            )}

            <DrawerItem
              open={open}
              url="/support"
              icon={<ContactSupportIcon />}
              text={<FormattedMessage id="drawerList.support" defaultMessage="Справка" />}
              exact
            />
          </div>
        </>
      )}
    </List>
  );
}
