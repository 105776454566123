import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const websiteComplianceRequirements = handleActions(
  {
    [actions.getWebsiteComplianceRequirementsSuccess]: (state, { payload }) => ({ ...state, viewList: payload }),
    [actions.setWebsiteComplianceRequirementsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    viewList: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const websiteComplianceRequirementsUI = handleActions(
  {
    [actions.getWebsiteComplianceRequirementsRequest]: () => ({ requestState: 'request' }),
    [actions.getWebsiteComplianceRequirementsSuccess]: () => ({ requestState: 'success' }),
    [actions.getWebsiteComplianceRequirementsFailure]: () => ({ requestState: 'failure' }),
    [actions.editWebsiteComplianceRequirementsRequest]: () => ({ requestState: 'request' }),
    [actions.editWebsiteComplianceRequirementsSuccess]: () => ({ requestState: 'success' }),
    [actions.editWebsiteComplianceRequirementsFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
