import React, { useEffect, useState } from 'react';
import { BarChart } from '../reused/Chart';
import Dashboard from '../Dashboard';
import { Button, Grid, Paper, TextField, FormControl, FormLabel, NativeSelect } from '@material-ui/core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { generateHeaders, getOrganizations } from '../../actions';
import { getPercent } from '../../common/utils';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Title from '../reused/Title';
import axios from 'axios';
import routes from '../../routes';

const params = {
  org_category_id: '',
  max_section_level: '1',
  organization_id: null,
};

const backgrounds = ['rgba(75, 192, 192, 1)', 'rgba(255, 159, 64, 1)', 'rgba(255, 99, 132, 1)'];

export default function Statistics() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const t = (id) => formatMessage({ id });
  const { register, handleSubmit, watch } = useForm();

  const filterBySection = watch('filter_by_section');

  const generateDataset = (data) => {
    const SECTION_PERCENTAGE = filterBySection;

    const totalCb = (acc, org) => {
      if (org.points.TOTAL_PERCENTAGE > 70) {
        acc[0] += 1;
        return acc;
      }

      if (org.points.TOTAL_PERCENTAGE > 55) {
        acc[1] += 1;
        return acc;
      }

      acc[2] += 1;
      return acc;
    };

    const sectionCb = (acc, org) => {
      if (!org.points[SECTION_PERCENTAGE]) return acc;

      if (org.points[SECTION_PERCENTAGE].point > 70) {
        acc[0] += 1;
        return acc;
      }

      if (org.points[SECTION_PERCENTAGE].point > 55) {
        acc[1] += 1;
        return acc;
      }

      acc[2] += 1;
      return acc;
    };

    const stat = data.reduce(SECTION_PERCENTAGE ? sectionCb : totalCb, [0, 0, 0]);

    return {
      labels: [getPercent(stat[0], data.length), getPercent(stat[1], data.length), getPercent(stat[2], data.length)],
      datasets: [
        {
          backgroundColor: backgrounds,
          data: stat,
        },
      ],
    };
  };

  useEffect(() => {
    dispatch(getOrganizations(params));
  }, [dispatch]);

  const { orgStat } = useSelector((state) => state.dictionary.statOrganizations, shallowEqual);

  const filteredGovOrg = orgStat.filter((org) => org.category_id === 1);
  const filteredEcoOrg = orgStat.filter((org) => org.category_id === 2);
  const govOrgStat = generateDataset(filteredGovOrg);
  const ecoOrgStat = generateDataset(filteredEcoOrg);
  const totalStat = generateDataset(orgStat);

  const initialState = {
    labels: [t('statistics.allOrg'), t('statistics.govOrg'), t('statistics.ecoOrg')],

    datasets: [
      {
        label: '0% - 55%',
        data: [totalStat.datasets[0].data[2], govOrgStat.datasets[0].data[2], ecoOrgStat.datasets[0].data[2]],
        total: [orgStat.length, filteredGovOrg.length, filteredEcoOrg.length],
        backgroundColor: 'rgb(254, 0, 0, 0.8)',
      },

      {
        label: '55% - 71%',
        data: [totalStat.datasets[0].data[1], govOrgStat.datasets[0].data[1], ecoOrgStat.datasets[0].data[1]],
        total: [orgStat.length, filteredGovOrg.length, filteredEcoOrg.length],
        backgroundColor: '#ffc000',
      },
      {
        label: '71% - 100%',
        data: [totalStat.datasets[0].data[0], govOrgStat.datasets[0].data[0], ecoOrgStat.datasets[0].data[0]],
        total: [orgStat.length, filteredGovOrg.length, filteredEcoOrg.length],
        backgroundColor: '#00af50',
      },
    ],
  };
  const [data, setData] = useState(initialState);

  useEffect(() => {
    if (orgStat.length > 0) {
      setData(initialState);
    }
  }, [orgStat.length]);

  const onSubmit = async (formValues) => {
    if (formValues.year && formValues.quarter) {
      const { data } = await axios({
        url: routes.statRating(),
        method: 'POST',
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 154),
        data: { ...params, ...formValues },
      });
      const _filteredGovOrg = data.result.orgStat.filter((org) => org.category_id === 1);
      const _filteredEcoOrg = data.result.orgStat.filter((org) => org.category_id === 2);
      const _govOrgStat = generateDataset(_filteredGovOrg);
      const _ecoOrgStat = generateDataset(_filteredEcoOrg);
      const _totalStat = generateDataset(data.result.orgStat);

      let leftStat = null;
      if (formValues.year1 && formValues.quarter1) {
        const response = await axios({
          url: routes.statRating(),
          method: 'POST',
          headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 154),
          data: { ...params, year: formValues.year1, quarter: formValues.quarter1 },
        });
        leftStat = response.data;
      }

      let leftGovOrgStat, leftEcoOrgStat, leftTotalStat, leftFilteredGov, leftFilteredEco;
      if (leftStat) {
        leftFilteredGov = leftStat.result.orgStat.filter((org) => org.category_id === 1);
        leftFilteredEco = leftStat.result.orgStat.filter((org) => org.category_id === 2);
        leftGovOrgStat = generateDataset(leftFilteredGov);
        leftEcoOrgStat = generateDataset(leftFilteredEco);
        leftTotalStat = generateDataset(leftStat.result.orgStat);
      }

      let chronology = {};
      if (leftStat) {
        chronology = {
          labels: [
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
            `${formValues.year} год / ${formValues.quarter} квартал`,
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
            `${formValues.year} год / ${formValues.quarter} квартал`,
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
            `${formValues.year} год / ${formValues.quarter} квартал`,
          ],

          datasets: [
            {
              label: '0% - 55%',
              data: [
                leftTotalStat.datasets[0].data[2],
                _totalStat.datasets[0].data[2],
                leftGovOrgStat.datasets[0].data[2],
                _govOrgStat.datasets[0].data[2],
                leftEcoOrgStat.datasets[0].data[2],
                _ecoOrgStat.datasets[0].data[2],
              ],
              total: [
                leftStat.result.orgStat.length,
                data.result.orgStat.length,
                leftFilteredGov.length,
                _filteredGovOrg.length,
                leftFilteredEco.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: 'rgb(254, 0, 0, 0.8)',
            },

            {
              label: '55% - 71%',
              data: [
                leftTotalStat.datasets[0].data[1],
                _totalStat.datasets[0].data[1],
                leftGovOrgStat.datasets[0].data[1],
                _govOrgStat.datasets[0].data[1],
                leftEcoOrgStat.datasets[0].data[1],
                _ecoOrgStat.datasets[0].data[1],
              ],
              total: [
                leftStat.result.orgStat.length,
                data.result.orgStat.length,
                leftFilteredGov.length,
                _filteredGovOrg.length,
                leftFilteredEco.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: '#ffc000',
            },
            {
              label: '71% - 100%',
              data: [
                leftTotalStat.datasets[0].data[0],
                _totalStat.datasets[0].data[0],
                leftGovOrgStat.datasets[0].data[0],
                _govOrgStat.datasets[0].data[0],
                leftEcoOrgStat.datasets[0].data[0],
                _ecoOrgStat.datasets[0].data[0],
              ],
              total: [
                leftStat.result.orgStat.length,
                data.result.orgStat.length,
                leftFilteredGov.length,
                _filteredGovOrg.length,
                leftFilteredEco.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: '#00af50',
            },
          ],
        };
      } else {
        chronology = {
          labels: [
            t('statistics.allOrg'),
            `${t('statistics.allOrg')} - ${formValues.year} год / ${formValues.quarter} квартал`,
            t('statistics.govOrg'),
            `${t('statistics.govOrg')} - ${formValues.year} год / ${formValues.quarter} квартал`,
            t('statistics.ecoOrg'),
            `${t('statistics.ecoOrg')} - ${formValues.year} год / ${formValues.quarter} квартал`,
          ],

          datasets: [
            {
              label: '0% - 55%',
              data: [
                totalStat.datasets[0].data[2],
                _totalStat.datasets[0].data[2],
                govOrgStat.datasets[0].data[2],
                _govOrgStat.datasets[0].data[2],
                ecoOrgStat.datasets[0].data[2],
                _ecoOrgStat.datasets[0].data[2],
              ],
              total: [
                orgStat.length,
                data.result.orgStat.length,
                filteredGovOrg.length,
                _filteredGovOrg.length,
                filteredEcoOrg.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: 'rgb(254, 0, 0, 0.8)',
            },

            {
              label: '55% - 71%',
              data: [
                totalStat.datasets[0].data[1],
                _totalStat.datasets[0].data[1],
                govOrgStat.datasets[0].data[1],
                _govOrgStat.datasets[0].data[1],
                ecoOrgStat.datasets[0].data[1],
                _ecoOrgStat.datasets[0].data[1],
              ],
              total: [
                orgStat.length,
                data.result.orgStat.length,
                filteredGovOrg.length,
                _filteredGovOrg.length,
                filteredEcoOrg.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: '#ffc000',
            },
            {
              label: '71% - 100%',
              data: [
                totalStat.datasets[0].data[0],
                _totalStat.datasets[0].data[0],
                govOrgStat.datasets[0].data[0],
                _govOrgStat.datasets[0].data[0],
                ecoOrgStat.datasets[0].data[0],
                _ecoOrgStat.datasets[0].data[0],
              ],
              total: [
                orgStat.length,
                data.result.orgStat.length,
                filteredGovOrg.length,
                _filteredGovOrg.length,
                filteredEcoOrg.length,
                _filteredEcoOrg.length,
              ],
              backgroundColor: '#00af50',
            },
          ],
        };
      }

      setData(chronology);
    } else {
      if (formValues.quarter1 && formValues.year1) {
        const { data } = await axios({
          url: routes.statRating(),
          method: 'POST',
          headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 154),
          data: { ...params, year: formValues.year1, quarter: formValues.quarter1 },
        });

        const leftFilteredGovOrg = data.result.orgStat.filter((org) => org.category_id === 1);
        const leftFilteredEcoOrg = data.result.orgStat.filter((org) => org.category_id === 2);
        const leftGovOrgStat = generateDataset(leftFilteredGovOrg);
        const leftEcoOrgStat = generateDataset(leftFilteredEcoOrg);
        const leftTotalStat = generateDataset(data.result.orgStat);

        const leftStat = {
          labels: [
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
            `${formValues.year1} год / ${formValues.quarter1} квартал`,
          ],

          datasets: [
            {
              label: '0% - 55%',
              data: [
                leftTotalStat.datasets[0].data[2],
                leftGovOrgStat.datasets[0].data[2],
                leftEcoOrgStat.datasets[0].data[2],
              ],
              total: [data.result.orgStat.length, leftFilteredGovOrg.length, leftFilteredEcoOrg.length],
              backgroundColor: 'rgb(254, 0, 0, 0.8)',
            },

            {
              label: '55% - 71%',
              data: [
                leftTotalStat.datasets[0].data[1],
                leftGovOrgStat.datasets[0].data[1],
                leftEcoOrgStat.datasets[0].data[1],
              ],
              total: [data.result.orgStat.length, leftFilteredGovOrg.length, leftFilteredEcoOrg.length],
              backgroundColor: '#ffc000',
            },
            {
              label: '71% - 100%',
              data: [
                leftTotalStat.datasets[0].data[0],
                leftGovOrgStat.datasets[0].data[0],
                leftEcoOrgStat.datasets[0].data[0],
              ],
              total: [data.result.orgStat.length, leftFilteredGovOrg.length, leftFilteredEcoOrg.length],
              backgroundColor: '#00af50',
            },
          ],
        };
        setData(leftStat);
      } else {
        setData(initialState);
      }
    }
  };

  const currentYear = new Date().getFullYear();

  const SubmitBtn = (
    <Button variant="contained" color="primary" type="submit" className="ml2">
      {t('global.requireBtnTitle')}
    </Button>
  );

  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">
            <Title disableBorder title={t('statistics.title')} className="mb2" />
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                margin: '0 auto',
                maxWidth: 1000,
              }}>
              <div className="dFJustifyFxCenter alignItemsCenter mb2">
                <TextField
                  inputRef={register()}
                  inputProps={{ min: 2020, max: currentYear }}
                  style={{ width: 120 }}
                  variant="outlined"
                  name="year1"
                  label={t('organizationStat.labelYear')}
                  type="number"
                />
                <TextField
                  inputRef={register()}
                  inputProps={{ min: 1, max: 4 }}
                  style={{ width: 120 }}
                  className="ml2"
                  variant="outlined"
                  name="quarter1"
                  label={t('organizationStat.labelQuarter')}
                  type="number"
                />
                {SubmitBtn}
              </div>

              <div className="dFJustifyFxCenter alignItemsCenter mb3">
                <TextField
                  inputRef={register()}
                  inputProps={{ min: 2020, max: currentYear }}
                  style={{ width: 120 }}
                  variant="outlined"
                  name="year"
                  label={t('organizationStat.labelYear')}
                  type="number"
                />
                <TextField
                  inputRef={register()}
                  inputProps={{ min: 1, max: 4 }}
                  style={{ width: 120 }}
                  className="ml2"
                  variant="outlined"
                  name="quarter"
                  label={t('organizationStat.labelQuarter')}
                  type="number"
                />
                {SubmitBtn}
              </div>
              <div className="dFJustifyFxCenter alignItemsCenter mb2 mr2">
                <FormControl component="fieldset" className="mb" fullWidth>
                  <FormLabel component="legend">Выберите фильтр по разделу (необязательно)</FormLabel>
                  <NativeSelect name="filter_by_section" inputProps={{ ref: register() }}>
                    <option value=""></option>
                    <option value="2_PERCENTAGE">Открытость и электронное участие</option>
                    <option value="3_PERCENTAGE">Электронные услуги</option>
                    <option value="4_PERCENTAGE">Информационная и кибербезопасность</option>
                    <option value="5_PERCENTAGE">Информационные системы</option>
                    <option value="6_PERCENTAGE">Организационные вопросы</option>
                  </NativeSelect>
                </FormControl>
                {SubmitBtn}
              </div>
            </form>
            <BarChart data={data} total={orgStat.length} />
          </Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}
