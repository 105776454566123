import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { editQuestionnaireManagerInfo } from '../../../actions';

const Form = ({ defaultValues, submitAction }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ defaultValues });
  const labels = useSelector((state) => state.sectionTwoQuestionnaireContentManager.labels, shallowEqual);
  const onSubmit = async (data) => {
    dispatch(editQuestionnaireManagerInfo(data));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.full_name}
        className="mb"
        label={labels.full_name}
        name="full_name"
      />
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.position_name}
        className="mb"
        label={labels.position_name}
        name="position_name"
      />
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.phone}
        className="mb"
        label={labels.phone}
        name="phone"
      />
      <Button type="submit" color="primary" variant="outlined" className="mt">
        <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
      </Button>
    </form>
  );
};

export default Form;
