import React from 'react';
import ErrorImg from '../../assests/img/error.png';

function Error({ error }) {
  return (
    <div className="error m0Auto">
      <div className="errorStatus">{error.status}</div>
      <div className="errorMessage">{error.message}</div>
      <img src={ErrorImg} className="m0Auto" style={{ maxWidth: '100%' }} alt="" />
    </div>
  );
}

export default Error;
