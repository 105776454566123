import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { addPublicElement, editPublicElement, uploadFile } from '../../../actions';
import { useMemo } from 'react';
import UploadFile from '../../reused/UploadFile';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Form = ({
  submitAction,
  defaultValues,
  isEditableMode,
  defaultServices = [],
  defaultUserServices = [],
  defaultServicesFormType = [],
  defaultMechanismServices = [],
}) => {
  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: { ...defaultValues, regulation_file: '' },
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const intl = useIntl();
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpertAgu = useMemo(() => currentUser?.roles.includes('expert-agu'), [currentUser]);
  const isOrganization = useMemo(() => currentUser?.roles.includes('organization'), [currentUser]);

  const [valueServices, setValueServices] = useState(defaultServices);

  const [valueUserServices, setValueUserServices] = useState(defaultUserServices);

  const [valueServicesFormType, setValueServicesFormType] = useState(defaultServicesFormType);

  const [valueMechanismElectronServices, setValueMechanismElectronServices] = useState(defaultMechanismServices);

  const { formTypes, resultTypes, trackingTypes, userTypes } = useSelector((state) => state.dictionary, shallowEqual);

  const radios = watch();

  const { labels, element, services } = useSelector((state) => state.sectionThreeInfoPublicServices, shallowEqual);

  const nameServices = services.map((service) => service.title);

  const onSubmit = async (data) => {
    if (isOrganization) {
      const resetValue = {};

      if (!valueServices.find((value) => value.id === 12)) resetValue.other_result_title = '';
      if (radios.has_tracking_mechanism === '0') resetValue.tracking_types = [];
      if (!valueServicesFormType.find((value) => value.id === 2)) {
        resetValue.link_epigu = '';
        resetValue.link_website = '';
        resetValue.link_mobile_app = '';
        resetValue.link_other = '';
      }

      if (isEditableMode) {
        let file;
        if (data.regulation_file.length === 0) file = defaultValues.regulation_file;
        else {
          const filePath = await uploadFile(data.regulation_file, 3.1);
          file = filePath;
        }

        const editData = {
          ...data,
          ...resetValue,
          regulation_file: file,
          service_user_types: valueUserServices.map((el) => el.id),
          service_form_types: valueServicesFormType.map((el) => el.id),
          service_result_types: valueServices.map((el) => el.id),
          tracking_types: valueMechanismElectronServices.map((el) => el.id),
        };

        dispatch(editPublicElement(element.id.value, editData));
      } else {
        let filePath = null;
        filePath = await uploadFile(data.regulation_file, 3.1);
        dispatch(
          addPublicElement({
            ...data,
            ...resetValue,
            regulation_file: filePath,
            service_user_types: valueUserServices.map((el) => el.id),
            service_form_types: valueServicesFormType.map((el) => el.id),
            service_result_types: valueServices.map((el) => el.id),
            tracking_types: valueMechanismElectronServices.map((el) => el.id),
          })
        );
      }
    } else {
      if (isEditableMode) dispatch(editPublicElement(element.id.value, data));
      else dispatch(addPublicElement(data));
    }
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w90 m0Auto">
      <div className="">
        {isExpertAgu ? (
          <div>
            <TextField
              fullWidth
              inputRef={register({
                required: true,
                validate: (value) => {
                  let isValid = false;
                  if (!isEditableMode) isValid = nameServices.includes(value);
                  return !isValid;
                },
              })}
              label={labels.title}
              name="title"
              error={!!errors.title}
              className="mb"
              helperText={
                errors.title?.type === 'validate' &&
                intl.formatMessage({
                  id: 'global.errorDublicateServices',
                  defaultMessage: 'Наименование услуги не должно повторяться!',
                })
              }
            />
            {isEditableMode && (
              <div className="mb2">
                <label
                  htmlFor="is_changed_regulation_file"
                  className={`nativeLabel ${!!errors.is_changed_regulation_file && 'error'}`}>
                  {labels.is_changed_regulation_file}
                </label>
                <Controller
                  as={
                    <RadioGroup row>
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                      />
                      <FormControlLabel
                        value="0"
                        control={<Radio color="primary" />}
                        label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                      />
                    </RadioGroup>
                  }
                  name="is_changed_regulation_file"
                  control={control}
                  rules={{ required: true }}
                />
              </div>
            )}
          </div>
        ) : (
          <TextField
            fullWidth
            inputProps={{ readOnly: true }}
            label={labels.title}
            inputRef={register({ required: true })}
            name="title"
            className="mb"
          />
        )}

        {isOrganization && (
          <>
            <div className="mb2">
              <Autocomplete
                fullWidth
                multiple
                value={valueUserServices}
                onChange={(event, newValue) => {
                  setValueUserServices(newValue);
                }}
                filterSelectedOptions={true}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={userTypes}
                disableCloseOnSelect
                className="mb2"
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox color="primary" icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={labels.service_user_types} required={valueUserServices.length === 0} />
                )}
              />
            </div>

            <div className="mb2">
              <Autocomplete
                fullWidth
                multiple
                value={valueServicesFormType}
                onChange={(event, newValue) => {
                  setValueServicesFormType(newValue);
                }}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={formTypes}
                disableCloseOnSelect
                className="mb2"
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox color="primary" icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={labels.service_form_types}
                    required={valueServicesFormType.length === 0}
                  />
                )}
              />
              {valueServicesFormType.find((value) => value.id === 2) && (
                <FormControl component="fieldset" className="ml2">
                  <TextField
                    fullWidth
                    name="link_epigu"
                    inputRef={register}
                    type="url"
                    label={labels.link_epigu}
                    error={!!errors.link_epigu}
                    className="mb"
                  />
                  <TextField
                    fullWidth
                    name="link_website"
                    inputRef={register}
                    type="url"
                    label={labels.link_website}
                    error={!!errors.link_website}
                    className="mb"
                  />
                  <TextField
                    fullWidth
                    name="link_mobile_app"
                    inputRef={register}
                    type="url"
                    label={labels.link_mobile_app}
                    error={!!errors.link_mobile_app}
                    className="mb"
                  />
                  <TextField
                    fullWidth
                    name="link_other"
                    inputRef={register}
                    type="url"
                    label={labels.link_other}
                    error={!!errors.link_other}
                    className="mb"
                  />
                </FormControl>
              )}
            </div>
            <div className="mb2">
              <label htmlFor="is_paid_service" className={`nativeLabel ${!!errors.is_paid_service && 'error'}`}>
                {labels.service_paid}: {labels.is_paid_service}
              </label>
              <Controller
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="is_paid_service"
                control={control}
                rules={{ required: true }}
              />
              {radios.is_paid_service === '1' && (
                <FormControl component="fieldset" className="w80">
                  <FormLabel component="legend">Если «да», укажите стоимость услуги</FormLabel>
                  <TextField
                    fullWidth
                    name="service_paid"
                    inputRef={register({ required: true })}
                    className="mb"
                    type="number"
                  />
                </FormControl>
              )}
            </div>

            <div className="mb2">
              <Autocomplete
                fullWidth
                multiple
                value={valueServices}
                onChange={(event, newValue) => {
                  setValueServices(newValue);
                }}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                options={resultTypes}
                disableCloseOnSelect
                className="mb2"
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox color="primary" icon={icon} checkedIcon={checkedIcon} checked={selected} />
                    {option.title}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField {...params} label={labels.service_result_types} required={valueServices.length === 0} />
                )}
              />
              {valueServices.find((value) => value.id === 12) && (
                <TextField
                  name="other_result_title"
                  inputRef={register({ required: true })}
                  error={!!errors.other_result_title}
                  className="mb2 w80"
                  helperText={labels.other_result_title}
                />
              )}
            </div>
            <div className="mb2">
              <label
                htmlFor="has_tracking_mechanism"
                className={`nativeLabel ${!!errors.has_tracking_mechanism && 'error'}`}>
                {labels.has_tracking_mechanism}
              </label>
              <Controller
                as={
                  <RadioGroup aria-label="individual" name="individual" row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="has_tracking_mechanism"
                control={control}
                rules={{ required: true }}
              />
              {radios.has_tracking_mechanism === '1' && (
                <div>
                  <FormLabel component="legend">Если «да», через</FormLabel>
                  <Autocomplete
                    fullWidth
                    multiple
                    value={valueMechanismElectronServices}
                    onChange={(event, newValue) => {
                      setValueMechanismElectronServices(newValue);
                    }}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    options={trackingTypes}
                    disableCloseOnSelect
                    className="mb2"
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox color="primary" icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        {option.title}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={labels.has_tracking_mechanism}
                        required={valueMechanismElectronServices.length === 0}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className="mb2">
              <FormLabel component="legend" className="mb">
                {labels.regulation_file_id}
              </FormLabel>

              <UploadFile
                error={!!errors.regulation_file}
                register={register({ required: !isEditableMode })}
                name="regulation_file"
              />
            </div>
          </>
        )}
      </div>

      <Button variant="contained" color="primary" className="m0Auto mb mt" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
