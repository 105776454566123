import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Form from './Form';
import Error from '../../reused/Error';
import ExpertMark from '../../reused/ExpertMark';
import EvaluationActivation from '../../reused/EvaluationActivation';
import ExpertAssessment from '../../reused/ExpertAssessment';
import Dialog from '../../reused/SimpleDialog';
import Loader from '../../reused/Loader';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import { useDialog } from '../../../common/hooks';
import {
  deleteSocialLink,
  getSocialLabels,
  getSocialLink,
  getSocialPages,
  getEvaluations,
  getSimpleMark,
  saveSimpleMark,
  deleteSimpleMark,
  getSimpleAssessment,
  saveSimpleAssessment,
  deleteSimpleAssessment,
  resetError,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  toggleTabState,
} from '../../../actions';
import Alert from '../../reused/Alert';

export default function Section25() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getSocialPages());
    dispatch(getSocialLabels());

    dispatch(getEvalActivationInfo(21));
    dispatch(getEvaluations(21));
    dispatch(getSimpleMark(21));
    dispatch(getSimpleAssessment(21));
  }, [dispatch]);

  const { pages, labels, link } = useSelector((state) => state.sectionTwoLinksSocialPages, shallowEqual);

  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  const handleDeleteLink = (id) => () => dispatch(deleteSocialLink(id));

  const handleEditLink = (id) => async () => {
    handleOpenEdit();
    await dispatch(getSocialLink(id));
  };

  const finalFields = useMemo(() => Object.values(link), [link]);
  const defaultValues = useMemo(() => finalFields.reduce((acc, link) => ({ ...acc, [link.code_name]: `${link.value}` }), {}), [finalFields]);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-aimk');
  const isOrganization = currentUser?.roles.includes('organization');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const { tabs, evaluations } = useSelector((state) => state.dictionary, shallowEqual);
  const { mark, assessment } = useSelector((state) => state.simpleEvaluation, shallowEqual);
  const { requestMarkState, requestAssessmentState } = useSelector((state) => state.simpleEvaluationUI, shallowEqual);

  const { requestLabelState, requestItemState } = useSelector((state) => state.sectionTwoLinksSocialPagesUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoLinksSocialPages.error, shallowEqual);

  const handleSubmitMark = (data) => dispatch(saveSimpleMark(21, data));
  const handleDeleteMark = () => dispatch(deleteSimpleMark(21));
  const handleSubmitAssessment = (data) => dispatch(saveSimpleAssessment(21, data));
  const handleDeleteAssessment = () => dispatch(deleteSimpleAssessment(21));
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(21, data));
    dispatch(getSimpleMark(21));
    dispatch(getSimpleAssessment(21));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>
      <div className="addLinks__container">
        <TableContainer component={Paper} className={classes.container}>
          {requestLabelState === 'request' ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>№</StyledTableCell>
                  <StyledTableCell>{labels.link}</StyledTableCell>
                  <StyledTableCell align="center">{labels.reason}</StyledTableCell>
                  {isVisibleOrg && <StyledTableCell align="right"></StyledTableCell>}
                </TableRow>
              </TableHead>
              <TransitionGroup component={TableBody} className="addLinks__tableBody">
                {pages.map((item, ind) => {
                  const url = new URL(item.link);
                  return (
                    <CSSTransition key={item.id} timeout={1200} classNames="listItemUI">
                      <StyledTableRow>
                        <StyledTableCell>{ind + 1}</StyledTableCell>
                        <StyledTableCell>
                          <a className="link" href={item.link}>
                            {url.href}
                          </a>
                        </StyledTableCell>
                        {item.is_frequently === '1' ? <StyledTableCell /> : <StyledTableCell align="center">{item.reason}</StyledTableCell>}

                        {isVisibleOrg && (
                          <StyledTableCell align="center">
                            <ButtonGroup orientation="vertical" variant="contained" color="primary">
                              <Button onClick={handleEditLink(item.id)} aria-label="edit">
                                <CreateIcon />
                              </Button>
                              <Button color="secondary" onClick={handleDeleteLink(item.id)} aria-label="delete">
                                <DeleteIcon />
                              </Button>
                            </ButtonGroup>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            </Table>
          )}
        </TableContainer>
        {isVisibleOrg && (
          <Button className="mb m0Auto" variant="contained" color="primary" type="submit" onClick={handleOpenAdd}>
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          </Button>
        )}
        <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation}>
          <div className="w100 spaceBetween wrap mt">
            <div className="w50">
              {requestMarkState === 'request' ? (
                <Loader />
              ) : (
                <ExpertMark mark={mark} isExpert={isVisibleExpert} onSubmit={handleSubmitMark} onDelete={handleDeleteMark} />
              )}
            </div>
            <div className="w50">
              {requestAssessmentState === 'request' ? (
                <Loader />
              ) : (
                <ExpertAssessment
                  assessment={assessment}
                  onSubmit={handleSubmitAssessment}
                  onDelete={handleDeleteAssessment}
                  evaluations={evaluations}
                  isExpert={isVisibleExpert}
                  isVisible={mark}
                />
              )}
            </div>
          </div>
        </EvaluationActivation>
      </div>

      <Dialog
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
        dialogState={dialogStateAdd}
        onClose={handleCloseAdd}>
        <Form submitAction={handleCloseAdd} />
      </Dialog>
      <Dialog
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}>
        {requestItemState === 'request' ? <Loader /> : <Form submitAction={handleCloseEdit} defaultValues={defaultValues} isEditableMode />}
      </Dialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateLinks',
          defaultMessage: 'Ссылка не должна повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  );
}
