import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectTabIndex } from '../../actions';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

export default function CustomizedTabs({ tabs }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const handleChange = (event, newValue) => {
    dispatch(selectTabIndex(newValue));
  };
  const raw = location.pathname.substring(12);
  const slashIndex = raw.indexOf('/');
  const sectionId = slashIndex === -1 ? raw.substring(0) : raw.substring(0, slashIndex);
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <div className="dFlex w100"></div>
        <AntTabs
          value={selectedTabIndex}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          selectionFollowsFocus={true}>
          {tabs.map((tab, index) => (
            <AntTab
              label={
                <div className="spaceBetween">
                  {tab.code_name}
                  {tab.is_active !== null &&
                    (tab.is_active === 1 ? (
                      <RadioButtonCheckedIcon className={classes.icon} fontSize="small" color="primary" />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.icon} fontSize="small" color="primary" />
                    ))}
                </div>
              }
              component={NavLink}
              to={`/section-id-${sectionId}/section${tab.component_no}`}
              key={tab.id}
            />
          ))}
        </AntTabs>
        <Typography className={classes.padding} />
      </div>
    </div>
  );
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#4656b8',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#4656b8',
      opacity: 1,
    },
    '&$selected': {
      color: '#4656b8',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#4656b8',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    alignSelf: 'flex-start',
    marginLeft: 4.5,
  },
}));
