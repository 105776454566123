import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { Button, FormControl, FormLabel, NativeSelect, TextField } from '@material-ui/core';

import Loader from './Loader';

function EvaluationActivation({ children, isExpert, onSubmit, className = '' }) {
  const intl = useIntl();

  const data = useSelector((state) => state.evaluationActivation, shallowEqual);
  const requestState = useSelector((state) => state.evaluationActivationUI.requestState, shallowEqual);

  const activationInfo = data.is_active.value;
  const defaultValues = {
    is_active: activationInfo,
    reason_deactivation: data.reason_deactivation.value,
  };

  const { register, handleSubmit, errors, watch, reset } = useForm({ defaultValues });
  const isActive = watch('is_active');

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const _onSubmit = (formValues) => {
    const { is_active } = formValues;
    const data = is_active === '0' ? formValues : { is_active, reason_deactivation: '' };
    onSubmit(data);
    setEditMode(false);
  };

  const [editMode, setEditMode] = useState(false);

  if (isExpert)
    return requestState === 'request' ? (
      <Loader />
    ) : (
      <>
        {activationInfo === null || editMode ? (
          <form className={className} onSubmit={handleSubmit(_onSubmit)}>
            <div className="titleContainer mb4 mt4">
              <h2 className="title">
                <FormattedMessage id="evalActivation.title" defaultMessage="Сведения о наличии оценки в данном разделе" />
              </h2>
            </div>
            <FormControl className="mb2" fullWidth>
              <FormLabel component="legend">{data.is_active.label}</FormLabel>
              <NativeSelect inputProps={{ ref: register({ required: true }) }} error={!!errors.is_active} name="is_active">
                <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
                <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
              </NativeSelect>
            </FormControl>
            {String(isActive) === '0' && (
              <TextField
                fullWidth
                className="mb2"
                name="reason_deactivation"
                label={data.reason_deactivation.label}
                inputRef={register({ required: true })}
                error={!!errors.reason_deactivation}
              />
            )}
            <Button variant="contained" type="submit" color="primary" className="m0Auto">
              <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
            </Button>
          </form>
        ) : (
          <div className={`assessment mt4 ${className}`}>
            <div className="titleContainer">
              <h2 className="title">
                <FormattedMessage id="evalActivation.title" defaultMessage="Сведения о наличии оценки в данном разделе" />
              </h2>
            </div>
            <div className="w100 mt4">
              <div className="assessment__score mb">
                {data.is_active.label}: <strong>{data.is_active.translated_value}</strong>
              </div>
              {activationInfo === 0 && (
                <div className="assessment__comment">
                  {data.reason_deactivation.label}: &nbsp; <span className="mt">{data.reason_deactivation.value}</span>
                </div>
              )}
            </div>
            <Button onClick={() => setEditMode(true)} variant="contained" color="primary" className="m0Auto mt3">
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          </div>
        )}
        {activationInfo === null || activationInfo === 1 ? children : null}
      </>
    );

  return requestState === 'request' ? (
    <Loader />
  ) : (
    <>
      {activationInfo === null || activationInfo === 1 ? (
        children
      ) : (
        <div className="assessment mt4">
          <div className="titleContainer">
            <h2 className="title">Данный раздел не оценивается !</h2>
          </div>
          <div className="w80 mt2">
            {activationInfo === 0 && (
              <div className="assessment__comment">
                {data.reason_deactivation.label}: &nbsp; <span className="mt">{data.reason_deactivation.value}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default EvaluationActivation;
