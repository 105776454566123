import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';

import { useDialog } from '../../../common/hooks';
import Dialog from '../../reused/SimpleDialog';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import { deleteAct, getCheckbox, getCurrentAct, getDocumentTypes, getOrgActs, getOrgActsLabels, resetError, updateCheckbox } from '../../../actions';
import { ButtonGroup } from '@material-ui/core';
import Download from '../../reused/Download';
import Loader from '../../reused/Loader';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import Form from './Form';

export default function Section19() {
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getOrgActsLabels());
    dispatch(getCheckbox());
    dispatch(getOrgActs());
    dispatch(getDocumentTypes());
  }, [dispatch]);

  const { orgActs, labels, currentAct, checkbox } = useSelector((state) => state.sectionOneOrgActs, shallowEqual);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const defaultValues = useMemo(() => Object.values(currentAct).reduce((acc, field) => ({ ...acc, [field.code_name]: field.value }), {}), [
    currentAct,
  ]);

  const classes = useStyles();
  const finalLabels = useMemo(() => Object.values(labels), [labels]);
  const [dialogState, handleOpen, handleClose] = useDialog();
  const [editDialogState, handleOpenEdit, handleCloseEdit] = useDialog();

  const handleEdit = (id) => async () => {
    await dispatch(getCurrentAct(id));
    handleOpenEdit();
  };

  const handleDelete = (id) => () => dispatch(deleteAct(id));

  useEffect(() => {
    if (checkbox.checkbox_value) {
      setValue(checkbox.checkbox_value);
      setComment(checkbox.comment_value);
      if (!checkbox.comment_value?.trim()) setIsEditMode(true);
    } else {
      setValue('');
      setComment('');
      setIsEditMode(false);
    }
  }, [checkbox]);

  const [value, setValue] = useState('');
  const [comment, setComment] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const handleChange = (e) => {
    if (e.target.value === '1') {
      dispatch(
        updateCheckbox({
          ...checkbox,
          checkbox_value: +e.target.value,
          comment_value: '',
        })
      );
    }
    setValue(e.target.value);
    setIsEditMode(true);
  };
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      updateCheckbox({
        ...checkbox,
        checkbox_value: value,
        comment_value: comment,
      })
    );
    setIsEditMode(false);
  };

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestItemState, requestLabelState } = useSelector((state) => state.sectionOneOrgActsUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionOneOrgActs.error, shallowEqual);

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <div>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>
      <FormControl component="fieldset" disabled={!isVisible} className="mb">
        <FormLabel>{checkbox.checkbox_title}</FormLabel>
        <RadioGroup name="is_exists" value={value} onChange={handleChange} row>
          <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
          <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
        </RadioGroup>
      </FormControl>
      {value === '0' && isVisible && (
        <>
          {isEditMode ? (
            <form>
              <TextField required className="w50" label={checkbox.comment_title} value={comment} onChange={(e) => setComment(e.target.value)} />
              <Button variant="contained" color="primary" type="submit" onClick={handleClick} className="dBlock mt2">
                <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
              </Button>
            </form>
          ) : (
            <div>
              <p className="assessment__score mb">{checkbox.comment_title}</p>
              <p className="assessment__score mb">{comment}</p>
              <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
                <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
              </Button>
            </div>
          )}
        </>
      )}

      {value === '1' && (
        <TableContainer component={Paper} className={classes.container}>
          {requestLabelState === 'request' ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {isVisible && <StyledTableCell />}
                  <StyledTableCell>№</StyledTableCell>
                  {finalLabels.map((label) => (
                    <StyledTableCell key={label} align="center">
                      {label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TransitionGroup component={TableBody}>
                {orgActs.map((act, ind) => (
                  <CSSTransition key={act.id} timeout={1200} classNames="listItemUI">
                    <StyledTableRow key={act.id}>
                      {isVisible && (
                        <StyledTableCell>
                          <ButtonGroup orientation="vertical" color="primary" variant="contained">
                            <Button onClick={handleEdit(act.id)}>
                              <CreateIcon />
                            </Button>
                            <Button color="secondary" onClick={handleDelete(act.id)}>
                              <DeleteIcon />
                            </Button>
                          </ButtonGroup>
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="center">{ind + 1}</StyledTableCell>
                      <StyledTableCell align="center">{act.document_no}</StyledTableCell>
                      <StyledTableCell align="center">{act.document_date}</StyledTableCell>
                      <StyledTableCell align="center">{act.document_type_name}</StyledTableCell>
                      <StyledTableCell align="center">{act.title}</StyledTableCell>
                      <StyledTableCell align="center">{act.interaction_nature}</StyledTableCell>
                      <StyledTableCell align="center">
                        <Download fileName={act.file} />
                      </StyledTableCell>
                    </StyledTableRow>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </Table>
          )}
        </TableContainer>
      )}
      {value === '1' && isVisible && (
        <Button variant="contained" color="primary" type="submit" onClick={handleOpen} className="m0Auto">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}

      <Dialog dialogState={dialogState} onClose={handleClose} title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleClose} />
      </Dialog>
      <Dialog
        dialogState={editDialogState}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        {requestItemState === 'request' ? <Loader /> : <Form defaultValues={defaultValues} submitAction={handleCloseEdit} isEditableMode />}
      </Dialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateNames',
          defaultMessage: 'Наименование документа не должно повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
}
