import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { setError } from './index';

export const generateHeaders = (token, lang = 'ru', orgId) => ({
  Authorization: `Bearer ${token}`,
  language: lang,
  'organization-id': orgId,
});

export const getRegionsSuccess = createAction('REGIONS_GET_SUCCESS');

export const getRegions = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.regions(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getRegionsSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(setError(e.response));
  }
};

export const getDistrictsSuccess = createAction('DISTRICTS_GET_SUCCESS');
export const resetDistricts = createAction('DISTRICTS_RESET');

export const getDistricts = (regionId) => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.regions(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: {
        parent_id: regionId,
      },
    });
    dispatch(getDistrictsSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(setError(e.response));
  }
};

export const getPositionsSuccess = createAction('POSITION_GET_SUCCESS');

export const getPositions = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.positions(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getPositionsSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(setError(e.response));
  }
};

export const toggleTabState = createAction('TOGGLE_TAB_STATE');
export const getTabsSuccess = createAction('TABS_GET_SUCCESS');
export const getAccordionsSuccess = createAction('ACCORDIONS_GET_SUCCESS');
export const getAccordionsRequest = createAction('ACCORDIONS_GET_REQUEST');
export const getAccordionContentSuccess = createAction('ACCORDION_CONTENT_GET_SUCCESS');
export const getAccordionContentRequest = createAction('ACCORDION_CONTENT_GET_REQUEST');

export const getSections =
  (sectionId, sectionType = 'tabs') =>
  async (dispatch) => {
    if (sectionType === 'accordionContent') dispatch(getAccordionContentRequest());
    if (sectionType === 'accordions') dispatch(getAccordionsRequest());
    try {
      const { data } = await axios.get(routes.sectionTabs(), {
        params: { parent_id: sectionId },
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      });
      if (sectionType === 'tabs') dispatch(getTabsSuccess(data));
      if (sectionType === 'accordions') dispatch(getAccordionsSuccess(data));
      if (sectionType === 'accordionContent') dispatch(getAccordionContentSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(setError(e.response));
    }
  };

export const getDocumentTypesSuccess = createAction('DOCUMENT_TYPES_GET_SUCCESS');

export const getDocumentTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.documentTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDocumentTypesSuccess(data));
  } catch (e) {
    console.log(e);
  }
};

export const getAcceptedDocumentsSuccess = createAction('ACCEPTED_DOCUMENTS_GET_SUCCESS');

export const getAcceptedDocuments = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.acceptedDocuments(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getAcceptedDocumentsSuccess(data));
  } catch (e) {
    console.log(e);
  }
};

export const getOrganizationsSuccess = createAction('ORGANIZATIONS_GET_SUCCESS');
export const getOrganizationsRequest = createAction('ORGANIZATIONS_GET_REQUEST');

export const getOrganizations = (orgData) => async (dispatch) => {
  dispatch(getOrganizationsRequest());
  try {
    const { data } = await axios({
      url: routes.statRating(),
      method: 'POST',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 154),
      data: orgData,
    });
    if (data.success) {
      dispatch(getOrganizationsSuccess(data.result));
    }
  } catch (e) {
    console.log(e);
    dispatch(setError(e.response));
  }
};

export const getOrgStatRequest = createAction('STAT_ORG_GET_REQUEST');
export const getOrgStatSuccess = createAction('STAT_ORG_GET_SUCCESS');

export const getOrgStat = (formData) => async (dispatch) => {
  dispatch(getOrgStatRequest());
  try {
    const { data } = await axios.get(routes.orgAllStat(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: formData,
    });
    const sections = await axios.get(routes.orgAllSections(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: formData,
    });
    dispatch(getOrgStatSuccess({ data, nestedSections: sections.data }));
  } catch (e) {
    console.log(e);
    dispatch(setError(e.response));
  }
};

export const getTypesIctDivisionSuccess = createAction('ICT_DIVISION_TYPES_GET_SUCCESS');

export const getTypesIctDivision = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.ictDivisionTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getTypesIctDivisionSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getDivisionOrgTypesSuccess = createAction('DIVISION_TYPES_ORG_GET_SUCCESS');

export const getDivisionOrgTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.orgDivisionFormTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDivisionOrgTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getStageListSuccess = createAction('INFO_SYSTEM_STATES_GET_SUCCESS');

export const getStageList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.stageList(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getStageListSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getPurposeTypesSuccess = createAction('INFO_SYSTEM_PURPOSE_TYPES_GET_SUCCESS');

export const getPurposeTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.purposeTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getPurposeTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.reponse));
  }
};

export const getClassifierTypesSuccess = createAction('INFO_SYSTEM_CLASSIFIER_GET_SUCCESS');

export const getClassifierTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.classifierTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getClassifierTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getAutoProcessTypesSuccess = createAction('INFO_SYSTEM_AUTO_PROCESS_GET_SUCCESS');

export const getAutoProcessTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.autoProcessTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getAutoProcessTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getFormTypesSuccess = createAction('FORM_TYPES_GET_SUCCESS');

export const getFormTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.formTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getFormTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getResultTypesSuccess = createAction('RESULT_TYPES_GET_SUCCESS');

export const getResultTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.resultTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getResultTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getTrackingTypesSuccess = createAction('TRACKING_TYPES_GET_SUCCESS');

export const getTrackingTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.trackingTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getTrackingTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getUserTypesSuccess = createAction('USER_TYPES_GET_SUCCESS');

export const getUserTypes = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.userTypes(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getUserTypesSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getEvaluationsSuccess = createAction('EVALUATIONS_GET_SUCCESS');

export const getEvaluations =
  (tabId, paramId = 1) =>
  async (dispatch) => {
    try {
      if (paramId instanceof Array) {
        const promises = paramId.map(async (id) => {
          const { data } = await axios.get(routes.evaluations(), {
            headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
            params: { id: tabId, param_id: id },
          });

          return data;
        });
        const data = await Promise.all(promises);
        dispatch(getEvaluationsSuccess(data));
      } else {
        const { data } = await axios.get(routes.evaluations(), {
          headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
          params: { id: tabId, param_id: paramId },
        });

        dispatch(getEvaluationsSuccess(data));
      }
    } catch (e) {
      dispatch(setError(e.response));
    }
  };

export const getEvaluationParamsSuccess = createAction('EVALUATIONS_PARAMS_GET_SUCCESS');

export const getEvaluationParams = (tabId, isSelectParam) => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.evaluationParams(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { id: tabId, is_select: isSelectParam },
    });

    dispatch(getEvaluationParamsSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getCategoriesOrgSuccess = createAction('CATEGORY_ORG_GET_SUCCESS');

export const getCategoriesOrg = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.categoriesOrg(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getCategoriesOrgSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};

export const getReportTemplateListSuccess = createAction('REPORT_TEMPLATE_LIST_GET_SUCCESS');

export const getReportTemplateList = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.reportTemplateList(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 26),
    });
    dispatch(getReportTemplateListSuccess(data.result));
  } catch (e) {
    dispatch(setError(e.response));
  }
};


export const getExcelFileLinkSuccess = createAction('EXCEL_FILE_LINK_GET');

export const getExcelFileLink = () => async (dispatch) => {
  try {
    const { data } = await axios.get(routes.excelLink(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getExcelFileLinkSuccess(data));
  } catch (e) {
    dispatch(setError(e.response));
  }
};