export const host = process.env.NODE_ENV === 'production' ? 'https://api.nis.uz' : 'http://nis.track.uz:8071';

export default {
  login: () => [host, 'user/login'].join('/'),
  logout: () => [host, 'user/logout'].join('/'),
  profile: () => [host, 'user/detail'].join('/'),

  sectionOneOrgList: () => [host, 'section_1/organizations'].join('/'),
  sectionOneOrgInfo: (id) => [host, `section_1/organizations/${id}`].join('/'),

  sectionOneOrgLegalBasisLabels: () => [host, 'section_1/organization-legal-basis/labels'].join('/'),
  sectionOneOrgLegalBasisData: () => [host, 'section_1/organization-legal-bases'].join('/'),
  sectionOneOrgLegalBasisOperationInfo: (id) => [host, `section_1/organization-legal-bases/${id}`].join('/'),

  sectionOneOrgActsLabels: () => [host, 'section_1/organization-legal-act-basis/labels'].join('/'),
  sectionOneOrgActs: () => [host, 'section_1/organization-legal-act-bases'].join('/'),
  sectionOneOrgAct: (id) => [host, `section_1/organization-legal-act-bases/${id}`].join('/'),
  sectionOneOrgActCheckbox: () => [host, 'section_1/organization-legal-act-basis/checkbox-'].join('/'),

  sectionOneSubdivisionLabels: () => [host, 'section_1/organization-structure-divisions-stat/labels'].join('/'),
  sectionOneSubdivisionData: () => [host, 'section_1/organization-structure-divisions-stats'].join('/'),

  sectionOneSubordinateDivisionLabels: () => [host, 'section_1/organization-structure-division/labels'].join('/'),
  sectionOneSubordinateDivisionData: () => [host, 'section_1/organization-structure-divisions'].join('/'),
  sectionOneSubordinateDivisionDataOperation: (id) =>
    [host, `section_1/organization-structure-divisions/${id}`].join('/'),

  sectionOneInfoIctManager: () => [host, 'section_1/info-ict-managers'].join('/'),
  sectionOneInfoIctLabels: () => [host, 'section_1/info-ict-manager/labels'].join('/'),

  sectionOneEmployeesStat: () => [host, 'section_1/organization-employees-stat'].join('/'),

  sectionTwoWebsiteApps: () => [host, 'section_2/info-website-applications'].join('/'),
  sectionTwoWebsiteAppLabels: () => [host, 'section_2/info-website-application/labels'].join('/'),

  sectionTwoWebsiteComplianceRequirements: () => [host, 'section_2/website-compliance/view'].join('/'),
  sectionTwoUpdateWebsiteCompliance: () => [host, 'section_2/website-compliance/update'].join('/'),

  sectionTwoWebsiteManager: () => [host, 'section_2/info-website-content-managers'].join('/'),
  sectionTwoWebsiteManagerLabels: () => [host, 'section_2/info-website-content-manager/labels'].join('/'),

  sectionTwoQuestionnaireManager: () => [host, 'section_2/info-questionnaire-content-manager'].join('/'),
  sectionTwoQuestionnaireManagerPut: () => [host, 'section_2/info-questionnaire-content-manager/create'].join('/'),
  sectionTwoQuestionnaireManagerLabels: () => [host, 'section_2/info-questionnaire-content-manager/labels'].join('/'),

  sectionTwoWebsiteAccess: () => [host, 'section_2/info-website-accessibilities'].join('/'),
  sectionTwoWebsiteAccessLabels: () => [host, 'section_2/info-website-accessibility/labels'].join('/'),

  sectionTwoLinksSocialPages: () => [host, 'section_2/info-social-network-pages'].join('/'),
  sectionTwoLinksSocialLabels: () => [host, 'section_2/info-social-network-page/labels'].join('/'),
  sectionTwoLinksSocialOperation: (id) => [host, `section_2/info-social-network-pages/${id}`].join('/'),

  sectionTwoLinksChannels: () => [host, 'section_2/info-messengers-channels'].join('/'),
  sectionTwoLinksChannelsLabels: () => [host, 'section_2/info-messengers-channel/labels'].join('/'),
  sectionTwoLinksChannelsOperation: (id) => [host, `section_2/info-messengers-channels/${id}`].join('/'),

  sectionTwoWebsiteOpenDataLabels: () => [host, 'section_2/info-open-data/labels'].join('/'),
  sectionTwoWebsiteOpenDatas: () => [host, 'section_2/info-open-datas'].join('/'),
  sectionTwoWebsiteOpenDataOperation: (id) => [host, `section_2/info-open-datas/${id}`].join('/'),

  sectionTwoOnlineConsultant: () => [host, 'section_2/info-online-consultants'].join('/'),
  sectionTwoOnlineConsultantLabels: () => [host, 'section_2/info-online-consultant/labels'].join('/'),

  sectionTwoWebsiteHelplineInfo: () => [host, 'section_2/info-website-helplines'].join('/'),
  sectionTwoWebsiteHelplineInfoLabels: () => [host, 'section_2/info-website-helpline/labels'].join('/'),

  sectionTwoProcessingActs: () => [host, 'section_2/stat-legal-acts'].join('/'),
  sectionTwoProcessingActLabels: () => [host, 'section_2/stat-legal-act/labels'].join('/'),

  sectionThreeInfoPublicServices: () => [host, 'section_3/info-public-services'].join('/'),
  sectionThreeInfoPublicServiceLabels: () => [host, 'section_3/info-public-service/labels'].join('/'),
  sectionThreeInfoPublicServicesOperation: (id) => [host, `section_3/info-public-services/${id}`].join('/'),

  sectionThreeEpiguStats: () => [host, 'section_3/epigu-services-stats'].join('/'),
  sectionThreeEpiguStatLabels: () => [host, 'section_3/epigu-services-stat/labels'].join('/'),

  sectionThreeEpiguStatsRequests: () => [host, 'section_3/epigu-auto/index'].join('/'),
  sectionThreeEpiguStatsRequestsLabels: () => [host, 'section_3/epigu-auto/labels'].join('/'),
  sectionThreeEpiguStatsRequestsActivate: () => [host, 'section_3/epigu-auto/activate-service'].join('/'),

  sectionFourCyberSecurityView: () => [host, 'section_4/cyber-security/view'].join('/'),
  sectionFourCyberSecurityUpdate: () => [host, 'section_4/cyber-security/update'].join('/'),
  cyberSecurityEvaluation: (type) => [host, `section_4/evaluation/evaluation/${type}`].join('/'),

  sectionFiveInfoSystemLabels: () => [host, 'section_5/information-system/labels'].join('/'),
  sectionFiveInfoSystems: () => [host, 'section_5/information-systems'].join('/'),
  sectionFiveInfoSystemsOperation: (id) => [host, `section_5/information-systems/${id}`].join('/'),
  infoSystemsEvaluation: (method) => [host, `section_5/evaluation/evaluation/${method}`].join('/'),

  sectionFiveTechnoProccess: () => [host, 'section_5/stat-technoprocesses'].join('/'),
  sectionFiveTechnoProccessLabels: () => [host, 'section_5/stat-technoprocess/labels'].join('/'),

  sectionSevenComputersInfo: () => [host, 'section_7/organization-computers'].join('/'),

  sectionEightSpendingStat: () => [host, 'section_8/ict-founds-spending-stat'].join('/'),
  sectionEightSourceStat: () => [host, 'section_8/ict-founds-source-stat'].join('/'),

  sectionSixInfoGovernmentProject: () => [host, 'section_6/info-e-government-project/index-six-point-one'].join('/'),
  sectionSixInfoGovernmentProjectRadio: () => [host, 'section_6/info-e-government-project/checkbox-'].join('/'),
  sectionSixInfoGovernmentProjectLabels: () => [host, 'section_6/info-e-government-project/labels'].join('/'),
  sectionSixInfoGovernmentProjectOperations: (id) => [host, `section_6/info-e-government-projects/${id}`].join('/'),
  governmentProjectEvaluation: (method) => [host, `section_6/evaluation/evaluation/${method}`].join('/'),

  sectionSixOrgDigitalStrategy: () => [host, 'section_6/organization-digital-development-strategy'].join('/'),
  sectionSixOrgDigitalStrategyLabels: () =>
    [host, 'section_6/organization-digital-development-strategy/labels'].join('/'),
  sectionSixOrgDigitalStrategyOperations: () =>
    [host, 'section_6/organization-digital-development-strategies'].join('/'),
  sectionSixOrgDigitalStrategyCheckbox: () =>
    [host, 'section_6/organization-digital-development-strategy/checkbox-'].join('/'),

  sectionSixInfoIctDivisions: () => [host, 'section_6/info-ict-divisions'].join('/'),
  sectionSixInfoIctDivisionLabels: () => [host, 'section_6/info-ict-division/labels'].join('/'),

  sectionSixOrgHackathon: () => [host, 'section_6/info-organizations-hackathons'].join('/'),
  sectionSixOrgHackathonLabels: () => [host, 'section_6/info-organizations-hackathon/labels'].join('/'),
  sectionSixOrgHackathonOperations: () => [host, 'section_6/info-organizations-hackathons'].join('/'),
  sectionSixOrgHackathonRadio: () => [host, 'section_6/info-organizations-hackathon/checkbox-'].join('/'),

  sectionSevenOrganizationServers: () => [host, 'section_7/organization-servers'].join('/'),
  sectionSevenOrganizationServersLabels: () => [host, 'section_7/organization-servers/labels'].join('/'),
  sectionSevenOrganizationServersOperations: (id) => [host, `section_7/organization-servers/${id}`].join('/'),

  sectionEightInfoIctFounds: () => [host, 'section_8/ict-founds-info'].join('/'),
  sectionEightInfoIctFoundsLabels: () => [host, 'section_8/ict-founds-info/labels'].join('/'),
  sectionEightInfoIctFoundsOperations: () => [host, 'section_8/ict-founds-infos'].join('/'),

  evaluationActivation: (method) => [host, `evaluation/activation/${method}`].join('/'),

  complexEvaluationList: () => [host, 'evaluation/item/index'].join('/'),
  complexEvaluationView: (type = '') => [host, `evaluation/item${type}/view`].join('/'),
  complexMarkCreate: () => [host, 'evaluation/item-correctlies'].join('/'),
  complexMarkDelete: () => [host, 'evaluation/item-correctly/delete'].join('/'),
  complexAssessmentCreate: () => [host, 'evaluation/items'].join('/'),
  complexAssessmentDelete: () => [host, 'evaluation/item/delete'].join('/'),

  simpleAssessment: (method) => [host, `section_2/evaluation/evaluation/${method}`].join('/'),
  simpleMark: (method) => [host, `section_2/evaluation/is-correctly/${method}`].join('/'),

  sectionThreeAssessment: (method) => [host, `section_3/evaluation/evaluation/${method}`].join('/'),
  sectionSixAssessment: (method) => [host, `section_6/evaluation/evaluation/${method}`].join('/'),

  reportRating: () => [host, 'evaluation/report/rating'].join('/'),
  statRating: () => [host, 'evaluation/stat/rating'].join('/'),

  evaluations: () => [host, 'dictionary/evaluations'].join('/'),
  evaluationParams: () => [host, 'dictionary/evaluation-params'].join('/'),
  regions: () => [host, 'dictionary/regions'].join('/'),
  positions: () => [host, 'dictionary/positions'].join('/'),
  sectionTabs: () => [host, 'dictionary/sections'].join('/'),
  documentTypes: () => [host, 'dictionary/document-types'].join('/'),
  acceptedDocuments: () => [host, 'dictionary/authorities-accepted-documents'].join('/'),
  organizations: () => [host, 'dictionary/all-organizations'].join('/'),
  fileUpload: () => [host, 'file/upload'].join('/'),
  paramsUploadFile: () => [host, 'file/params'].join('/'),
  ictDivisionTypes: () => [host, 'dictionary/ict-division-types'].join('/'),
  orgDivisionFormTypes: () => [host, 'dictionary/org-form-types'].join('/'),
  stageList: () => [host, 'dictionary/states'].join('/'),
  purposeTypes: () => [host, 'dictionary/purpose-types'].join('/'),
  classifierTypes: () => [host, 'dictionary/classifiers-types'].join('/'),
  autoProcessTypes: () => [host, 'dictionary/service-process-types'].join('/'),
  formTypes: () => [host, 'dictionary/service-form-types'].join('/'),
  resultTypes: () => [host, 'dictionary/service-result-types'].join('/'),
  trackingTypes: () => [host, 'dictionary/service-tracking-types'].join('/'),
  userTypes: () => [host, 'dictionary/user-types'].join('/'),
  orgEvaluation: () => [host, 'dictionary/organization-gen-stat'].join('/'),
  orgEvaluationExport: () => [host, 'dictionary/export-organization-gen-stat'].join('/'),
  orgAllStat: () => [host, 'dictionary/organization-gen-stat-all'].join('/'),
  orgAllSections: () => [host, 'dictionary/organization-all-sections-history'].join('/'),
  percentData: () => [host, 'dictionary/count-percentage-data'].join('/'),
  categoriesOrg: () => [host, 'dictionary/category-org'].join('/'),
  excelLink: () => [host, 'dictionary/generate-excel'].join('/'),
  help: () => [host, 'dictionary/help'].join('/'),
  reportTemplateList: () => [host, 'dictionary/report-template-list'].join('/'),
};
