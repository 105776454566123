import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../routes';
import { generateHeaders } from './dictionary';

export const getSimpleMarkRequest = createAction('SIMPLE_MARK_GET_REQUEST');
export const getSimpleMarkSuccess = createAction('SIMPLE_MARK_GET_SUCCESS');
export const getSimpleMarkFailure = createAction('SIMPLE_MARK_GET_FAILURE');

export const getSimpleMark = (id) => async (dispatch) => {
  dispatch(getSimpleMarkRequest());

  try {
    const headers = generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId);

    const labels = await axios.get(routes.simpleMark('labels'), { headers });
    const mark = await axios.get(routes.simpleMark('view'), { headers, params: { section_id: id } });

    dispatch(getSimpleMarkSuccess({ mark: mark.data, labels: labels.data }));
  } catch (e) {
    //dispatch(getSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const saveSimpleMarkRequest = createAction('SIMPLE_MARK_SAVE_REQUEST');
export const saveSimpleMarkSuccess = createAction('SIMPLE_MARK_SAVE_SUCCESS');
export const saveSimpleMarkFailure = createAction('SIMPLE_MARK_SAVE_FAILURE');

export const saveSimpleMark = (id, formValues) => async (dispatch) => {
  dispatch(saveSimpleMarkRequest());

  try {
    const { data } = await axios({
      url: routes.simpleMark('create'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: id },
    });
    dispatch(saveSimpleMarkSuccess(data));
  } catch (e) {
    //dispatch(saveSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const deleteSimpleMarkRequest = createAction('SIMPLE_MARK_DELETE_REQUEST');
export const deleteSimpleMarkSuccess = createAction('SIMPLE_MARK_DELETE_SUCCESS');
export const deleteSimpleMarkFailure = createAction('SIMPLE_MARK_DELETE_FAILURE');

export const deleteSimpleMark = (id) => async (dispatch) => {
  dispatch(deleteSimpleMarkRequest());

  try {
    await axios.delete(routes.simpleMark('delete'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });
    dispatch(deleteSimpleMarkSuccess());
    dispatch(deleteSimpleAssessment(id));
  } catch (e) {
    //dispatch(deleteSimpleMarkFailure(e?.response));
    console.log(e);
  }
};

export const getSimpleAssessmentRequest = createAction('SIMPLE_ASSESSMENT_GET_REQUEST');
export const getSimpleAssessmentSuccess = createAction('SIMPLE_ASSESSMENT_GET_SUCCESS');
export const getSimpleAssessmentFailure = createAction('SIMPLE_ASSESSMENT_GET_FAILURE');

export const getSimpleAssessment = (id) => async (dispatch) => {
  dispatch(getSimpleAssessmentRequest());

  try {
    const headers = generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId);

    const labels = await axios.get(routes.simpleAssessment('labels'), { headers });
    const assessment = await axios.get(routes.simpleAssessment('view'), { headers, params: { section_id: id } });

    dispatch(getSimpleAssessmentSuccess({ assessment: assessment.data, labels: labels.data }));
  } catch (e) {
    //dispatch(getSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const saveSimpleAssessmentRequest = createAction('SIMPLE_ASSESSMENT_SAVE_REQUEST');
export const saveSimpleAssessmentSuccess = createAction('SIMPLE_ASSESSMENT_SAVE_SUCCESS');
export const saveSimpleAssessmentFailure = createAction('SIMPLE_ASSESSMENT_SAVE_FAILURE');

export const saveSimpleAssessment = (id, formValues) => async (dispatch) => {
  dispatch(saveSimpleAssessmentRequest());

  try {
    const { data } = await axios({
      url: routes.simpleAssessment('create'),
      method: 'post',
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: { ...formValues, section_id: id },
    });
    dispatch(saveSimpleAssessmentSuccess(data));
  } catch (e) {
    //dispatch(saveSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};

export const deleteSimpleAssessmentRequest = createAction('SIMPLE_ASSESSMENT_DELETE_REQUEST');
export const deleteSimpleAssessmentSuccess = createAction('SIMPLE_ASSESSMENT_DELETE_SUCCESS');
export const deleteSimpleAssessmentFailure = createAction('SIMPLE_ASSESSMENT_DELETE_FAILURE');

export const deleteSimpleAssessment = (id) => async (dispatch) => {
  dispatch(deleteSimpleAssessmentRequest());

  try {
    await axios.delete(routes.simpleAssessment('delete'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { section_id: id },
    });
    dispatch(deleteSimpleAssessmentSuccess());
  } catch (e) {
    //dispatch(deleteSimpleAssessmentFailure(e?.response));
    console.log(e);
  }
};
