import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { addElementOpenData, editElementOpenData, getEvaluationList } from '../../../actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Form = ({ submitAction, defaultValues, isEditableMode, defaultFormatValue = [] }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { register, handleSubmit, control, watch, errors } = useForm({ defaultValues, mode: 'onChange' });
  const radios = watch();

  const { labels, element, datas } = useSelector((state) => state.sectionTwoWebsiteOpenData, shallowEqual);

  const titlesUz = datas.map((title) => title.title_uz);
  const titlesRu = datas.map((title) => title.title_ru);

  const [value, setValue] = React.useState(defaultFormatValue);
  const [inputValue, setInputValue] = React.useState('');

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-stat');
  const isOrganization = currentUser?.roles.includes('organization');

  const onSubmit = (data) => {
    const valueReset = {};
    if (radios.has_identifier === '1') valueReset.reason_identifier = '';
    if (radios.has_geolocation === '1') valueReset.reason_geolocation = '';
    if (radios.has_services === '1') valueReset.reason_services = '';
    if (radios.has_services === '0') valueReset.services_info = '';
    if (isEditableMode)
      dispatch(
        editElementOpenData(element.id.value, {
          ...data,
          ...valueReset,
          organization_id: localStorage.orgId,
          section_id: 25,
          publication_forms: value,
        })
      );
    else {
      dispatch(
        addElementOpenData({
          ...data,
          ...valueReset,
          organization_id: localStorage.orgId,
          section_id: 25,
          publication_forms: value,
        })
      );
    }
    dispatch(getEvaluationList(25));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isExpert && (
        <>
          <TextField
            fullWidth
            name="title_uz"
            inputRef={register({
              required: true,
              validate: (value) => {
                let isValid = false;
                if (!isEditableMode) isValid = titlesUz.includes(value);
                return !isValid;
              },
            })}
            error={!!errors.title_uz}
            className="mb"
            label={labels.title_uz}
            helperText={
              errors.title_uz?.type === 'validate' &&
              intl.formatMessage({
                id: 'global.errorDublicateOpenData',
                defaultMessage: 'Наименование ОД не должно повторяться!',
              })
            }
          />
          <TextField
            fullWidth
            name="title_ru"
            inputRef={register({
              required: true,
              validate: (value) => {
                let isValid = false;
                if (!isEditableMode) isValid = titlesRu.includes(value);
                return !isValid;
              },
            })}
            error={!!errors.title_ru}
            className="mb2"
            label={labels.title_ru}
            helperText={
              errors.title_ru?.type === 'validate' &&
              intl.formatMessage({
                id: 'global.errorDublicateOpenData',
                defaultMessage: 'Наименование ОД не должно повторяться!',
              })
            }
          />
          <label htmlFor="mark_geolocation" className={`nativeLabel ${!!errors.mark_geolocation && 'error'}`}>
            {labels.mark_geolocation}
          </label>
          <Controller
            as={<RadioGroup row>{yesNo}</RadioGroup>}
            name="mark_geolocation"
            className="mb2"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
          <label htmlFor="mark_identifier" className={`nativeLabel ${!!errors.mark_identifier && 'error'}`}>
            {labels.mark_identifier}
          </label>
          <Controller
            as={<RadioGroup row>{yesNo}</RadioGroup>}
            name="mark_identifier"
            className="mb2"
            control={control}
            rules={{ required: true }}
            defaultValue=""
          />
          <TextField
            fullWidth
            name="com_geolocation"
            inputRef={register}
            error={!!errors.com_geolocation}
            className="mb"
            label={labels.com_geolocation}
          />
          <TextField
            fullWidth
            name="com_identifier"
            inputRef={register}
            error={!!errors.com_identifier}
            className="mb"
            label={labels.com_identifier}
          />
          <TextField fullWidth name="com_services" inputRef={register} className="mb" label={labels.com_services} />
        </>
      )}
      {isOrganization && (
        <>
          <div className="mb2">
            <label htmlFor="provision_term" className={`nativeLabel ${!!errors.provision_term && 'error'}`}>
              {labels.provision_term}
            </label>
            <TextField
              fullWidth
              name="provision_term"
              inputRef={register({ required: true })}
              error={!!errors.provision_term}
              className="mb"
              type="date"
            />
          </div>
          <TextField
            fullWidth
            name="frequency"
            inputRef={register({ required: true })}
            error={!!errors.frequency}
            className="mb"
            label={labels.frequency}
          />
          <div className="mb2">
            <Autocomplete
              multiple
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option.title === value.title}
              options={formats}
              disableCloseOnSelect
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} color="primary" />
                  {option.title}
                </React.Fragment>
              )}
              style={{ width: 500 }}
              renderInput={(params) => <TextField {...params} variant="outlined" label={labels.publication_forms} required={value.length === 0} />}
            />
          </div>
          <div className="mb2">
            <TextField
              fullWidth
              name="od_link"
              inputRef={register({ required: true })}
              error={!!errors.od_link}
              className="mb"
              type="url"
              label={labels.od_link}
            />
          </div>
          <div className="mb2">
            <label htmlFor="has_geolocation" className={`nativeLabel ${!!errors.has_geolocation && 'error'}`}>
              {labels.has_geolocation}
            </label>
            <Controller
              as={<RadioGroup row>{yesNo}</RadioGroup>}
              name="has_geolocation"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {radios.has_geolocation === '0' && (
              <TextField
                className="mb m0Auto"
                fullWidth
                inputRef={register({ required: true })}
                error={!!errors.reason_geolocation}
                name="reason_geolocation"
                label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
              />
            )}
          </div>
          <div className="mb2">
            <label htmlFor="has_identifier" className={`nativeLabel ${!!errors.has_identifier && 'error'}`}>
              {labels.has_identifier}
            </label>
            <Controller
              as={<RadioGroup row>{yesNo}</RadioGroup>}
              name="has_identifier"
              control={control}
              rules={{ required: true }}
              defaultValue=""
            />
            {radios.has_identifier === '0' && (
              <TextField
                className="mb m0Auto"
                fullWidth
                inputRef={register({ required: true })}
                name="reason_identifier"
                error={!!errors.reason_identifier}
                label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
              />
            )}
          </div>
          <label htmlFor="date" className="nativeLabel">
            {labels.data_modified_date}
          </label>
          <TextField
            type="date"
            fullWidth
            id="data_modified_date"
            className="mb m0Auto"
            name="data_modified_date"
            inputRef={register({ required: true })}
            error={!!errors.data_modified_date}
          />
          <div className="mb2">
            <label htmlFor="has_services" className={`nativeLabel ${!!errors.has_services && 'error'}`}>
              {labels.has_services}
            </label>
            <Controller as={<RadioGroup row>{yesNo}</RadioGroup>} name="has_services" control={control} rules={{ required: true }} />
            {radios.has_services === '1' && (
              <TextField
                className="mb m0Auto"
                fullWidth
                inputRef={register({ required: true })}
                name="services_info"
                error={!!errors.services_info}
                label={labels.services_info}
              />
            )}
            {radios.has_services === '0' && (
              <TextField
                className="mb m0Auto"
                fullWidth
                inputRef={register({ required: true })}
                name="reason_services"
                error={!!errors.reason_services}
                label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
              />
            )}
          </div>
        </>
      )}

      <Button className="mb" type="submit" color="primary" variant="contained">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;

const yesNo = (
  <React.Fragment>
    <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
    <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
  </React.Fragment>
);

const formats = [{ title: 'XLSX' }, { title: 'CSV' }, { title: 'XML' }, { title: 'JSON' }, { title: 'PDF' }];
