import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';

import Form from './Form';
import Dialog from '../../reused/FullScreenDialog';
import { useDialog } from '../../../common/hooks';
import Table from '../../reused/Table';
import Loader from '../../reused/Loader';
import { getDivisionOrgTypes, getInfoDivision, getTypesIctDivision } from '../../../actions';
import Download from '../../reused/Download';
import Error from '../../reused/Error';

function Section63() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getInfoDivision());
    dispatch(getTypesIctDivision());
    dispatch(getDivisionOrgTypes());
  }, [dispatch]);

  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();

  const { infoDivision, labels } = useSelector((state) => state.sectionSixInfoIctDivisions, shallowEqual);
  let filledData;

  if (infoDivision) {
    if (infoDivision.ict_structure_id?.value === 5)
      filledData = [infoDivision.ict_structure_id, infoDivision.reason_text];
    else filledData = Object.values(infoDivision).filter((el) => el.code_name !== 'reason_text');
  }

  const infoDivisionFields = useMemo(
    () => (infoDivision ? filledData : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))),
    [infoDivision, filledData, labels]
  );

  const filterDivisionFields = useMemo(
    () =>
      infoDivisionFields.filter((el) => {
        if (infoDivision) {
          if (el.code_name === 'normative_document_file') return infoDivision.has_normative_document.value === '1';
          if (el.code_name === 'work_plan_file') return infoDivision.has_work_plan.value === '1';
          if (el.code_name === 'financial_motivations_file')
            return infoDivision.has_financial_motivations_doc.value === '1';
          if (el.code_name === 'outsourcing_work_plan_file')
            return infoDivision.has_outsourcing_work_plan.value === '1';
          if (el.code_name === 'outsourcing_reports_file') return infoDivision.has_outsourcing_reports.value === '1';
          if (el.code_name === 'outsourcing_services_document_file')
            return infoDivision.has_outsourcing_services_doc.value === '1';
        }
        return !['id', 'is_correctly', 'comment', 'point'].includes(el.code_name);
      }),
    [infoDivision, infoDivisionFields]
  );

  const formattedFilesView = useMemo(
    () =>
      filterDivisionFields.map((el) => {
        if (fileCodeNames.includes(el.code_name))
          return {
            ...el,
            value: el.value ? <Download className="end" fileName={el.value} /> : '',
          };
        return el;
      }),
    [filterDivisionFields]
  );

  const defaultValues = useMemo(
    () => filterDivisionFields.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}),
    [filterDivisionFields]
  );

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const requestState = useSelector((state) => state.sectionSixInfoIctDivisionsUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionSixInfoIctDivisions.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <div>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <Table
          rows={formattedFilesView}
          title={sectionTabs[selectedTabIndex]?.title}
          head={
            isVisibleOrg && (
              <IconButton onClick={handleOpenEdit} color="inherit">
                <CreateIcon />
              </IconButton>
            )
          }
        />
      )}

      <Dialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleCloseEdit} defaultValues={defaultValues} />
      </Dialog>
    </div>
  );
}

export default Section63;

const fileCodeNames = [
  'outsourcing_reports_file',
  'outsourcing_work_plan_file',
  'outsourcing_services_document_file',
  'financial_motivations_file',
  'work_plan_file',
  'staff_certificates_file',
  'staff_placement_specialists_file',
  'divisions_structure_file',
  'normative_document_file',
];
