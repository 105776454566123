import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, FormLabel, NativeSelect, TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import { generateHeaders, editStep, get, getStepsLogs } from 'actions';
import { useParams } from 'react-router';

function CheckingForm({ label, closeDialog, url, value }) {
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    let active = true;
    get('step-status-types').then((data) => {
      if (active) {
        setStatuses(data);
      }
    });
    return () => (active = false);
  }, []);

  const step = useSelector((state) => state.m_steps.current);
  const { handleSubmit, register, errors } = useForm({ defaultValues: { status_id: step.status_id } });
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);

    data.status_id = value;

    axios({
      url: url,
      method: 'put',
      data: data,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editStep(data));
        dispatch(getStepsLogs(step.id)(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      <FormLabel component="legend">{label}</FormLabel>
      <NativeSelect
        inputProps={{ ref: register({ required: true }) }}
        error={!!errors.status_id}
        disabled
        className="w100 mb"
        value={value}
        name="status_id">
        <option />
        {statuses.map((item) => (
          <option key={item.id} value={item.id}>
            {item.title}
          </option>
        ))}
      </NativeSelect>
      <TextField
        name="comment"
        multiline
        rowsMax="4"
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
        inputRef={register}
        type="text"
        className="mb2"
        fullWidth
      />
      <Button color="primary" variant="contained" type="submit">
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        )}
      </Button>
    </form>
  );
}

export default CheckingForm;
