import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export * from './projects';
export * from './dictionary';
export * from './files';
export * from './steps';
export * from './log';
export * from './search';
export const addDrawerItem = createAction('DRAWER_ITEM_ADD');
export const resetDrawer = createAction('DRAWER_RESET');
export const removeDrawerItem = createAction('DRAWER_ITEM_REMOVE');

export const getActsRequest = createAction('ACTS_GET_REQUEST');
export const getActsSuccess = createAction('ACTS_GET_SUCCESS');
export const getActsFailure = createAction('ACTS_GET_FAILURE');

export const getActs = (page) => async (dispatch) => {
  dispatch(getActsRequest());

  try {
    const { data } = await axios.get(routes.acts(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });
    dispatch(getActsSuccess(data));
  } catch (e) {
    dispatch(getActsFailure());
    console.log(e);
  }
};

export const getAct = (id) => async (dispatch) => {
  dispatch(getActsRequest());

  try {
    const { data } = await axios.get(routes.act(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(
      getActsSuccess({
        items: [data],
        _meta: {
          totalCount: 0,
          pageCount: 0,
          currentPage: 1,
          perPage: 0,
        },
      })
    );
  } catch (e) {
    dispatch(getActsFailure());
    console.log(e);
  }
};

export const getRoadmapsRequest = createAction('ROADMAPS_GET_REQUEST');
export const getRoadmapsSuccess = createAction('ROADMAPS_GET_SUCCESS');
export const getRoadmapsFailure = createAction('ROADMAPS_GET_FAILURE');

export const getRoadmaps = (docId, page) => async (dispatch) => {
  dispatch(getRoadmapsRequest());

  try {
    const filter = `filter[document_id]`;
    const { data } = await axios.get(routes.roadmaps(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { [filter]: docId, page, 'per-page': 10 },
    });
    dispatch(getRoadmapsSuccess(data));
  } catch (e) {
    dispatch(getRoadmapsFailure());
    console.log(e);
  }
};

export const getRoadmap = (id) => async (dispatch) => {
  dispatch(getRoadmapsRequest());

  try {
    const { data } = await axios.get(routes.roadmap(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(
      getRoadmapsSuccess({
        items: [data],
        _meta: {
          totalCount: 0,
          pageCount: 0,
          currentPage: 1,
          perPage: 0,
        },
      })
    );
  } catch (e) {
    dispatch(getRoadmapsFailure());
    console.log(e);
  }
};

export const getStatisticsAllProjects = async (documentId) => {
  try {
    const { data } = await axios.get(routes.statistics(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: {
        document_id: documentId,
      },
    });
    return data;
  } catch (e) {
    console.log();
  }
};
