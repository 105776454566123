import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';

import Form from './Form';
import Dialog from '../../reused/SimpleDialog';
import Table from '../../reused/Table';
import Loader from '../../reused/Loader';
import { useDialog } from '../../../common/hooks';
import { getQuestionnaireManagerInfo } from '../../../actions';
import Download from '../../reused/Download';
import Error from '../../reused/Error';

function Section211() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const manager = useSelector((state) => state.sectionTwoQuestionnaireContentManager.manager, shallowEqual);

  useEffect(() => {
    dispatch(getQuestionnaireManagerInfo());
  }, [dispatch]);

  const managerInfo = Object.values(manager).map((row) => {
    if (row.code_name === 'file') {
      return {
        ...row,
        value: row.value ? <Download fileName={row.value} className="end" /> : '',
      };
    }
    return row;
  });

  const defaultValues = useMemo(() => managerInfo.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}), [managerInfo]);
  const finalRow = useMemo(() => managerInfo.filter((el) => el.code_name !== 'point'), [managerInfo]);

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const requestState = useSelector((state) => state.sectionTwoQuestionnaireContentManagerUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoQuestionnaireContentManager.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return requestState === 'request' ? (
    <Loader />
  ) : (
    <Table
      rows={finalRow}
      title={sectionTabs[selectedTabIndex]?.title}
      head={
        <>
          {isVisible && (
            <Button onClick={handleOpen} color="inherit">
              <CreateIcon />
            </Button>
          )}
          <Dialog
            dialogState={dialogState}
            onClose={handleClose}
            title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
            <Form defaultValues={defaultValues} submitAction={handleClose} />
          </Dialog>
        </>
      }
    />
  );
}

export default Section211;
