import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import Title from './Title';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getPercent } from '../../common/utils';
Chart.register(ChartDataLabels);

export const DoughnutChart = ({ data, title }) => {
  return (
    <div className="doughnut-chart">
      <Title disableBorder title={title} className="mb2" />
      <Doughnut data={data} />
    </div>
  );
};

export const BarChart = ({ data, total }) => {
  const options = {
    legend: {
      labels: {
        fontSize: 18,
      },
    },

    plugins: {
      datalabels: {
        formatter: function (value, context) {
          const total = context.dataset.total[context.dataset.data.indexOf(value)];
          return `${value} (${getPercent(value, total)})`;
        },
        font: {
          weight: 'bold',
          size: 16,
        },
        color: 'white',
      },
    },
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 100,
          stepSize: total / 4,
          callback: function (value) {
            return ((value / this.max) * 100).toFixed(0) + '%'; // convert it to percentage
          },
        },
      },
      x: {
        stacked: true,
      },
    },
  };
  return (
    <div className="bar-chart">
      <Bar data={data} options={options} plugins={[ChartDataLabels]} />
    </div>
  );
};
