import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setSubdivisionError = createAction('SUBDIVISION_ERROR_SET');

export const getDataSubdivisionRequest = createAction('SUBDIVISION_DATA_GET_REQUEST');
export const getDataSubdivisionSuccess = createAction('SUBDIVISION_DATA_GET_SUCCESS');
export const getDataSubdivisionFailure = createAction('SUBDIVISION_DATA_GET_FAILURE');

export const getDataSubdivision = () => async (dispatch) => {
  dispatch(getDataSubdivisionRequest());

  try {
    const { data } = await axios.get(routes.sectionOneSubdivisionData(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const response = await axios.get(routes.sectionOneSubdivisionLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getDataSubdivisionSuccess({ data, labels: response.data }));
  } catch (e) {
    dispatch(setSubdivisionError(e?.response));
  }
};

export const editSubdivisionDataRequest = createAction('SUBDIVISION_DATA_EDIT_REQUEST');
export const editSubdivisionDataSuccess = createAction('SUBDIVISION_DATA_EDIT_SUCCESS');
export const editSubdivisionDataFailure = createAction('SUBDIVISION_DATA_EDIT_FAILURE');

export const editSubdivisionData = (formData) => async (dispatch) => {
  dispatch(editSubdivisionDataRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionOneSubdivisionData(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editSubdivisionDataSuccess());
    dispatch(getDataSubdivision());
  } catch (e) {
    dispatch(setSubdivisionError(e?.response));
  }
};
