import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, Select, TableCell, withStyles } from '@material-ui/core';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useStyles } from 'components/reused/Table';
import { useDialog } from 'common/hooks';
import Dialog from 'components/reused/SimpleDialog';
import Form from 'regions/components/Organization/Form';
import Layout from 'regions/Layout';
import Loader from 'components/reused/Loader';
import { getRegions, getQuestionnaire, setCurrentForm, getRDeadline } from 'actions/regions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import clsx from 'classnames';

function Organization() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getRegions());
    if (region === 'all') {
      dispatch(getQuestionnaire(null, id));
    } else {
      dispatch(getQuestionnaire(region || null, id));
    }
    dispatch(getRDeadline(id));
  }, [id, dispatch]);

  const { regions } = useSelector((state) => state.r_dictionary);
  const fetching = useSelector((state) => state.r_questionnaire_ui.fetchingQuestionnaire);
  const {
    questionnaire: { formLabels, data },
    current,
  } = useSelector((state) => state.r_questionnaire);

  const classes = useStyles();
  const [open, handleOpen, handleClose] = useDialog();

  const currentUser = useSelector((state) => state.profile.currentUser);
  const isRegionObserver = currentUser?.roles.includes('region_observer');
  const { deadline } = useSelector((state) => state.r_dictionary);
  const deadlineConditionForm = new Date(deadline) >= Date.now();

  const openForm = (data) => () => {
    if (!region || region === 'all' || isRegionObserver || !deadlineConditionForm) return;
    if (String(data[0].id).length === 4) {
      const show = formLabels.find((el) => JSON.parse(el.region_type).includes('region'));
      if (!show) return;
    }

    dispatch(setCurrentForm(data));
    handleOpen();
  };

  const [region, setRegion] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setRegion(value);
    if (value === 'all') return dispatch(getQuestionnaire(null, id));
    dispatch(getQuestionnaire(value, id));
  };
  return (
    <Layout>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">Сведения об организации по областям</h2>
        <div className="titleBorder"></div>
      </div>
      <div className="filtrationContainer">
        <FormControl fullWidth>
          <InputLabel>Выберите регион</InputLabel>
          <Select native value={region} onChange={handleChange}>
            <option value="all">Все регионы</option>
            {regions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      {fetching === 'request' ? (
        <Loader />
      ) : (
        <TableContainer component={Paper} className={clsx(classes.container, 'tableScroll')}>
          <Table>
            <TableHead className={clsx('stickyHead')}>
              <TableRow>
                <DashedTableCell align="center">Районы и города</DashedTableCell>
                {formLabels.map((label) => {
                  if (label.hasOwnProperty('children')) {
                    let colSpan = label.children.length;
                    label.children.forEach((l) => {
                      if (l.hasOwnProperty('children')) {
                        colSpan += l.children.length;
                      }
                    });
                    return (
                      <React.Fragment key={label.indicator_id}>
                        <DashedTableCell align="center">
                          {label.title} ({label.unit_title})
                        </DashedTableCell>
                        <DashedTableCell align="center" colSpan={colSpan}>
                          {label.children_title}

                          {label.children.map((l) => {
                            if (l.hasOwnProperty('children')) {
                              return (
                                <React.Fragment key={l.indicator_id}>
                                  <DashedTableCell align="center">
                                    {l.title} ({l.unit_title})
                                  </DashedTableCell>
                                  <DashedTableCell align="center" colSpan={l.children.length}>
                                    {l.children_title}

                                    {l.children.map((child) => (
                                      <DashedTableCell align="center" key={child.indicator_id}>
                                        {child.title} ({child.unit_title})
                                      </DashedTableCell>
                                    ))}
                                  </DashedTableCell>
                                </React.Fragment>
                              );
                            }
                            return (
                              <DashedTableCell align="center" key={l.indicator_id}>
                                {l.title} ({l.unit_title})
                              </DashedTableCell>
                            );
                          })}
                        </DashedTableCell>
                      </React.Fragment>
                    );
                  }
                  return (
                    <DashedTableCell align="center" key={label.indicator_id}>
                      {label.title} ({label.unit_title})
                    </DashedTableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={i}>
                  {row.map((cell, i) => {
                    if (i === 0) {
                      return (
                        <DashedTableCell align="left" key={cell.id}>
                          <Button
                            color="primary"
                            variant="text"
                            size="small"
                            style={{ textAlign: 'left' }}
                            onClick={openForm(row)}>
                            {cell.title}
                          </Button>
                        </DashedTableCell>
                      );
                    }

                    let value = cell.value === null ? null : Number(cell.value);
                    if (cell.data_type === 'BOOLEAN') {
                      value =
                        value === 1 ? (
                          <FormattedMessage id="global.yesTitle" defaultMessage="Да" />
                        ) : (
                          <FormattedMessage id="global.noTitle" defaultMessage="Нет" />
                        );
                    } else {
                      value = cell.value === null ? null : Number(cell.value).toFixed(2);
                    }
                    return (
                      <DashedTableCell align="center" key={cell.indicator_id}>
                        {value}
                      </DashedTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog title={current[0].title} dialogState={open} onClose={handleClose}>
        <Form submitAction={handleClose} />
      </Dialog>
    </Layout>
  );
}

export default Organization;

export const DashedTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    padding: '10px',
    verticalAlign: 'top',
    borderTop: '1px solid',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    '&:first-child': {
      borderRadius: '25px 0 0 25px',
    },
    '&:last-child': {
      borderRadius: '0 25px 25px 0',
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '10px',
    borderBottom: '1px dashed #E4E4E4',
    borderRight: '1px dashed #E4E4E4',
    '&:last-child': {
      borderRight: '0px',
    },
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);
