import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const onlineConsultant = handleActions(
  {
    [actions.getOnlineConsultantInfoSuccess]: (state, { payload: { data, labels } }) => ({
      ...state,
      consultantInfo: data,
      labels,
    }),
    [actions.setOnlineConsultantError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    consultantInfo: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const onlineConsultantUI = handleActions(
  {
    [actions.getOnlineConsultantInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getOnlineConsultantInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getOnlineConsultantInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.editOnlineConsultantInfoRequest]: () => ({ requestState: 'request' }),
    [actions.editOnlineConsultantInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.editOnlineConsultantInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
