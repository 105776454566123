import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { editHelplinesInfo } from '../../../actions';
import { Controller, useForm } from 'react-hook-form';

const Form = ({ submitAction, defaultValues }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch, control } = useForm({ defaultValues });
  const radios = watch();

  const labels = useSelector((state) => state.sectionTwoWebsiteHelpline.labels, shallowEqual);
  const onSubmit = (data) => {
    let reasonReset = {};
    if (radios.has_in_website === '1') reasonReset.reason_website = '';
    if (radios.has_evaluation === '1') reasonReset.reason_evaluation = '';
    if (radios.has_result_evaluation === '1') reasonReset.reason_result = '';
    dispatch(editHelplinesInfo({ ...data, ...reasonReset, section_id: 29, organization_id: localStorage.orgId }));
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField fullWidth className="mb" name="helpline" inputRef={register({ required: true })} error={!!errors.helpline} label={labels.helpline} />
      <div className="mb2">
        <label htmlFor="has_in_website" className={`nativeLabel ${!!errors.has_in_website && 'error'}`}>
          {labels.has_in_website}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_in_website"
          control={control}
          rules={{ required: true }}
        />
        {radios.has_in_website === '0' && (
          <TextField
            fullWidth
            className="mb"
            name="reason_website"
            inputRef={register({ required: true })}
            error={!!errors.reason_website}
            label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
          />
        )}
      </div>
      <div className="mb2">
        <label htmlFor="has_evaluation" className={`nativeLabel ${!!errors.has_evaluation && 'error'}`}>
          {labels.has_evaluation}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_evaluation"
          control={control}
          rules={{ required: true }}
        />
        {radios.has_evaluation === '1' ? (
          <div className="mb2">
            <label htmlFor="has_result_evaluation" className={`nativeLabel ${!!errors.has_result_evaluation && 'error'}`}>
              {labels.has_result_evaluation}
            </label>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value="1"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio color="primary" />}
                    label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                  />
                </RadioGroup>
              }
              name="has_result_evaluation"
              control={control}
              rules={{ required: true }}
            />
            {radios.has_result_evaluation === '0' && (
              <TextField
                fullWidth
                className="mb"
                name="reason_result"
                inputRef={register({ required: true })}
                error={!!errors.reason_evaluation}
                label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
              />
            )}
          </div>
        ) : (
          <TextField
            fullWidth
            className="mb"
            name="reason_evaluation"
            inputRef={register({ required: true })}
            error={!!errors.reason_evaluation}
            label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
          />
        )}
      </div>
      <Button className="mb m0Auto" type="submit" color="primary" variant="outlined">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
