import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const websiteHelpline = handleActions(
  {
    [actions.getHelplinesInfoSuccess]: (state, { payload: { data, labels } }) => {
      return {
        ...state,
        helplineInfo: data,
        labels,
      };
    },
    [actions.setHelplinesInfoError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    helplineInfo: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const websiteHelplineUI = handleActions(
  {
    [actions.getHelplinesInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getHelplinesInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getHelplinesInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.editHelplinesInfoRequest]: () => ({ requestState: 'request' }),
    [actions.editHelplinesInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.editHelplinesInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
