import axios from 'axios';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export const getFiles = async (entity, id) => {
  try {
    const filter = `filter[${entity}]`;
    const { data } = await axios.get(routes.files(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: {
        [filter]: id,
        sort: 'id',
      },
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const generateExcel = async (lang, queries) => {
  try {
    const { data } = await axios({
      url: routes.excel(),
      method: 'post',
      headers: generateHeaders(localStorage.token, lang, localStorage.orgId),
      params: queries,
      responseType: 'blob',
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};
