import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setQuestionnaireManagerInfoError = createAction('QUESTIONNAIRE_MANAGER_INGO_SET_ERROR');

export const getQuestionnaireManagerInfoRequest = createAction('QUESTIONNAIRE_MANAGER_INFO_GET_REQUEST');
export const getQuestionnaireManagerInfoSuccess = createAction('QUESTIONNAIRE_MANAGER_INFO_GET_SUCCESS');
export const getQuestionnaireManagerInfoFailure = createAction('QUESTIONNAIRE_MANAGER_INFO_GET_FAILURE');

export const getQuestionnaireManagerInfo = () => async (dispatch) => {
  dispatch(getQuestionnaireManagerInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoQuestionnaireManager(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoQuestionnaireManagerLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getQuestionnaireManagerInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    console.log('e', e);
    dispatch(setQuestionnaireManagerInfoError(e?.response));
  }
};

export const editQuestionnaireManagerInfoRequest = createAction('QUESTIONNAIRE_MANAGER_INFO_EDIT_REQUEST');
export const editQuestionnaireManagerInfoSuccess = createAction('QUESTIONNAIRE_MANAGER_INFO_EDIT_SUCCESS');
export const editQuestionnaireManagerInfoFailure = createAction('QUESTIONNAIRE_MANAGER_INFO_EDIT_FAILURE');

export const editQuestionnaireManagerInfo = (formData) => async (dispatch) => {
  dispatch(editQuestionnaireManagerInfoRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoQuestionnaireManagerPut(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getQuestionnaireManagerInfo());
    dispatch(editQuestionnaireManagerInfoSuccess());
  } catch (e) {
    dispatch(setQuestionnaireManagerInfoError(e?.response));
    dispatch(editQuestionnaireManagerInfoFailure());
  }
};