import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const spendingStat = handleActions(
  {
    [actions.getSpendingStatSuccess]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      labels: payload.labels,
    }),
    [actions.setSpendingStatError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    labels: {
      quarters: [],
      titles: [],
    },
    data: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const spendingStatUI = handleActions(
  {
    [actions.getSpendingStatRequest]: () => ({ requestState: 'request' }),
    [actions.getSpendingStatSuccess]: () => ({ requestState: 'success' }),
    [actions.getSpendingStatFailure]: () => ({ requestState: 'failure' }),
    [actions.postSpendingStatRequest]: () => ({ requestState: 'request' }),
    [actions.postSpendingStatSuccess]: () => ({ requestState: 'success' }),
    [actions.postSpendingStatFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
