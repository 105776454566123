import React, { useEffect, useState } from 'react';
import Title from 'components/reused/Title';
import { getHelp } from 'actions';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Dashboard from 'components/Dashboard';
import Player from 'video-react/lib/components/Player';
import BigPlayButton from 'video-react/lib/components/BigPlayButton';
import Poster from 'assests/img/poster.png';
import GetAppIcon from '@material-ui/icons/GetApp';

function Help() {
  const [supportInfo, setSupportInfo] = useState([]);
  useEffect(() => {
    let active = true;
    getHelp().then((data) => {
      if (active) setSupportInfo(data);
    });
    return () => active === false;
  }, []);
  return (
    <div>
      <Dashboard>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className="p2">
              {supportInfo.map((el, ind) => {
                if (el.content_type === 'text') {
                  return (
                    <React.Fragment key={ind}>
                      <Title title={el.title} className="mb" />
                      <p dangerouslySetInnerHTML={{ __html: el.description }} className="mb2" />
                    </React.Fragment>
                  );
                } else if (el.content_type === 'video') {
                  return (
                    <React.Fragment key={ind}>
                      <Title title={el.title} className="mb" />
                      <div className="m0Auto mb2">
                        <div className="player">
                          <Player playsInline poster={Poster} src={`https://api.nis.uz/${el.file_link}`}>
                            <BigPlayButton position="center" />
                          </Player>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                } else if (el.content_type === 'file') {
                  return (
                    <a
                      key={ind}
                      href={`https://api.nis.uz/${el.file_link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn mt mb">
                      <div className="btn__fileUpload">
                        <div className="btn-wrap m0Auto">
                          <label className="btn mb">
                            {el.title} &nbsp;
                            <GetAppIcon />
                          </label>
                        </div>
                      </div>
                    </a>
                  );
                }
              })}
            </Paper>
          </Grid>
        </Grid>
      </Dashboard>
    </div>
  );
}

export default Help;
