import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { addAct, editAct, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { labels, orgActs } = useSelector((state) => state.sectionOneOrgActs, shallowEqual);
  const docTypes = useSelector((state) => state.dictionary.documentTypes, shallowEqual);
  const currentAct = useSelector((state) => state.sectionOneOrgActs.currentAct, shallowEqual);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...defaultValues, file: '' },
    mode: 'onChange',
  });

  const titles = orgActs.map((acts) => acts.title);

  const onSubmit = async (data) => {
    if (isEditableMode) {
      if (data.file.length === 0) dispatch(editAct(currentAct.id.value, { ...data, file: defaultValues.file }));
      else {
        const filePath = await uploadFile(data.file, 1.9);
        dispatch(editAct(currentAct.id.value, { ...data, file: filePath }));
      }
    } else {
      const filePath = await uploadFile(data.file, 1.9);
      dispatch(addAct({ ...data, file: filePath }));
    }
    submitAction();
  };

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="addOrganization">
      <TextField
        inputRef={register({ required: true })}
        error={!!errors.document_no}
        className="mb"
        label={labels.document_no}
        name="document_no"
        fullWidth
      />
      <label htmlFor="date" className="nativeLabel">
        {labels.document_date}
      </label>
      <TextField
        inputRef={register({ required: true })}
        error={!!errors.document_date}
        id="date"
        className="mb"
        name="document_date"
        defaultValue={defaultDate}
        type="date"
        fullWidth
      />
      <FormControl fullWidth className="mb">
        <InputLabel id="document_type_id">{labels.document_type_id}</InputLabel>
        <Select
          native
          inputProps={{ ref: register({ required: true }) }}
          error={!!errors.document_type_id}
          name="document_type_id"
          labelId="document_type_id">
          {docTypes.map((docType) => (
            <option value={docType.id} key={docType.id}>
              {docType.title}
            </option>
          ))}
        </Select>
      </FormControl>
      <TextField
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = titles.includes(value);
            return !isValid;
          },
        })}
        error={!!errors.title}
        className="mb"
        label={labels.title}
        name="title"
        fullWidth
        helperText={
          errors.title?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateNames',
            defaultMessage: 'Наименование документа не должно повторяться!',
          })
        }
      />
      <TextField
        inputRef={register({ required: true })}
        error={!!errors.interaction_nature}
        className="mb"
        label={labels.interaction_nature}
        name="interaction_nature"
        fullWidth
      />
      <UploadFile error={!!errors.file} register={register({ required: !isEditableMode })} />
      <Button variant="contained" color="primary" type="submit" className="m0Auto">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
