import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import routes from 'monitoring/routes';
import { useDispatch } from 'react-redux';
import { editCurrentProject, generateHeaders } from 'actions';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';

function CostEffectivenessForm({ labels, closeDialog, defaultValue }) {
  const { handleSubmit, register, errors } = useForm({ defaultValues: { cost_effectiveness: defaultValue } });
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.editProject('change-cost-effectiveness', projectId),
      method: 'put',
      data: data,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editCurrentProject(data));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      <TextField
        inputRef={register({ required: true })}
        name="cost_effectiveness"
        className="mb"
        label={labels.cost_effectiveness}
        error={!!errors.cost_effectiveness}
        fullWidth
      />

      <Button color="primary" variant="contained" type="submit">
        {loading ? <CircularProgress color="inherit" size={30} /> : <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />}
      </Button>
    </form>
  );
}

export default CostEffectivenessForm;
