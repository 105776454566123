import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setPublicServicesError = createAction('PUBLIC_SERVICES_SET_ERROR');

export const getPublicServicesRequest = createAction('PUBLIC_SERVICES_GET_REQUEST');
export const getPublicServicesSuccess = createAction('PUBLIC_SERVICES_GET_SUCCESS');
export const getPublicServicesFailure = createAction('PUBLIC_SERVICES_GET_FAILURE');
export const getPublicServices = (page) => async (dispatch) => {
  dispatch(getPublicServicesRequest());

  try {
    const { data } = await axios.get(routes.sectionThreeInfoPublicServices(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { page },
    });

    dispatch(getPublicServicesSuccess(data));
  } catch (e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(getPublicServicesFailure());
  }
};

export const getPublicServiceLabelsSuccess = createAction('SERVICE_LABELS_GET_SUCCESS');
export const getPublicServiceLabelsRequest = createAction('SERVICE_LABELS_GET_REQUEST');
export const getPublicServiceLabelsFailure = createAction('SERVICE_LABELS_GET_FAILURE');
export const getPublicServiceLabels = () => async (dispatch) => {
  dispatch(getPublicServiceLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionThreeInfoPublicServiceLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getPublicServiceLabelsSuccess(data));
  } catch(e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(getPublicServicesFailure());
  };
};

export const getPublicElementRequest = createAction('PUBLIC_ELEMENT_GET_REQUEST');
export const getPublicElementSuccess = createAction('PUBLIC_ELEMENT_GET_SUCCESS');
export const getPublicElementFailure = createAction('PUBLIC_ELEMENT_GET_FAILURE');

export const getPublicElement = (id) => async (dispatch) => {
  dispatch(getPublicElementRequest());

  try {
    const { data } = await axios.get(routes.sectionThreeInfoPublicServicesOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getPublicElementSuccess(data));
  } catch (e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(getPublicElementFailure());
  }
};

export const deletePublicElementRequest = createAction('PUBLIC_ELEMENT_DELETE_REQUEST');
export const deletePublicElementSuccess = createAction('PUBLIC_ELEMENT_DELETE_SUCCESS');
export const deletePublicElementFailure = createAction('PUBLIC_ELEMENT_DELETE_FAILURE');

export const deletePublicElement = (id) => async (dispatch) => {
  dispatch(deletePublicElementRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionThreeInfoPublicServicesOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deletePublicElementSuccess({ id }));
  } catch (e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(deletePublicElementFailure());
  }
};

export const editPublicElementRequest = createAction('PUBLIC_ELEMENT_EDIT_REQUEST');
export const editPublicElementSuccess = createAction('PUBLIC_ELEMENT_EDIT_SUCCESS');
export const editPublicElementFailure = createAction('PUBLIC_ELEMENT_EDIT_FAILURE');

export const editPublicElement = (id, formData) => async (dispatch) => {
  dispatch(editPublicElementRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionThreeInfoPublicServicesOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editPublicElementSuccess(data));
    dispatch(getPublicElement(id));
  } catch (e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(editPublicElementFailure());
  }
};

export const addPublicElementRequest = createAction('PUBLIC_ELEMENT_ADD_REQUEST');
export const addPublicElementSuccess = createAction('PUBLIC_ELEMENT_ADD_SUCCESS');
export const addPublicElementFailure = createAction('PUBLIC_ELEMENT_ADD_FAILURE');

export const addPublicElement = (formData) => async (dispatch) => {
  dispatch(addPublicElementRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionThreeInfoPublicServices(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addPublicElementSuccess(data));
  } catch (e) {
    dispatch(setPublicServicesError(e?.response));
    dispatch(addPublicElementFailure());
  }
};

