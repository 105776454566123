import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const cyberSecurity = handleActions(
  {
    [actions.getCyberSecurityInfoSuccess]: (state, { payload }) => ({ ...state, cyberSecurityInfo: payload }),
    [actions.getCyberSecurityEvaluationSuccess]: (state, { payload }) => ({
      ...state,
      evaluation: { data: payload.evaluation, alert: payload.alert },
    }),
    [actions.setCyberSecurityError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    cyberSecurityInfo: [],
    evaluation: {
      data: {},
      alert: '',
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const cyberSecurityUI = handleActions(
  {
    [actions.getCyberSecurityInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getCyberSecurityInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getCyberSecurityInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
