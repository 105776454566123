import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, useHistory } from 'react-router-dom';
import { Avatar, Container, IconButton, MenuItem } from '@material-ui/core';

import Logo from '../../assests/img/blue-logo.svg';
import { StyledMenu } from './styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { changeLang, logoutSystem, setApp } from '../../actions';
import { LOCALES } from '../../lang/locales';

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const currentApp = useSelector((state) => state.app.currentApp, shallowEqual);
  const apps = useSelector((state) => state.app.list, shallowEqual);
  const locale = useSelector((state) => state.language.locale, shallowEqual);
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    localStorage.setItem('lang', e.target.value);
    dispatch(changeLang(e.target.value));
    window.location.reload();
  };

  const handleLogout = () => {
    history.replace('/sign-in');
    dispatch(logoutSystem());
    sessionStorage.clear();
  };

  const selectApp = (app) => () => {
    dispatch(setApp(app));
    history.replace('/');
  };

  const toSupportPage = () => {
    history.push('/support');
  };

  let title = '';
  if (currentApp === 'nis') {
    title = (
      <FormattedMessage
        defaultMessage="Рейтинговая оценка широкого использования ИКТ в органах государственного и хозяйственного управления"
        id="homeHeader.headerTitle"
      />
    );
  } else if (currentApp === 'monitoring') {
    title = (
      <FormattedMessage
        defaultMessage='МОНИТОРИНГ ПРОЕКТОВ СТРАТЕГИИ "ЦИФРОВОЙ УЗБЕКИСТАН - 2030"'
        id="monitoringHomeHeader.headerTitle"
      />
    );
  } else if (currentApp === 'region') {
    title = (
      <FormattedMessage defaultMessage="РЕЙТИНГОВАЯ ОЦЕНКА ЦИФРОВИЗАЦИИ РЕГИОНОВ" id="regionHomeHeader.headerTitle" />
    );
  }
  return (
    <Container maxWidth="xl">
      <div className="homeHeader">
        <img src={Logo} alt="logo" />

        <h1 className="mainTitle">{title}</h1>
        <div className="homeHeader__rightSide">
          <div className="avatarContainer">
            <span onClick={handleMenu}>{currentUser?.username}</span>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              style={{ padding: 10 }}
              color="inherit">
              <Avatar />
            </IconButton>
            <StyledMenu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              elevation={0}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              {apps.length > 1 &&
                apps.map((app) => (
                  <MenuItem key={app.platform} onClick={selectApp(app.platform)}>
                    {app.title}
                  </MenuItem>
                ))}
              <MenuItem onClick={toSupportPage}>
                <FormattedMessage id="drawerList.support" defaultMessage="Справка" />
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <FormattedMessage id="global.logout" defaultMessage="Выйти" />
              </MenuItem>
            </StyledMenu>
          </div>
          <select name="language" className="nativeSelect" onChange={handleChange} value={locale}>
            <option value={LOCALES.RUS}>
              {intl.formatMessage({
                id: 'signIn.languageRusOptionTitle',
                defaultMessage: 'Русский',
              })}
            </option>
            <option value={LOCALES.UZB}>
              {intl.formatMessage({
                id: 'signIn.languageUzbOptionTitle',
                defaultMessage: 'Узбекский',
              })}
            </option>
          </select>
        </div>
      </div>
    </Container>
  );
}

export default Header;
