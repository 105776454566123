import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';

import Form from './Form';
import Table from '../../reused/Table';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { IconButton } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
  deleteSimpleAssessment,
  getEvalActivationInfo,
  getEvaluations,
  getHelplinesInfo,
  getSimpleAssessment,
  saveSimpleAssessment,
  toggleTabState,
  updateEvalActivationInfo,
} from '../../../actions';
import Loader from '../../reused/Loader';
import ExpertAssessment from '../../reused/ExpertAssessment';
import EvaluationActivation from '../../reused/EvaluationActivation';
import Error from '../../reused/Error';

export default function Section210() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHelplinesInfo());
    dispatch(getSimpleAssessment(29));
    dispatch(getEvaluations(29));
    dispatch(getEvalActivationInfo(29));
  }, [dispatch]);
  const { helplineInfo, labels } = useSelector((state) => state.sectionTwoWebsiteHelpline, shallowEqual);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-cep');
  const isOrganization = currentUser?.roles.includes('organization');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const helplineInfoValues = helplineInfo ? Object.values(helplineInfo) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }));

  const filteredHelplineInfo = useMemo(
    () =>
      Object.values(helplineInfoValues).filter((el) => {
        if (helplineInfo) {
          if (el.code_name === 'reason_website') return helplineInfo.has_in_website.value === '0';
          if (el.code_name === 'reason_evaluation') return helplineInfo.has_evaluation.value === '0';
          if (el.code_name === 'has_result_evaluation') return helplineInfo.has_evaluation.value === '1';
          if (el.code_name === 'reason_result') return helplineInfo.has_evaluation.value === '1' && helplineInfo.has_result_evaluation.value === '0';
        }

        return !['is_correctly', 'comment', 'point'].includes(el.code_name);
      }),
    [helplineInfo, helplineInfoValues]
  );

  const defaultValues = useMemo(
    () => Object.values(helplineInfoValues).reduce((acc, el) => ({ ...acc, [el.code_name]: el.value ? `${el.value}` : '' }), {}),
    [helplineInfoValues]
  );

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const { tabs, evaluations } = useSelector((state) => state.dictionary, shallowEqual);
  const { assessment } = useSelector((state) => state.simpleEvaluation, shallowEqual);
  const { requestAssessmentState } = useSelector((state) => state.simpleEvaluationUI, shallowEqual);

  const requestState = useSelector((state) => state.sectionTwoWebsiteHelplineUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoWebsiteHelpline.error, shallowEqual);

  const handleSubmitAssessment = (data) => dispatch(saveSimpleAssessment(29, data));
  const handleDeleteAssessment = () => dispatch(deleteSimpleAssessment(29));
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(29, data));
    dispatch(getSimpleAssessment(29));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return requestState === 'request' ? (
    <Loader />
  ) : (
    <>
      <Table
        rows={filteredHelplineInfo}
        title={tabs[selectedTabIndex]?.title}
        head={
          <>
            {isVisibleOrg && (
              <IconButton onClick={handleOpen} color="inherit">
                <CreateIcon />
              </IconButton>
            )}

            <Dialog
              dialogState={dialogState}
              onClose={handleClose}
              title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
              <Form submitAction={handleClose} defaultValues={defaultValues} />
            </Dialog>
          </>
        }
      />
      {requestAssessmentState === 'request' ? (
        <Loader />
      ) : (
        <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="w30 mAuto mb4">
          <div className="w50 m0Auto">
            <ExpertAssessment
              assessment={assessment}
              onSubmit={handleSubmitAssessment}
              onDelete={handleDeleteAssessment}
              evaluations={evaluations}
              isExpert={isVisibleExpert}
            />
          </div>
        </EvaluationActivation>
      )}
    </>
  );
}
