import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoIctDivisionError = createAction('INFO_DIVISION_SET_ERROR');

export const getInfoDivisionRequest = createAction('DIVISIONS_INFO_GET_REQUEST');
export const getInfoDivisionSuccess = createAction('DIVISIONS_INFO_GET_SUCCESS');
export const getInfoDivisionFailure = createAction('DIVISIONS_INFO_GET_FAILURE');

export const getInfoDivision = () => async (dispatch) => {
  dispatch(getInfoDivisionRequest());

  try {
    const { data } = await axios.get(routes.sectionSixInfoIctDivisions(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionSixInfoIctDivisionLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getInfoDivisionSuccess({ data, labels: labels.data }));
  } catch (e) {
    console.log(e);
    dispatch(setInfoIctDivisionError(e?.response));
    dispatch(getInfoDivisionFailure());
  }
};

export const editInfoDivisionRequest = createAction('INFO_DIVISION_ADD_REQUEST');
export const editInfoDivisionSuccess = createAction('INFO_DIVISION_ADD_SUCCESS');
export const editInfoDivisionFailure = createAction('INFO_DIVISION_ADD_FAILURE');

export const editInfoDivision = (formData) => async (dispatch) => {
  dispatch(editInfoDivisionRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionSixInfoIctDivisions(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getInfoDivision());
  } catch (e) {
    dispatch(setInfoIctDivisionError(e?.response));
    dispatch(editInfoDivisionFailure());
  }
};
