import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Select,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import Search from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import Title from 'components/reused/Title';
import SimpleDialog from 'components/reused/SimpleDialog';
import Loader from 'components/reused/Loader';
import Autocomplete from 'components/reused/Autocomplete';
import Layout from 'regions/Layout';
import Form from './Form';
import { useDialog } from 'common/hooks';
import { rApiExcludeIndicator } from 'actions/regions';
import routes from 'regions/routes';

import Empty from 'components/reused/Empty';
import { useStyles, StyledTableCell, StyledTableRow } from 'components/reused/Table';
import axios from 'axios';
import { generateHeaders } from 'actions/dictionary';
import { useForm } from 'react-hook-form';

function ExcludeIndicators() {
  const [indicatorsView, setIndicatorsView] = useState({ items: [], _meta: {} });
  const [loader, setLoader] = useState(false);

  const [enums, setEnums] = useState({ regions: [], districts: [], indicators: [], types: [] });

  useEffect(() => {
    setLoader(true);

    rApiExcludeIndicator(routes.excludedIndicator('index'), 'get').then((data) => {
      setIndicatorsView(data);
      setLoader(false);
    });

    rApiExcludeIndicator(routes.excludedIndicator('regions'), 'get').then((data) =>
      setEnums((prevState) => ({ ...prevState, regions: data }))
    );

    rApiExcludeIndicator(routes.excludedIndicator('indicators'), 'get', { region_type: 'region' }).then((data) =>
      setEnums((prevState) => ({ ...prevState, indicators: data }))
    );

    rApiExcludeIndicator(routes.excludedIndicator('districts'), 'get').then((data) =>
      setEnums((prevState) => ({ ...prevState, districts: data }))
    );

    axios
      .get(routes.excludedIndicator('region-types'), {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      })
      .then((res) => setEnums((prevState) => ({ ...prevState, types: Object.entries(res.data) })));
  }, []);

  const { handleSubmit, watch, register, control, setValue, reset } = useForm();

  const allValues = watch();

  const [page, setPage] = useState(1);

  const handleChangeRegion = async (value) => {
    const data = await rApiExcludeIndicator(routes.excludedIndicator('districts'), 'get', {
      region_id: value ? value.id : undefined,
    });
    setEnums((prevState) => ({ ...prevState, districts: data }));
  };

  const handleChangeType = (e) => {
    rApiExcludeIndicator(routes.excludedIndicator('indicators'), 'get', {
      region_type: e.target.value,
    }).then((data) => setEnums((prevState) => ({ ...prevState, indicators: data })));
  };

  const onSubmit = async (formValues) => {
    setLoader(true);

    const filter = formatValues(formValues);

    await rApiExcludeIndicator(routes.excludedIndicator('index'), 'get', filter).then((data) =>
      setIndicatorsView(data)
    );

    setPage(1);
    setLoader(false);
  };

  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const [indicatorDeleteId, setIndicatorDeleteId] = useState(null);

  const handleModalDelete = (id) => () => {
    handleOpenDelete();
    setIndicatorDeleteId(id);
  };

  const handleDelete = async () => {
    setLoader(true);
    handleCloseDelete();
    await rApiExcludeIndicator(routes.excludedIndicator('delete'), 'post', null, { id: indicatorDeleteId });

    const filter = formatValues(allValues);

    const data = await rApiExcludeIndicator(routes.excludedIndicator('index'), 'get', { page, ...filter });
    setIndicatorsView(data);

    setLoader(false);
  };

  const handleChangePage = async (_, value) => {
    setLoader(true);
    setPage(value);

    const filter = formatValues(allValues);
    filter.page = value;

    const data = await rApiExcludeIndicator(routes.excludedIndicator('index'), 'get', filter);
    setIndicatorsView(data);

    setLoader(false);
  };

  const [editIndicatorsState, openEditIndicators, closeEditIndicators] = useDialog();

  const classes = useStyles();

  const list = indicatorsView.items;
  const pagination = indicatorsView._meta;

  const structuredRegions = enums.regions.reduce(
    (acc, el) => ({ ...acc, [el.id]: { id: el.id.toString(), title: el.title } }),
    {}
  );

  const groupedDistricts = enums.districts
    .map((el) => {
      const region = el.id.toString().substring(0, 4);
      const regionTitle = structuredRegions[region]?.title;
      return {
        regionTitle,
        ...el,
      };
    })
    .sort((a, b) => a.id - b.id);

  return (
    <Layout>
      <Title title="Исключение индикаторов" className="mb" />

      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TableContainer component={Paper} className={classes.container}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={style} align="center">
                    Название региона
                  </TableCell>
                  <TableCell style={style} align="center">
                    Название района
                  </TableCell>
                  <TableCell style={style} align="center">
                    Тип индикатора
                  </TableCell>
                  <TableCell style={style} align="center">
                    Название индикатора
                  </TableCell>
                  <TableCell style={style} align="center">
                    Причина
                  </TableCell>
                  <TableCell style={style} align="center">
                    <IconButton color="primary" onClick={openEditIndicators}>
                      <AddCircleOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={style} align="center">
                    <Autocomplete
                      defaultValue={null}
                      isAutocomplete={false}
                      control={control}
                      name="region_id"
                      options={enums.regions}
                      onSelect={handleChangeRegion}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                  <TableCell style={style} align="center">
                    <Autocomplete
                      defaultValue={null}
                      isAutocomplete={false}
                      control={control}
                      name="district_id"
                      style={{ minWidth: 200 }}
                      options={groupedDistricts}
                      groupBy={(option) => option.regionTitle}
                      getOptionLabel={(option) => option.title}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>

                  <TableCell style={{ ...style, paddingTop: 0 }} align="center">
                    <Select
                      native
                      name="region_type"
                      inputRef={register()}
                      style={{ width: 100 }}
                      onChange={handleChangeType}>
                      <option value=""></option>
                      {enums.types.map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </Select>
                  </TableCell>

                  <TableCell style={style} align="center">
                    <Autocomplete
                      defaultValue={null}
                      isAutocomplete={false}
                      control={control}
                      name="indecator_id"
                      options={enums.indicators}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.id === value.id}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </TableCell>
                  <TableCell style={style} align="center" />
                  <TableCell style={style} align="center">
                    <IconButton color="primary" type="submit">
                      <Search />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loader ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan="5">
                      <div className="dFJustifyFxCenter alignItemsCenter" style={{ height: 250 }}>
                        <Loader />
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  list.map((indicator) => (
                    <StyledTableRow key={indicator.id}>
                      <StyledTableCell align="center">{indicator.region_title}</StyledTableCell>
                      <StyledTableCell align="center">{indicator.district_title}</StyledTableCell>
                      <StyledTableCell align="center">{indicator.region_type}</StyledTableCell>
                      <StyledTableCell align="center">{indicator.indicator_title}</StyledTableCell>
                      <StyledTableCell align="center">{indicator.reason}</StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton edge="end" color="secondary" onClick={handleModalDelete(indicator.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
                {list.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell colSpan="5">
                      <Empty title="Данных нет" />
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
        <SimpleDialog title="Подтвердить удаление" dialogState={openDelete} onClose={handleCloseDelete}>
          <Button className="mr ml mt mb" variant="contained" color="primary" onClick={handleCloseDelete}>
            Закрыть
          </Button>
          <Button className="mr ml mt mb" variant="contained" color="secondary" onClick={handleDelete}>
            Удалить?
          </Button>
        </SimpleDialog>
        {pagination.pageCount > 1 && (
          <div className="dFJustifyFxCenter mt2">
            <Pagination
              count={pagination.pageCount}
              page={page}
              onChange={handleChangePage}
              siblingCount={2}
              variant="outlined"
              color="primary"
            />
          </div>
        )}
      </div>
      <SimpleDialog
        dialogState={editIndicatorsState}
        onClose={closeEditIndicators}
        disableBackdropClick
        title="Добавление исключения индикатора">
        <Form
          closeDialog={closeEditIndicators}
          setIndicatorsView={setIndicatorsView}
          regions={enums.regions}
          regionId={allValues.region_id?.id}
          setType={(value) => {
            reset();
            setValue('region_type', value);
          }}
          setPage={setPage}
        />
      </SimpleDialog>
    </Layout>
  );
}

export default ExcludeIndicators;

const style = {
  backgroundColor: '#F0F2FF',
  color: '#4656B8',
  borderBottom: 0,
  borderRadius: 0,
};

const formatValues = (values) => {
  const filter = {};

  Object.keys(values).forEach((key) => {
    if (values[key] instanceof Object) {
      filter[key] = values[key].id;
    } else {
      filter[key] = values[key];
    }
  });

  return filter;
};
