import { combineReducers } from 'redux';
import { loginUI, login } from './login';
import { profile, profileUI } from './profile';
import { fileUpload } from './fileUpload';
import { dictionary, dictionaryUI } from './dictionary';
import { orgInfo, orgInfoUI } from './sectionOne/orgInfo';
import { orgLegalBasis, orgLegalBasisUI } from './sectionOne/orgLegalBasis';
import { orgActs, orgActsUI } from './sectionOne/orgActs';
import { employeesStat, employeesStatUI } from './sectionOne/employeesStat';
import { orgStructuralSubdivision, orgStructuralSubdivisionUI } from './sectionOne/orgStructuralSubdivision';
import { orgSubordinateDivision, orgSubordinateDivisionUI } from './sectionOne/orgSubordinateDivision';
import { orgInfoIctManager, orgInfoIctManagerUI } from './sectionOne/orgInfoIctManager';
import { websiteApps, websiteAppsUI } from './sectionTwo/websiteApps';
import { websiteContentManager, websiteContentManagerUI } from './sectionTwo/websiteContentManager';
import { questionnaireContentManager, questionnaireContentManagerUI } from './sectionTwo/questionnaireContentManager';
import { websiteAccess, websiteAccessUI } from './sectionTwo/websiteAccess';
import { linksSocialPages, linksSocialPagesUI } from './sectionTwo/linksSocialPages';
import { linksMessengerChannels, linksMessengerChannelsUI } from './sectionTwo/linksMessengerChannels';
import { websiteOpenData, websiteOpenDataUI } from './sectionTwo/websiteOpenData';
import { processingActs, processingActsUI } from './sectionTwo/processingActs';
import { onlineConsultant, onlineConsultantUI } from './sectionTwo/onlineConsultant';
import { websiteHelpline, websiteHelplineUI } from './sectionTwo/websiteHelpline';
import { websiteComplianceRequirements, websiteComplianceRequirementsUI } from './sectionTwo/websiteCompliance';
import { infoPublicServices, infoPublicServicesUI } from './sectionThree/infoPublicServices';
import { epiguStats, epiguStatsUI } from './sectionThree/epiguStats';
import { epiguServicesInfo, epiguServicesInfoUI } from './sectionThree/epiguServicesInfo';
import { cyberSecurity, cyberSecurityUI } from './cyberSecurity';
import { computers, computersUI } from './sectionSeven/computers';
import { spendingStat, spendingStatUI } from './sectionEight/spendingStat';
import { sourceStat, sourceStatUI } from './sectionEight/sourceStat';
import { infoSystems, infoSystemsUI } from './sectionFive/infoSystems';
import { statTechnoprocesses, statTechnoprocessesUI } from './sectionFive/statTechnoprocesses';
import { infoGovernmentProject, infoGovernmentProjectUI } from './sectionSix/infoGovernmentProject';
import { organizationDigitalStrategy, organizationDigitalStrategyUI } from './sectionSix/organizationDigitalStrategy';
import { infoIctDivision, infoIctDivisionUI } from './sectionSix/infoIctDivision';
import { organizationHackathon, organizationHackathonUI } from './sectionSix/organizationHackathon';
import { sectionSixEvaluation } from './sectionSix/sectionSixEvaluation';
import { organizationServers, organizationServersUI } from './sectionSeven/organizationServers';
import { infoIctFounds, infoIctFoundsUI } from './sectionEight/infoIctFounds';
import { tabs } from './tabs';
import { language } from './language';
import { errorHandler } from './errorHandler';
import { complexEvaluation } from './complexEvaluation';
import { simpleEvaluation, simpleEvaluationUI } from './simpleEvaluation';
import { evaluationActivation, evaluationActivationUI } from './evaluationActivation';
import { app } from './app';
import { regionDictionary, regionDictionaryUI } from './regions/dictionary';
import { questionnaire, questionnaireUI } from './regions/questionnaire';
import { documents, documentsUI } from './monitoring/documents';
import { drawer } from './monitoring/drawer';
import { projects } from './monitoring/projects';
import { steps } from './monitoring/steps';
import { log } from './monitoring/log';
import { searchProjects, searchProjectsUI } from './monitoring/search';

export default combineReducers({
  app,
  r_dictionary: regionDictionary,
  r_dictionary_ui: regionDictionaryUI,
  r_questionnaire: questionnaire,
  r_questionnaire_ui: questionnaireUI,
  m_documents: documents,
  m_documentsUI: documentsUI,
  m_drawer: drawer,
  m_projects: projects,
  m_steps: steps,
  m_log: log,
  m_search: searchProjects,
  m_search_ui: searchProjectsUI,
  login,
  loginUI,
  profile,
  profileUI,
  fileUpload,
  errorHandler,
  tabs,
  language,
  dictionary,
  dictionaryUI,
  evaluationActivation,
  evaluationActivationUI,
  complexEvaluation,
  simpleEvaluation,
  simpleEvaluationUI,
  sectionOneOrgInfo: orgInfo,
  sectionOneOrgInfoUI: orgInfoUI,
  sectionOneOrgLegalBasis: orgLegalBasis,
  sectionOneOrgLegalBasisUI: orgLegalBasisUI,
  sectionOneOrgActs: orgActs,
  sectionOneOrgActsUI: orgActsUI,
  sectionOneSubdivision: orgStructuralSubdivision,
  sectionOneSubdivisionUI: orgStructuralSubdivisionUI,
  sectionOneSubordinateDivision: orgSubordinateDivision,
  sectionOneSubordinateDivisionUI: orgSubordinateDivisionUI,
  sectionOneEmployeesStat: employeesStat,
  sectionOneEmployeesStatUI: employeesStatUI,
  sectionOneInfoIctManager: orgInfoIctManager,
  sectionOneInfoIctManagerUI: orgInfoIctManagerUI,
  sectionTwoWebsiteApps: websiteApps,
  sectionTwoWebsiteAppsUI: websiteAppsUI,
  sectionTwoWebsiteContentManager: websiteContentManager,
  sectionTwoWebsiteContentManagerUI: websiteContentManagerUI,
  sectionTwoQuestionnaireContentManager: questionnaireContentManager,
  sectionTwoQuestionnaireContentManagerUI: questionnaireContentManagerUI,
  sectionTwoWebsiteAccess: websiteAccess,
  sectionTwoWebsiteAccessUI: websiteAccessUI,
  sectionTwoWebsiteComplianceRequirements: websiteComplianceRequirements,
  sectionTwoWebsiteComplianceRequirementsUI: websiteComplianceRequirementsUI,
  sectionTwoLinksSocialPages: linksSocialPages,
  sectionTwoLinksSocialPagesUI: linksSocialPagesUI,
  sectionTwoLinksChannels: linksMessengerChannels,
  sectionTwoLinksChannelsUI: linksMessengerChannelsUI,
  sectionTwoWebsiteOpenData: websiteOpenData,
  sectionTwoWebsiteOpenDataUI: websiteOpenDataUI,
  sectionTwoOnlineConsultant: onlineConsultant,
  sectionTwoOnlineConsultantUI: onlineConsultantUI,
  sectionTwoWebsiteHelpline: websiteHelpline,
  sectionTwoWebsiteHelplineUI: websiteHelplineUI,
  sectionTwoProcessingActs: processingActs,
  sectionTwoProcessingActsUI: processingActsUI,
  sectionThreeInfoPublicServices: infoPublicServices,
  sectionThreeInfoPublicServicesUI: infoPublicServicesUI,
  sectionThreeEpiguStats: epiguStats,
  sectionThreeEpiguStatsUI: epiguStatsUI,
  sectionThreeEpiguServicesInfo: epiguServicesInfo,
  sectionThreeEpiguServicesInfoUI: epiguServicesInfoUI,
  sectionFiveInfoSystems: infoSystems,
  sectionFiveInfoSystemsUI: infoSystemsUI,
  sectionFiveStateTechnoprocess: statTechnoprocesses,
  sectionFiveStateTechnoprocessUI: statTechnoprocessesUI,
  sectionSixInfoGovernmentProject: infoGovernmentProject,
  sectionSixInfoGovernmentProjectUI: infoGovernmentProjectUI,
  sectionSixOrgDigitalStrategy: organizationDigitalStrategy,
  sectionSixOrgDigitalStrategyUI: organizationDigitalStrategyUI,
  sectionSixInfoIctDivisions: infoIctDivision,
  sectionSixInfoIctDivisionsUI: infoIctDivisionUI,
  sectionSixOrgHackathon: organizationHackathon,
  sectionSixOrgHackathonUI: organizationHackathonUI,
  sectionSixEvaluation: sectionSixEvaluation,
  sectionSevenOrganizationServers: organizationServers,
  sectionSevenOrganizationServersUI: organizationServersUI,
  sectionEightInfoIctFounds: infoIctFounds,
  sectionEightInfoIctFoundsUI: infoIctFoundsUI,
  cyberSecurity: cyberSecurity,
  cyberSecurityUI: cyberSecurityUI,
  sectionSevenComputers: computers,
  sectionSevenComputersUI: computersUI,
  sectionEightSpendingStat: spendingStat,
  sectionEightSpendingStatUI: spendingStatUI,
  sectionEightSourceStat: sourceStat,
  sectionEightSourceStatUI: sourceStatUI,
});
