import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Switch, useHistory, Route } from 'react-router-dom';

import Layout from './Layout';
import Acts from 'monitoring/Lists/Acts';
import Roadmaps from 'monitoring/Lists/Roadmaps';
import ProjectList from 'monitoring/Lists/ProjectList';
import Project from 'monitoring/Project';
import Search from 'monitoring/Search';
import Support from 'components/reused/Support';

function App() {
  const history = useHistory();
  const fetchingProfile = useSelector((state) => state.profileUI.requestingState, shallowEqual);
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);

  useEffect(() => {
    if (currentUser && currentUser.roles.includes('monitoring_organization')) {
      localStorage.setItem('orgId', currentUser.organization.id);
    } else {
      localStorage.removeItem('orgId');
    }

    if (fetchingProfile === 'failure') {
      history.push('/sign-in');
      localStorage.removeItem('token');
      localStorage.removeItem('orgId');
      localStorage.removeItem('orgTitle');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingProfile]);

  return (
    <Switch>
      <Route path="/" exact>
        <Layout>
          <Acts />
        </Layout>
      </Route>
      <Route path="/search" exact>
        <Layout>
          <Search />
        </Layout>
      </Route>
      <Route path="/search/projects/:projectId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/search/projects/:projectId/step-status/:stepStatusId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id" exact>
        <Layout>
          <Roadmaps />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId" exact>
        <Layout>
          <ProjectList />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId/status/:statusId" exact>
        <Layout>
          <ProjectList />
        </Layout>
      </Route>
      <Route path="/acts/:id/status/:statusId" exact>
        <Layout>
          <ProjectList />
        </Layout>
      </Route>
      <Route path="/acts/:id/status/:statusId/projects/:projectId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId/projects/:projectId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId/status/:statusId/projects/:projectId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id/status/:statusId/projects/:projectId/step-status/:stepStatusId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId/projects/:projectId/step-status/:stepStatusId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/acts/:id/roadmaps/:roadmapId/status/:statusId/projects/:projectId/step-status/:stepStatusId" exact>
        <Layout>
          <Project />
        </Layout>
      </Route>
      <Route path="/support" exact>
        <Support />
      </Route>
    </Switch>
  );
}

export default App;
