import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { editOnlineConsultantInfo } from '../../../actions';

const Form = ({ submitAction, defaultValues }) => {
  const { register, handleSubmit, control, watch, errors } = useForm({ defaultValues });
  const dispatch = useDispatch();
  const radios = watch();
  const labels = useSelector((state) => state.sectionTwoOnlineConsultant.labels, shallowEqual);
  const onSubmit = (data) => {
    const reasonReset = {};
    if (radios.has_online_consultant === '1') reasonReset.reason_consultant = '';
    if (radios.is_working === '1') reasonReset.reason_working = '';
    if (radios.is_frequently === '1') reasonReset.reason_frequently = '';
    dispatch(editOnlineConsultantInfo({ ...data, ...reasonReset, section_id: 28, organization_id: localStorage.orgId }));
    submitAction();
  };

  return (
    <form className="pt2" onSubmit={handleSubmit(onSubmit)}>
      <div className="mb2">
        <label htmlFor="has_online_consultant" className={`nativeLabel ${!!errors.has_online_consultant && 'error'}`}>
          {labels.has_online_consultant}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="has_online_consultant"
          control={control}
          rules={{ required: true }}
        />
        {radios.has_online_consultant === '1' && (
          <>
            <div className="mb2">
              <label htmlFor="is_working" className={`nativeLabel ${!!errors.is_working && 'error'}`}>
                {labels.is_working}
              </label>
              <Controller
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="is_working"
                control={control}
                rules={{ required: true }}
              />
              {radios.is_working === '0' && (
                <TextField
                  fullWidth
                  className="mb"
                  name="reason_working"
                  inputRef={register({ required: true })}
                  error={!!errors.reason_working}
                  label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
                />
              )}
            </div>
            <div className="mb2">
              <label htmlFor="is_frequently" className={`nativeLabel ${!!errors.is_frequently && 'error'}`}>
                {labels.is_frequently}
              </label>
              <Controller
                as={
                  <RadioGroup row>
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                    />
                  </RadioGroup>
                }
                name="is_frequently"
                control={control}
                rules={{ required: true }}
              />
              {radios.is_frequently === '0' && (
                <TextField
                  fullWidth
                  className="mb"
                  name="reason_frequently"
                  inputRef={register({ required: true })}
                  error={!!errors.reason_frequently}
                  label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
                />
              )}
            </div>
          </>
        )}
        {radios.has_online_consultant === '0' && (
          <TextField
            fullWidth
            className="mb"
            name="reason_consultant"
            inputRef={register({ required: true })}
            error={!!errors.reason_consultant}
            label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
          />
        )}
      </div>

      <Button className="mb" type="submit" color="primary" variant="contained">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
