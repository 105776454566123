import axios from 'axios';
import { generateHeaders } from 'actions/dictionary';

export const rApiExcludeIndicator = async (url, method, params, requestData = {}) => {
  try {
    const {
      data: { result },
    } = await axios({
      url,
      method,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: params ? params : null,
      data: requestData ? requestData : null,
    });
    return result;
  } catch (e) {
    return e;
  }
};
