import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { addHackathon, editHackathon } from '../../../actions';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const dispatch = useDispatch();
  const { labels, id } = useSelector((state) => state.sectionSixOrgHackathon, shallowEqual);

  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());

  const onSubmit = (data) => {
    if (isEditableMode) {
      dispatch(editHackathon(id, data));
    } else {
      dispatch(addHackathon(data));
    }
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.event_title}
        name="event_title"
        label={labels.event_title}
        className="mb"
      />
      <div className="dFlex flexDirectionCol">
        <label htmlFor="date" className="nativeLabel">
          {labels.event_date}
        </label>
        <TextField
          inputRef={register({ required: true })}
          error={!!errors.event_date}
          name="event_date"
          id="date"
          type="date"
          defaultValue={defaultDate}
          className="mb"
        />
      </div>

      <Button variant="outlined" color="primary" className="m0Auto" type="submit">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default Form;
