import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';

import Form from './Form';
import Table from '../../reused/Table';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/SimpleDialog';
import ExpertMark from '../../reused/ExpertMark';
import ExpertAssessment from '../../reused/ExpertAssessment';
import { useDialog } from '../../../common/hooks';
import { FormattedMessage } from 'react-intl';
import {
  deleteSimpleMark,
  getEvalActivationInfo,
  getSimpleAssessment,
  getSimpleMark,
  getStatsTechnoprocess,
  saveSimpleMark,
  toggleTabState,
  updateEvalActivationInfo,
} from '../../../actions';
import Error from '../../reused/Error';
import EvaluationActivation from '../../reused/EvaluationActivation';
import Download from '../../reused/Download';

function Section52() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStatsTechnoprocess());
    dispatch(getSimpleMark(179));
    dispatch(getSimpleAssessment(179));
    dispatch(getEvalActivationInfo(179));
  }, [dispatch]);
  const { stats, labels } = useSelector((state) => state.sectionFiveStateTechnoprocess, shallowEqual);
  const { mark, assessment } = useSelector((state) => state.simpleEvaluation, shallowEqual);

  const statsFields = useMemo(
    () => (stats ? Object.values(stats) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))),
    [stats, labels]
  );

  const filteredStatsFields = useMemo(
    () =>
      statsFields.map((el) => {
        if (el.code_name === 'file')
          return {
            ...el,
            value: el.value ? <Download className="end" fileName={el.value} /> : '',
          };
        return el;
      }),
    [statsFields]
  );

  const defaultValues = useMemo(
    () => statsFields.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value }), {}),
    [statsFields]
  );

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-cep');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const requestState = useSelector((state) => state.sectionFiveStateTechnoprocessUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionFiveStateTechnoprocess.error, shallowEqual);
  const handleSubmitMark = (data) => {
    dispatch(saveSimpleMark(179, data));
    dispatch(getSimpleAssessment(179));
  };
  const handleDeleteMark = () => dispatch(deleteSimpleMark(179));
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(179, data));
    dispatch(getSimpleMark(179));
    dispatch(getSimpleAssessment(179));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <div className="mb">
        <div className="spaceBetween mb3">
          <div className="titleContainer">
            <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
          </div>
          {currentUser?.roles.includes('organization') && (
            <Button onClick={handleOpen} variant="contained" color="primary">
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          )}
        </div>
      </div>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <>
          <Table
            title={<FormattedMessage id="section52.title" defaultMessage="Наименование" />}
            head={<FormattedMessage id="global.countTitle" defaultMessage="Количество" />}
            rows={filteredStatsFields}
          />
          <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="mAuto mt4 w30">
            <div className="w50 mAuto">
              <ExpertMark
                mark={mark}
                isExpert={isVisibleExpert}
                onSubmit={handleSubmitMark}
                onDelete={handleDeleteMark}
              />
              <ExpertAssessment assessment={assessment} isExpert={false} showComment={false} />
            </div>
          </EvaluationActivation>
        </>
      )}

      <Dialog
        dialogState={dialogState}
        onClose={handleClose}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleClose} defaultValues={defaultValues} />
      </Dialog>
    </>
  );
}

export default Section52;
