import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgInfo = handleActions(
  {
    [actions.getOrgInfoSuccess]: (state, { payload }) => ({ ...state, data: payload }),
    [actions.getCurrentOrgEvaluationsSuccess]: (state, { payload: { data, exportData } }) => ({
      ...state,
      currentOrgEvals: data,
      exportOrgEvals: exportData,
    }),
    [actions.getPercentSuccess]: (state, { payload }) => ({ ...state, percentData: payload }),
    [actions.getOrgInfoFailure]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    data: {},
    currentOrgEvals: [],
    exportOrgEvals: [],
    percentData: 0,
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgInfoUI = handleActions(
  {
    [actions.getOrgInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getOrgInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getOrgInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
