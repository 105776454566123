import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import { editChannelLink, addChannelLink } from '../../../actions';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const { register, handleSubmit, control, watch, errors } = useForm({ defaultValues, mode: 'onChange' });
  const dispatch = useDispatch();
  const intl = useIntl();
  const radios = watch();
  const { channel, labels, channels } = useSelector((state) => state.sectionTwoLinksChannels, shallowEqual);

  const linkChannels = channels.map((link) => link.link);

  const onSubmit = (data) => {
    const reasonReset = {};
    if (data.is_frequently === '1') reasonReset.reason = '';
    if (isEditableMode)
      dispatch(
        editChannelLink(channel.id.value, {
          ...data,
          ...reasonReset,
          organization_id: localStorage.orgId,
          section_id: 23,
        })
      );
    else dispatch(addChannelLink({ ...data, ...reasonReset, organization_id: localStorage.orgId, section_id: 23 }));
    submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className="mb m0Auto"
        fullWidth
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = linkChannels.includes(value);
            return !isValid;
          },
        })}
        error={!!errors.link}
        name="link"
        label={labels.link}
        defaultValue="https://"
        type="url"
        helperText={
          errors.link?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateLinks',
            defaultMessage: 'Ссылка не должна повторяться!',
          })
        }
      />
      <div className="mb2">
        <label htmlFor="is_frequently" className={`nativeLabel ${!!errors.is_frequently && 'error'}`}>
          {labels.is_frequently}
        </label>
        <Controller
          as={
            <RadioGroup row>
              <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
              <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
            </RadioGroup>
          }
          name="is_frequently"
          control={control}
          rules={{ required: true }}
          defaultValue="1"
        />
        {radios.is_frequently === '0' && (
          <TextField
            className="mb m0Auto"
            fullWidth
            inputRef={register({ required: true })}
            name="reason"
            label={<FormattedMessage id="global.stateReason" defaultMessage="Укажите причину" />}
          />
        )}
      </div>

      <Button className="mb" variant="contained" color="primary" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
