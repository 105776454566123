import axios from 'axios';
import { createAction } from 'redux-actions';
import { generateHeaders } from 'actions/dictionary';
import routes from 'regions/routes';
import { handleValidationError } from 'common/utils';

export const get = async (handbook) => {
  try {
    const { data } = await axios.get(routes.dictionary(handbook), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getLabels = async (entity) => {
  try {
    const { data } = await axios.get(routes.labels(entity), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const getRegionsRequest = createAction('GET_REGIONS_REQUEST');
export const getRegionsSuccess = createAction('GET_REGIONS_SUCCESS');
export const getRegionsFailure = createAction('GET_REGIONS_FAILURE');

export const getRegions = () => async (dispatch) => {
  dispatch(getRegionsRequest());

  try {
    const {
      data: { result },
    } = await axios.get(routes.dictionary('regions'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getRegionsSuccess(result));
  } catch (e) {
    dispatch(getRegionsFailure());
    console.log(e);
  }
};

export const getRDistrictsRequest = createAction('GET_R_DISTRICTS_REQUEST');
export const getRDistrictsSuccess = createAction('GET_R_DISTRICTS_SUCCESS');
export const getRDistrictsFailure = createAction('GET_R_DISTRICTS_FAILURE');

export const getRDistricts = (regionId) => async (dispatch) => {
  dispatch(getRDistrictsRequest());

  try {
    const {
      data: { result },
    } = await axios.get(routes.dictionary(`districts?region_id=${regionId}`), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getRDistrictsSuccess(result));
  } catch (e) {
    dispatch(getRDistrictsFailure());
    console.log(e);
  }
};

export const getOrganizationTablesRequest = createAction('GET_ORGANIZATION_TABLES_REQUEST');
export const getOrganizationTablesSuccess = createAction('GET_ORGANIZATION_TABLES_SUCCESS');
export const getOrganizationTablesFailure = createAction('GET_ORGANIZATION_TABLES_FAILURE');

export const getOrganizationTables = () => async (dispatch) => {
  dispatch(getOrganizationTablesRequest());

  try {
    const {
      data: { result },
    } = await axios.get(routes.dictionary('organization-tables'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getOrganizationTablesSuccess(result || []));
  } catch (e) {
    dispatch(getOrganizationTablesFailure());
    console.log(e);
  }
};

export const getRDeadlineRequest = createAction('GET_R_DEADLINE_REQUEST');
export const getRDeadlineSuccess = createAction('GET_R_DEADLINE_SUCCESS');
export const getRDeadlineFailure = createAction('GET_R_DEADLINE_FAILURE');

export const getRDeadline = (id) => async (dispatch) => {
  dispatch(getRDeadlineRequest());

  try {
    let params = {};
    if (id) params.table_id = id;
    const {
      data: { result },
    } = await axios.get(routes.dictionary('region-deadline'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params,
    });
    dispatch(getRDeadlineSuccess(result));
  } catch (e) {
    dispatch(getRDeadlineFailure());
    console.log(e);
  }
};

export const reportApi = async (dictionary, params) => {
  try {
    const { data } = await axios.get(routes.report(dictionary), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params,
      responseType: params ? 'blob' : 'json',
    });
    return data;
  } catch (e) {
    console.log(e);
    if (e.response) {
      const json = await e.response.data.text();
      e.response.data = JSON.parse(json);
      handleValidationError(e);
    }
  }
};
