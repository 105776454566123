// http://nis.track.uz:8071/monitoring/project/search-module
import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export const getSearchProjectsRequest = createAction('SEARCH_PROJECTS_GET_REQUEST');
export const getSearchProjectsSuccess = createAction('SEARCH_PROJECTS_GET_SUCCESS');
export const getSearchProjectsFailure = createAction('SEARCH_PROJECTS_GET_FAILURE');

export const getSearchProjects = (paramsData, page) => async (dispatch) => {
  dispatch(getSearchProjectsRequest());

  try {
    const { data } = await axios.get(routes.search(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { ...paramsData, page },
    });
    dispatch(getSearchProjectsSuccess(data));
  } catch (e) {
    dispatch(getSearchProjectsFailure());
    console.log(e);
  }
};

export const setSearchFiltrationFields = createAction('SEARCH_FILTRATION_FIELDS_SET');
