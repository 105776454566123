import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const complexEvaluation = handleActions(
  {
    [actions.getEvaluationListSuccess]: (state, { payload }) => ({ ...state, evaluationList: payload }),
    [actions.getComplexMarkSuccess]: (state, { payload }) => ({ ...state, mark: payload }),
    [actions.saveComplexMarkSuccess]: (state, { payload }) => ({ ...state, mark: payload }),
    [actions.deleteСomplexMarkSuccess]: (state) => ({ ...state, mark: null }),
    [actions.getComplexAssessmentSuccess]: (state, { payload }) => ({ ...state, assessment: payload }),
    [actions.saveComplexAssessmentSuccess]: (state, { payload }) => ({ ...state, assessment: payload }),
    //[actions.deleteComplexAssessmentSuccess]: (state) => ({ ...state, assessment: [] }),
  },
  {
    mark: null,
    assessment: {},
    evaluationList: [],
  }
);
