import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Button, CircularProgress, TextField } from '@material-ui/core';

import { generateHeaders, editCurrentProject, getProjectLogs } from 'actions';
import routes from 'monitoring/routes';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Autocomplete from 'components/reused/Autocomplete';

function OrganizationForm({ organizationList = [], labels, closeDialog, defaultValue }) {
  const { handleSubmit, register, errors, control } = useForm();
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.editProject('change-organization', projectId),
      method: 'put',
      data: { ...data, organization_id: data.organization_id.id },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        dispatch(editCurrentProject(data));
        dispatch(getProjectLogs(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
      <Autocomplete
        control={control}
        options={organizationList}
        name="organization_id"
        label={labels.organization_id}
        error={!!errors.organization_id}
        rules={{ validate: (value) => (value.id !== null ? undefined : 'value') }}
        defaultValue={defaultValue}
        isAutocomplete={false}
      />
      <TextField
        name="comment"
        multiline
        rowsMax="4"
        label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
        inputRef={register}
        type="text"
        className="mb2"
        fullWidth
      />
      <Button color="primary" variant="contained" type="submit">
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        )}
      </Button>
    </form>
  );
}

export default OrganizationForm;
