import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';

import Form from './Form';
import Table from '../../reused/Table';
import ExpertAssessment from '../../reused/ExpertAssessment';
import EvaluationActivation from '../../reused/EvaluationActivation';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import { getEvalActivationInfo, getProcessingActs, getSimpleAssessment, toggleTabState, updateEvalActivationInfo } from '../../../actions';
import Error from '../../reused/Error';

export default function Section28() {
  const [dialogState, handleOpen, handleClose] = useDialog();
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProcessingActs());
    dispatch(getSimpleAssessment(27));
    dispatch(getEvalActivationInfo(27));
  }, [dispatch]);

  const { data, labels } = useSelector((state) => state.sectionTwoProcessingActs, shallowEqual);
  const finalFields = useMemo(() => (data ? Object.values(data) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))), [
    data,
    labels,
  ]);
  const finalTableRow = useMemo(() => finalFields.filter((field) => !['point'].includes(field.code_name)), [finalFields]);
  const defaultValues = useMemo(() => finalFields.reduce((acc, field) => ({ ...acc, [field.code_name]: field.value ? `${field.value}` : '' }), {}), [
    finalFields,
  ]);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-cep');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const { tabs } = useSelector((state) => state.dictionary, shallowEqual);
  const assessment = useSelector((state) => state.simpleEvaluation.assessment, shallowEqual);
  const { requestAssessmentState } = useSelector((state) => state.simpleEvaluationUI, shallowEqual);

  const requestState = useSelector((state) => state.sectionTwoProcessingActsUI.requestState, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoProcessingActs.error, shallowEqual);

  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(27, data));
    dispatch(getSimpleAssessment(27));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };
  if (errorHandler.hasError) return <Error error={errorHandler} />;
  return (
    <>
      <div className="mb">
        <div className="spaceBetween mb3">
          <div className="titleContainer">
            <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
          </div>
          {isVisibleExpert && (
            <Button onClick={handleOpen} variant="contained" color="primary">
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          )}
        </div>
      </div>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <>
          <Table title={intl.formatMessage({
            id: 'section28.title',
            defaultMessage: 'Наименование'
          })} head={intl.formatMessage({
            id: 'section28.count',
            defaultMessage: 'Кол-во'
          })} rows={finalTableRow} />
          {localStorage.orgCategoryId === '1' && (
            <EvaluationActivation isExpert={isVisibleExpert} className="w30 mAuto" onSubmit={updateEvalActivation}>
              <div className="w70 m0Auto mt4">
                {requestAssessmentState === 'request' ? (
                  <Loader />
                ) : (
                  <ExpertAssessment assessment={assessment} isExpert={false} showComment={false} />
                )}
              </div>
            </EvaluationActivation>
          )}
        </>
      )}

      <Dialog dialogState={dialogState} onClose={handleClose} title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form submitAction={handleClose} defaultValues={defaultValues} />
      </Dialog>
    </>
  );
}
