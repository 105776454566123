import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const login = handleActions(
  {
    [actions.userLoginFailure]: (state, { payload: { message } }) => {
      if (message === 'Wrong username or password.') return { errorMessage: 'Неправильный логин или пароль.' };
      else {
        return {};
      }
    },
    [actions.resetErrorMessage]: () => ({ errorMessage: '' }),
  },
  {}
);

export const loginUI = handleActions(
  {
    [actions.userLoginRequest]: () => ({ requestingState: 'request' }),
    [actions.userLoginSuccess]: () => ({ requestingState: 'success' }),
    [actions.userLoginFailure]: () => ({ requestingState: 'failure' }),
  },
  {}
);
