import React, { useEffect, useState } from 'react';
import { Paper, Table, TableContainer, TableHead, TableBody, TableRow } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import { StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import Loader from 'components/reused/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { getActs, resetDrawer } from 'actions';
import { Pagination } from '@material-ui/lab';
import Empty from 'components/reused/Empty';

function Acts() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getActs(page));
    if (sessionStorage.projectPaginationPage || sessionStorage.roadmapsPaginationPage) {
      sessionStorage.removeItem('projectPaginationPage');
      sessionStorage.removeItem('roadmapsPaginationPage');
    }
  }, []);

  const [page, setPage] = useState(parseInt(sessionStorage.getItem('actsPaginationPage')) || 1);
  const handleChange = (event, value) => {
    sessionStorage.setItem('actsPaginationPage', value);
    setPage(value);
    dispatch(getActs(value));
  };

  const classes = useStyles();
  const { acts, configActs } = useSelector((state) => state.m_documents);

  const loading = useSelector((state) => state.m_documentsUI.loading);
  return (
    <>
      <TableContainer component={Paper} className={classes.container}>
        {loading ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.headRow}>
                <StyledTableCell>Название НПА</StyledTableCell>
                <StyledTableCell>Тип и номер</StyledTableCell>
                <StyledTableCell>Дата утверждения</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {acts.map((act) => (
                <StyledTableRow key={act.id}>
                  <StyledTableCell>
                    <NavLink className="m_link" to={`/acts/${act.id}`} onClick={() => dispatch(resetDrawer())}>
                      {act.title}
                    </NavLink>
                  </StyledTableCell>
                  <StyledTableCell>{act.short_title}</StyledTableCell>
                  <StyledTableCell>{act.accepted_date}</StyledTableCell>
                </StyledTableRow>
              ))}

              {acts.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan="3">
                    <Empty title="Данных нет" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {configActs.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt2">
          <Pagination
            count={configActs.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
    </>
  );
}

export default Acts;
