import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import NotFoundImg from '../../assests/img/not-found2.png';
import Dashboard from '../Dashboard';

function NotFound() {
  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">
            <img className="notFound" src={NotFoundImg} alt="page not found" />
          </Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default NotFound;
