import React from 'react';
import { Button } from '@material-ui/core';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

function ExportCSV({ csvData, fileName }) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (excelData, name) => () => {
    const keys = Object.keys(excelData[0]).filter((el) => el !== 'Итого');
    keys.push('Итого');
    const keysLength = keys.length;
    const matrix = [];
    for (let cntKeys = 0; cntKeys < keysLength; cntKeys += 1) {
      let arr = [];
      arr[0] = keys[cntKeys];
      for (let cnt = 0; cnt < excelData.length; cnt += 1) {
        arr[cnt+1] = excelData[cnt][keys[cntKeys]]
      }
      matrix[cntKeys] = arr
    }
    const ws = XLSX.utils.aoa_to_sheet(matrix);
    const wb = { Sheets: { evaluations: ws }, SheetNames: ['evaluations'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `${name}${fileExtension}`);
  };
  return (
    <Button variant="contained" color="primary" onClick={exportToCSV(csvData, fileName)}>
      Экспорт
    </Button>
  );
}

export default ExportCSV;
