import { LOCALES } from '../locales';
export default {
  [LOCALES.UZB]: {
    'signIn.enterToSystem': 'Тизимга кириш',
    'signIn.nameSystem': 'Миллий ахборот тизими',
    'signIn.buttonNameEnter': 'Кириш',
    'signIn.signUpTitle': 'Логин',
    'signIn.passwordTitle': 'Парол',
    'signIn.helpTitle': 'Ёрдам',
    'signIn.techniqueSupportTitle': 'Техник кўллаб-қувватлаш',
    'signIn.singleIntegratorTitle': 'Ягона интегратор',
    'signIn.checkboxRememberTitle': 'Эслаб қолиш',
    'signIn.languageUzbOptionTitle': 'Ўзбекча',
    'signIn.languageRusOptionTitle': 'Русча',
    'drawerList.generalInfoOrg': 'Ташкилот тўғрисида умумий маълумотлар',
    'drawerList.infrastructure': 'Инфратузилма',
    'drawerList.financing': 'Молиялаштириш',
    'drawerList.organizationList': 'Ташкилотлар рўйҳати',
    'drawerList.support': 'Маълумотнома',
    'drawerList.total': 'Жами',
    'drawerList.rating': 'Рейтинг',
    'drawerList.nameOrganization': 'Ташкилотнинг номи',
    'drawerList.ratingOrganization': 'Ташкилотнинг баҳолаш',
    'statistics.title': 'Барча ташкилотлар статистикаси',
    'statistics.govOrg': 'Давлат органлари',
    'statistics.ecoOrg': 'Хўжалик бирлашмалари',
    'statistics.allOrg': 'Барча ташкилотлар',
    'homeHeader.headerTitle': 'Давлат ва хўжалик бошқарув органларида АКТдан кенг фойдаланишнинг рейтинг баҳоси',
    'monitoringHomeHeader.headerTitle': '"РАҚАМЛИ ЎЗБЕКИСТОН - 2030" СТРАТЕГИЯСИ ДОИРАСИДАГИ ПРОЕКТЛАР МОНИТОРИНГИ',
    'regionHomeHeader.headerTitle': 'ҲУДУДЛАРИНГ РАҚАМЛИ РИВОЖЛАНИШ ДАРАЖАСИНИ РЕЙТИНГ БАҲОСИ',
    'homeHeader.day': 'к',
    'homeHeader.hour': 'с',
    'homeHeader.minutes': 'м',
    'homeHeader.seconds': 'с',
    'homeHeader.time': 'гача',
    'drawerFooter.footerTitle': 'Ягона интегратор',
    'intro.systemSupportUsed': 'Тизимдан фойдаланиш бўйича кўрсатмалар',
    'intro.downloadQuestionaire': 'Сўровномани юклаб олиш',
    'intro.downloadMethodologyState': 'Давлат органи методикасини юклаб олиш',
    'intro.downloadMethodologyEconomic': 'Хўжалик бошқаруви органи методикасини юклаб олиш',
    'intro.contacts': 'Боғланиш учун маълумот',
    'contact.generalConsultation': 'Тизимда ишлаш бўйича умумий консультация ва қўллаб-қувватлаш – (55)-501-36-26',
    'contact.consultationForFillingSectionOpenData':
      '“Очиқ маълумотлар порталида очиқ маълумотлар тўпламининг мавжудлиги” бўлимини маълумотлар билан тўлдириш бўйича консультация – (71)-202-80-60',
    'contact.consultationForFillingSectionElectronServices':
      '“Электрон хизматлар” бўлимини маълумотлар билан тўлдириш бўйича консультация – (71)-207-00-66 (1080)',
    'contact.phoneCentralCyberHelpLine': '"Киберхавфсизлик Маркази" ДУК - (71)-203-00-26, (71)-203-00-54',
    'evalActivation.title': 'Ушбу бўлимда баҳо мавжудлиги тўғрисида маълумот',
    'expertMark.markItemTitle': 'Элементни тўлдиришнинг тўғрилиги ёки нотўғрилиги тўғрисида белги',
    'expertMark.fillItem': 'Тўлдирилган',
    'expertMark.commentItem': 'Изоҳ',
    'expertMark.noMarkItem': 'Ҳеч қандай белги йўқ',
    'expertMark.correctly': 'Тўғри',
    'expertMark.notCorrectly': 'Нотўғри',
    'expertAssessment.expertAssessmentTitle': 'Эксперт баҳоси',
    'expertAssessment.ballItem': 'Ҳисоб',
    'expertAssessment.commentItem': 'Изоҳ',
    'expertAssessment.assesmentTitle': 'Баҳолаш',
    'expertAssessment.noAssessment': 'Баҳо йўқ',
    'section13.directorTitle': 'Ташкилот раҳбари',
    'section13.legalAddressTitle': 'Юридик манзил',
    'section13.reportExportTemplateFirst': 'Шакл 1',
    'section13.reportExportTemplateSecond': 'Шакл 2',
    'section17.indicatorsTitle': 'Кўрсаткичлар',
    'section171.headerTitle':
      'Ташкилот тизимига кирувчи идоравий мансуб ташкилотлар, ҳудудий ва бошқа бўлинмалар рўйхати',
    'section18.errorComputationVacancy':
      'Диққат! "Бўш иш ўринлари инобатга олинмаганда ҳақиқатда ишлаётган ходимлар сони" кўрсатилганда, штат жадвали бўйича банд бўлган лавозимлар сони кўрсатилади. Масалан, битта лавозимда 0,5 ставкада 2 та ходим бўлса, "Бўш иш ўринлари инобатга олинмаганда ҳақиқатда ишловчи ҳодимлар" устунида 1 та бирлик кўрсатилади. Бўлинмалар ва лавозимлар бўйича штат жадвалига мувофиқ ташкилот ходимларининг умумий сони, шунингдек бўлинмалар кесимида ва лавозимлар бўйича ҳақиқатда ишлаётган ходимлар кесимида кўрсатилган жами ходимлар сони бир хил қийматга эга бўлиши керак.',
    'section110.deleteFilledFieldsTitle':
      'Агар сиз амални тасдиқласангиз, биз киритирилган маълумотларни ўчириб ташлаймиз!',
    'section110.responsiblePerson': 'Масъул шахс',
    'section24.partlyTitle': 'Қисман',
    'section24.indicatorName': 'Кўрсаткич номи',
    'section24.estimatedTitle': 'Баҳоланади',
    'section24.indicatorCritition': 'Кўрсаткич мезони',
    'section27.infoOpenDataTitle': 'Ахборот тизими тўғрисида маълумот',
    'section28.title': 'Номланиши',
    'section28.count': 'Миқдори',
    'section31.addServiceBtnTitle': 'Электрон хизматни қўшинг',
    'section32.title': 'Номланиши',
    'section32.count': 'Миқдори',
    'section33.empty': 'Сизнинг ташкилотингиз учун сўровлар йўқ еди',
    'section33.actualData': 'Данные актуальны',
    'section33.actualFrom': 'от',
    'section33.actualTo': 'до',
    'section40.questionsTitle': 'Саволлар',
    'section40.infoSecurity': 'Ахборот хавфсизлиги',
    'section40.questionTitle': 'Савол номи',
    'section40.inputData': 'Маълумотларни киритиш',
    'section40.link': 'Ахборот ва киберхавфсизлик мониторинг тизими',
    'section51.activeEvaluation': 'Балларни ҳисоблаш',
    'section51.infoAboutInfoSystem': 'Ахборот тизими тўғрисида маълумот',
    'section51.notAssessed': 'Истисно',
    'section52.title': 'Исм',
    'section63.enterNum': 'Ҳислоблаш учун рақамни киритинғ',
    'section81.title': 'Номланиши',
    'organizationStat.formLabel': 'Статистика олиш учун йил ва чоракни киритинг',
    'organizationStat.labelYear': 'Йил',
    'organizationStat.labelQuarter': 'Чорак',
    'monitoring.project.statusForm': 'Ҳолати ўзгартириш',
    'monitoring.project.organizationForm': 'Ташкилотни ўзгартириш',
    'monitoring.project.forecastForm': 'Прогноз маблағларини ўзгартириш',
    'monitoring.project.costEffectivenessForm': 'Лойиханинг иқтисодий самарадорлигини ўзгартиринг',
    'monitoring.project.history': 'Лойиҳа тарихи',
    'monitoring.form.commentTitle': 'Шарҳ',
    'monitoring.roadmaps.fullNameApp': 'Илованинг тўлиқ номланиши',
    'monitoring.roadmaps.shortNameApp': 'Илованинг қиска номланиши',
    'monitoring.roadmaps.yearOfExecution': 'Ижро йили',
    'monitoring.roadmaps.statusProjects': 'Лойиҳа ҳолати',
    'monitoring.roadmaps.stepStatistic': 'Босқичлар бўйича маълумот',
    'monitoring.roadmaps.attachmentFiles': ' Лойиҳа хужжатларини бириктириш',
    'monitoring.roadmaps.hasntFiles': ' Хужжатлар мавжуд эмас...',
    'monitoring.roadmaps.stepsProject': ' Лойиҳа босқичлари',
    'monitoring.roadmaps.historySteps': ' Босқич тарихи',
    'monitoring.step.comment': `
        <strong>Лойиҳа босқичи бўйича мазмунли изоҳ киритилиши лозим!</strong>
        <br />Босқич ижроси тўғрисида матнли изоҳда маълумот аниқ кўрсаткичлар ( хат ёзишмалари бўлса – хат муаллифи , юборилган манзили ва
        сана , рўйҳат рақами ; шартнома бўлса – шартнома ким билан имзолангани , шартнома мавзуси , имзоланган санаси ; объект , юридик ва
        жисмоний шахс ва / ёки ҳодисалар билан боғлиқ бошқа тавсилотларда ҳам – сон , миқдор , сифат ва нисбий қўрсаткичлар сифатида )
        киритилиши лозим . Акс ҳолда киритилган маълумотлар Мониторинг модули Модератори
        <strong>
          томонидан етарлича маълумот тақдим
          этилмагани сабабли қабул қилинмаслиги мумкин
        </strong>`,
    'global.requiredFill': 'Мажбурий майдон!',
    'global.addBtnTitle': 'Қўшиш',
    'global.deleteItem': 'Ўчириш',
    'global.sendBtnTitle': 'Хабар юборинг',
    'global.requireBtnTitle': 'Сўров',
    'global.changeBtnTitle': 'Таҳрирлаш',
    'global.saveBtnTitle': 'Сақлаш',
    'global.attachFileTitle': 'Файлни бириктиринг',
    'global.downloadFileTitle': 'Файлни юклаб олиш',
    'global.stateReason': 'Асосни киритинг',
    'global.yesTitle': 'Ҳа',
    'global.noTitle': 'Йўқ',
    'global.attachOrderAppoint': 'Буйруқни илова қилинг',
    'global.logout': 'Чиқиш',
    'global.editBtnTitle': 'Таҳрирлаш',
    'global.showBtntitle': 'Қаранг',
    'global.fillFormTitle': 'Шаклни тўлдиринг',
    'global.chooseRadioTitle': 'Қийматни танланг',
    'global.countTitle': 'Миқдори',
    'global.errorDublicateNames': 'Ҳужжатнинг номи такрорланмаслиги керак!',
    'global.errorDublicateLinks': 'Хавола такрорланмаслиги керак!',
    'global.errorDublicateServices': 'Хизмат номи такрорланмаслиги керак!',
    'global.errorDublicateOpenData': 'ОД номи такрорланмаслиги керак!',
    'global.errorDublicateInformationSystems': 'АТ номи такрорланмаслиги керак!',
    'global.errorDublicateProjects': 'Лойиха номи такрорланмаслиги керак!',
    'global.errorDublicateServers': 'Сервер тури ва бренди бир хил бўлмаслиғи керак!',
    'global.errorDataNotFound': 'Маълумот йўқ',
    'global.commentExpert': 'Эксперт изоҳи',
    'global.categoryName': 'Туркум',
  },
};
