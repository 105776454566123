import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { useStyles } from '../../reused/Table';
import { addElementBases, editElementBases, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: { ...defaultValues, file: '' },
    mode: 'onChange',
  });
  const dispatch = useDispatch();
  const intl = useIntl();

  const document = useSelector((state) => state.sectionOneOrgLegalBasis.document, shallowEqual);
  const onSubmit = async (data) => {
    // const totalFilesSize = Object.values(data.file).reduce((acc, file) => acc + file.size, 0);

    if (isEditableMode) {
      if (data.file.length === 0) dispatch(editElementBases(document.id.value, { ...data, file: defaultValues.file }));
      else {
        const filePath = await uploadFile(data.file, 1.6);
        dispatch(editElementBases(document.id.value, { ...data, file: filePath }));
      }
    } else {
      const filePath = await uploadFile(data.file, 1.6);
      dispatch(addElementBases({ ...data, file: filePath }));
    }

    submitAction();
  };

  const { documentTypes, acceptedDocuments } = useSelector((state) => state.dictionary, shallowEqual);

  const { labels, data } = useSelector((state) => state.sectionOneOrgLegalBasis, shallowEqual);
  const titles = data.map((document) => document.title);

  const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        inputRef={register({ required: true })}
        className="mb"
        label={labels.document_no}
        name="document_no"
        type="text"
        error={!!errors.document_no}
        fullWidth
      />
      <label htmlFor="date" className="nativeLabel">
        {labels.document_date}
      </label>
      <TextField
        inputRef={register({ required: true })}
        id="date"
        className="mb"
        name="document_date"
        type="date"
        defaultValue={defaultDate}
        error={!!errors.document_date}
        fullWidth
      />
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="doc_type">{labels.document_type_id}</InputLabel>
        <Select
          native
          inputProps={{ ref: register({ required: true }) }}
          name="document_type_id"
          labelId="doc_type"
          className="mb"
          error={!!errors.document_type_id}>
          {documentTypes.map((option) => (
            <option value={option.id} key={option.id}>
              {option.title}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id="accpted_authority">{labels.accepted_authority_id}</InputLabel>

        <Select
          native
          inputProps={{ ref: register({ required: true }) }}
          name="accepted_authority_id"
          labelId="accepted_authority"
          className="mb"
          error={!!errors.accepted_authority_id}>
          {acceptedDocuments.map((option) => (
            <option value={option.id} key={option.id}>
              {option.title}
            </option>
          ))}
        </Select>
      </FormControl>
      <TextField
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = titles.includes(value);
            return !isValid;
          },
        })}
        className="mb"
        margin="dense"
        label={labels.title}
        name="title"
        type="text"
        fullWidth
        error={!!errors.title}
        helperText={
          errors.title?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateNames',
            defaultMessage: 'Наименование документа не должно повторяться!',
          })
        }
      />
      <UploadFile error={!!errors.file} register={register({ required: !isEditableMode })} />
      {isEditableMode ? (
        <Button variant="contained" className="m0Auto" color="primary" type="submit">
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        </Button>
      ) : (
        <Button variant="contained" className="m0Auto" color="primary" type="submit">
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}
    </form>
  );
};

export default Form;
