import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, Select, TableCell } from '@material-ui/core';
import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useStyles } from 'components/reused/Table';
import Layout from 'regions/Layout';
import Spinner from 'components/reused/Spinner';
import Title from 'components/reused/Title';
import { useDispatch, useSelector } from 'react-redux';
import { getRegions, fetchRating, get, reportApi } from 'actions/regions';
import { withStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import fileDownload from 'js-file-download';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Rating() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch } = useForm();

  const [chronologyFirstDate, setChronologyFirstDate] = useState([]);

  useEffect(() => {
    dispatch(getRegions());
    dispatch(fetchRating({ region_id: '', region_type: 'region' }));
    let active = true;

    get('date-stat').then((data) =>
      active && data.hasOwnProperty('result') ? setChronologyFirstDate(data.result) : setChronologyFirstDate([])
    );

    return () => (active = false);
  }, [dispatch]);

  const currentUser = useSelector((state) => state.profile.currentUser);
  const showExportBtn = currentUser?.permissions.includes('r_export_reports');

  const [chronologySecondDate, setChronologySecondDate] = useState([]);
  const handleChange = ({ target: { value } }) => {
    if (value) {
      get(`date-stat?date_first_stat_id=${value}`).then((data) => {
        if (data.hasOwnProperty('result')) {
          setChronologySecondDate(data.result);
        } else {
          setChronologySecondDate([]);
        }
      });
    }
  };

  const { regions } = useSelector((state) => state.r_dictionary);
  const loading = useSelector((state) => state.r_questionnaire_ui.rating);
  const table = useSelector((state) => state.r_questionnaire.rating);
  const isCompare = table.template === 'compare';

  const onSubmit = (data) => {
    const filterOtherValues = {};
    if (data.chronology_first_date_id) filterOtherValues.chronology_first_date_id = data.chronology_first_date_id;
    if (data.chronology_second_date_id) filterOtherValues.chronology_second_date_id = data.chronology_second_date_id;

    if (data.region_id === 'allRegions' || data.region_id === 'allDistricts') {
      const region_type = data.region_id === 'allRegions' ? 'region' : 'district';
      dispatch(fetchRating({ region_id: '', region_type, ...filterOtherValues }));
    } else {
      dispatch(fetchRating({ region_id: data.region_id, region_type: 'district', ...filterOtherValues }));
    }
  };

  const [fetchingFile, setFetchingFile] = useState(false);
  const handleExportFile = () => {
    setFetchingFile(true);
    const formValues = watch();
    const params = {};

    if (formValues.chronology_first_date_id) params.chronology_first_date_id = formValues.chronology_first_date_id;
    if (formValues.chronology_second_date_id) params.chronology_second_date_id = formValues.chronology_second_date_id;

    if (formValues.region_id === 'allRegions' || formValues.region_id === 'allDistricts') {
      params.region_id = '';
      params.region_type = formValues.region_id === 'allRegions' ? 'region' : 'district';
    } else {
      params.region_id = formValues.region_id;
      params.region_type = 'district';
    }

    reportApi('report/rating', params).then((data) => {
      fileDownload(data, `${uuidv4()}.xlsx`);
      setFetchingFile(false);
    });
  };

  return (
    <Layout>
      <Title title="РЕЙТИНГ" className="mb" />
      <div className="dFJustifyFxSpaceBtwn">
        <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex' }}>
          <div className="filtrationContainer dFJustifyFxCenter alignItemsCenter">
            <FormControl>
              <InputLabel>Выберите регион</InputLabel>
              <Select native name="region_id" inputRef={register()}>
                <option value="allRegions">Все регионы</option>
                <option value="allDistricts">Все районы и города</option>
                {regions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          {chronologyFirstDate.length !== 0 && (
            <div className="filtrationContainer">
              <FormControl fullWidth>
                <InputLabel>Выберите дату</InputLabel>
                <Select
                  native
                  className="mb"
                  name="chronology_first_date_id"
                  inputRef={register()}
                  onChange={handleChange}>
                  <option value="" />
                  {chronologyFirstDate.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.comment || option.date_on}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {chronologySecondDate.length !== 0 && (
                <FormControl fullWidth>
                  <InputLabel>Выберите дату до</InputLabel>
                  <Select native name="chronology_second_date_id" inputRef={register()}>
                    <option value="" />
                    {chronologySecondDate.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.comment || option.date_on}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          )}
          <div className="dFlex flexDirectionCol ml">
            <Button variant="contained" color="primary" type="submit" className="mt mb">
              Фильтровать
            </Button>
            {showExportBtn && (
              <Button variant="contained" color="primary" onClick={handleExportFile} disabled={fetchingFile}>
                {fetchingFile ? <CircularProgress color="secondary" size="30px" /> : 'Экспорт'}
              </Button>
            )}
          </div>
        </form>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper} className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                <DashedTableCell align="center" rowSpan={table.has_parent || isCompare ? 2 : 1}>
                  {table.i18n.region_name}
                </DashedTableCell>
                {table.has_parent && (
                  <DashedTableCell align="center" rowSpan={isCompare ? 3 : 1}>
                    {table.i18n.district_or_region_name}
                  </DashedTableCell>
                )}
                {table.categories.map((cell) => {
                  return (
                    <React.Fragment key={cell.id}>
                      <DashedTableCell align="center" colSpan={isCompare ? 2 : 1}>
                        {cell.title}
                      </DashedTableCell>
                      <DashedTableCell align="center" rowSpan={isCompare ? 3 : 1}>
                        {table.i18n.percent}
                      </DashedTableCell>
                    </React.Fragment>
                  );
                })}
                <DashedTableCell rowSpan={isCompare ? 2 : 1} colSpan={isCompare ? 2 : 1} align="center">
                  {table.i18n.label}
                </DashedTableCell>
                {isCompare && (
                  <DashedTableCell rowSpan={3} align="center">
                    {table.i18n.sum_score_compare_label}
                  </DashedTableCell>
                )}
              </TableRow>
              {isCompare && (
                <TableRow>
                  {table.categories.map((cell) => {
                    return (
                      <React.Fragment key={cell.id}>
                        <DashedTableCell align="center">{table.first_date}</DashedTableCell>
                        <DashedTableCell align="center">{table.second_date}</DashedTableCell>
                      </React.Fragment>
                    );
                  })}
                </TableRow>
              )}
              <TableRow>
                <DashedTableCell align="center">{table.i18n.score_label}</DashedTableCell>
                {table.categories.map((cell) => {
                  return (
                    <React.Fragment key={cell.id}>
                      <DashedTableCell align="center">{cell.max_score}</DashedTableCell>
                      {isCompare && <DashedTableCell align="center">{cell.max_score}</DashedTableCell>}
                      {!isCompare && <DashedTableCell />}
                    </React.Fragment>
                  );
                })}
                <DashedTableCell align="center">{table.i18n.max_score}</DashedTableCell>
                {isCompare && <DashedTableCell align="center">{table.i18n.max_score}</DashedTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {table.regions.map((cell) => {
                return (
                  <TableRow key={cell.id || cell.region_id}>
                    {table.has_parent && <DashedTableCell>{cell.parent_title}</DashedTableCell>}
                    <DashedTableCell>{cell.title}</DashedTableCell>
                    {cell.ratings.map((el) => {
                      return (
                        <React.Fragment key={el.category_id}>
                          <DashedTableCell align="center">{el.score.toFixed(2)}</DashedTableCell>
                          {isCompare && (
                            <DashedTableCell align="center">{el?.score_second?.toFixed(2)}</DashedTableCell>
                          )}
                          <DashedTableCell align="center">
                            {isCompare ? el?.compare?.toFixed(2) : el.percent.toFixed(2)}
                          </DashedTableCell>
                        </React.Fragment>
                      );
                    })}
                    <DashedTableCell align="center">{cell.sum_score.toFixed(2)}</DashedTableCell>
                    {isCompare && (
                      <>
                        <DashedTableCell align="center">{cell?.sum_score_second?.toFixed(2)}</DashedTableCell>
                        <DashedTableCell align="center">{cell?.compare?.toFixed(2)}</DashedTableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Layout>
  );
}

export const DashedTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    padding: '10px',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '10px',
    borderBottom: '1px dashed #E4E4E4',
    borderRight: '1px dashed #E4E4E4',
    '&:last-child': {
      borderRight: '0px',
    },
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);
