import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const log = handleActions(
  {
    [actions.getProjectLogsSuccess]: (state, { payload }) => {
      return { ...state, list: payload.items, config: payload._meta, loading: false };
    },
    [actions.getStepsLogsSuccess]: (state, { payload }) => {
      return { ...state, stepLogs: payload.items, configStepsLogs: payload._meta, loading: false };
    },
    [actions.getProjectLogsRequest]: (state) => ({ ...state, loading: true }),
    [actions.getProjectLogsFailure]: (state) => ({ ...state, loading: false }),
    [actions.addCommentSuccess]: (state, { payload }) => ({ ...state, list: [payload, ...state.list] }),
    [actions.addStepsCommentSuccess]: (state, { payload }) => ({ ...state, stepLogs: [payload, ...state.stepLogs] }),
    [actions.updateStepLogSuccess]: (state, { payload }) => ({
      ...state,
      list: state.list.map((log) => (log.id !== payload.id ? log : payload)),
      stepLogs: state.stepLogs.map((log) => (log.id !== payload.id ? log : payload)),
    }),
    [actions.updateStepLogFailure]: (state, { payload }) => ({ ...state, errors: payload }),
  },
  {
    list: [],
    stepLogs: [],
    errors: [],
    config: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
    configStepsLogs: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
    loading: false,
  }
);
