import React from 'react';

function Title({ title, className, disableBorder }) {
  return (
    <div className={`titleContainer ${className}`}>
      {!disableBorder && <div className="titleBorder" />}
      <h2 className="title">{title}</h2>
      {!disableBorder && <div className="titleBorder" />}
    </div>
  );
}

export default Title;
