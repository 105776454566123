import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setInfoIctFoundsError = createAction('INFO_ICT_FOUNDS_SET_ERROR');

export const getInfoIctFoundsRequest = createAction('INFO_ICT_FOUNDS_GET_REQUEST');
export const getInfoIctFoundsSuccess = createAction('INFO_ICT_FOUNDS_GET_SUCCESS');
export const getInfoIctFoundsFailure = createAction('INFO_ICT_FOUNDS_GET_FAILURE');

export const getInfoIctFounds = () => async (dispatch) => {
  dispatch(getInfoIctFoundsRequest());

  try {
    const { data } = await axios.get(routes.sectionEightInfoIctFounds(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionEightInfoIctFoundsLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getInfoIctFoundsSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setInfoIctFoundsError(e?.response));
    dispatch(getInfoIctFoundsFailure());
  }
};

export const editInfoIctFoundsRequest = createAction('INFO_ICT_FOUNDS_EDIT_REQUEST');
export const editInfoIctFoundsSuccess = createAction('INFO_ICT_FOUNDS_EDIT_SUCCESS');
export const editInfoIctFoundsFailure = createAction('INFO_ICT_FOUNDS_EDIT_FAILURE');

export const editInfoIctFounds = (data) => async (dispatch) => {
  dispatch(editInfoIctFoundsRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionEightInfoIctFoundsOperations(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data,
    });
    dispatch(getInfoIctFounds());
  } catch (e) {
    dispatch(setInfoIctFoundsError(e?.response));
    dispatch(editInfoIctFoundsFailure());
  }
};
