import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';

const ExpertAssessment = ({
  assessment,
  onSubmit,
  onDelete,
  isExpert,
  evaluations = [],
  showComment = true,
  isVisible = true,
  enable_notAssessed = true,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const point = assessment?.point;
  const comment = assessment?.description;
  const label = assessment?.label;

  const _point =
      (Number(point) === -1 && enable_notAssessed ) ? <FormattedMessage id="section51.notAssessed" defaultMessage="Исключено" /> : point;
  if (!isVisible) return null;
  if (isExpert)
    return point ? (
      <div className="assessment">
        <div className="titleContainer">
          <h2 className="title">
            {label ? (
              label
            ) : (
              <FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />
            )}
          </h2>
        </div>
        <div className="w50 mt2">
          <div className="assessment__score mb">
            <FormattedMessage id="expertAssessment.assesmentTitle" defaultMessage="Оценка" />: <strong>{_point}</strong>
          </div>
          <div className="assessment__comment">
            <FormattedMessage id="expertAssessment.commentItem" defaultMessage="Комментарий" />: &nbsp;{' '}
            <span className="mt">{comment}</span>
          </div>
        </div>
        <Button onClick={onDelete} variant="contained" color="secondary" className="mt2">
          <FormattedMessage id="global.deleteItem" defaultMessage="Удалить" />
        </Button>
      </div>
    ) : (
      <form onSubmit={handleSubmit(onSubmit)} className="assessment">
        <label className="nativeLabel" style={{ fontSize: '1.5em' }}>
          {label ? (
            label
          ) : (
            <FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />
          )}
        </label>
        <div className="w80 mt3">
          <FormControl component="fieldset" className="mb" fullWidth>
            <FormLabel component="legend">
              <FormattedMessage id="expertAssessment.assesmentTitle" defaultMessage="Оценка" />
            </FormLabel>
            <NativeSelect error={!!errors.point} name="point" inputProps={{ ref: register({ required: true }) }}>
              <option value=""></option>
              {evaluations.map((evaluation) => {
                if (Number(evaluation.value) === -1)
                  return (
                    <option key={evaluation.value} value={evaluation.value}>
                      {evaluation.title}
                    </option>
                  );
                return (
                  <option key={evaluation.value} value={evaluation.value}>
                    {evaluation.value} - {evaluation.title}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <TextField inputRef={register} name="description" fullWidth label="Комментарий" className="mb3" />
          <Button type="submit" variant="contained" color="primary" className="m0Auto">
            <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
          </Button>
        </div>
      </form>
    );

  return (
    <>
      <div className="titleContainer mb3">
        <div className="titleBorder"></div>
        <h2 className="title">
          {label ? (
            label
          ) : (
            <FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />
          )}
        </h2>
        <div className="titleBorder"></div>
      </div>
      {point ? (
        <div className="assessment">
          <div className="w50">
            <div className="assessment__score mb">
              <FormattedMessage id="expertAssessment.assesmentTitle" defaultMessage="Оценка" /> :{' '}
              <strong>{_point}</strong>
            </div>
            {showComment && (
              <div className="assessment__comment">
                <FormattedMessage id="expertAssessment.commentItem" defaultMessage="Комментарий" /> : &nbsp;
                <span className="mt">{comment}</span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="assessment__no mt3">
          <FormattedMessage id="expertAssessment.noAssessment" defaultMessage="Нет оценки" />
        </div>
      )}
    </>
  );
};

export default ExpertAssessment;
