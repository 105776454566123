import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Dashboard from 'components/Dashboard';

function Layout({ children }) {
  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">{children}</Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default Layout;
