import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton, TableCell } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Button,
  FormControl,
  Select,
  MenuItem,
  withStyles,
} from '@material-ui/core';

import {
  addDrawerItem,
  removeDrawerItem,
  getProjects,
  deleteProject,
  getStatisticsAllProjects,
  getLabels,
} from 'actions';
import { StyledTableRow, useStyles } from 'components/reused/Table';
import Dialog from 'components/reused/FullScreenDialog';
import SimpleDialog from 'components/reused/SimpleDialog';
import Title from 'components/reused/Title';
import Loader from 'components/reused/Spinner';
import ProjectForm from 'monitoring/Project/Form';
import { useDialog } from 'common/hooks';
import { get, getAct } from 'actions';
import Empty from 'components/reused/Empty';
import ProjectStats from '../reused/ProjectStats';
import CounterPage from 'components/reused/CounterPage';

import { getRoadmaps } from '../../actions/monitoring';

function ProjectList() {
  const { id, roadmapId, statusId } = useParams();

  const { url } = useRouteMatch();
  const [labels, setLabels] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [stepStatuses, setStepStatuses] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [statisticsAllRoadmaps, setStatisticsAllRoadmaps] = useState([]);

  useEffect(() => {
    const filter = {};
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (!statusId && roadmapId) {
      filter.roadmap_id = roadmapId;
    } else if (statusId && !roadmapId) {
      getStatisticsAllProjects(id).then((data) => {
        if (active) setStatisticsAllRoadmaps(data);
      });
      if (statusId === '0') {
        setStatus('all');
        filter.document_id = id;
      } else if (statusId === '4') {
        filter.document_id = id;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.document_id = id;
        filter.status_id = statusId;
      }
    } else if (statusId && roadmapId) {
      if (statusId === '4') {
        filter.roadmap_id = roadmapId;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.roadmap_id = roadmapId;
        filter.status_id = statusId;
      }
    }
    dispatch(getProjects(filter, page));

    let active = true;
    get('project-status-types').then((data) => {
      if (active) setStatuses([...data, { id: '4', title: 'Срок исполнения составляет менее 30 дней' }]);
    });
    get('step-status-types').then((data) => {
      if (active) setStepStatuses(data);
    });
    get('organizations').then((data) => {
      if (active) setOrganizations(data);
    });
    getLabels('project').then((data) => {
      if (active) setLabels(data);
    });
    return () => (active = false);
  }, []);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { roadmaps } = useSelector((state) => state.m_documents);
  const projects = useSelector((state) => state.m_projects.list);
  const acts = useSelector((state) => state.m_documents.acts);
  const pagination = useSelector((state) => state.m_projects.pagination);
  const { loading } = useSelector((state) => state.m_documentsUI);
  const currentUser = useSelector((state) => state.profile.currentUser);
  const isModerator = currentUser?.roles.includes('monitoring_moderator');
  const isSupervisor = currentUser?.roles.includes('monitoring_supervisor');

  const roadmap = roadmaps.find((_roadmap) => _roadmap.id === parseInt(roadmapId));
  const act = acts.find((_act) => _act.id === parseInt(id));

  const structureStatistics = (array = []) => {
    const resultArray = array
      .map((el) => {
        let title = statusId ? el[`title_${localStorage.lang || 'ru'}`] : el.title;
        return el.is_expire
          ? { status_id: 4, title, count: el.cnt }
          : { status_id: el.status_id, title, count: el.cnt };
      })
      .sort((a, b) => a.status_id - b.status_id);
    const allStatusesProject = array.reduce((acc, stat) => (stat.is_expire ? acc : acc + stat.cnt), 0);
    resultArray.push({ status_id: 0, title: 'Общее кол-во проектов', count: allStatusesProject });
    return resultArray;
  };

  const projectStats = roadmap?.project_stat || [];
  const finalProjectAllStats = structureStatistics(projectStats);
  const finalRoadmapsProjectsAllStats = structureStatistics(statisticsAllRoadmaps);

  const handleClickProjectsStat = (id) => () => {
    const filter = {};
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    if (!statusId && roadmapId) {
      filter.roadmap_id = roadmapId;
      if (id === 4) {
        filter.end_date = endDate;
        filter.status_id = 2;
      } else if (id !== 0) {
        filter.status_id = id;
      }
    }
    if (statusId && !roadmapId) {
      if (id === 4) {
        filter.end_date = endDate;
        filter.status_id = 2;
      } else if (id !== 0) {
        filter.status_id = id;
      }
    }
    if (!!organization) {
      filter.organization_id = organization.id;
    }
    if (stepStatus !== 'all') {
      filter.step_stat = stepStatus;
    }
    dispatch(getProjects(filter, page));
  };

  useEffect(() => {
    if (!act) {
      dispatch(getAct(id));
    }
  }, [act]);

  useEffect(() => {
    if (!roadmap && !statusId) {
      dispatch(getRoadmaps(id));
    }
  }, [roadmap]);

  const [open, handleOpen, handleClose] = useDialog();
  const handleAdd = () => {
    dispatch(removeDrawerItem(`${url}`));
    if (roadmap) {
      dispatch(addDrawerItem({ path: `${url}`, title: roadmap?.short_title }));
    }
  };

  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const [projectDeleteId, setProjectDeleteId] = useState(null);
  const handleModalDelete = (id) => () => {
    handleOpenDelete();
    setProjectDeleteId(id);
  };

  const handleDelete = () => {
    handleCloseDelete();
    dispatch(deleteProject(projectDeleteId));
  };

  const [page, setPage] = useState(parseInt(sessionStorage.getItem('projectPaginationPage')) || 1);
  const handleChange = (event, value) => {
    sessionStorage.setItem('projectPaginationPage', value);
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    setPage(value);
    const filter = {};
    if ((!statusId && roadmapId) || (statusId && roadmapId)) {
      if (status === 'all') {
        filter.roadmap_id = roadmapId;
      } else if (status === '4') {
        filter.roadmap_id = id;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.roadmap_id = roadmapId;
        filter.status_id = status;
      }
    } else if (statusId && !roadmapId) {
      if (statusId === '0') {
        filter.document_id = id;
      } else if (status === '4') {
        filter.document_id = id;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.document_id = id;
        filter.status_id = statusId;
      }
    }
    if (!!organization) {
      filter.organization_id = organization.id;
    }
    if (stepStatus !== 'all') {
      filter.step_stat = stepStatus;
    }
    dispatch(getProjects(filter, value));
  };

  const [status, setStatus] = useState(statusId || sessionStorage.getItem('roadmapStatus') || 'all');
  const handleChangeStatus = (event) => {
    sessionStorage.removeItem('projectPaginationPage');
    const value = event.target.value;
    sessionStorage.setItem('roadmapStatus', value);

    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    setStatus(value);
    const filter = {};
    if ((!statusId && roadmapId) || (statusId && roadmapId)) {
      if (value === 'all') {
        filter.roadmap_id = roadmapId;
      } else if (value === '4') {
        filter.roadmap_id = roadmapId;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.roadmap_id = roadmapId;
        filter.status_id = value;
      }
    } else if (statusId && !roadmapId) {
      if (value === 'all') {
        filter.document_id = id;
      } else if (value === '4') {
        filter.document_id = id;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.document_id = id;
        filter.status_id = value;
      }
    }
    if (!!organization) {
      filter.organization_id = organization.id;
    }
    if (stepStatus !== 'all') {
      filter.step_stat = stepStatus;
    }
    dispatch(getProjects(filter, page));
  };

  const [stepStatus, setStepStatus] = useState('all');
  const handleChangeStepStatus = (event) => {
    const value = event.target.value;
    setStepStatus(value);
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const filter = {};
    if ((!statusId && roadmapId) || (statusId && roadmapId)) {
      if (status === 'all') {
        filter.roadmap_id = roadmapId;
        if (value !== 'all') {
          filter.step_stat = value;
        }
      } else if (status === '4') {
        filter.roadmap_id = roadmapId;
        filter.step_stat = value;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.roadmap_id = roadmapId;
        filter.step_stat = value;
        filter.status_id = status;
      }
    } else if (statusId && !roadmapId) {
      if (status === 'all') {
        filter.document_id = id;
        filter.step_stat = value;
      } else if (status === '4') {
        filter.document_id = id;
        filter.step_stat = value;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.document_id = id;
        filter.step_stat = value;
        filter.status_id = status;
      }
    }
    if (!!organization) {
      filter.organization_id = organization.id;
    }
    dispatch(getProjects(filter, page));
  };

  const [organization, setOrganization] = useState(null);
  const handleChangeOrganization = (autocompleteData) => {
    const value = autocompleteData?.id;
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30).toLocaleDateString('uz-UZ', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    setOrganization(autocompleteData);
    const filter = {};
    if ((!statusId && roadmapId) || (statusId && roadmapId)) {
      if (status === 'all') {
        filter.roadmap_id = roadmapId;
        filter.organization_id = value;
      } else if (status === '4') {
        filter.roadmap_id = roadmapId;
        filter.organization_id = value;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.roadmap_id = roadmapId;
        filter.organization_id = value;
        filter.status_id = status;
      }
    } else if (statusId && !roadmapId) {
      if (status === 'all') {
        filter.document_id = id;
        filter.organization_id = value;
      } else if (status === '4') {
        filter.document_id = id;
        filter.organization_id = value;
        filter.end_date = endDate;
        filter.status_id = 2;
      } else {
        filter.document_id = id;
        filter.organization_id = value;
        filter.status_id = status;
      }
    }
    if (stepStatus !== 'all') {
      filter.step_stat = stepStatus;
    }
    dispatch(getProjects(filter, page));
  };

  const style = {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    borderRadius: 0,
  };

  return (
    <>
      <div className="spaceBetween mb3 mt2">
        {!roadmap ? (
          <Title title={`Проекты по ${act?.short_title}`} disableBorder />
        ) : (
          <Title title={roadmap?.short_title} disableBorder />
        )}
        {isModerator && !statusId && (
          <Button variant="contained" color="primary" onClick={handleOpen}>
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          </Button>
        )}
      </div>
      {!statusId ? (
        <ProjectStats stats={finalProjectAllStats} callback={handleClickProjectsStat} isBtns />
      ) : (
        <ProjectStats stats={finalRoadmapsProjectsAllStats} callback={handleClickProjectsStat} isBtns />
      )}
      <Dialog dialogState={open} onClose={handleClose}>
        <ProjectForm labels={labels} closeDialog={handleClose} />
      </Dialog>
      {projects.length !== 0 && <CounterPage config={pagination} page={page} length={projects.length} />}
      <TableContainer component={Paper} className={classes.container}>
        {loading ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ minWidth: 120, ...style }} align="center" rowSpan="2">
                  №
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  {labels[`title_${localStorage.lang || 'ru'}`]}
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  {labels.organization_id}
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  {labels.status_id}
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  {labels.amount_forecast_funds}
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  {labels.financial_sources}
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  <FormattedMessage id="monitoring.roadmaps.stepStatistic" defaultMessage="Статистика по этапам" />
                </StyledTableCell>
                {isSupervisor && <StyledTableCell style={style} />}
              </TableRow>
              <TableRow>
                <StyledTableCell style={style} align="center" />
                <StyledTableCell style={style} align="center">
                  <Autocomplete
                    style={{ width: 300, display: 'inline-block' }}
                    options={organizations}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    noOptionsText="Нет подходящего элемента в списке"
                    onChange={(e, data) => handleChangeOrganization(data)}
                    value={organization}
                    renderOption={(option) => <React.Fragment>{option.title}</React.Fragment>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                      />
                    )}
                  />
                </StyledTableCell>
                <StyledTableCell style={style} align="center">
                  <FormControl style={{ width: 150 }}>
                    <Select
                      className="w100"
                      labelId="status-select-label"
                      id="status-select"
                      value={status}
                      onChange={handleChangeStatus}>
                      <MenuItem value="all">Все</MenuItem>
                      {statuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
                <StyledTableCell style={style} align="center" />
                <StyledTableCell style={style} align="center" />
                <StyledTableCell style={style} align="center">
                  <FormControl style={{ width: 150 }}>
                    <Select
                      className="w100"
                      labelId="step-status-select-label"
                      id="step-status-select"
                      value={stepStatus}
                      onChange={handleChangeStepStatus}>
                      <MenuItem value="all">Все</MenuItem>
                      {stepStatuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledTableCell>
                {isSupervisor && <StyledTableCell style={style} align="center" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => {
                let color = '';
                switch (project.status_id) {
                  case 1:
                    color = '#00ff0060';
                    break;
                  case 2:
                    color = '#0000ff60';
                    break;
                  case 3:
                    color = 'orange';
                    break;
                  case 4:
                    color = '#ffff00';
                    break;
                  case 5:
                    color = 'violet';
                    break;
                  case 6:
                    color = '#87d0fb';
                    break;
                  case 7:
                    color = 'red';
                    break;
                  default:
                    color = '#fff';
                }
                return (
                  <StyledTableRow key={project.id}>
                    <StyledTableCell align="center">{project.project_no}</StyledTableCell>
                    <StyledTableCell>
                      <NavLink className="m_link" onClick={handleAdd} to={`${url}/projects/${project.id}`}>
                        {project.title}
                      </NavLink>
                    </StyledTableCell>
                    <StyledTableCell>{project.organization_name}</StyledTableCell>
                    {/* <StyledTableCell>{project.co_executors_names.map((el) => el.title).join(', ')}</StyledTableCell> */}
                    <StyledTableCell align="center" style={{ backgroundColor: color }}>
                      {project.status_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{project.amount_forecast_funds}</StyledTableCell>
                    <StyledTableCell align="center">
                      {project.financial_source_names.map((el) => el.title).join(', ')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="container-statuses">
                        {project.step_stat
                          .sort((a, b) => a.status_id - b.status_id)
                          .map((el) => {
                            let color = '';
                            switch (el.status_id) {
                              case 1:
                                color = '#00ff0060';
                                break;
                              case 2:
                                color = '#0000ff60';
                                break;
                              case 3:
                                color = 'red';
                                break;
                              case 4:
                                color = '#ffff00';
                                break;
                              default:
                                color = '#fff';
                            }
                            return (
                              <div
                                key={el.status_id}
                                style={{ border: `2px solid ${color}`, margin: 2, borderRadius: 25 }}>
                                <NavLink
                                  className="status_link"
                                  to={`${url}/projects/${project.id}/step-status/${el.status_id}`}
                                  title={el.title}>
                                  {el.cnt}
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    </StyledTableCell>
                    {isSupervisor && (
                      <StyledTableCell>
                        <IconButton edge="end" color="secondary" onClick={handleModalDelete(project.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              })}

              {projects.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={isSupervisor ? 7 : 6}>
                    <Empty title="Данных нет" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {pagination.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt2">
          <Pagination
            count={pagination.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            variant="outlined"
            color="primary"
          />
        </div>
      )}

      <SimpleDialog title="Подтвердить удаление" dialogState={openDelete} onClose={handleCloseDelete}>
        <Button className="mr ml mt mb" variant="contained" color="primary" onClick={handleCloseDelete}>
          Закрыть
        </Button>
        <Button className="mr ml mt mb" variant="contained" color="secondary" onClick={handleDelete}>
          Удалить?
        </Button>
      </SimpleDialog>
    </>
  );
}

export default ProjectList;

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F2FF',
    color: '#4656B8',
    borderBottom: 0,
    fontWeight: 700,
    fontSize: 14,
    fontFamily: '"Montserrat", sans-serif',
    [theme.breakpoints.up('md')]: {
      maxWidth: 500,
    },
    '&:first-child': {
      borderRadius: '25px 0 0 25px',
    },
    '&:last-child': {
      borderRadius: '0 25px 25px 0',
    },
  },
  body: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: 500,
    },
    fontSize: 14,
    padding: '16px 40px',
    borderBottom: '1px solid #F3F3F3',
    fontFamily: '"Montserrat", sans-serif',
  },
}))(TableCell);
