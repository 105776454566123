import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import { CircularProgress, FormLabel, NativeSelect, Button, TextField } from '@material-ui/core';

import Autocomplete from 'components/reused/Autocomplete';
import { get, generateHeaders } from 'actions';
import routes from 'monitoring/routes';
import { useDispatch } from 'react-redux';
import { editCurrentProject, getProjectLogs } from 'actions';

function Form({ labels, closeDialog, defaultValues, orgId }) {
  const dispatch = useDispatch();
  const { roadmapId, id, projectId } = useParams();
  const [organizations, setOrganizations] = useState([]);
  const [financingSources, setFinancingSources] = useState([]);
  const [directionTypes, setDirectionTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);

  useEffect(() => {
    let active = true;
    get('organizations').then((data) => {
      if (active) {
        setOrganizations(data);
      }
    });

    get('financing-sources').then((data) => {
      if (active) {
        setFinancingSources(data);
      }
    });

    get('direction-types').then((data) => {
      if (active) {
        setDirectionTypes(data);
        reset({ ...defaultValues, direction_type_id: defaultValues.direction_type_id });
      }
    });

    return () => (active = false);
  }, []);

  const { register, handleSubmit, errors, control, reset } = useForm({ defaultValues });
  const onSubmit = (data) => {
    setLoading(true);
    axios({
      url: routes.project(projectId),
      method: 'put',
      data: {
        ...data,
        co_executors: data.co_executors.map((el) => el.id),
        financial_sources: data.financial_sources.map((el) => el.id),
        roadmap_id: roadmapId,
        document_id: id,
        organization_id: orgId,
      },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then((response) => {
        setValidation([]);
        dispatch(editCurrentProject(response.data));
        dispatch(getProjectLogs(projectId, 1));
        setLoading(false);
        closeDialog();
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setValidation(err.response.data);
        }
        setLoading(false);
      });
  };

  return (
    <>
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      <form onSubmit={handleSubmit(onSubmit)} className="mt mb">
        <TextField
          inputRef={register({ required: true })}
          name="project_no"
          error={!!errors.project_no}
          className="mb"
          label={'№'}
          type="number"
          fullWidth
        />
        <TextField
          inputRef={register({ required: true })}
          name="title_uz"
          error={!!errors.title_uz}
          className="mb"
          label={labels.title_uz}
          fullWidth
        />
        <TextField
          inputRef={register({ required: true })}
          name="title_ru"
          error={!!errors.title_ru}
          className="mb"
          label={labels.title_ru}
          fullWidth
        />

        <Autocomplete
          control={control}
          options={financingSources}
          name="financial_sources"
          label={labels.financial_sources}
        />
        <FormLabel component="legend">{labels.direction_type_id}</FormLabel>
        <NativeSelect
          inputProps={{ ref: register({ required: true }) }}
          name="direction_type_id"
          error={!!errors.direction_type_id}
          className="w100 mb">
          <option />
          {directionTypes.map((item) => (
            <option key={item.id} value={item.id}>
              {item.title}
            </option>
          ))}
        </NativeSelect>
        <Autocomplete control={control} name="co_executors" options={organizations} label={labels.co_executors} />
        <TextField
          name="comment"
          multiline
          rowsMax="4"
          label={<FormattedMessage id="monitoring.form.commentTitle" defaultMessage="Комментарий" />}
          inputRef={register}
          type="text"
          className="mb2"
          fullWidth
        />

        <Button type="submit" color="primary" variant="contained" className="m0Auto mt" style={{ minWidth: 110 }}>
          {loading ? <CircularProgress color="inherit" size={30} /> : <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />}
        </Button>
      </form>
    </>
  );
}

export default Form;
