import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert, AlertTitle } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  Button,
  FormControl,
  FormLabel,
  NativeSelect,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import TableInfoSec from '../reused/TableInfoSec';
import Dialog from '../reused/SimpleDialog';
import Loader from '../reused/Loader';
import { useDialog } from '../../common/hooks';
import {
  getSections,
  getCyberSecurityInfo,
  uploadFile,
  updateEvalActivationInfo,
  toggleTabState,
  getEvalActivationInfo,
} from '../../actions';
import { StyledTableCell, StyledTableRow, useStyles } from '../reused/Table';
import { findParent } from '../../common/utils';
import { getCyberSecurityEvaluation, postCyberSecurityInfo } from '../../actions/cyberSecurity';
import Download from '../reused/Download';
import Error from '../reused/Error';
import EvaluationActivation from '../reused/EvaluationActivation';

const defaultDate = new Intl.DateTimeFormat('uz', { dateStyle: 'short' }).format(Date.now());
const generateDefaultValues = (data) =>
  data.reduce((acc, el) => {
    if (!el.depends)
      return {
        ...acc,
        [el.meta_field_id]: el.column_type === 'file' ? '' : el.value,
        [el.meta_field_id]: el.column_type === 'date' ? defaultDate : el.value,
      };
    return { ...acc, [el.meta_field_id]: el.value, ...generateDefaultValues(el.depends) };
  }, {});

const generateFileValue = (value) => (value ? <Download fileName={value} /> : '');

const Form = ({ fields, submitAction }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const defaultValues = useMemo(() => generateDefaultValues(fields), [fields]);
  const { register, handleSubmit, watch, errors } = useForm({ defaultValues });

  const generateField = (field) => {
    const select = (
      <FormControl className="w100 mb mt" key={field.meta_field_id}>
        <FormLabel>{field.title}</FormLabel>

        <NativeSelect
          inputProps={{ ref: register({ required: true }) }}
          error={!!errors[field.meta_field_id]}
          name={`${field.meta_field_id}`}>
          <option value=""></option>
          <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
          <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
        </NativeSelect>
      </FormControl>
    );
    const textField = (
      <TextField
        key={field.meta_field_id}
        name={`${field.meta_field_id}`}
        inputRef={register({ required: true })}
        error={!!errors[field.meta_field_id]}
        className="mb"
        label={field.title}
        fullWidth
      />
    );
    const dateField = (
      <React.Fragment key={field.meta_field_id}>
        <label htmlFor="date" className="nativeLabel">
          {field.title}
        </label>
        <TextField
          name={`${field.meta_field_id}`}
          inputRef={register({ required: true })}
          error={!!errors[field.meta_field_id]}
          type="date"
          className="mb"
          fullWidth
        />
      </React.Fragment>
    );

    const fileField = (
      <div key={field.meta_field_id} className="btn mt w100">
        <FormLabel>{field.title}</FormLabel>
        <div className="btn__fileUpload mt">
          <div className="btn-wrap">
            <label className={`btn mb ${!!errors[`file${field.meta_field_id}`] && 'error'}`} htmlFor="upload">
              <FormattedMessage id="global.attachFileTitle" defaultMessage="Вложить файл" />
              &nbsp;
              <GetAppIcon />
            </label>
            <input
              name={'file' + field.meta_field_id}
              id="upload"
              type="file"
              ref={register({ required: !field.value })}
            />
          </div>
        </div>
      </div>
    );

    if (field.parent_id) {
      const parent = findParent(fields, field.parent_id);
      const watchParent = watch(JSON.stringify(field.parent_id));
      const visibleCondition = watchParent === field.parent_value;
      if (parent.column_type === 'select') {
        if (field.column_type === 'select') return visibleCondition && select;

        if (field.column_type === 'string') return visibleCondition && textField;

        if (field.column_type === 'date') return visibleCondition && dateField;

        if (field.column_type === 'file') return visibleCondition && fileField;
      }
    }

    if (field.column_type === 'select') return select;

    if (field.column_type === 'string') return textField;

    if (field.column_type === 'date') return dateField;

    if (field.column_type === 'file') return fileField;
  };

  const generateFieldWithDependFields = (field) => {
    if (!field.depends) return generateField(field);
    return (
      <React.Fragment key={field.meta_field_id}>
        {generateField(field)}
        {field.depends.map((depend) => generateFieldWithDependFields(depend))}
      </React.Fragment>
    );
  };

  const onSubmit = async (formValues) => {
    const promises = Object.keys(formValues).map(async (id) => {
      if (id.includes('file')) {
        const isEditMode = defaultValues[id];
        let filePath;
        if (isEditMode && formValues[id] === '') filePath = defaultValues[id];
        else filePath = await uploadFile(formValues[id], 4.1);
        return { id: id.substring(4), value: filePath };
      }

      return { id, value: formValues[id] };
    });
    const metaData = await Promise.all(promises);

    // const metaIdList = Object.keys(formValues);
    // let metaData = [];

    // for (const id of metaIdList) {
    //   if (id.includes('file')) {
    //     const isEditMode = defaultValues[id];
    //     let filePath;
    //     if (isEditMode && formValues[id] === '') filePath = defaultValues[id];
    //     else filePath = await uploadFile(formValues[id], 4.1);

    //     metaData.push({ id: id.substring(4), value: filePath });
    //   } else metaData.push({ id, value: formValues[id] });
    // }

    const data = {
      section_id: fields[0].section_id,
      metadata: metaData,
    };
    dispatch(postCyberSecurityInfo(data));
    submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mAuto">
      {fields.map((field) => generateFieldWithDependFields(field))}
      <Button type="submit" variant="contained" color="primary" className="mt2">
        <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
      </Button>
    </form>
  );
};

export default function Section4({ tabId }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    dispatch(getSections(tabId, 'accordions'));
    dispatch(getCyberSecurityEvaluation(tabId));
    dispatch(getEvalActivationInfo(tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { accordions, accordionContent } = useSelector((state) => state.dictionary, shallowEqual);
  const { fetchingAccordionContent, fetchingAccordions } = useSelector((state) => state.dictionaryUI, shallowEqual);
  const { cyberSecurityInfo, evaluation, error } = useSelector((state) => state.cyberSecurity, shallowEqual);
  const fetchingCyberSecurity = useSelector((state) => state.cyberSecurityUI.requestState, shallowEqual);

  const [expanded, setExpanded] = useState(false);
  const handleChange = (id) => (_, isExpanded) => {
    setExpanded(isExpanded ? id : false);
    if (isExpanded) dispatch(getSections(id, 'accordionContent'));
  };

  const [editDialog, handleOpenEdit, handleCloseEdit] = useDialog();
  const [viewDialog, handleOpen, handleClose] = useDialog();
  const handleOpenView = (id) => () => {
    dispatch(getCyberSecurityInfo(id));
    handleOpen();
  };

  const handleEdit = (id) => () => {
    handleOpenEdit();
    dispatch(getCyberSecurityInfo(id));
  };
  const classes = useStyles();

  const generateRow = (row) => {
    const value = row.translated_value ? row.translated_value : row.value;

    const _row = (
      <StyledTableRow>
        <StyledTableCell>{row.title}</StyledTableCell>
        <StyledTableCell align="right">{row.column_type === 'file' ? generateFileValue(value) : value}</StyledTableCell>
      </StyledTableRow>
    );

    if (!row.depends && !row.parent_id) return _row;

    if (!row.depends && row.parent_id) {
      const parent = findParent(cyberSecurityInfo, row.parent_id);
      return row.parent_value === parent.value && _row;
    }

    return (
      <React.Fragment key={row.meta_field_id}>
        {_row}
        {row.depends.map((depend) => generateRow(depend))}
      </React.Fragment>
    );
  };

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isCkExpert = currentUser?.roles.includes('expert-ck');

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  if (error.hasError) return <Error error={error} />;

  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(tabId, data));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  return (
    <div>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <Alert className="cyberSecurity__alert" severity="info">
        <AlertTitle>{evaluation.alert}</AlertTitle>
        {evaluation.data.label} — <strong> {evaluation.data.point}</strong>
        <a
          href="https://sm.csec.uz"
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'block', marginTop: 10, color: '#4656b8' }}>
          <FormattedMessage
            id="section40.link"
            defaultMessage="Система мониторинга информационной и кибербезопасности"
          />
        </a>
      </Alert>
      {fetchingAccordions === 'request' ? (
        <Loader />
      ) : (
        accordions.map((accordion) => (
          <Accordion key={accordion.id} expanded={expanded === accordion.id} onChange={handleChange(accordion.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography>
                {accordion.short_title}&nbsp; {accordion.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {fetchingAccordionContent === 'request' ? (
                <Loader />
              ) : (
                <TableInfoSec rows={accordionContent} onOpen={handleOpenView} onEdit={handleEdit} />
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}

      <Dialog dialogState={editDialog} onClose={handleCloseEdit} title="Информационная кибербезопасность">
        {fetchingCyberSecurity === 'request' ? (
          <div style={{ width: 500 }}>
            <Loader />
          </div>
        ) : (
          <Form fields={cyberSecurityInfo} submitAction={handleCloseEdit} />
        )}
      </Dialog>

      <Dialog
        dialogState={viewDialog}
        onClose={handleClose}
        title={intl.formatMessage({
          id: 'section40.infoSecurity',
          defaultMessage: 'Информационная безопасность',
        })}>
        <TableContainer component={Paper} className={classes.container}>
          {fetchingCyberSecurity === 'request' ? (
            <div style={{ width: 500 }}>
              <Loader />
            </div>
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    <FormattedMessage id="section40.questionTitle" defaultMessage="Наименование вопроса" />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <FormattedMessage id="section40.inputData" defaultMessage="Вводимые данные" />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{cyberSecurityInfo.map((row) => generateRow(row))}</TableBody>
            </Table>
          )}
        </TableContainer>
      </Dialog>
      <EvaluationActivation isExpert={isCkExpert} onSubmit={updateEvalActivation}></EvaluationActivation>
    </div>
  );
}

export const Section41 = () => <Section4 tabId={35} />;
export const Section42 = () => <Section4 tabId={54} />;
export const Section43 = () => <Section4 tabId={127} />;
