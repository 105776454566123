import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const linksMessengerChannels = handleActions(
  {
    [actions.getChannelsSuccess]: (state, { payload }) => {
      return {
        ...state,
        channels: payload,
      };
    },
    [actions.getChannelsLabelsSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.addChannelLinkSuccess]: (state, { payload }) => {
      return {
        ...state,
        channels: [...state.channels, payload],
      };
    },
    [actions.deleteChannelLinkSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        channels: state.channels.filter((el) => el.id !== id),
      };
    },
    [actions.editChannelLinkSuccess]: (state, { payload }) => {
      return {
        ...state,
        channels: state.channels.map((channel) => (channel.id === payload.id ? payload : channel)),
      };
    },
    [actions.getChannelLinkSuccess]: (state, { payload }) => {
      return {
        ...state,
        channel: payload,
      };
    },
    [actions.setChannelsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    channels: [],
    labels: {},
    channel: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const linksMessengerChannelsUI = handleActions(
  {
    [actions.getChannelsLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getChannelsLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getChannelsLabelsFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editChannelLinkRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editChannelLinkSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editChannelLinkFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getChannelLinkRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getChannelLinkSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getChannelLinkFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
