import Swal from 'sweetalert2';

export const isEmpty = (obj) => {
  for (let key in obj) {
    // если тело цикла начнет выполняться - значит в объекте есть свойства
    return false;
  }
  return true;
};

export const validateDate = (value) => /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i.test(value);

export const findParent = (tree, parentId) => {
  const parent = tree
    .map((el) => {
      if (el.depends && el.meta_field_id === parentId) return el;
      if (el.depends) return findParent(el.depends, parentId);
      return null;
    })
    .filter((el) => el !== null);

  return parent.length === 1 ? parent[0] : null;
};

export const sum = (allFields) => {
  const total = Object.values(allFields).reduce(
    (acc, el) => {
      const quarter1_fact = parseFloat(acc.quarter1_fact) + parseFloat(el.quarter1_fact);
      const quarter1_plan = parseFloat(acc.quarter1_plan) + parseFloat(el.quarter1_plan);
      const quarter2_fact = parseFloat(acc.quarter2_fact) + parseFloat(el.quarter2_fact);
      const quarter2_plan = parseFloat(acc.quarter2_plan) + parseFloat(el.quarter2_plan);
      const quarter3_fact = parseFloat(acc.quarter3_fact) + parseFloat(el.quarter3_fact);
      const quarter3_plan = parseFloat(acc.quarter3_plan) + parseFloat(el.quarter3_plan);
      const quarter4_fact = parseFloat(acc.quarter4_fact) + parseFloat(el.quarter4_fact);
      const quarter4_plan = parseFloat(acc.quarter4_plan) + parseFloat(el.quarter4_plan);
      return {
        quarter1_fact,
        quarter1_plan,
        quarter2_fact,
        quarter2_plan,
        quarter3_fact,
        quarter3_plan,
        quarter4_fact,
        quarter4_plan,
      };
    },
    {
      quarter1_fact: 0,
      quarter1_plan: 0,
      quarter2_fact: 0,
      quarter2_plan: 0,
      quarter3_fact: 0,
      quarter3_plan: 0,
      quarter4_fact: 0,
      quarter4_plan: 0,
    }
  );
  return total;
};

export const parse = (n) => parseFloat(n || 0);

export const setLang = (lang) => ({ language: lang || 'ru' });

export const convertDate = (date) => {
  const wrapDate = date ? new Date(date) : date;
  return wrapDate?.toLocaleDateString('uz-UZ', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getPercent = (firstNum, secondNum) => `${Math.round((firstNum / secondNum) * 100)}%`;

export const handleValidationError = (error) => {
  if (error.response) {
    if (error.response.status === 422) {
      Swal.fire({
        icon: 'error',
        html: error.response.data.error.reduce((acc, err) => acc + `<h1>${err.message}</h1>`, ''),
      });
    }
  }
};
