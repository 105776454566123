import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const evaluationActivation = handleActions(
  {
    [actions.getEvalActivationInfoSuccess]: (state, { payload }) => payload,
    [actions.updateEvalActivationInfoSuccess]: (state, { payload }) => payload,
  },
  {
    is_active: {
      code_name: 'is_active',
    },
    reason_deactivation: {
      code_name: 'reason_deactivation',
    },
  }
);

export const evaluationActivationUI = handleActions(
  {
    [actions.getEvalActivationInfoRequest]: () => ({ requestState: 'request' }),
    [actions.getEvalActivationInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.getEvalActivationInfoFailure]: () => ({ requestState: 'failure' }),
    [actions.updateEvalActivationInfoRequest]: () => ({ requestState: 'request' }),
    [actions.updateEvalActivationInfoSuccess]: () => ({ requestState: 'success' }),
    [actions.updateEvalActivationInfoFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
