import React, { useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Home from 'regions/Home';
import Organization from 'regions/components/Organization';
import DetailAssessment from 'regions/components/DetailAssessment';
import ExcludeIndicators from 'regions/components/ExcludeIndicators';
import Rating from 'regions/components/Rating';
import Support from 'components/reused/Support';
import { getOrganizationTables } from 'actions';

function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  const fetchingProfile = useSelector((state) => state.profileUI.requestingState, shallowEqual);

  useEffect(() => {
    // в случае если токен устарел идет redirect на страницу входа
    if (fetchingProfile === 'success') {
      dispatch(getOrganizationTables());
    }
    if (fetchingProfile === 'failure') {
      history.push('/sign-in');
      localStorage.removeItem('token');
      localStorage.removeItem('orgId');
      localStorage.removeItem('orgTitle');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingProfile]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/organization/:id" component={Organization} />
      <Route path="/assessment" component={DetailAssessment} />
      <Route path="/excluded-indicator" component={ExcludeIndicators} />
      <Route path="/support" component={Support} />

      <Route path="/rating" component={Rating} />
      {/* <Route path="/sign-in" component={SignIn} /> */}
    </Switch>
  );
}

export default App;
