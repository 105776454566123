import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const orgInfoIctManager = handleActions(
  {
    [actions.getInfoIctManagerSuccess]: (state, { payload }) => {
      return {
        ...state,
        data: payload,
      };
    },
    [actions.getInfoIctLabelsSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.setInfoIctManagerError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    data: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const orgInfoIctManagerUI = handleActions(
  {
    [actions.getInfoIctManagerRequest]: () => ({ requestState: 'request' }),
    [actions.getInfoIctManagerSuccess]: () => ({ requestState: 'success' }),
    [actions.getInfoIctManagerFailure]: () => ({ requestState: 'failure' }),
    [actions.editInfoIctManagerRequest]: () => ({ requestState: 'request' }),
    [actions.editInfoIctManagerSuccess]: () => ({ requestState: 'success' }),
    [actions.editInfoIctManagerFailure]: () => ({ requestState: 'failure' }),
    [actions.sendExistsDataRequest]: () => ({ requestState: 'request' }),
    [actions.sendExistsDataSuccess]: () => ({ requestState: 'success' }),
    [actions.sendExistsDataFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
