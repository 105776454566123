import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setEmployeesStatError = createAction('EMPLOYEES_STAT_SET_ERROR');

export const getEmployeesStatRequest = createAction('EMPLOYEES_STAT_GET_REQUEST');
export const getEmployeesStatSuccess = createAction('EMPLOYEES_STAT_GET_SUCCESS');
export const getEmployeesStatFailure = createAction('EMPLOYEES_STAT_GET_FAILURE');

export const getEmployeesStat = () => async (dispatch) => {
  dispatch(getEmployeesStatRequest());

  try {
    const { data } = await axios.get(`${routes.sectionOneEmployeesStat()}/view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(`${routes.sectionOneEmployeesStat()}/labels`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    dispatch(getEmployeesStatSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setEmployeesStatError(e?.response));
  }
};

export const postEmployeesStatRequest = createAction('EMPLOYEES_STAT_POST_REQUEST');
export const postEmployeesStatSuccess = createAction('EMPLOYEES_STAT_POST_SUCCESS');
export const postEmployeesStatFailure = createAction('EMPLOYEES_STAT_POST_FAILURE');

export const postEmployeesStat = (formValues) => async (dispatch) => {
  dispatch(postEmployeesStatRequest());
  try {
    await axios({
      method: 'post',
      url: `${routes.sectionOneEmployeesStat()}/update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getEmployeesStat());
  } catch (e) {
    dispatch(setEmployeesStatError(e?.response));
    dispatch(postEmployeesStatFailure());
  }
};
