import React from 'react';
import { Button, Divider, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';

function ExpertMark({ mark, isExpert, onSubmit, onDelete }) {
  const { register, handleSubmit, control } = useForm();

  const intl = useIntl();
  const status = mark?.is_correctly?.value;
  const comment = mark?.description?.value;

  if (isExpert)
    return mark ? (
      <div className="mark mt3 pb2">
        <div className="w70">
          <div className="titleContainer mb4 mt">
            <h2 className="title">
              <FormattedMessage id="expertMark.markItemTitle" defaultMessage="Отметка о правильности или неправильности заполнения пункта" />
            </h2>
          </div>
          <div className="mark__status mb">
            <FormattedMessage id="expertMark.fillItem" defaultMessage="Заполнено" />: &nbsp;
            <span>
              {parseInt(status) === 1 ? (
                <FormattedMessage id="expertMark.correctly" defaultMessage="Правильно" />
              ) : (
                <FormattedMessage id="expertMark.notCorrectly" defaultMessage="Неправильно" />
              )}
            </span>
          </div>
          <div className="mark__comment">
            <FormattedMessage id="expertMark.commentItem" defaultMessage="Комментарий" />:<div className="mt">{comment}</div>
          </div>
          <Button onClick={onDelete} color="secondary" variant="contained" className="m0Auto pb2 mt mb">
            <FormattedMessage id="global.deleteItem" defaultMessage="Удалить" />
          </Button>
          <Divider />
        </div>
      </div>
    ) : (
      <form onSubmit={handleSubmit(onSubmit)} className="mb mark mt3">
        <div>
          <FormLabel>
            <FormattedMessage id="expertMark.markItemTitle" defaultMessage="Отметка о правильности или неправильности заполнения пункта" />
          </FormLabel>
          <Controller
            name="is_correctly"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            as={
              <RadioGroup row>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'expertMark.correctly',
                    defaultMessage: 'Правильно',
                  })}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: 'expertMark.notCorrectly',
                    defaultMessage: 'Неправильно',
                  })}
                />
              </RadioGroup>
            }
          />

          <TextField
            className="w80 mb3"
            name="description"
            inputRef={register}
            label={<FormattedMessage id="expertMark.commentItem" defaultMessage="Комментарий" />}
          />
          <Button type="submit" className="dBlock mb" variant="contained" color="primary">
            <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
          </Button>

          <Divider />
        </div>
      </form>
    );
  return (
    <div className="mark w50 mt3 m0Auto pb2">
      <div className="titleContainer mb3">
        <h2 className="title">
          <FormattedMessage id="expertMark.markItemTitle" defaultMessage="Отметка о правильности или неправильности заполнения пункта" />
        </h2>
      </div>
      {mark ? (
        <>
          <div className="mark__status mb">
            <FormattedMessage id="expertMark.fillItem" defaultMessage="Заполнено" />: &nbsp;
            <span>
              {parseInt(status) === 1 ? (
                <FormattedMessage id="expertMark.correctly" defaultMessage="Правильно" />
              ) : (
                <FormattedMessage id="expertMark.notCorrectly" defaultMessage="Неправильно" />
              )}
            </span>
          </div>
          <div className="mark__comment mb">
            <FormattedMessage id="expertMark.commentItem" defaultMessage="Комментарий" />:<div className="mt">{comment}</div>
          </div>
        </>
      ) : (
        <div className="mark__no mb">
          <FormattedMessage id="expertMark.noMarkItem" defaultMessage="Нет отметки" />
        </div>
      )}
      <Divider />
    </div>
  );
}

export default ExpertMark;
