import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const infoIctFounds = handleActions(
  {
    [actions.getInfoIctFoundsSuccess]: (state, { payload: { data, labels } }) => {
      return {
        ...state,
        founds: data,
        labels,
      };
    },
    [actions.setInfoIctFoundsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    founds: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const infoIctFoundsUI = handleActions(
  {
    [actions.getInfoIctFoundsRequest]: () => ({ requestState: 'request' }),
    [actions.getInfoIctFoundsSuccess]: () => ({ requestState: 'success' }),
    [actions.getInfoIctFoundsFailure]: () => ({ requestState: 'failure' }),
    [actions.editInfoIctFoundsRequest]: () => ({ requestState: 'request' }),
    [actions.editInfoIctFoundsSuccess]: () => ({ requestState: 'success' }),
    [actions.editInfoIctFoundsFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
