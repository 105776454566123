import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import RouteWithSubRoutes from './reused/RouteWithSubRoutes';
import SignIn from 'components/pages/SignIn';
import { routes } from 'common/nis-routes';

function App() {
  const { selectedTabIndex } = useSelector((state) => state.tabs, shallowEqual);

  useEffect(() => {
    window.onbeforeunload = () => {
      sessionStorage.setItem('selectedTab', selectedTabIndex);
    };
  }, [selectedTabIndex]);

  const url = useLocation().pathname;
  const history = useHistory();
  const fetchingProfile = useSelector((state) => state.profileUI.requestingState, shallowEqual);
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);

  useEffect(() => {
    if (fetchingProfile === 'success' && url === '/') {
      if (currentUser.roles.includes('expert')) history.replace('/organizations');

      if (currentUser.roles.includes('organization')) {
        localStorage.setItem('orgId', currentUser.organization.id);
        history.replace('/section-id-1/section13');
      }
    }

    // в случае если токен устарел идет redirect на страницу входа
    if (fetchingProfile === 'failure') {
      history.push('/sign-in');
      localStorage.removeItem('token');
      localStorage.removeItem('orgId');
      localStorage.removeItem('orgTitle');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, fetchingProfile]);

  return (
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
      <Redirect to={'/not-found'} />
    </Switch>
  );
}

export default App;
