import { useState } from 'react';

export const useDialog = () => {
  const [dialogState, setDialogState] = useState(false);

  const handleOpen = () => setDialogState(true);
  const handleClose = () => setDialogState(false);

  return [dialogState, handleOpen, handleClose];
};
