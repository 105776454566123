import React from 'react';
import clsx from 'clsx';

function CounterPage({ config, page, length, className = 'mb2' }) {
  return localStorage.lang === 'ru' ? (
    <p className={clsx('summary-stat', className)}>
      <span>
        Показаны записи{' '}
        <b>
          {(page - 1) * 10 + 1}-{page !== config.pageCount ? length * page : config.totalCount}
        </b>{' '}
        из <b>{config.totalCount}</b>
      </span>
    </p>
  ) : (
    <p className={clsx('summary-stat', className)}>
      <span>
        <b>{config.totalCount}</b> дан{' '}
        <b>
          {(page - 1) * 10 + 1}-{page !== config.pageCount ? length * page : config.totalCount}
        </b>
      </span>
    </p>
  );
}

export default CounterPage;
