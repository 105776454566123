import axios from 'axios';
import { createAction } from 'redux-actions';
import { generateHeaders } from './dictionary';
import routes from '../routes';
import { store } from 'index.js';
import { getProfileFailure } from './profile';

axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(getProfileFailure(error.response));
    }

    return Promise.reject(error);
  }
);

export const setApp = createAction('APP_SET');
export const setAccessibleApps = createAction('ACCESSIBLE_APPS_SET');
export const changeLang = createAction('LANG_CHANGE');
export const selectTabIndex = createAction('TAB_INDEX_SELECT');
export const resetTabIndex = createAction('TAB_INDEX_RESET');
export const setError = createAction('ERROR_SET');
export const resetError = createAction('ERROR_RESET');
export const errorFileUpload = createAction('ERROR_FILE_UPLOAD');

export const uploadFile = async (files, section, fileName) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    formData.append('attachments[' + i + ']', files[i]);
  }
  formData.append('section', section);

  try {
    const { data } = await axios.post(routes.fileUpload(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      },
    });

    store.dispatch(errorFileUpload({ error: [] }));
    return data.file;
  } catch (e) {
    if (e?.response?.status === 422) {
      console.log(e.response);
      store.dispatch(errorFileUpload({ error: e?.response?.data || [], fileName }));
    }
    throw e;
  }
};

export const getHelp = async () => {
  try {
    const { data } = await axios.get(routes.help(), {
      params: { app: localStorage.app },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    return data;
  } catch (e) {
    console.log(e);
  }
};
export * from './sectionOne/orgActs';
export * from './login';
export * from './profile';
export * from './dictionary';
export * from './sectionOne/orgInfo';
export * from './sectionOne/orgLegalBasis';
export * from './sectionOne/orgStructuralSubdivision';
export * from './sectionOne/orgSubordinateDivision';
export * from './sectionOne/orgInfoIctManager';
export * from './sectionOne/employeesStat';
export * from './sectionTwo/websiteCompliance';
export * from './sectionTwo/websiteApps';
export * from './sectionTwo/websiteContentManager';
export * from './sectionTwo/questionnaireContentManager';
export * from './sectionTwo/websiteAccess';
export * from './sectionTwo/linksSocialPages';
export * from './sectionTwo/linksMessengerChannels';
export * from './sectionTwo/websiteOpenData';
export * from './sectionTwo/processingActs';
export * from './sectionTwo/onlineConsultant';
export * from './sectionTwo/websiteHelpline';
export * from './sectionThree/infoPublicServices';
export * from './sectionThree/epiguStats';
export * from './sectionThree/epiguServicesInfo';
export * from './sectionFive/statTechnoprocesses';
export * from './sectionSix/infoGovernmentProject';
export * from './sectionSix/organizationDigitalStrategy';
export * from './sectionSix/infoIctDivision';
export * from './sectionSix/organizationHackathon';
export * from './sectionSeven/organizationServers';
export * from './sectionSeven/computers';
export * from './sectionEight/spendingStat';
export * from './sectionEight/sourceStat';
export * from './sectionEight/infoIctFounds';
export * from './cyberSecurity';
export * from './sectionFive/infoSystems';
export * from './complexEvaluation';
export * from './simpleEvaluation';
export * from './sectionSix/sectionSixEvaluation';
export * from './evaluationActivation';

export * from './monitoring';
export * from './regions';
