import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const steps = handleActions(
  {
    [actions.getStepsSuccess]: (state, { payload }) => ({
      ...state,
      list: payload.items,
      paginationConfig: payload._meta,
    }),
    [actions.deleteStepSuccess]: (state, { payload: id }) => ({
      ...state,
      list: state.list.filter((project) => project.id !== id),
    }),
    [actions.editStep]: (state, { payload }) => ({
      ...state,
      list: state.list.map((step) => (step.id === payload.id ? payload : step)),
      current: payload,
    }),
    [actions.setCurrentStep]: (state, { payload }) => ({ ...state, current: payload }),
  },
  {
    list: [],
    paginationConfig: { totalCount: 0, pageCount: 0, currentPage: 1, perPage: 0 },
    current: {},
  }
);
