import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const dictionary = handleActions(
  {
    [actions.getEvaluationsSuccess]: (state, { payload }) => ({ ...state, evaluations: payload }),
    [actions.getEvaluationParamsSuccess]: (state, { payload }) => ({ ...state, evaluationParams: payload }),
    [actions.getRegionsSuccess]: (state, { payload }) => ({ ...state, regions: [{ id: '', title: '' }, ...payload] }),
    [actions.getDistrictsSuccess]: (state, { payload }) => ({
      ...state,
      districts: [{ id: '', title: '' }, ...payload],
    }),
    [actions.resetDistricts]: (state) => ({ ...state, districts: [] }),
    [actions.getPositionsSuccess]: (state, { payload }) => ({
      ...state,
      positions: [{ id: '', title: '' }, ...payload],
    }),
    [actions.getDocumentTypesSuccess]: (state, { payload }) => ({
      ...state,
      documentTypes: [{ id: '', title: '' }, ...payload],
    }),
    [actions.getAcceptedDocumentsSuccess]: (state, { payload }) => ({
      ...state,
      acceptedDocuments: [{ id: '', title: '' }, ...payload],
    }),
    [actions.getOrganizationsSuccess]: (state, { payload }) => ({ ...state, statOrganizations: payload }),
    [actions.getOrgStatSuccess]: (state, { payload: { data, nestedSections } }) => ({
      ...state,
      orgStatHistory: data,
      orgAllSections: nestedSections,
    }),
    [actions.setError]: (state) => ({ ...state, orgStatHistory: [] }),
    [actions.getTabsSuccess]: (state, { payload }) => ({ ...state, tabs: payload }),
    [actions.toggleTabState]: (state, { payload }) => ({
      ...state,
      tabs: state.tabs.map((tab) => (tab.id === payload.id ? payload : tab)),
    }),
    [actions.getAccordionsSuccess]: (state, { payload }) => ({ ...state, accordions: payload }),
    [actions.getAccordionContentSuccess]: (state, { payload }) => ({
      ...state,
      accordionContent: payload,
    }),
    [actions.getTypesIctDivisionSuccess]: (state, { payload }) => ({ ...state, divisionTypes: payload }),
    [actions.getDivisionOrgTypesSuccess]: (state, { payload }) => ({
      ...state,
      orgFormTypes: [{ id: '', title: '' }, ...payload],
    }),
    [actions.getStageListSuccess]: (state, { payload }) => ({
      ...state,
      stageList: [{ id: '', title: '' }, ...payload],
    }),
    [actions.getPurposeTypesSuccess]: (state, { payload }) => ({ ...state, purposeTypes: payload }),
    [actions.getClassifierTypesSuccess]: (state, { payload }) => ({ ...state, classifierTypes: payload }),
    [actions.getAutoProcessTypesSuccess]: (state, { payload }) => ({ ...state, autoProcessTypes: payload }),
    [actions.getFormTypesSuccess]: (state, { payload }) => ({ ...state, formTypes: payload }),
    [actions.getResultTypesSuccess]: (state, { payload }) => ({ ...state, resultTypes: payload }),
    [actions.getTrackingTypesSuccess]: (state, { payload }) => ({ ...state, trackingTypes: payload }),
    [actions.getUserTypesSuccess]: (state, { payload }) => ({ ...state, userTypes: payload }),
    [actions.getCategoriesOrgSuccess]: (state, { payload }) => ({ ...state, categoriesOrg: payload }),
    [actions.getReportTemplateListSuccess]: (state, { payload }) => ({ ...state, reportTemplateList: payload }),
    [actions.getExcelFileLinkSuccess]: (state, { payload }) => ({ ...state, link: payload }),
  },
  {
    evaluations: [],
    evaluationParams: [],
    regions: [],
    districts: [],
    positions: [],
    tabs: [],
    documentTypes: [],
    acceptedDocuments: [],
    statOrganizations: { sections: {}, orgStat: [] },
    orgStatHistory: [],
    orgAllSections: [],
    accordions: [],
    accordionContent: [],
    divisionTypes: [],
    orgFormTypes: [],
    stageList: [],
    purposeTypes: [],
    classifierTypes: [],
    autoProcessTypes: [],
    formTypes: [],
    resultTypes: [],
    trackingTypes: [],
    userTypes: [],
    categoriesOrg: [],
    reportTemplateList: [],
    link: '',
  }
);

export const dictionaryUI = handleActions(
  {
    [actions.getAccordionContentRequest]: (state) => ({ ...state, fetchingAccordionContent: 'request' }),
    [actions.getAccordionContentSuccess]: (state) => ({ ...state, fetchingAccordionContent: 'success' }),
    [actions.getAccordionsRequest]: (state) => ({ ...state, fetchingAccordions: 'request' }),
    [actions.getAccordionsSuccess]: (state) => ({ ...state, fetchingAccordions: 'success' }),
    [actions.getOrganizationsRequest]: (state) => ({ ...state, fetchingOrganizations: 'request' }),
    [actions.getOrganizationsSuccess]: (state) => ({ ...state, fetchingOrganizations: 'success' }),
    [actions.getOrgStatRequest]: (state) => ({ ...state, fetchingOrganizationsStat: 'request' }),
    [actions.getOrgStatSuccess]: (state) => ({ ...state, fetchingOrganizationsStat: 'success' }),
    [actions.setError]: (state) => ({ ...state, fetchingOrganizationsStat: 'failure' }),
  },
  {}
);
