import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const organizationServers = handleActions(
  {
    [actions.getInfoServersSuccess]: (state, { payload }) => {
      return {
        ...state,
        servers: payload.items,
        metaData: payload._meta,
      };
    },
    [actions.getLabelsServersSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.addItemServerSuccess]: (state, { payload }) => {
      return {
        ...state,
        servers: [...state.servers, payload],
      };
    },
    [actions.deleteItemServerSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        servers: state.servers.filter((el) => el.id !== id),
      };
    },
    [actions.editItemServerSuccess]: (state, { payload }) => {
      return {
        ...state,
        servers: state.servers.map((el) => (el.id === payload.id ? payload : el)),
      };
    },
    [actions.getItemServerSuccess]: (state, { payload: { id, data } }) => {
      return {
        ...state,
        element: data,
        id,
      };
    },
    [actions.setInfoServersError]: (state, { payload }) => {
      return {
        ...state,
        error: {
          hasError: true,
          status: payload.status,
          message: payload.statusText,
        },
      };
    },
    [actions.resetError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: false,
        message: '',
        status: '',
      },
    }),
  },
  {
    servers: [],
    element: {},
    labels: {},
    id: 0,
    metaData: {
      pageCount: 1,
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const organizationServersUI = handleActions(
  {
    [actions.getLabelsServersRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsServersSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsServersFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editItemServerRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editItemServerSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editItemServerFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getItemServerRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getItemServerSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getItemServerFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
