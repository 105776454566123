import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IntlProvider } from 'react-intl';

import SignIn from 'components/pages/SignIn';
import NISApp from 'components/App';
import Monitoring from 'monitoring/App';
import Regions from 'regions/App';
import { getProfile, setApp, setAccessibleApps } from 'actions';
import messages from 'lang/messages';

function System() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const fetchingProfile = useSelector((state) => state.profileUI.requestingState, shallowEqual);
  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const currentApp = useSelector((state) => state.app.currentApp);

  const locale = useSelector((state) => state.language.locale, shallowEqual);

  const chooseApp = () => {
    if (currentUser.apps.find((el) => el.platform === localStorage.app)) {
      return dispatch(setApp(localStorage.app));
    }

    if (currentUser.apps.find((el) => el.platform === 'nis')) {
      return dispatch(setApp('nis'));
    } else {
      dispatch(setApp(currentUser.apps[0].platform));
    }
  };
  useEffect(() => {
    if (fetchingProfile === 'success') {
      chooseApp();
      dispatch(setAccessibleApps(currentUser.apps));
    }

    // в случае если токен устарел идет redirect на страницу входа
    if (fetchingProfile === 'failure') {
      history.push('/sign-in');
      localStorage.removeItem('token');
      localStorage.removeItem('orgId');
      localStorage.removeItem('orgTitle');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingProfile]);

  let App = null;
  if (currentApp === 'nis') App = NISApp;
  if (currentApp === 'monitoring') App = Monitoring;
  if (currentApp === 'region') App = Regions;

  return (
    <div className="app">
      <IntlProvider locale={locale} messages={messages[locale]} defaultLocale="ru">
        <Route path="/sign-in" component={SignIn} />
        {App && <App />}
      </IntlProvider>
    </div>
  );
}

export default System;
