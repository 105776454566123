import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, NavLink } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Paper, Table, TableContainer, TableHead, TableBody, IconButton, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';

import { useDialog } from 'common/hooks';
import Title from 'components/reused/Title';
import { StyledTableCell, StyledTableRow, useStyles } from 'components/reused/Table';
import { getSearchProjects, deleteProject, get, getLabels } from 'actions';
import SimpleDialog from 'components/reused/SimpleDialog';
import Empty from 'components/reused/Empty';
import Loader from 'components/reused/Spinner';

import CounterPage from 'components/reused/CounterPage';
import SearchForm from './SearchForm';

function Search() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { url } = useRouteMatch();

  const currentUser = useSelector((state) => state.profile.currentUser);
  const loading = useSelector((state) => state.m_search_ui.fetchingProjects);
  const isSupervisor = currentUser?.roles.includes('monitoring_supervisor');

  const [labels, setLabels] = useState({});
  const { pagination, projects, isExpanded } = useSelector((state) => state.m_search);
  const [dictionaries, setDictionaries] = useState({
    organizations: [],
    organizationType: [],
    roadmaps: [],
    documents: [],
    projectStatuses: [],
  });

  useEffect(() => {
    let active = true;
    get('organizations').then((data) => {
      if (active) setDictionaries((state) => ({ ...state, organizations: data }));
    });
    get('organization-type').then((data) => {
      if (active) setDictionaries((state) => ({ ...state, organizationType: data }));
    });
    get('roadmaps').then((data) => {
      if (active) setDictionaries((state) => ({ ...state, roadmaps: data }));
    });
    get('document').then((data) => {
      if (active) setDictionaries((state) => ({ ...state, documents: data }));
    });
    get('project-status-types').then((data) => {
      if (active) setDictionaries((state) => ({ ...state, projectStatuses: data }));
    });
    getLabels('project').then((data) => {
      if (active) setLabels(data);
    });
    return () => (active = false);
  }, []);

  const [page, setPage] = useState(1);
  const handleChange = (formFields) => (event, value) => {
    setPage(value);
    const orgName = formFields?.organization_name?.title;
    const orgType = formFields?.organization_type?.title;
    const finalSearchParams = {
      ...formFields,
      organization_name: orgName,
      organization_type: orgType,
    };
    dispatch(getSearchProjects(finalSearchParams, value));
  };

  const [openDelete, handleOpenDelete, handleCloseDelete] = useDialog();
  const [projectDeleteId, setProjectDeleteId] = useState(null);
  const handleModalDelete = (id) => () => {
    handleOpenDelete();
    setProjectDeleteId(id);
  };

  const handleDelete = () => {
    handleCloseDelete();
    dispatch(deleteProject(projectDeleteId));
  };

  return (
    <div>
      <Title title={'Поисковая страница'} className="mb2" />
      <Accordion className="mb2" defaultExpanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>Фильтрация</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchForm
            getFormValues={handleChange}
            dictionaries={dictionaries}
            page={page}
            setDictionaries={setDictionaries}
          />
        </AccordionDetails>
      </Accordion>
      {projects.length !== 0 && <CounterPage config={pagination} page={page} length={projects.length} />}
      <TableContainer component={Paper} className={classes.container}>
        {loading ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Наименование НПА</StyledTableCell>
                <StyledTableCell style={{ minWidth: 120 }} align="center" rowSpan="2">
                  №
                </StyledTableCell>
                <StyledTableCell align="center">{labels[`title_${localStorage.lang || 'ru'}`]}</StyledTableCell>
                <StyledTableCell align="center">{labels.organization_id}</StyledTableCell>
                <StyledTableCell align="center">{labels.status_id}</StyledTableCell>
                <StyledTableCell align="center">{labels.amount_forecast_funds}</StyledTableCell>
                <StyledTableCell align="center">{labels.financial_sources}</StyledTableCell>
                <StyledTableCell align="center">Статистика по этапам</StyledTableCell>
                {isSupervisor && <StyledTableCell />}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => {
                let color = '';
                switch (project.status_id) {
                  case 1:
                    color = '#00ff0060';
                    break;
                  case 2:
                    color = '#0000ff60';
                    break;
                  case 3:
                    color = 'orange';
                    break;
                  case 4:
                    color = '#ffff00';
                    break;
                  case 5:
                    color = 'violet';
                    break;
                  case 6:
                    color = '#87d0fb';
                    break;
                  case 7:
                    color = 'red';
                    break;
                  default:
                    color = '#fff';
                }
                return (
                  <StyledTableRow key={project.id}>
                    <StyledTableCell align="center">{project.npa_title}</StyledTableCell>
                    <StyledTableCell align="center">{project.project_no}</StyledTableCell>
                    <StyledTableCell>
                      <NavLink className="m_link" to={`${url}/projects/${project.id}`}>
                        {project.title}
                      </NavLink>
                    </StyledTableCell>
                    <StyledTableCell>{project.organization_name}</StyledTableCell>
                    <StyledTableCell align="center" style={{ backgroundColor: color }}>
                      {project.status_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">{project.amount_forecast_funds}</StyledTableCell>
                    <StyledTableCell align="center">
                      {project.financial_source_names.map((el) => el.title).join(', ')}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <div className="container-statuses">
                        {project.step_stat
                          .sort((a, b) => a.status_id - b.status_id)
                          .map((el) => {
                            let color = '';
                            switch (el.status_id) {
                              case 1:
                                color = '#00ff0060';
                                break;
                              case 2:
                                color = '#0000ff60';
                                break;
                              case 3:
                                color = 'red';
                                break;
                              case 4:
                                color = '#ffff00';
                                break;
                              default:
                                color = '#fff';
                            }
                            return (
                              <div
                                key={el.status_id}
                                style={{ border: `2px solid ${color}`, margin: 2, borderRadius: 25 }}>
                                <NavLink
                                  className="status_link"
                                  to={`${url}/projects/${project.id}/step-status/${el.status_id}`}
                                  title={el.title}>
                                  {el.cnt}
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    </StyledTableCell>
                    {isSupervisor && (
                      <StyledTableCell>
                        <IconButton edge="end" color="secondary" onClick={handleModalDelete(project.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              })}
              {projects.length === 0 && (
                <StyledTableRow>
                  <StyledTableCell colSpan={isSupervisor ? 9 : 8}>
                    <Empty title="Данных нет" />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {pagination.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt2">
          <Pagination
            count={pagination.pageCount}
            page={page}
            onChange={handleChange()}
            siblingCount={2}
            variant="outlined"
            color="primary"
          />
        </div>
      )}
      <SimpleDialog title="Подтвердить удаление" dialogState={openDelete} onClose={handleCloseDelete}>
        <Button className="mr ml mt mb" variant="contained" color="primary" onClick={handleCloseDelete}>
          Закрыть
        </Button>
        <Button className="mr ml mt mb" variant="contained" color="secondary" onClick={handleDelete}>
          Удалить?
        </Button>
      </SimpleDialog>
    </div>
  );
}

export default Search;
