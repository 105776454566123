import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setEpiguStatsError = createAction('EPIGU_STATS_SET_ERROR');

export const getEpiguStatsRequest = createAction('EPIGU_STATS_GET_REQUEST');
export const getEpiguStatsSuccess = createAction('EPIGU_STATS_GET_SUCCESS');
export const getEpiguStatsFailure = createAction('EPIGU_STATS_GET_FAILURE');

export const getEpiguStats = () => async (dispatch) => {
  dispatch(getEpiguStatsRequest());

  try {
    const { data } = await axios.get(routes.sectionThreeEpiguStats(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionThreeEpiguStatLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getEpiguStatsSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setEpiguStatsError(e?.response));
    dispatch(getEpiguStatsFailure());
  }
};

export const editEpiguStatsRequest = createAction('EPIGU_STATS_EDIT_REQUEST');
export const editEpiguStatsSuccess = createAction('EPIGU_STATS_EDIT_SUCCESS');
export const editEpiguStatsFailure = createAction('EPIGU_STATS_EDIT_FAILURE');

export const editEpiguStats = (data) => async (dispatch) => {
  dispatch(editEpiguStatsRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionThreeEpiguStats(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: data,
    });
    dispatch(getEpiguStats());
    dispatch(getSectionThreeAssessment(33));
  } catch (e) {
    dispatch(setEpiguStatsError(e?.response));
    dispatch(editEpiguStatsFailure());
  }
};

export const getSectionThreeAssessmentRequest = createAction('SECTION_THREE_GET_ASSESSMENT_REQUEST');
export const getSectionThreeAssessmentSuccess = createAction('SECTION_THREE_GET_ASSESSMENT_SUCCESS');
export const getSectionThreeAssessmentFailure = createAction('SECTION_THREE_GET_ASSESSMENT_FAILURE');

export const getSectionThreeAssessment = (sectionId) => async (dispatch) => {
  dispatch(getSectionThreeAssessmentRequest());

  try {
    const { data } = await axios.get(routes.sectionThreeAssessment('view'), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: {
        section_id: sectionId,
      },
    });
    dispatch(getSectionThreeAssessmentSuccess(data));
  } catch (e) {
    console.log(e);
  }
};
