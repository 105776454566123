import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  TextField,
  Collapse,
  IconButton,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import { rApiExcludeIndicator } from 'actions/regions';
import routes from 'regions/routes';
import Loader from 'components/reused/Loader';
import axios from 'axios';
import { generateHeaders } from 'actions/dictionary';

function Form({ closeDialog, setIndicatorsView, regions, regionId, setType, setPage }) {
  const { handleSubmit, register, errors, setValue, control, watch } = useForm({
    defaultValues: { region_id: regionId },
  });

  const [categories, setCategories] = useState([]);
  const [indicators, setIndicators] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [error, setError] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [regionTypes, setRegionTypes] = useState([]);

  const [loading, setLoading] = useState({
    form: false,
    selectIndicator: false,
    sendButton: false,
  });

  const regionType = watch('region_type');
  const region = watch('region_id');
  const category = watch('category');

  useEffect(() => {
    let active = true;
    rApiExcludeIndicator(routes.excludedIndicator('category'), 'get').then((data) => {
      if (active) setCategories(data);
    });

    axios
      .get(routes.excludedIndicator('region-types'), {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      })
      .then((res) => {
        if (active) setRegionTypes(Object.entries(res.data));
      });

    return () => (active = false);
  }, []);

  const handleChangeCategory = async (e) => {
    const value = e.target.value;
    if (value) {
      setLoading((state) => ({ ...state, selectIndicator: true }));

      const data = await rApiExcludeIndicator(routes.excludedIndicator(`indicators`), 'get', {
        category_id: value,
        region_type: regionType,
      });

      setIndicators(data);
      setLoading((state) => ({ ...state, selectIndicator: false }));
    } else {
      setIndicators([]);
    }
  };

  const handleChangeType = async (e) => {
    if (category) {
      setLoading((state) => ({ ...state, selectIndicator: true }));

      const data = await rApiExcludeIndicator(routes.excludedIndicator(`indicators`), 'get', {
        category_id: category,
        region_type: e.target.value,
      });

      setIndicators(data);
      setLoading((state) => ({ ...state, selectIndicator: false }));
    }
  };

  const handleChangeIndicator = (e) => {
    const value = e.target.value;
    if (value) {
      setLoading((state) => ({ ...state, form: true }));
    }
  };

  const handleChangeRegion = (e) => {
    const value = e.target.value;
    if (value) {
      rApiExcludeIndicator(routes.excludedIndicator('districts'), 'get', { region_id: value }).then((data) =>
        setDistricts(data)
      );
    } else {
      setDistricts([]);
      setValue('district_id', '');
    }
  };

  const onSubmit = async (formValues) => {
    setLoading((state) => ({ ...state, sendButton: true, form: true }));

    try {
      const { data } = await axios.post(routes.excludedIndicator('create'), formValues, {
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      });
      console.log(data);
      closeDialog();
    } catch (e) {
      if (e.response?.data) {
        setOpenAlert(true);
        setError(e.response.data.message);
      }
    }

    setLoading((state) => ({ ...state, sendButton: false, form: false }));

    setType(formValues.region_type);
    setPage(1);
    await rApiExcludeIndicator(routes.excludedIndicator('index'), 'get', { region_type: formValues.region_type }).then(
      (data) => {
        setIndicatorsView(data);
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
      {error.length !== 0 && (
        <Collapse in={openAlert} className="mb2">
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {error}
          </Alert>
        </Collapse>
      )}

      <FormLabel>Тип индикатора</FormLabel>
      <Controller
        name="region_type"
        control={control}
        rules={{ required: true }}
        defaultValue="region"
        as={
          <RadioGroup row>
            {regionTypes.map(([key, value]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color="primary" />}
                label={value}
                onChange={handleChangeType}
              />
            ))}
          </RadioGroup>
        }
      />

      <FormControl className="mb" fullWidth error={!!errors.region}>
        <InputLabel>Выберите регион</InputLabel>
        <Select native name="region_id" inputRef={register({ required: true })} onChange={handleChangeRegion}>
          <option value="" />
          {regions.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
        </Select>
      </FormControl>

      {region && regionType === 'district' && districts.length > 0 && (
        <FormControl className="mb" fullWidth error={!!errors.district_id}>
          <InputLabel>Выберите район</InputLabel>
          <Select native name="district_id" inputRef={register({ required: true })}>
            <option value="" />
            {districts.map((option) => (
              <option key={option.id} value={option.id}>
                {option.title}
              </option>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl className="mb" fullWidth error={!!errors.category}>
        <InputLabel>Выберите категорию</InputLabel>
        <Select native name="category" inputRef={register({ required: true })} onChange={handleChangeCategory}>
          <option aria-label="None" value="" />
          {categories.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </Select>
      </FormControl>
      {loading.selectIndicator ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loader />
        </div>
      ) : (
        indicators.length !== 0 && (
          <>
            <FormControl className="mb" fullWidth error={!!errors.indicator_id}>
              <InputLabel>Выберите индикатор</InputLabel>
              <Select
                native
                name="indicator_id"
                inputRef={register({ required: true })}
                onChange={handleChangeIndicator}>
                <option />
                {indicators.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.title}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="reason"
              error={!!errors.reason}
              multiline
              inputRef={register({ required: true })}
              fullWidth
              className="mb2"
              label="Причина"
            />
          </>
        )
      )}

      <Button variant="contained" color="primary" type="submit">
        {loading.sendButton ? (
          <div style={{ width: 100 }}>
            <CircularProgress color="secondary" size={20} />
          </div>
        ) : (
          'Сохранить'
        )}
      </Button>
    </form>
  );
}

export default Form;
