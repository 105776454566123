import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setOrgActError = createAction('ORG_ACTS_SET_ERROR');

export const getOrgActsLabelsRequest = createAction('ORG_ACTS_LABELS_GET_REQUEST');
export const getOrgActsLabelsSuccess = createAction('ORG_ACTS_LABELS_GET_SUCCESS');

export const getOrgActsLabels = () => async (dispatch) => {
  dispatch(getOrgActsLabelsRequest());
  try {
    const { data } = await axios.get(routes.sectionOneOrgActsLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getOrgActsLabelsSuccess(data));
  } catch (e) {
    dispatch(setOrgActError(e?.response));
  }
};

export const getOrgActsRequest = createAction('ORG_ACTS_GET_REQUEST');
export const getOrgActsSuccess = createAction('ORG_ACTS_GET_SUCCESS');
export const getOrgActsFailure = createAction('ORG_ACTS_GET_FAILURE');

export const getOrgActs = () => async (dispatch) => {
  dispatch(getOrgActsRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgActs(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getOrgActsSuccess(data.items));
  } catch (e) {
    dispatch(getOrgActsFailure());
    dispatch(setOrgActError(e?.response));
  }
};

export const deleteActRequest = createAction('ACT_DELETE_REQUEST');
export const deleteActSuccess = createAction('ACT_DELETE_SUCCESS');
export const deleteActFailure = createAction('ACT_DELETE_FAILURE');

export const deleteAct = (id) => async (dispatch) => {
  dispatch(deleteActRequest());

  try {
    await axios.delete(routes.sectionOneOrgAct(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteActSuccess({ id }));
  } catch (e) {
    dispatch(deleteActFailure());
    dispatch(setOrgActError(e?.response));
  }
};

export const editActRequest = createAction('ACT_EDIT_REQUEST');
export const editActSuccess = createAction('ACT_EDIT_SUCCESS');
export const editActFailure = createAction('ACT_EDIT_FAILURE');

export const editAct = (id, formValues) => async (dispatch) => {
  dispatch(editActRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionOneOrgAct(id),
      data: formValues,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(editActSuccess(data));
  } catch (e) {
    dispatch(setOrgActError(e?.response));
    dispatch(editActFailure());
  }
};

export const addActRequest = createAction('ACT_ADD_REQUEST');
export const addActSuccess = createAction('ACT_ADD_SUCCESS');
export const addActFailure = createAction('ACT_ADD_FAILURE');

export const addAct = (formValues) => async (dispatch) => {
  dispatch(addActRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionOneOrgActs(),
      data: formValues,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(addActSuccess(data));
  } catch (e) {
    dispatch(setOrgActError(e?.response));
    dispatch(addActFailure());
  }
};

export const getCurrentActRequest = createAction('CURRENT_ACT_GET_REQUEST');
export const getCurrentActSuccess = createAction('CURRENT_ACT_GET_SUCCESS');
export const getCurrentActFailure = createAction('CURRENT_ACT_GET_FAILURE');

export const getCurrentAct = (id) => async (dispatch) => {
  dispatch(getCurrentActRequest());

  try {
    const { data } = await axios.get(routes.sectionOneOrgAct(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getCurrentActSuccess(data));
  } catch (e) {
    dispatch(setOrgActError(e?.response));
    dispatch(getCurrentActFailure());
  }
};

export const updateCheckboxRequest = createAction('CHECKBOX_UPDATE_REQUEST');
export const updateCheckboxFailure = createAction('CHECKBOX_UPDATE_FAILURE');

export const updateCheckbox = (formValues) => async (dispatch) => {
  dispatch(updateCheckboxRequest());
  try {
    await axios({
      method: 'post',
      url: `${routes.sectionOneOrgActCheckbox()}update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getCheckbox());
  } catch (e) {
    dispatch(setOrgActError(e?.response));
    dispatch(updateCheckboxFailure());
  }
};

export const getCheckboxRequest = createAction('CHECKBOX_GET_REQUEST');
export const getCheckboxSuccess = createAction('CHECKBOX_GET_SUCCESS');
export const getCheckboxFailure = createAction('CHECKBOX_GET_FAILURE');

export const getCheckbox = () => async (dispatch) => {
  dispatch(getCheckboxRequest());

  try {
    const { data } = await axios.get(`${routes.sectionOneOrgActCheckbox()}view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getCheckboxSuccess(data));
  } catch (e) {
    dispatch(setOrgActError(e?.response));
    dispatch(getCheckboxFailure());
  }
};
