import axios from 'axios';
import { createAction } from 'redux-actions';
import routes from 'monitoring/routes';
import { generateHeaders } from 'actions/dictionary';

export const getProjectsRequest = createAction('PROJECTS_GET_REQUEST');
export const getProjectsSuccess = createAction('PROJECTS_GET_SUCCESS');
export const getProjectsFailure = createAction('PROJECTS_GET_FAILURE');

export const getProjects = (filtration, page) => async (dispatch) => {
  dispatch(getProjectsRequest());

  try {
    const filter = Object.keys(filtration).reduce((acc, el) => {
      if (el === 'end_date') {
        return { ...acc, [`filter[${el}][lt]`]: filtration[el] };
      } else {
        return { ...acc, [`filter[${el}]`]: filtration[el] }
      }
    }, {});

    const { data } = await axios.get(routes.projects(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      params: { ...filter, page, 'per-page': 10 },
    });
    dispatch(getProjectsSuccess(data));
  } catch (e) {
    dispatch(getProjectsFailure());
    console.log(e);
  }
};

export const getProjectSuccess = createAction('PROJECT_GET_SUCCESS');

export const getProject = (id) => async (dispatch) => {
  dispatch(getProjectsRequest());

  try {
    const { data } = await axios.get(routes.project(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getProjectSuccess(data));
  } catch (e) {
    dispatch(getProjectsFailure());
    console.log(e);
  }
};

export const addProject = createAction('PROJECT_ADD');

export const editCurrentProject = createAction('CURRENT_PROJECT_EDIT');

export const deleteProjectSuccess = createAction('PROJECT_DELETE_SUCCESS');
export const deleteProjectFailure = createAction('PROJECT_DELETE_FAILURE');
export const deleteProjectRequest = createAction('PROJECT_DELETE_REQUEST');

export const deleteProject = (id) => async (dispatch) => {
  dispatch(deleteProjectRequest());

  try {
    const res = await axios.delete(routes.project(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    if (res.status === 204) {
      dispatch(deleteProjectSuccess(id));
    }
  } catch (e) {
    dispatch(deleteProjectFailure());
    console.log(e);
  }
};
