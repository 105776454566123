import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, TextField } from '@material-ui/core';
import { addItemServer, editItemServer } from '../../../actions';

const Form = ({ submitAction, defaultValues, isEditableMode }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { register, handleSubmit, errors, watch } = useForm({ defaultValues, mode: 'onChange' });

  const { labels, id, servers } = useSelector((state) => state.sectionSevenOrganizationServers, shallowEqual);

  const serverTypes = servers.map((server) => server.server_type);
  const serverBrands = servers.map((server) => server.server_brand_type);
  const serverType = watch('server_type');
  const serverBrand = watch('server_brand_type');

  const onSubmit = (data) => {
    if (isEditableMode) dispatch(editItemServer(id, data));
    else dispatch(addItemServer(data));
    submitAction();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        name="server_type"
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = serverTypes.includes(value) && serverBrands.includes(serverBrand);

            return !isValid;
          },
        })}
        error={!!errors.server_type}
        label={labels.server_type}
        helperText={
          !!errors.server_type &&
          errors.server_type?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateServers',
            defaultMessage: 'Тип и марка сервера не должны повторятся!',
          })
        }
        className="mb"
      />
      <TextField
        fullWidth
        name="server_brand_type"
        inputRef={register({
          required: true,
          validate: (value) => {
            let isValid = false;
            if (!isEditableMode) isValid = serverBrands.includes(value) && serverTypes.includes(serverType);

            return !isValid;
          },
        })}
        error={!!errors.server_brand_type}
        label={labels.server_brand_type}
        helperText={
          !!errors.server_brand_type &&
          errors.server_brand_type?.type === 'validate' &&
          intl.formatMessage({
            id: 'global.errorDublicateServers',
            defaultMessage: 'Тип и марка сервера не должны повторятся!',
          })
        }
        className="mb"
      />
      <TextField
        fullWidth
        name="server_configuration"
        inputRef={register({ required: true })}
        error={!!errors.server_configuration}
        helperText={labels.server_configuration}
        className="mb"
      />
      <TextField
        fullWidth
        name="server_functions"
        inputRef={register({ required: true })}
        error={!!errors.server_functions}
        helperText={labels.server_functions}
        className="mb"
      />
      <TextField
        fullWidth
        name="servers_count"
        inputRef={register({ required: true })}
        error={!!errors.servers_count}
        helperText={labels.servers_count}
        className="mb"
        type="number"
      />
      <Button color="primary" variant="outlined" type="submit">
        {isEditableMode ? (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        ) : (
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        )}
      </Button>
    </form>
  );
};

export default Form;
