import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const organizationDigitalStrategy = handleActions({
  [actions.getInfoDigitalStrategySuccess]: (state, { payload }) => {
    return {
      ...state,
      data: payload,
    }
  },
  [actions.getLabelsDigitalStrategySuccess]: (state, { payload }) => {
    return {
      ...state,
      labels: payload,
    }
  },
  [actions.addElementDigitalStrategySuccess]: (state, { payload }) => {
    return {
      ...state,
      data: [...state.data, payload],
    }
  },
  [actions.deleteElementDigitalStrategySuccess]: (state, { payload: { id } }) => {
    return {
      ...state,
      data: state.data.filter((el) => el.id !== id),
    }
  },
  [actions.editElementDigitalStrategySuccess]: (state, { payload }) => {
    return {
      ...state,
      data: state.data.map((el) => el.id === payload.id ? payload : el),
    }
  },
  [actions.getElementDigitalStrategySuccess]: (state, { payload }) => {
    return {
      ...state,
      elementStrategy: payload,
    }
  },
  [actions.getCheckboxDigitalStrategySuccess]: (state, { payload }) => ({ ...state, checkbox: payload }),
  [actions.setInfoDigitalStrategyError]: (state, { payload }) => ({ ...state, error: { hasError: true, status: payload.status, message: payload.message }}),
  [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
}, {
  data: [],
  labels: {},
  elementStrategy: {},
  checkbox: {},
  error: {
      hasError: false,
      message: '',
      status: '',
    },
});

export const organizationDigitalStrategyUI = handleActions(
  {
    [actions.getLabelsDigitalStrategyRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getLabelsDigitalStrategySuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getLabelsDigitalStrategyFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editElementDigitalStrategyRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editElementDigitalStrategySuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editElementDigitalStrategyFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getElementDigitalStrategyRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getElementDigitalStrategySuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getElementDigitalStrategyFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);