import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const infoGovernmentProject = handleActions(
  {
    [actions.getGovernmentProjectsSuccess]: (state, { payload }) => {
      return {
        ...state,
        projects: payload.items,
        pagination: payload._meta,
      };
    },
    [actions.getGovernmentLabelsSuccess]: (state, { payload }) => {
      return {
        ...state,
        labels: payload,
      };
    },
    [actions.getElementGovernmentSuccess]: (state, { payload }) => {
      return {
        ...state,
        currentProject: payload,
      };
    },
    [actions.deleteElementGovernmentSuccess]: (state, { payload: { id } }) => {
      return {
        ...state,
        projects: state.projects.filter((el) => el.id !== id),
      };
    },
    [actions.addElementGovernmentSuccess]: (state, { payload }) => {
      return {
        ...state,
        projects: [...state.projects, payload],
      };
    },
    [actions.editElementGovernmentSuccess]: (state, { payload }) => {
      return {
        ...state,
        projects: state.projects.map((el) => (el.id === payload.id ? payload : el)),
      };
    },
    [actions.getRadioBtnSuccess]: (state, { payload }) => {
      return {
        ...state,
        radio: payload,
      };
    },
    [actions.getGovProjectEvaluationSuccess]: (state, { payload }) => ({ ...state, evaluation: payload }),
    [actions.setGovernmentProjectsError]: (state, { payload }) => {
      return {
        ...state,
        error: {
          hasError: true,
          status: payload.status,
          message: payload.message,
        },
      };
    },
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    projects: [],
    currentProject: {},
    evaluation: [],
    labels: {},
    radio: {},
    pagination: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const infoGovernmentProjectUI = handleActions(
  {
    [actions.getGovernmentLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getGovernmentLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getGovernmentLabelsFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editElementGovernmentRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editElementGovernmentSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editElementGovernmentFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getElementGovernmentRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getElementGovernmentSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getElementGovernmentFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  { requestLabelState: '', requestItemState: '' }
);
