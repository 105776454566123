import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';
import { ButtonGroup, Checkbox, TableBody, TableContainer, TableHead, TableRow } from '@material-ui/core';

import Form from './Form';
import CustomTable, { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import Spinner from '../../reused/Spinner';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/FullScreenDialog';
import ExpertAssessment from '../../reused/ExpertAssessment';
import { useDialog } from '../../../common/hooks';
import {
  deleteElementInfoSystems,
  getInfoSystems,
  getElementInfoSystems,
  getClassifierTypes,
  getPurposeTypes,
  getStageList,
  getAutoProcessTypes,
  deleteComplexAssessment,
  getComplexAssessment,
  saveComplexAssessment,
  getEvaluations,
  getEvaluationList,
  getInfoSystemsEvaluation,
  saveInfoSystemsEvaluation,
  deleteInfoSystemsEvaluation,
  getInfoSystemsPoints,
  getLabelsInfoSystem,
  resetError,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  toggleTabState,
  editElementInfoSystems,
} from '../../../actions';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import EvaluationActivation from '../../reused/EvaluationActivation';

export default function Section51() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getInfoSystems(page));
    dispatch(getLabelsInfoSystem());

    dispatch(getInfoSystemsEvaluation());
    dispatch(getEvaluationList(168));
    dispatch(getInfoSystemsPoints(168, [25, 26, 27, 28, 29, 30, 32, 33, 34, 35]));
    dispatch(getEvalActivationInfo(168));

    dispatch(getStageList());
    dispatch(getPurposeTypes());
    dispatch(getClassifierTypes());
    dispatch(getAutoProcessTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const { data, labels, element, evaluation, error, metaData, points } = useSelector(
    (state) => state.sectionFiveInfoSystems,
    shallowEqual
  );

  const purpose = element?.purpose?.translated_value;
  let viewPurpose;
  if (purpose) viewPurpose = purpose.map((el) => el.title).join(', ');

  const classifiers = element?.classifiers?.translated_value;
  let viewClassifiers;
  if (classifiers) viewClassifiers = classifiers.map((el) => el.title).join(', ');

  const autoProcesses = element?.auto_processes?.translated_value;
  let viewAutoProcesses;
  if (autoProcesses) viewAutoProcesses = autoProcesses.map((el) => el.title).join(', ');

  const elementFields = useMemo(
    () =>
      Object.values(element)
        .filter((el) => {
          if (el.code_name === 'classifiers') return element.is_used_classifiers.value === '1';
          if (el.code_name === 'reason_used_classifiers') return element.is_used_classifiers.value === '0';
          if (el.code_name === 'other_classifier_name')
            return element.is_used_classifiers.value === '1' && element.classifiers.value.includes(6);
          if (el.code_name === 'auto_processes') return element.purpose.value.includes(1);

          if (el.code_name === 'reason_integration') return element.has_integration.value === '0';
          if (dependFields.includes(el.code_name)) return element.has_integration.value === '1';

          if (el.code_name === 'data_interdepartmental') return element.has_interdepartmental_integration.value === '1';
          if (el.code_name === 'data_classifiers') return element.has_classifier_integration.value === '1';
          if (el.code_name === 'data_central_db') return element.has_central_db_integration.value === '1';
          if (el.code_name === 'data_complex_system') return element.has_complex_system_integration.value === '1';

          if (el.code_name === 'payment_system_name') return element.has_payment_system.value === '1';
          if (el.code_name === 'reason_payment_system') return element.has_payment_system.value === '0';

          return !['id', 'comment', 'is_correctly'].includes(el.code_name);
        })
        .map((el) => {
          if (el.code_name === 'purpose') return { ...el, translated_value: viewPurpose };
          if (el.code_name === 'classifiers') return { ...el, translated_value: viewClassifiers };
          if (el.code_name === 'auto_processes') return { ...el, translated_value: viewAutoProcesses };
          return el;
        }),
    [element, viewAutoProcesses, viewClassifiers, viewPurpose]
  );

  const defaultValues = Object.values(element).reduce(
    (acc, el) => ({ ...acc, [el.code_name]: el.value ? `${el.value}` : '' }),
    {}
  );
  const [addDialog, handleOpenAdd, handleCloseAdd] = useDialog();
  const [editDialog, handleOpenEdit, handleCloseEdit] = useDialog();
  const [viewDialog, handleOpenView, handleCloseView] = useDialog();

  const onClickView = (id) => () => {
    handleOpenView();
    dispatch(getElementInfoSystems(id));
    dispatch(getComplexAssessment(id, 185));
    dispatch(getEvaluations(185, [20, 21, 22, 23]));
  };

  const onClickDelete = (id) => () => dispatch(deleteElementInfoSystems(id));

  const { assessment, evaluationList } = useSelector((state) => state.complexEvaluation, shallowEqual);
  const _evaluationList = evaluationList.map((el) => ({ ...el, value: el.point, code_name: el.param_id }));
  const sortedAssessments = assessment instanceof Array ? assessment.sort((a, b) => a.param_id - b.param_id) : [];

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const userRoles = currentUser?.roles || [];
  const isOrganization = userRoles.includes('organization');
  const isExpert = userRoles.includes('expert-cep');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;
  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const { tabs, evaluations } = useSelector((state) => state.dictionary, shallowEqual);

  const isActiveTab = tabs[selectedTabIndex]?.is_active;
  const { requestLabelState, requestItemState } = useSelector((state) => state.sectionFiveInfoSystemsUI, shallowEqual);

  const handleChangeActiveSystem = async (e, checked) => {
    const id = Number(e.target.value);
    await dispatch(editElementInfoSystems(id, { is_correctly: Number(checked) }));
    await dispatch(getEvaluationList(168));
    if (!checked) {
      let disableEvaluation = true;
      data.forEach((el) => {
        if (el.id === id) return;
        if (el.is_correctly !== 0) disableEvaluation = false;
      });
      if (!isEconomicOrg && disableEvaluation) return updateEvalActivation(disableData);

      let confirmDisable = true;
      evaluation.forEach((el) => {
        if (Number(el.point) !== -1) confirmDisable = false;
      });

      if (disableEvaluation && confirmDisable) return updateEvalActivation(disableData);
    }
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getInfoSystems(value));
  };

  const handleSubmitAssessment = (id, paramId) => async (data) => {
    await dispatch(saveComplexAssessment(id, 185, paramId, data));

    if (Number(data.point) === -1) {
      let disableItemEvaluation = true;
      sortedAssessments.forEach((el) => {
        if (el.param_id === paramId) return;
        if (isEconomicOrg && el.param_id === 22) return;
        if (Number(el.point) !== -1) {
          disableItemEvaluation = false;
        }
      });
      if (disableItemEvaluation) {
        await dispatch(editElementInfoSystems(id, { is_correctly: 0 }));
        handleCloseView();
      }
    }
    dispatch(getEvaluationList(168));
  };

  const handleDeleteAssessment = (id, paramId) => async () => {
    await dispatch(deleteComplexAssessment(id, 185, paramId));
    dispatch(getEvaluationList(168));
  };

  const handleSubmitEvaluation = (paramId) => async (value) => {
    await dispatch(saveInfoSystemsEvaluation(paramId, value));

    if (Number(value.point) === -1) {
      let disableEvaluation = true;
      evaluation.forEach((el) => {
        if (el.param_id === paramId) return;
        if (Number(el.point) !== -1) {
          disableEvaluation = false;
        }
      });

      let confirmDisable = true;
      data.forEach((el) => {
        if (el.is_correctly !== 0) confirmDisable = false;
      });

      if (disableEvaluation && confirmDisable) updateEvalActivation(disableData);
    }
    dispatch(getEvaluationList(168));
  };
  const handleDeleteEvaluation = (paramId) => async () => {
    await dispatch(deleteInfoSystemsEvaluation(paramId));
    dispatch(getEvaluationList(168));
  };

  const evalActivation = useSelector((state) => state.evaluationActivation, shallowEqual);
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(168, data));
    dispatch(getEvaluationList(168));
    if (isEconomicOrg) dispatch(getInfoSystemsEvaluation());
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const isEconomicOrg = localStorage.orgCategoryId === '2';

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (error.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, error]);
  if (error.hasError && error.status !== 422) return <Error error={error} />;

  return (
    <>
      <div className="infoSystem mb">
        <div className="titleContainer mb4">
          <div className="titleBorder"></div>
          <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
          <div className="titleBorder"></div>
        </div>
        <TableContainer component={Paper} className={classes.container}>
          {requestLabelState === 'request' ? (
            <Loader />
          ) : (
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">№</StyledTableCell>
                  <StyledTableCell align="center">{labels.title}</StyledTableCell>
                  <StyledTableCell align="center">{labels.state_id}</StyledTableCell>
                  <StyledTableCell align="center">{labels.commissioning_date}</StyledTableCell>
                  <StyledTableCell align="center">{labels.count_users}</StyledTableCell>
                  {isActiveTab === 1 && (
                    <StyledTableCell align="center">
                      <FormattedMessage id="section51.activeEvaluation" defaultMessage="Расчёт оценки" />
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="left" />
                </TableRow>
              </TableHead>
              <TransitionGroup component={TableBody}>
                {data.map((row, ind) => (
                  <CSSTransition key={row.id} timeout={1200} classNames="listItemUI">
                    <StyledTableRow>
                      <StyledTableCell align="center">{(page - 1) * 20 + ind + 1}</StyledTableCell>
                      <StyledTableCell align="center">{row.title}</StyledTableCell>
                      <StyledTableCell align="center">{row.state_name}</StyledTableCell>
                      <StyledTableCell align="center">{row.commissioning_date}</StyledTableCell>
                      <StyledTableCell align="center">{row.count_users}</StyledTableCell>
                      {isActiveTab === 1 && (
                        <StyledTableCell align="center">
                          <Checkbox
                            disabled={!isExpert}
                            value={row.id}
                            checked={Boolean(row.is_correctly)}
                            onChange={handleChangeActiveSystem}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </StyledTableCell>
                      )}
                      <StyledTableCell align="left" style={{ width: 50 }}>
                        <ButtonGroup orientation="vertical" variant="contained" color="primary">
                          <Button color="primary" aria-label="show" onClick={onClickView(row.id)}>
                            <VisibilityIcon />
                          </Button>
                          {isVisibleOrg && (
                            <Button color="secondary" aria-label="edit" onClick={onClickDelete(row.id)}>
                              <DeleteIcon />
                            </Button>
                          )}
                        </ButtonGroup>
                      </StyledTableCell>
                    </StyledTableRow>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </Table>
          )}
        </TableContainer>
        {isVisibleOrg && (
          <Button color="primary" variant="contained" className="infoSystem__addButton" onClick={handleOpenAdd}>
            <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
          </Button>
        )}
        {metaData.pageCount > 1 && (
          <div className="dFJustifyFxCenter mt4">
            <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
          </div>
        )}
      </div>
      <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="w30 mAuto mt4">
        {isEconomicOrg &&
          evaluation.map((_eval, i) => (
            <div className="w70 mAuto mt3 mb3" key={_eval.param_id}>
              <ExpertAssessment
                assessment={_eval}
                evaluations={points[i]}
                isExpert={isVisibleExpert}
                onSubmit={handleSubmitEvaluation(_eval.param_id)}
                onDelete={handleDeleteEvaluation(_eval.param_id)}
              />
            </div>
          ))}
        {_evaluationList.length > 0 && (
          <div className="w50 m0Auto mt4">
            {requestItemState === 'request' ? (
              <Loader />
            ) : (
              <CustomTable
                rows={_evaluationList}
                title={
                  <FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />
                }
              />
            )}
          </div>
        )}
      </EvaluationActivation>
      <Dialog dialogState={viewDialog} onClose={handleCloseView} title={tabs[selectedTabIndex]?.title}>
        <div className="containerTableComponent">
          {requestItemState === 'request' ? (
            <div className="mt10">
              <Spinner />
            </div>
          ) : (
            <>
              <CustomTable
                rows={elementFields}
                title={
                  <FormattedMessage
                    id="section51.infoAboutInfoSystem"
                    defaultMessage="Сведения об информационной системе"
                  />
                }
                head={
                  isVisibleOrg && (
                    <IconButton onClick={handleOpenEdit} color="inherit">
                      <CreateIcon />
                    </IconButton>
                  )
                }
              />
              {evalActivation.is_active.value !== 0 &&
                element?.is_correctly?.value !== '0' &&
                sortedAssessments.map((assmt, i) => {
                  if (isEconomicOrg && assmt.param_id === 22) return null;
                  return (
                    <div className="mAuto mb4 w70 mt4" key={assmt.param_id}>
                      <ExpertAssessment
                        isExpert={isVisibleExpert}
                        assessment={assmt}
                        evaluations={evaluations[i]}
                        onSubmit={handleSubmitAssessment(element.id?.value, assmt.param_id)}
                        onDelete={handleDeleteAssessment(element.id?.value, assmt.param_id)}
                      />
                    </div>
                  );
                })}
            </>
          )}
        </div>
      </Dialog>
      <Dialog
        dialogState={editDialog}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form
          submitAction={handleCloseEdit}
          defaultValues={defaultValues}
          isEditableMode
          defaultClassifiers={classifiers}
          defaultAutoProcesses={autoProcesses}
          defaultPurpose={purpose}
        />
      </Dialog>
      <Dialog
        dialogState={addDialog}
        onClose={handleCloseAdd}
        title={intl.formatMessage({
          id: 'section51.infoAboutInfoSystem',
          defaultMessage: 'Сведения об информационной системе',
        })}>
        <Form submitAction={handleCloseAdd} />
      </Dialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateInformationSystems',
          defaultMessage: 'Наименование ИС не должно повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  );
}

const dependFields = [
  'has_interdepartmental_integration',
  'has_classifier_integration',
  'has_central_db_integration',
  'has_complex_system_integration',
];

const disableData = {
  is_active: '0',
  reason_deactivation: 'Истисно',
};
