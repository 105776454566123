import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

import Dialog from '../../reused/SimpleDialog';
import Table from '../../reused/Table';
import Loader from '../../reused/Loader';
import { useDialog } from '../../../common/hooks';
import { getWebsiteAppsInfo } from '../../../actions';
import Error from '../../reused/Error';
import Form from './Form';

export default function Section22() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebsiteAppsInfo());
  }, [dispatch]);

  const { websiteApps, labels } = useSelector((state) => state.sectionTwoWebsiteApps, shallowEqual);

  const WebsiteAppsInfo = useMemo(
    () => (websiteApps ? Object.values(websiteApps) : Object.keys(labels).map((key) => ({ label: labels[key], code_name: key }))),
    [websiteApps, labels]
  );

  const filteredWebsiteAppsInfo = useMemo(
    () =>
      Object.values(WebsiteAppsInfo).filter((el) => {
        if (websiteApps) {
          if (el.code_name === 'link_android_app') return websiteApps.has_android_app?.value === '1';
          if (el.code_name === 'link_ios_app') return websiteApps.has_ios_app?.value === '1';
          if (el.code_name === 'link_another_app') return websiteApps.has_another_app?.value === '1';
        }
        return !['is_correctly', 'comment', 'point'].includes(el.code_name);
      }),
    [WebsiteAppsInfo, websiteApps]
  );

  const defaultValues = filteredWebsiteAppsInfo.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value ? `${el.value}` : '' }), {});

  const [dialogState, handleOpen, handleClose] = useDialog();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTime = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisible = isOrganization && finishTime > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestState } = useSelector((state) => state.sectionTwoWebsiteAppsUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoWebsiteApps.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;
  return (
    <>
      {requestState === 'request' ? (
        <Loader />
      ) : (
        <Table
          rows={filteredWebsiteAppsInfo}
          title={sectionTabs[selectedTabIndex]?.title}
          head={
            <>
              {isVisible && (
                <IconButton onClick={handleOpen} color="inherit">
                  <CreateIcon />
                </IconButton>
              )}
              <Dialog
                dialogState={dialogState}
                onClose={handleClose}
                title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
                <Form defaultValues={defaultValues} submitAction={handleClose} />
              </Dialog>
            </>
          }
        />
      )}
    </>
  );
}
