/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'classnames';

import Dashboard from '../Dashboard';
import { DashedTableCell, StyledTableRow, useStyles } from '../reused/Table';
import Spinner from '../reused/Spinner';
import { generateHeaders, getCategoriesOrg, getOrganizations, getReportTemplateList, resetTabIndex } from 'actions';
import axios from 'axios';
import fileDownload from 'js-file-download';
import routes from '../../routes';
import { useForm } from 'react-hook-form';

function OrganizationList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors, handleSubmit, register, watch } = useForm();
  const intl = useIntl();

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const fetchingProfile = useSelector((state) => state.profileUI.requestingState, shallowEqual);
  useEffect(() => {
    if (fetchingProfile === 'success' && !currentUser.roles.includes('expert')) {
      localStorage.setItem('orgId', currentUser.organization.id);
      history.replace('/not-found');
    }
  }, [fetchingProfile]);

  const { statOrganizations, categoriesOrg, reportTemplateList } = useSelector(
    (state) => state.dictionary,
    shallowEqual
  );
  useEffect(() => {
    dispatch(getOrganizations(paramsOrganizationFetch));
    dispatch(getCategoriesOrg());
    dispatch(resetTabIndex());
    dispatch(getReportTemplateList());
  }, []);

  const dataStatOrganizations = statOrganizations.orgStat;
  const labelsStatOrganizations = statOrganizations.sections;

  const fetchingOrganizations = useSelector((state) => state.dictionaryUI.fetchingOrganizations, shallowEqual);

  const handleClick = (org) => () => {
    localStorage.setItem('orgId', org.id);
    localStorage.setItem('orgTitle', org.short_title);
    history.push('/section-id-1/section13');
  };

  const getCategoryFilter = sessionStorage.getItem('filter_category');
  const [category, setCategory] = useState(getCategoryFilter || '');
  const paramsOrganizationFetch = {
    org_category_id: category,
    max_section_level: '1',
    organization_id: null,
  };
  const formValues = watch();

  useEffect(() => {
    const { year, quarter } = formValues;
    if (year && quarter) {
      paramsOrganizationFetch['year'] = year;
      paramsOrganizationFetch['quarter'] = quarter;
    }
  }, [formValues]);

  const handleChangeCategory = (event) => {
    paramsOrganizationFetch['org_category_id'] = event.target.value;
    sessionStorage.setItem('filter_category', event.target.value);
    dispatch(getOrganizations(paramsOrganizationFetch));
    setCategory(event.target.value);
  };

  const [value, setValue] = useState('');
  const linkStyles = { cursor: 'pointer', color: '#3f51b5', fontWeight: 700 };
  const handleDownload = () => {
    const template = reportTemplateList.find((el) => el.report_template_type === value);
    if (template) {
      axios({
        url: routes.reportRating(),
        method: 'post',
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId || 26),
        responseType: 'blob',
        data: { ...template, year: paramsOrganizationFetch.year, quarter: paramsOrganizationFetch.quarter },
      }).then((res) => {
        fileDownload(res.data, `${template.title}.xlsx`);
      });
    }
  };

  const onSubmit = (data) => {
    dispatch(getOrganizations(paramsOrganizationFetch));
  };
  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">
            <div className="mb2 dFlex dFJustifyFxEnd">
              <NativeSelect value={value} onChange={(e) => setValue(e.target.value)}>
                <option value=""></option>
                {reportTemplateList.map((temp, i) => (
                  <option key={i} value={temp.report_template_type}>
                    {temp.title}
                  </option>
                ))}
              </NativeSelect>
              <Button color="primary" variant="contained" className="ml2" onClick={handleDownload}>
                Экспорт
              </Button>
            </div>
            <Accordion className="mb2">
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography>Архив</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <form onSubmit={handleSubmit(onSubmit)} className="dFlex alignItemsCenter">
                  <TextField
                    inputRef={register()}
                    inputProps={{ min: 2020, max: 2099 }}
                    style={{ width: 120 }}
                    error={!!errors.year}
                    variant="outlined"
                    name="year"
                    label={intl.formatMessage({
                      id: 'organizationStat.labelYear',
                    })}
                    type="number"
                  />
                  <TextField
                    inputRef={register()}
                    inputProps={{ min: 1, max: 4 }}
                    style={{ width: 120 }}
                    className={clsx('ml2')}
                    error={!!errors.quarter}
                    variant="outlined"
                    name="quarter"
                    label={intl.formatMessage({
                      id: 'organizationStat.labelQuarter',
                    })}
                    type="number"
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={clsx('ml2')}
                    disabled={fetchingOrganizations === 'request'}>
                    {fetchingOrganizations === 'request' ? (
                      <CircularProgress color="secondary" size={28} />
                    ) : (
                      <FormattedMessage id="global.requireBtnTitle" defaultMessage="Запрос" />
                    )}
                  </Button>
                </form>
              </AccordionDetails>
            </Accordion>
            <TableContainer component={Paper} className={classes.container}>
              {fetchingOrganizations === 'request' ? (
                <Spinner />
              ) : (
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <DashedTableCell align="center">№</DashedTableCell>
                      <DashedTableCell align="center" style={{ width: 350 }}>
                        <FormattedMessage id="drawerList.nameOrganization" defaultMessage="Наименование организации" />
                      </DashedTableCell>
                      <DashedTableCell align="center">
                        <FormControl>
                          <InputLabel id="category-select-label">
                            <FormattedMessage id="global.categoryName" defaultMessage="Категория" />
                          </InputLabel>
                          <Select
                            labelId="category-select-label"
                            id="category-select"
                            value={category}
                            onChange={handleChangeCategory}
                            style={{ width: 120 }}>
                            <MenuItem value="">Все</MenuItem>
                            {categoriesOrg.map((category) => (
                              <MenuItem key={category.id} value={category.id}>
                                {category.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </DashedTableCell>
                      <DashedTableCell align="center">{labelsStatOrganizations[2]?.title}</DashedTableCell>
                      <DashedTableCell align="center">{labelsStatOrganizations[3]?.title}</DashedTableCell>
                      <DashedTableCell align="center">{labelsStatOrganizations[4]?.title}</DashedTableCell>
                      <DashedTableCell align="center">{labelsStatOrganizations[5]?.title}</DashedTableCell>
                      <DashedTableCell align="center">{labelsStatOrganizations[6]?.title}</DashedTableCell>
                      <DashedTableCell align="center">
                        <FormattedMessage id="drawerList.total" defaultMessage="Итого" />
                      </DashedTableCell>
                      <DashedTableCell align="center">%</DashedTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataStatOrganizations.map((org, ind) => (
                      <StyledTableRow key={org.id}>
                        <DashedTableCell align="center">{ind + 1}</DashedTableCell>
                        <DashedTableCell style={linkStyles} onClick={handleClick(org)}>
                          {org.short_title}
                        </DashedTableCell>
                        <DashedTableCell align="center">{org.category_title}</DashedTableCell>
                        <DashedTableCell align="center">
                          {org.points[2]?.point ? org.points[2]?.point : 0}
                        </DashedTableCell>
                        <DashedTableCell align="center">
                          {org.points[3]?.point ? org.points[3]?.point : 0}
                        </DashedTableCell>
                        <DashedTableCell align="center">
                          {org.points[4]?.point ? org.points[4]?.point : 0}
                        </DashedTableCell>
                        <DashedTableCell align="center">
                          {org.points[5]?.point ? org.points[5]?.point : 0}
                        </DashedTableCell>
                        <DashedTableCell align="center">
                          {org.points[6]?.point ? org.points[6]?.point : 0}
                        </DashedTableCell>
                        <DashedTableCell align="center">{org.points.TOTAL?.point.toFixed(2)}</DashedTableCell>
                        <DashedTableCell align="center">
                          {isNaN(org.points.TOTAL_PERCENTAGE)
                            ? (0.0).toFixed(2)
                            : org.points.TOTAL_PERCENTAGE?.toFixed(2)}
                        </DashedTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default OrganizationList;
