import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { generateHeaders } from 'actions/dictionary';
import routes from 'regions/routes';
import { Alert } from '@material-ui/lab';
import { getQuestionnaire } from '../../../actions/regions/questionnaire';
import parseWithOptions from 'date-fns/fp/parseWithOptions';

function Form({ submitAction }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    questionnaire: { formLabels },
    current,
  } = useSelector((state) => state.r_questionnaire);

  const { register, handleSubmit, control, watch, errors } = useForm();

  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState([]);

  const onSubmit = (data) => {
    setLoading(true);

    const formData = Object.keys(data).map((key) => ({ indicator_secondary_id: key.substring(13), value: data[key] }));
    axios({
      url: routes.questionnaire('store'),
      method: 'post',
      data: { region_id: current[0].id, table_id: id, formData },
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    })
      .then(({ data }) => {
        if (data.success) {
          dispatch(getQuestionnaire(String(current[0].id).substring(0, 4), id)).then((data) => {
            setValidation([]);
            setLoading(false);
            if (submitAction) submitAction();
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          console.log('validation', err.response.data.error);
          setValidation(err.response.data.error);
        }
        setLoading(false);
        console.log(err);
      });
  };

  const allFields = watch();
  const generateField = (field) => {
    // определяю показывать инпут или нет
    if (!JSON.parse(field.region_type).includes(current[0].region_type)) return null;

    // задаю дефолтное значение
    let defaultValue = '';
    const currentField = current.find((el) => el.indicator_id === field.indicator_id);
    if (currentField) {
      defaultValue = currentField.value === null ? null : Number(currentField.value);
    }

    // определяю тип инпута
    let input;
    if (field.unit_type === 'BOOLEAN') {
      input = (
        <div key={field.indicator_id}>
          <FormHelperText>{field.title}</FormHelperText>
          <Controller
            as={
              <RadioGroup row>
                <FormControlLabel
                  value="1"
                  control={<Radio color="primary" />}
                  label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />}
                />
                <FormControlLabel
                  value="0"
                  control={<Radio color="primary" />}
                  label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />}
                />
              </RadioGroup>
            }
            name={`secondary_id_${field.indicator_id}`}
            control={control}
            // rules={{ required: true }}
            defaultValue={`${defaultValue}`}
          />
        </div>
      );
    } else {
      const props = {};
      const propsInput = {};
      if (field.unit_type === 'DOUBLE PRECISION') {
        props.validate = (value) => (!/[0-9]\.[0-9]{5}/i.test(value) ? undefined : 'world');
        props.min = 0;
        propsInput.inputProps = { step: 0.0001, min: 0 };
      }
      input = (
        <TextField
          key={field.indicator_id}
          inputRef={register({
            required: false,
            ...props,
          })}
          error={!!errors[`secondary_id_${field.indicator_id}`]}
          name={`secondary_id_${field.indicator_id}`}
          helperText={`${field.title} (${field.unit_title})`}
          defaultValue={defaultValue}
          type="number"
          className="mb"
          fullWidth
          {...propsInput}
        />
      );
    }

    if (!field.hasOwnProperty('children')) {
      return input;
    }

    return (
      <React.Fragment key={field.indicator_id}>
        {input}
        {(Number(allFields[`secondary_id_${field.indicator_id}`]) > 0 ||
          current.find((el) => el.indicator_id === field.indicator_id)?.value > 0) && (
          <>
            <FormLabel className="dFJustifyFxCenter">{field.children_title}</FormLabel>
            {field.children.map(generateField)}
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt">
      {validation.length > 0 &&
        validation.map((err, i) => (
          <Alert key={i} severity="error" className="mt">
            {err.message}
          </Alert>
        ))}
      {formLabels.map((field) => {
        return generateField(field);
      })}
      <Button className="mb" variant="contained" color="primary" type="submit" style={{ minWidth: 110 }}>
        {loading ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
        )}
      </Button>
    </form>
  );
}

export default Form;
