import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Pagination from '@material-ui/lab/Pagination';

import Form from './Form';
import Download from '../../reused/Download';
import Spinner from '../../reused/Spinner';
import Loader from '../../reused/Loader';
import { StyledTableCell, StyledTableRow, useStyles } from '../../reused/Table';
import CustomTable from '../../reused/Table';
import Dialog from '../../reused/FullScreenDialog';
import SimpleDialog from '../../reused/SimpleDialog';
import ExpertAssessment from '../../reused/ExpertAssessment';
import ExpertMark from '../../reused/ExpertMark';
import { useDialog } from '../../../common/hooks';
import Error from '../../reused/Error';
import Alert from '../../reused/Alert';
import {
  deletePublicElement,
  getFormTypes,
  getResultTypes,
  getTrackingTypes,
  getUserTypes,
  getPublicElement,
  getPublicServices,
  getEvaluations,
  getComplexMark,
  saveComplexMark,
  deleteСomplexMark,
  saveComplexAssessment,
  deleteComplexAssessment,
  getEvaluationList,
  getPublicServiceLabels,
  resetError,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  toggleTabState,
} from '../../../actions';
import EvaluationActivation from '../../reused/EvaluationActivation';

function Section311() {
  const classes = useStyles();
  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateShow, handleOpenShow, handleCloseShow] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    dispatch(getPublicServices(page));
    dispatch(getPublicServiceLabels());
    dispatch(getEvalActivationInfo(31));
    dispatch(getEvaluationList(31));
    dispatch(getEvaluations(32, [11, 12]));

    dispatch(getFormTypes());
    dispatch(getResultTypes());
    dispatch(getTrackingTypes());
    dispatch(getUserTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = currentUser?.roles.includes('expert-agu');
  const isOrganization = currentUser?.roles.includes('organization');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;
  const isVisibleBoth = isVisibleExpert || isVisibleOrg;

  const { services, labels, element, metaData } = useSelector(
    (state) => state.sectionThreeInfoPublicServices,
    shallowEqual
  );
  const serviceResult = element?.service_result_types?.translated_value;
  let publicServices;
  if (serviceResult) {
    publicServices = serviceResult.map((el) => el.title).join(', ');
  }

  const userService = element?.service_user_types?.translated_value;
  let publicUserServices;
  if (userService) {
    publicUserServices = userService.map((el) => el.title).join(', ');
  }

  const formServices = element?.service_form_types?.translated_value;
  let publicServicesFormType;
  if (formServices) {
    publicServicesFormType = formServices.map((el) => el.title).join(', ');
  }

  const mechanismServices = element?.tracking_types?.translated_value;
  let publicMechanismServices;
  if (mechanismServices) {
    publicMechanismServices = mechanismServices.map((el) => el.title).join(', ');
  }
  const filteredElement = useMemo(
    () =>
      Object.values(element).filter((el) => {
        if (links.includes(el.code_name)) return element[links.find((link) => link === el.code_name)]?.value !== '';
        if (links.includes(el.code_name) && element.service_form_types?.value)
          return element.service_form_types.value.find((id) => id === 2);
        if (el.code_name === 'service_paid') return element.is_paid_service?.value === '1';
        if (el.code_name === 'tracking_types') return element.has_tracking_mechanism?.value === '1';
        if (el.code_name === 'other_result_title' && element.service_result_types?.value)
          return element.service_result_types.value.find((id) => id === 12);
        return !['id', 'is_correctly', 'comment'].includes(el.code_name);
      }),
    [element]
  );

  const itemData = useMemo(
    () =>
      filteredElement.map((el) => {
        if (el.code_name === 'service_result_types') return { ...el, translated_value: publicServices };
        if (el.code_name === 'service_user_types') return { ...el, translated_value: publicUserServices };
        if (el.code_name === 'service_form_types') return { ...el, translated_value: publicServicesFormType };
        if (el.code_name === 'tracking_types') return { ...el, translated_value: publicMechanismServices };
        if (el.code_name === 'regulation_file')
          return {
            ...el,
            value: el.value ? <Download fileName={el.value} className="end" /> : '',
          };
        return el;
      }),
    [publicMechanismServices, publicServices, publicServicesFormType, publicUserServices, filteredElement]
  );

  const defaultValues = useMemo(
    () => filteredElement.reduce((acc, el) => ({ ...acc, [el.code_name]: el.value ? `${el.value}` : '' }), {}),
    [filteredElement]
  );

  const onClickShowEdit = (id) => () => {
    handleOpenShow();
    dispatch(getPublicElement(id));
    dispatch(getComplexMark(id, 32));
  };

  const onClickDelete = (id) => () => dispatch(deletePublicElement(id));

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { mark, assessment, evaluationList } = useSelector((state) => state.complexEvaluation, shallowEqual);
  const _evaluationList = evaluationList.map((el) => ({ ...el, value: el.point, code_name: el.param_id }));
  const evaluations = useSelector((state) => state.dictionary.evaluations, shallowEqual);
  const isEconomicOrg = localStorage.orgCategoryId === '2';
  const handleSubmitMark = (id) => async (data) => {
    await dispatch(saveComplexMark(id, 32, data));
    // dispatch(getPublicServices());
    dispatch(getEvaluationList(31));
  };
  const handleDeleteMark = (id) => async () => {
    await dispatch(deleteСomplexMark(id, 32));
    await dispatch(deleteComplexAssessment(id, 32, 11));
    if (isEconomicOrg) await dispatch(deleteComplexAssessment(id, 32, 12));
    // dispatch(getPublicServices());
    dispatch(getEvaluationList(31));
  };
  const handleSubmitAssessment = (id, paramId) => async (data) => {
    await dispatch(saveComplexAssessment(id, 32, paramId, data));
    // dispatch(getPublicServices());
    dispatch(getEvaluationList(31));
  };
  const handleDeleteAssessment = (id, paramId) => async () => {
    await dispatch(deleteComplexAssessment(id, 32, paramId));
    // dispatch(getPublicServices());
    dispatch(getEvaluationList(31));
  };

  const evalActivation = useSelector((state) => state.evaluationActivation, shallowEqual);
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(31, data));
    dispatch(getEvaluationList(31));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getPublicServices(value));
  };

  const { requestLabelState, requestItemState } = useSelector(
    (state) => state.sectionThreeInfoPublicServicesUI,
    shallowEqual
  );
  const errorHandler = useSelector((state) => state.sectionThreeInfoPublicServices.error, shallowEqual);

  const [alertState, setAlertState] = React.useState(false);
  useEffect(() => {
    if (errorHandler.status === 422) {
      setAlertState(true);

      setTimeout(() => {
        dispatch(resetError());
      }, 1000);
    }
  }, [dispatch, errorHandler]);
  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer mb4">
        <div className="titleBorder"></div>
        <h2 className="title">{tabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <TableContainer component={Paper} className={classes.container}>
        {requestLabelState === 'request' ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow className={classes.headRow}>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">№</StyledTableCell>
                <StyledTableCell align="center">{labels.title}</StyledTableCell>
                <StyledTableCell align="center">{labels.service_user_types}</StyledTableCell>
                <StyledTableCell align="center">{labels.service_form_types}</StyledTableCell>
                <StyledTableCell align="center">{labels.service_paid}</StyledTableCell>
                <StyledTableCell align="center">{labels.service_result_types}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TransitionGroup component={TableBody}>
              {services.map((service, ind) => {
                const rawUserTypes = service.service_user_type_names?.join(', ');
                const rawServiceFormTypes = service.service_form_type_names?.join(', ');
                const rawServiceResultTypes = service.service_result_type_names?.join(', ');
                return (
                  <CSSTransition key={service.id} timeout={1200} classNames="listItemUI">
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        <ButtonGroup orientation="vertical" color="primary" variant="contained">
                          <Button onClick={onClickShowEdit(service.id)}>
                            <VisibilityIcon />
                          </Button>
                          {isVisibleExpert && (
                            <Button color="secondary" onClick={onClickDelete(service.id)}>
                              <DeleteIcon />
                            </Button>
                          )}
                        </ButtonGroup>
                      </StyledTableCell>
                      <StyledTableCell align="center">{(page - 1) * 20 + ind + 1}</StyledTableCell>
                      <StyledTableCell align="center">{service.title}</StyledTableCell>
                      <StyledTableCell align="center">{rawUserTypes}</StyledTableCell>
                      <StyledTableCell align="center">{rawServiceFormTypes}</StyledTableCell>
                      <StyledTableCell align="center">{service.service_paid}</StyledTableCell>
                      <StyledTableCell align="center">{rawServiceResultTypes}</StyledTableCell>
                    </StyledTableRow>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </Table>
        )}
      </TableContainer>
      {isVisibleExpert && (
        <Button variant="contained" color="primary" className="mb2 m0Auto" onClick={handleOpenAdd}>
          <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
        </Button>
      )}
      {metaData.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt4">
          <Pagination count={metaData.pageCount} page={page} onChange={handleChange} size="large" />
        </div>
      )}
      <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="mAuto w30 mb4 mt4">
        {_evaluationList.length !== 0 && (
          <div className="w50 m0Auto mt4">
            <CustomTable
              rows={_evaluationList}
              title={<FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />}
            />
          </div>
        )}
      </EvaluationActivation>

      <Dialog dialogState={dialogStateShow} onClose={handleCloseShow}>
        <div className="mt10 tableData">
          {requestItemState === 'request' ? (
            <Spinner />
          ) : (
            <>
              <CustomTable
                rows={itemData}
                title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}
                head={
                  isVisibleBoth && (
                    <Button onClick={handleOpenEdit} color="inherit">
                      <CreateIcon />
                    </Button>
                  )
                }
              />
              {evalActivation.is_active.value !== 0 && (
                <React.Fragment>
                  <ExpertMark
                    mark={mark}
                    isExpert={isVisibleExpert}
                    onSubmit={handleSubmitMark(element.id?.value)}
                    onDelete={handleDeleteMark(element.id?.value)}
                  />
                  <div className="mb3">
                    <ExpertAssessment
                      assessment={assessment[0]}
                      isExpert={isVisibleExpert}
                      evaluations={evaluations[0]}
                      onSubmit={handleSubmitAssessment(element.id?.value, 11)}
                      onDelete={handleDeleteAssessment(element.id?.value, 11)}
                      isVisible={mark}
                    />
                  </div>
                  {isEconomicOrg && (
                    <div className="mb3">
                      <ExpertAssessment
                        assessment={assessment[1]}
                        isExpert={isVisibleExpert}
                        evaluations={evaluations[1]}
                        onSubmit={handleSubmitAssessment(element.id?.value, 12)}
                        onDelete={handleDeleteAssessment(element.id?.value, 12)}
                        isVisible={mark}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </>
          )}
        </div>
      </Dialog>
      <SimpleDialog
        dialogState={dialogStateEdit}
        onClose={handleCloseEdit}
        title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
        <Form
          submitAction={handleCloseEdit}
          defaultValues={defaultValues}
          defaultServices={serviceResult}
          defaultUserServices={userService}
          defaultServicesFormType={formServices}
          defaultMechanismServices={mechanismServices}
          isEditableMode
        />
      </SimpleDialog>
      <SimpleDialog dialogState={dialogStateAdd} onClose={handleCloseAdd} title="Сведения о государственной услуге">
        <Form submitAction={handleCloseAdd} />
      </SimpleDialog>
      <Alert
        open={alertState}
        onClose={() => setAlertState(false)}
        message={intl.formatMessage({
          id: 'global.errorDublicateServices',
          defaultMessage: 'Наименование услуги не должно повторяться!',
        })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </>
  );
}

export default Section311;

const links = ['link_epigu', 'link_website', 'link_mobile_app', 'link_other'];
