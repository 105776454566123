const host =
  process.env.NODE_ENV === 'production' ? 'https://api.nis.uz/region/api' : 'http://nis.track.uz:8071/region/api';

export default {
  dictionary: (handbook) => [host, `dictionary/${handbook}`].join('/'),
  report: (dictionary) => [host, dictionary].join('/'),
  questionnaire: (method) => [host, `questionnaire/${method}`].join('/'),
  statistics: () => [host, 'statistics/detailed-evaluation'].join('/'),
  rating: () => [host, 'statistics/rating'].join('/'),
  excludedIndicator: (method) => [host, `r-excluded-indicator/${method}`].join('/'),
};
