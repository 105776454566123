import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

export const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  icon: {
    color: '#fff',
  },
  button: {
    width: '60%',
    display: 'flex',
    margin: '15px auto 10px',
  },
  appealButton: {
    display: 'flex',
    margin: '0 auto',
  },
}));

export default function SimpleDialog({ dialogState, onClose, children, title, ...props }) {
  const classes = useStyles();
  return (
    <div>
      <Dialog open={dialogState} onClose={onClose} {...props}>
        <DialogTitle>
          <span>{title}</span>
          <Button onClick={onClose} className={classes.closeButton}>
            <CloseIcon className={classes.icon} />
          </Button>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </Dialog>
    </div>
  );
}
