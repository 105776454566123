import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Loader from '../../reused/Loader';
import CustomTable from '../../reused/Table';
import Title from '../../reused/Title';
import Error from '../../reused/Error';
import { useStyles, StyledTableCell, StyledTableRow } from '../../reused/Table';
import {
  getEvalActivationInfo,
  getGovernmentLabels,
  getGovernmentProjects,
  getGovProjectEvaluation,
  toggleTabState,
  updateEvalActivationInfo,
} from '../../../actions';
import EvaluationActivation from '../../reused/EvaluationActivation';
import { Pagination } from '@material-ui/lab';

function Section61() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getGovernmentProjects(page));
    dispatch(getGovernmentLabels());
    dispatch(getEvalActivationInfo(170));
    dispatch(getGovProjectEvaluation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { projects, labels, evaluation, pagination } = useSelector(
    (state) => state.sectionSixInfoGovernmentProject,
    shallowEqual
  );
  const _evaluation = evaluation.reduce((acc, el) => {
    if (el.param_id === 1) return [...acc, { ...el, value: el.point || el.val, code_name: el.param_id }];
    return [...acc, { ...el, value: el.point || el.val, code_name: el.param_id }];
  }, []);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const userRoles = currentUser?.roles || [];
  const isExpert = userRoles.includes('expert-cep');
  const isOrganization = userRoles.includes('organization');

  const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);

  const timeProfile =
    parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
  const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleExpert = isExpert && finishTimeExpert > dateNow;
  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const { requestLabelState } = useSelector((state) => state.sectionSixInfoGovernmentProjectUI, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionSixInfoGovernmentProject.error, shallowEqual);

  if (errorHandler.hasError && errorHandler.status !== 422) return <Error error={errorHandler} />;

  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(170, data));
    dispatch(getGovProjectEvaluation());
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  const handleChange = (event, value) => {
    setPage(value);
    dispatch(getGovernmentProjects(value));
  };

  return (
    <>
      <Title title={tabs[selectedTabIndex]?.title} className="mb4" />

      <TableContainer component={Paper} className={classes.container}>
        {requestLabelState === 'request' ? (
          <Loader />
        ) : (
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {isVisibleOrg && <StyledTableCell align="right"></StyledTableCell>}
                <StyledTableCell align="center">№</StyledTableCell>
                <StyledTableCell align="center">{labels.title}</StyledTableCell>
                <StyledTableCell align="center">{labels.document_no}</StyledTableCell>
                <StyledTableCell align="center">{labels.document_date}</StyledTableCell>
                <StyledTableCell align="center">{labels.status || 'Статус проекта'}</StyledTableCell>
                <StyledTableCell align="center">{labels.realization_mechanism}</StyledTableCell>
                <StyledTableCell align="center">{labels.realization_deadline}</StyledTableCell>
                <StyledTableCell align="center">{labels.financing_source}</StyledTableCell>
                <StyledTableCell align="center">{labels.project_cost}</StyledTableCell>
                <StyledTableCell align="center">{labels.disbursed_funds}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TransitionGroup component={TableBody}>
              {projects.map((project, i) => {
                let color = '';
                switch (project.status_id) {
                  case 1:
                    color = '#00ff0060';
                    break;
                  case 2:
                    color = '#0000ff60';
                    break;
                  case 3:
                    color = 'orange';
                    break;
                  case 4:
                    color = '#ffff00';
                    break;
                  case 5:
                    color = 'violet';
                    break;
                  case 6:
                    color = '#87d0fb';
                    break;
                  case 7:
                    color = 'red';
                    break;
                  default:
                    color = '#fff';
                }

                return (
                  <CSSTransition key={i} timeout={1200} classNames="listItemUI">
                    <StyledTableRow>
                      <StyledTableCell align="center">{(page - 1) * 20 + i + 1}</StyledTableCell>
                      <StyledTableCell align="center">{project.title}</StyledTableCell>
                      <StyledTableCell align="center">{project.document_no}</StyledTableCell>
                      <StyledTableCell align="center">{project.document_date}</StyledTableCell>
                      <StyledTableCell align="center" style={{ backgroundColor: color }}>
                        {project.status_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">{project.realization_mechanism}</StyledTableCell>
                      <StyledTableCell align="center">{project.realization_deadline}</StyledTableCell>
                      <StyledTableCell
                        align="center"
                        dangerouslySetInnerHTML={{ __html: project.financial_source }}></StyledTableCell>
                      <StyledTableCell align="center">{project.project_cost}</StyledTableCell>
                      <StyledTableCell align="center">{project.disbursed_funds}</StyledTableCell>
                    </StyledTableRow>
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </Table>
        )}
      </TableContainer>
      {pagination.pageCount > 1 && (
        <div className="dFJustifyFxCenter mt3">
          <Pagination
            count={pagination.pageCount}
            page={page}
            onChange={handleChange}
            siblingCount={2}
            color="primary"
            variant="outlined"
          />
        </div>
      )}
      <EvaluationActivation isExpert={isVisibleExpert} onSubmit={updateEvalActivation} className="w30 mAuto mt4">
        <div className="w70 mAuto mt4">
          <CustomTable
            rows={_evaluation}
            title={<FormattedMessage id="expertAssessment.expertAssessmentTitle" defaultMessage="Оценка эксперта" />}
          />
        </div>
      </EvaluationActivation>
    </>
  );
}

export default Section61;
