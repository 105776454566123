import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { editManagerInfo, uploadFile } from '../../../actions';
import UploadFile from '../../reused/UploadFile';

const Form = ({ defaultValues, submitAction }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({ defaultValues: { ...defaultValues, file: '' } });
  const labels = useSelector((state) => state.sectionTwoWebsiteContentManager.labels, shallowEqual);
  const isEditMode = defaultValues.file;

  const onSubmit = async (data) => {
    if (isEditMode && data.file.length === 0) {
      dispatch(editManagerInfo({ ...data, file: defaultValues.file }));
    } else {
      const file = await uploadFile(data.file, 2.1);
      dispatch(editManagerInfo({ ...data, file }));
    }
    submitAction();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.full_name}
        className="mb"
        label={labels.full_name}
        name="full_name"
      />
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.position_name}
        className="mb"
        label={labels.position_name}
        name="position_name"
      />
      <TextField
        fullWidth
        inputRef={register({ required: true })}
        error={!!errors.phone}
        className="mb"
        label={labels.phone}
        name="phone"
      />

      <UploadFile
        classNames={['dFJustifyFxCenter']}
        error={!!errors.file}
        register={register({ required: !isEditMode })}
        label={<FormattedMessage id="global.attachOrderAppoint" defaultMessage="Вложить приказ о назначении" />}
      />

      <Button type="submit" color="primary" variant="outlined" className="mt">
        <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
      </Button>
    </form>
  );
};

export default Form;
