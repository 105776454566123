import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const linksSocialPages = handleActions(
  {
    [actions.getSocialPagesSuccess]: (state, { payload }) => {
      return {
        ...state,
        pages: payload,
      };
    },
    [actions.getSocialLabelsSuccess]: (state, { payload }) => ({
      ...state,
      labels: payload,
    }),
    [actions.deleteSocialLinkSuccess]: (state, { payload: { id } }) => ({
      ...state,
      pages: state.pages.filter((el) => el.id !== id)
    }),
    [actions.addSocialLinkSuccess]: (state, { payload }) => ({
      ...state,
      pages: [...state.pages, payload],
    }),
    [actions.editSocialLinkSuccess]: (state, { payload }) => ({
      ...state,
      pages: state.pages.map((link) => (link.id === payload.id ? payload : link)),
    }),
    [actions.getSocialLinkSuccess]: (state, { payload }) => ({
      ...state,
      link: payload,
    }),
    [actions.setSocialPagesError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    pages: [],
    labels: {},
    link: [],
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const linksSocialPagesUI = handleActions(
  {
    [actions.getSocialLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getSocialLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getSocialLabelsFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editSocialLinkRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editSocialLinkSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editSocialLinkFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getSocialLinkRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getSocialLinkSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getSocialLinkFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
