import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const epiguServicesInfo = handleActions(
  {
    [actions.getEpiguServicesInfoSuccess]: (state, { payload: { data, labels } }) => ({
      data: data.services.items,
      metaData: data.services._meta,
      dates: data.date,
      labels: labels,
    }),
  },
  {
    data: [],
    metaData: {},
    labels: {},
    dates: { from: '', to: '' },
  }
);

export const epiguServicesInfoUI = handleActions(
  {
    [actions.getEpiguServicesInfoRequest]: () => ({ fetchRequest: 'request' }),
    [actions.getEpiguServicesInfoSuccess]: () => ({ fetchRequest: 'success' }),
    [actions.getEpiguServicesInfoFailure]: () => ({ fetchRequest: 'failure' }),
  },
  {
    fetchRequest: '',
  }
);
