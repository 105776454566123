import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Form from './Form';
import Loader from '../../reused/Loader';
import Dialog from '../../reused/SimpleDialog';
import { useDialog } from '../../../common/hooks';
import {
  getLabelsHackathon,
  getHackathonsList,
  deleteHackathon,
  getHackathon,
  getHackathonRadioBtn,
  updateHackathonRadioBtn,
} from '../../../actions';
import Error from '../../reused/Error';
import { StyledTableCell, StyledTableRow, useStyles } from '../../reused/Table';

function Section64() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dialogStateAdd, handleOpenAdd, handleCloseAdd] = useDialog();
  const [dialogStateEdit, handleOpenEdit, handleCloseEdit] = useDialog();
  useEffect(() => {
    dispatch(getHackathonRadioBtn());
    dispatch(getHackathonsList());
    dispatch(getLabelsHackathon());
  }, [dispatch]);

  const { list, event, labels, radio } = useSelector((state) => state.sectionSixOrgHackathon, shallowEqual);
  const { requestItemState, requestLabelState, requestCheckboxState } = useSelector((state) => state.sectionSixOrgHackathonUI, shallowEqual);

  const defaultValues = useMemo(
    () => Object.values(event).reduce((acc, el) => ({ ...acc, [el.code_name]: el.value || el.value === 0 ? `${el.value}` : '' }), {}),
    [event]
  );

  useEffect(() => {
    if (radio.checkbox_value) setValue(radio.checkbox_value);
    else setValue('');
  }, [radio]);

  const [value, setValue] = useState('');
  const handleChange = (e) => {
    dispatch(updateHackathonRadioBtn({ ...radio, checkbox_value: +e.target.value }));
    setValue(e.target.value);
  };

  const onClickDelete = (id) => () => dispatch(deleteHackathon(id));

  const onClickEdit = (id) => async () => {
    await dispatch(getHackathon(id));
    handleOpenEdit();
  };

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isOrganization = currentUser?.roles.includes('organization');

  const finishTimeOrg = Date.parse(currentUser?.organization?.deadline_questionnaire);
  const dateNow = Date.now();

  const isVisibleOrg = isOrganization && finishTimeOrg > dateNow;

  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const sectionTabs = useSelector((state) => state.dictionary.tabs, shallowEqual);

  const errorHandler = useSelector((state) => state.sectionSixOrgHackathon.error, shallowEqual);

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  return (
    <>
      <div className="titleContainer marginsTitle">
        <div className="titleBorder"></div>
        <h2 className="title">{sectionTabs[selectedTabIndex]?.title}</h2>
        <div className="titleBorder"></div>
      </div>

      <FormControl component="fieldset" disabled={!isVisibleOrg}>
        <FormLabel component="legend">{radio.checkbox_title}</FormLabel>
        <RadioGroup value={value} onChange={handleChange} row>
          <FormControlLabel value="1" control={<Radio color="primary" />} label={<FormattedMessage id="global.yesTitle" defaultMessage="Да" />} />
          <FormControlLabel value="0" control={<Radio color="primary" />} label={<FormattedMessage id="global.noTitle" defaultMessage="Нет" />} />
        </RadioGroup>
      </FormControl>

      {value === '1' &&
        (requestCheckboxState === 'request' ? (
          <Loader />
        ) : (
          <div className="hackaton">
            <TableContainer component={Paper} className={classes.container}>
              {requestLabelState === 'request' ? (
                <Loader />
              ) : (
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      {isVisibleOrg && <StyledTableCell align="right"></StyledTableCell>}
                      <StyledTableCell align="center">{labels.event_title}</StyledTableCell>
                      <StyledTableCell align="center">{labels.event_date}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TransitionGroup component={TableBody}>
                    {list.map((event) => (
                      <CSSTransition key={event.id} timeout={1200} classNames="listItemUI">
                        <StyledTableRow>
                          {isVisibleOrg && (
                            <StyledTableCell align="center">
                              <ButtonGroup orientation="vertical" variant="contained" color="primary">
                                <Button onClick={onClickEdit(event.id)} aria-label="edit">
                                  <CreateIcon />
                                </Button>
                                <Button onClick={onClickDelete(event.id)} color="secondary" aria-label="delete">
                                  <DeleteIcon />
                                </Button>
                              </ButtonGroup>
                            </StyledTableCell>
                          )}
                          <StyledTableCell align="center">{event.event_title}</StyledTableCell>
                          <StyledTableCell align="center">{event.event_date}</StyledTableCell>
                        </StyledTableRow>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </Table>
              )}
            </TableContainer>
            {isVisibleOrg && (
              <Button className="mb m0Auto" variant="contained" color="primary" type="submit" onClick={handleOpenAdd}>
                <FormattedMessage id="global.addBtnTitle" defaultMessage="Добавить" />
              </Button>
            )}
            <Dialog
              dialogState={dialogStateEdit}
              onClose={handleCloseEdit}
              title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
              {requestItemState === 'request' ? <Loader /> : <Form submitAction={handleCloseEdit} defaultValues={defaultValues} isEditableMode />}
            </Dialog>
            <Dialog
              dialogState={dialogStateAdd}
              onClose={handleCloseAdd}
              title={<FormattedMessage id="global.fillFormTitle" defaultMessage="Заполните форму" />}>
              <Form submitAction={handleCloseAdd} />
            </Dialog>
          </div>
        ))}
    </>
  );
}

export default Section64;
