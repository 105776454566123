import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const processingActs = handleActions(
  {
    [actions.getProcessingActsSuccess]: (state, { payload: { data, labels } }) => ({
      ...state,
      data: data,
      labels: labels,
    }),
    [actions.setProcessingActsError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
  },
  {
    data: {},
    labels: {},
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const processingActsUI = handleActions(
  {
    [actions.getProcessingActsRequest]: () => ({ requestState: 'request' }),
    [actions.getProcessingActsSuccess]: () => ({ requestState: 'success' }),
    [actions.getProcessingActsFailure]: () => ({ requestState: 'failure' }),
    [actions.editProcessingActsRequest]: () => ({ requestState: 'request' }),
    [actions.editProcessingActsSuccess]: () => ({ requestState: 'success' }),
    [actions.editProcessingActsFailure]: () => ({ requestState: 'failure' }),
  },
  {}
);
