/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useStyles, StyledTableCell, StyledTableRow } from '../reused/Table';
import {
  getWebsiteComplianceRequirements,
  editWebsiteComplianceRequirements,
  getEvalActivationInfo,
  updateEvalActivationInfo,
  getSimpleAssessment,
  toggleTabState,
} from '../../actions';
import { FormControl, NativeSelect } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { isEmpty } from '../../common/utils';
import Spinner from '../reused/Spinner';
import EvaluationActivation from '../reused/EvaluationActivation';
import Error from '../reused/Error';
import ExpertAssessment from '../reused/ExpertAssessment';

function Section24() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { viewList } = useSelector((state) => state.sectionTwoWebsiteComplianceRequirements, shallowEqual);

  const currentUser = useSelector((state) => state.profile.currentUser, shallowEqual);
  const isExpert = useMemo(() => currentUser?.roles.includes('expert-aimk'), [currentUser?.roles]);

const timeOrg = useSelector((state) => state.sectionOneOrgInfo.data?.deadline_evaluations?.value, shallowEqual);
  
const timeProfile = parseInt(localStorage.orgId) === currentUser?.organization?.id
      ? currentUser?.organization?.deadline_evaluations
      : currentUser?.deadline_evaluation;
const finishTimeExpert = Date.parse(timeOrg || timeProfile);
  const dateNow = Date.now();

  const isVisible = isExpert && finishTimeExpert > dateNow;

  useEffect(() => {
    dispatch(getWebsiteComplianceRequirements());
    dispatch(getEvalActivationInfo(17));
    dispatch(getSimpleAssessment(17));
  }, []);

  const defaultValues = useMemo(
    () =>
      viewList.reduce(
        (acc, field) => ({
          ...acc,
          [`${field.meta_field_id}[value]`]: field.value,
          [`${field.meta_field_id}[has_evaluate]`]: field.has_evaluate,
        }),
        {}
      ),
    [viewList]
  );

  const { register, handleSubmit, reset, errors } = useForm({ defaultValues });

  useEffect(() => {
    if (!isEmpty(defaultValues)) reset(defaultValues);
  }, [viewList]);

  const generateQuestion = (row) => {
    if (row.widget_name === 'label')
      return (
        <StyledTableCell colSpan="2" align="center">
          {row.title}
        </StyledTableCell>
      );

    if (row.widget_name === 'yes_no')
      return (
        <>
          <StyledTableCell component="th" scope="row">
            {row.title}
          </StyledTableCell>
          <StyledTableCell align="center">
            <FormControl className="w30">
              <NativeSelect
                inputProps={{ ref: register({ required: true }) }}
                error={!!errors[`${row.meta_field_id}[has_evaluate]`]}
                name={`${row.meta_field_id}[has_evaluate]`}>
                <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
                <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
              </NativeSelect>
            </FormControl>
          </StyledTableCell>

          <StyledTableCell align="right">
            <FormControl className="w30">
              <NativeSelect inputProps={{ ref: register }} name={`${row.meta_field_id}[value]`}>
                <option value=""></option>
                <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
                <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
              </NativeSelect>
            </FormControl>
          </StyledTableCell>
        </>
      );

    if (row.widget_name === 'yes_partially_no')
      return (
        <>
          <StyledTableCell component="th" scope="row">
            {row.title}
          </StyledTableCell>
          <StyledTableCell align="center">
            <FormControl className="w30">
              <NativeSelect
                inputProps={{ ref: register({ required: true }) }}
                error={!!errors[`${row.meta_field_id}[has_evaluate]`]}
                name={`${row.meta_field_id}[has_evaluate]`}>
                <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
                <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
              </NativeSelect>
            </FormControl>
          </StyledTableCell>
          <StyledTableCell align="right">
            <FormControl className="w30">
              <NativeSelect inputProps={{ ref: register }} name={`${row.meta_field_id}[value]`}>
                <option value=""></option>
                <option value="1">{intl.formatMessage({ id: 'global.yesTitle', defaultMessage: 'Да' })}</option>
                <option value="0.5">{intl.formatMessage({ id: 'section24.partlyTitle', defaultMessage: 'Частично' })}</option>
                <option value="0">{intl.formatMessage({ id: 'global.noTitle', defaultMessage: 'Нет' })}</option>
              </NativeSelect>
            </FormControl>
          </StyledTableCell>
        </>
      );

    return null;
  };

  const onSubmit = async (data) => {
    const finalData = Object.keys(data).map((id) => ({
      id: id,
      ...data[id],
    }));
    await dispatch(editWebsiteComplianceRequirements(finalData));
    dispatch(getSimpleAssessment(17));
    setIsEditMode(false);
  };

  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const requestState = useSelector((state) => state.sectionTwoWebsiteComplianceRequirementsUI.requestState, shallowEqual);
  const selectedTabIndex = useSelector((state) => state.tabs.selectedTabIndex, shallowEqual);
  const tabs = useSelector((state) => state.dictionary.tabs, shallowEqual);
  const errorHandler = useSelector((state) => state.sectionTwoWebsiteComplianceRequirements.error, shallowEqual);

  const assessment = useSelector((state) => state.simpleEvaluation.assessment);
  const updateEvalActivation = async (data) => {
    await dispatch(updateEvalActivationInfo(17, data));
    dispatch(getSimpleAssessment(17));
    dispatch(toggleTabState({ ...tabs[selectedTabIndex], is_active: parseInt(data.is_active) }));
  };

  if (errorHandler.hasError) return <Error error={errorHandler} />;

  if (requestState === 'request') return <Spinner />;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="spaceBetween mb4">
          <div className="titleContainer">
            <h2 className="title" style={{ margin: 0, padding: '15px 20px' }}>
              {tabs[selectedTabIndex]?.title}
            </h2>
          </div>
          {isVisible && isEditMode && (
            <Button variant="contained" color="primary" type="submit">
              <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
            </Button>
          )}
          {isVisible && !isEditMode && (
            <Button color="primary" variant="contained" onClick={() => setIsEditMode(true)}>
              <FormattedMessage id="global.editBtnTitle" defaultMessage="Редактировать" />
            </Button>
          )}
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <FormattedMessage id="section24.indicatorName" defaultMessage="Наименование показателя" />
                </StyledTableCell>
                <StyledTableCell align="center">
                  <FormattedMessage id="section24.estimatedTitle" defaultMessage="Оценивается" />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <FormattedMessage id="section24.indicatorCritition" defaultMessage="Критерий показателя" />
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {viewList.map((row) => {
                if (row.widget_name === 'label')
                  return (
                    <StyledTableRow key={row.meta_label_id}>
                      <StyledTableCell colSpan="2" align="center">
                        {row.title}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                if (isEditMode) return <StyledTableRow key={row.meta_field_id}>{generateQuestion(row)}</StyledTableRow>;
                else
                  return (
                    <StyledTableRow key={row.meta_field_id}>
                      <StyledTableCell align="left">{row.title}</StyledTableCell>
                      <StyledTableCell align="center">{row.translated_evaluate}</StyledTableCell>
                      <StyledTableCell align="right">{row.translated_value ? row.translated_value : row.value}</StyledTableCell>
                    </StyledTableRow>
                  );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {isVisible && isEditMode && (
          <Button variant="contained" type="submit" color="primary" className="m0Auto">
            <FormattedMessage id="global.saveBtnTitle" defaultMessage="Сохранить" />
          </Button>
        )}
      </form>

      <EvaluationActivation isExpert={isVisible} onSubmit={updateEvalActivation} className="w30 m0Auto mb4">
        <div className="w50 mAuto mt4">
          <ExpertAssessment isExpert={false} assessment={assessment} showComment={false} />
        </div>
      </EvaluationActivation>
    </>
  );
}

export default Section24;
