import { handleActions } from 'redux-actions';
import * as actions from '../../actions';

export const infoPublicServices = handleActions(
  {
    [actions.getPublicServicesSuccess]: (state, { payload }) => {
      return {
        ...state,
        services: payload.items,
        metaData: payload._meta,
      };
    },
    [actions.getPublicServiceLabelsSuccess]: (state, { payload }) => ({ ...state, labels: payload }),
    [actions.getPublicElementSuccess]: (state, { payload }) => ({ ...state, element: payload }),
    [actions.deletePublicElementSuccess]: (state, { payload: { id } }) => ({
      ...state,
      services: state.services.filter((service) => service.id !== id),
    }),
    [actions.editPublicElementSuccess]: (state, { payload }) => ({
      ...state,
      services: state.services.map((el) => (el.id === payload.id ? payload : el)),
    }),
    [actions.addPublicElementSuccess]: (state, { payload }) => ({ ...state, services: [...state.services, payload] }),
    [actions.setPublicServicesError]: (state, { payload }) => ({
      ...state,
      error: {
        hasError: true,
        status: payload.status,
        message: payload.message,
      },
    }),
    [actions.resetError]: (state) => ({ ...state, error: { hasError: false, message: '', status: '' } }),
  },
  {
    services: [],
    element: {},
    labels: {},
    metaData: {
      pageCount: 1,
    },
    error: {
      hasError: false,
      message: '',
      status: '',
    },
  }
);

export const infoPublicServicesUI = handleActions(
  {
    [actions.getPublicServiceLabelsRequest]: (state) => ({ ...state, requestLabelState: 'request' }),
    [actions.getPublicServiceLabelsSuccess]: (state) => ({ ...state, requestLabelState: 'success' }),
    [actions.getPublicServiceLabelsFailure]: (state) => ({ ...state, requestLabelState: 'failure' }),
    [actions.editPublicElementRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.editPublicElementSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.editPublicElementFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
    [actions.getPublicElementRequest]: (state) => ({ ...state, requestItemState: 'request' }),
    [actions.getPublicElementSuccess]: (state) => ({ ...state, requestItemState: 'success' }),
    [actions.getPublicElementFailure]: (state) => ({ ...state, requestItemState: 'failure' }),
  },
  {
    requestLabelState: '',
    requestItemState: '',
  }
);
