import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setSpendingStatError = createAction('SPENDING_STAT_SET_ERROR');

export const getSpendingStatRequest = createAction('SPENDING_STAT_GET_REQUEST');
export const getSpendingStatSuccess = createAction('SPENDING_STAT_GET_SUCCESS');
export const getSpendingStatFailure = createAction('SPENDING_STAT_GET_FAILURE');

export const getSpendingStat = () => async (dispatch) => {
  dispatch(getSpendingStatRequest());

  try {
    const { data } = await axios.get(`${routes.sectionEightSpendingStat()}/view`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(`${routes.sectionEightSpendingStat()}/labels`, {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });

    dispatch(getSpendingStatSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setSpendingStatError(e?.response));
  }
};

export const postSpendingStatRequest = createAction('SPENDING_STAT_POST_REQUEST');
export const postSpendingStatSuccess = createAction('SPENDING_STAT_POST_SUCCESS');
export const postSpendingStatFailure = createAction('SPENDING_STAT_POST_FAILURE');

export const postSpendingStat = (formValues) => async (dispatch) => {
  dispatch(postSpendingStatRequest());
  try {
    await axios({
      method: 'post',
      url: `${routes.sectionEightSpendingStat()}/update`,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getSpendingStat());
    dispatch(postSpendingStatSuccess());
  } catch (e) {
    dispatch(setSpendingStatError(e?.response));
    dispatch(postSpendingStatFailure());
  }
};
