import { handleActions } from 'redux-actions';
import * as actions from '../actions';

export const errorHandler = handleActions(
  {
    [actions.setError]: (_, { payload }) => ({
      hasError: true,
      status: payload?.status,
      message: payload?.data.message,
    }),
    [actions.resetError]: () => ({
      hasError: false,
      message: '',
      status: '',
    }),
  },
  {
    hasError: false,
    message: '',
    status: '',
  }
);
