import axios from 'axios';
import { createAction } from 'redux-actions';
import { generateHeaders } from 'actions/dictionary';
import routes from 'regions/routes';
import { handleValidationError } from 'common/utils';
export const getQuestionnaireRequest = createAction('GET_QUESTIONNAIRE_REQUEST');
export const getQuestionnaireSuccess = createAction('GET_QUESTIONNAIRE_SUCCESS');
export const getQuestionnaireFailure = createAction('GET_QUESTIONNAIRE_FAILURE');

export const getQuestionnaire =
  (region, table = 1) =>
  async (dispatch) => {
    dispatch(getQuestionnaireRequest());

    try {
      const { data } = await axios({
        url: routes.questionnaire('view'),
        method: 'post',
        data: { region_id: region, table_id: table },
        headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      });
      dispatch(getQuestionnaireSuccess(data));
    } catch (e) {
      dispatch(getQuestionnaireFailure());
      console.log(e);
    }
  };

export const sendQuestionnaireData = (formData) => async (dispatch) => {
  try {
    await axios({
      url: routes.questionnaire('store'),
      method: 'post',
      data: formData,
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    // const region = String(formData.region_id).substr(0, 4);
    // dispatch(getQuestionnaire(region, formData.table_id));
    // dispatch(getDistricts(region));
  } catch (e) {
    console.log(e);
  }
};

export const setCurrentForm = createAction('CURRENT_FORM_SET');

export const getDetailedEvaluationRequest = createAction('GET_DETAILED_EVALUATION_REQUEST');
export const getDetailedEvaluationSuccess = createAction('GET_DETAILED_EVALUATION_SUCCESS');
export const getDetailedEvaluationFailure = createAction('GET_DETAILED_EVALUATION_FAILURE');

export const getDetailedEvaluation = (body) => async (dispatch) => {
  dispatch(getDetailedEvaluationRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.statistics(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: body,
    });
    if (data.success) {
      dispatch(getDetailedEvaluationSuccess(data.result.collection));
    } else {
      alert(data.message || 'Server Error');
    }
  } catch (e) {
    dispatch(getDetailedEvaluationFailure());
    handleValidationError(e);
    console.log(e);
  }
};

export const fetchRatingRequest = createAction('RATING_FETCH_REQUEST');
export const fetchRatingSuccess = createAction('RATING_FETCH_SUCCESS');
export const fetchRatingFailure = createAction('RATING_FETCH_FAILURE');

export const fetchRating = (body) => async (dispatch) => {
  dispatch(fetchRatingRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.rating(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: body,
    });
    if (data.success) {
      dispatch(fetchRatingSuccess(data.result));
    } else {
      alert(data.message || 'Server Error');
    }
  } catch (e) {
    dispatch(fetchRatingFailure());
    handleValidationError(e);
    console.log(e);
  }
};
