import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setWebsiteAccessError = createAction('WEBSITE_ACCESS_SET_ERROR');

export const getWebsiteInfoAccessRequest = createAction('WEBSITE_ACCESS_INFO_GET_REQUEST');
export const getWebsiteInfoAccessSuccess = createAction('WEBSITE_ACCESS_INFO_GET_SUCCESS');
export const getWebsiteInfoAccessFailure = createAction('WEBSITE_ACCESS_INFO_GET_FAILURE');
export const getWebsiteInfoAccess = () => async (dispatch) => {
  dispatch(getWebsiteInfoAccessRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteAccess(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoWebsiteAccessLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getWebsiteInfoAccessSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setWebsiteAccessError(e?.response));
    dispatch(getWebsiteInfoAccessFailure());
  }
};

export const editWebsiteInfoAccessRequest = createAction('WEBSITE_ACCESS_INFO_REQUEST');
export const editWebsiteInfoAccessSuccess = createAction('WEBSITE_ACCESS_INFO_SUCCESS');
export const editWebsiteInfoAccessFailure = createAction('WEBSITE_ACCESS_INFO_FAILURE');

export const editWebsiteInfoAccess = (formData) => async (dispatch) => {
  dispatch(editWebsiteInfoAccessRequest());

  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoWebsiteAccess(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(getWebsiteInfoAccess());
    dispatch(editWebsiteInfoAccessSuccess());
  } catch (e) {
    dispatch(setWebsiteAccessError(e?.response));
  }
};
