import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setWebsiteAppsError = createAction('WEBSITE_APPS_SET_ERROR');

export const getWebsiteAppsInfoRequest = createAction('WEBSITE_APPS_INFO_GET_REQUEST');
export const getWebsiteAppsInfoSuccess = createAction('WEBSITE_APPS_INFO_GET_SUCCESS');
export const getWebsiteAppsInfoFailure = createAction('WEBSITE_APPS_INFO_GET_FAILURE');

export const getWebsiteAppsInfo = () => async (dispatch) => {
  dispatch(getWebsiteAppsInfoRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoWebsiteApps(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    const labels = await axios.get(routes.sectionTwoWebsiteAppLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getWebsiteAppsInfoSuccess({ data, labels: labels.data }));
  } catch (e) {
    dispatch(setWebsiteAppsError(e?.response));
    dispatch(getWebsiteAppsInfoFailure());
  }
};

export const editWebsiteAppsInfoRequest = createAction('WEBSITE_APPS_INFO_EDIT_REQUEST');
export const editWebsiteAppsInfoSuccess = createAction('WEBSITE_APPS_INFO_EDIT_SUCCESS');
export const editWebsiteAppsInfoFailure = createAction('WEBSITE_APPS_INFO_EDIT_FAILURE');

export const editWebsiteAppsInfo = (formValues) => async (dispatch) => {
  dispatch(editWebsiteAppsInfoRequest());
  try {
    await axios({
      method: 'post',
      url: routes.sectionTwoWebsiteApps(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formValues,
    });
    dispatch(getWebsiteAppsInfo());
    dispatch(editWebsiteAppsInfoRequest());
  } catch (e) {
    dispatch(setWebsiteAppsError(e?.response));
    dispatch(editWebsiteAppsInfoFailure());
  }
};
