import { handleActions } from 'redux-actions';
import * as actions from 'actions';

export const documents = handleActions(
  {
    [actions.getActsSuccess]: (state, { payload }) => {
      return { ...state, acts: payload.items, configActs: payload._meta };
    },
    [actions.getRoadmapsSuccess]: (state, { payload }) => ({
      ...state,
      roadmaps: payload.items,
      configRoadmaps: payload._meta,
    }),
  },
  {
    acts: [],
    configActs: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
    roadmaps: [],
    configRoadmaps: {
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      perPage: 0,
    },
  }
);

export const documentsUI = handleActions(
  {
    [actions.getActsRequest]: () => ({ loading: true }),
    [actions.getActsSuccess]: () => ({ loading: false }),
    [actions.getActsFailure]: () => ({ loading: false }),
    [actions.getRoadmapsRequest]: () => ({ loading: true }),
    [actions.getRoadmapsSuccess]: () => ({ loading: false }),
    [actions.getRoadmapsFailure]: () => ({ loading: false }),
    [actions.getStepsRequest]: () => ({ loadingSteps: true }),
    [actions.getStepsSuccess]: () => ({ loadingSteps: false }),
    [actions.getStepsFailure]: () => ({ loadingSteps: false }),
    [actions.getProjectsRequest]: () => ({ loading: true }),
    [actions.getProjectsSuccess]: () => ({ loading: false }),
    [actions.getProjectsFailure]: () => ({ loading: false }),
    [actions.getProjectSuccess]: () => ({ loading: false }),
    [actions.deleteProjectRequest]: () => ({ deleteLoading: true }),
    [actions.deleteProjectSuccess]: () => ({ deleteLoading: false }),
    [actions.deleteProjectFailure]: () => ({ deleteLoading: false }),
  },
  { loading: false, deleteLoading: false, loadingSteps: false }
);
