import { handleActions } from 'redux-actions';
import * as actions from 'actions/regions';

export const regionDictionary = handleActions(
  {
    [actions.getRegionsSuccess]: (state, { payload }) => ({ ...state, regions: payload }),
    [actions.getRDistrictsSuccess]: (state, { payload }) => ({ ...state, districts: payload }),
    [actions.getOrganizationTablesSuccess]: (state, { payload }) => ({ ...state, organizations: payload }),
    [actions.getRDeadlineSuccess]: (state, { payload }) => ({ ...state, deadline: payload.deadline }),
  },
  {
    regions: [],
    districts: [],
    organizations: [],
    deadline: '',
  }
);

export const regionDictionaryUI = handleActions(
  {
    [actions.getRegionsRequest]: () => ({ fetchingRegions: 'request' }),
    [actions.getRegionsSuccess]: () => ({ fetchingRegions: 'success' }),
    [actions.getRegionsFailure]: () => ({ fetchingRegions: 'failure' }),
  },
  {
    fetchingRegions: '',
  }
);
