import { createAction } from 'redux-actions';
import axios from 'axios';
import routes from '../../routes';
import { generateHeaders } from '../dictionary';

export const setSocialPagesError = createAction('SOCIAL_LINK_SET_ERROR');

export const getSocialPagesRequest = createAction('SOCIAL_PAGES_GET_REQUEST');
export const getSocialPagesSuccess = createAction('SOCIAL_PAGES_GET_SUCCESS');
export const getSocialPagesFailure = createAction('SOCIAL_PAGES_GET_FAILURE');

export const getSocialPages = () => async (dispatch) => {
  dispatch(getSocialPagesRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksSocialPages(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getSocialPagesSuccess(data.items));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(getSocialPagesFailure());
  }
};

export const getSocialLabelsRequest = createAction('SOCIAL_LABELS_GET_REQUEST');
export const getSocialLabelsSuccess = createAction('SOCIAL_LABELS_GET_SUCCESS');
export const getSocialLabelsFailure = createAction('SOCIAL_LABELS_GET_FAILURE');

export const getSocialLabels = () => async (dispatch) => {
  dispatch(getSocialLabelsRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksSocialLabels(), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getSocialLabelsSuccess(data));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(getSocialLabelsFailure());
  }
};

export const addSocialLinkRequest = createAction('SOCIAL_LINK_ADD_REQUEST');
export const addSocialLinkSuccess = createAction('SOCIAL_LINK_ADD_SUCCESS');
export const addSocialLinkFailure = createAction('SOCIAL_LINK_ADD_FAILURE');

export const addSocialLink = (formData) => async (dispatch) => {
  dispatch(addSocialLinkRequest());

  try {
    const { data } = await axios({
      method: 'post',
      url: routes.sectionTwoLinksSocialPages(),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(addSocialLinkSuccess(data));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(addSocialLinkFailure());
  }
};

export const deleteSocialLinkRequest = createAction('SOCIAL_LINK_DELETE_REQUEST');
export const deleteSocialLinkSuccess = createAction('SOCIAL_LINK_DELETE_SUCCESS');
export const deleteSocialLinkFailure = createAction('SOCIAL_LINK_DELETE_FAILURE');

export const deleteSocialLink = (id) => async (dispatch) => {
  dispatch(deleteSocialLinkRequest());

  try {
    await axios({
      method: 'delete',
      url: routes.sectionTwoLinksSocialOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(deleteSocialLinkSuccess({ id }));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(deleteSocialLinkFailure());
  }
};

export const editSocialLinkRequest = createAction('SOCIAL_LINK_EDIT_REQUEST');
export const editSocialLinkSuccess = createAction('SOCIAL_LINK_EDIT_SUCCESS');
export const editSocialLinkFailure = createAction('SOCIAL_LINK_EDIT_FAILURE');

export const editSocialLink = (id, formData) => async (dispatch) => {
  dispatch(editSocialLinkRequest());

  try {
    const { data } = await axios({
      method: 'put',
      url: routes.sectionTwoLinksSocialOperation(id),
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
      data: formData,
    });
    dispatch(editSocialLinkSuccess(data));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(editSocialLinkFailure());
  }
};

export const getSocialLinkRequest = createAction('SOCIAL_LINK_GET_REQUEST');
export const getSocialLinkSuccess = createAction('SOCIAL_LINK_GET_SUCCESS');
export const getSocialLinkFailure = createAction('SOCIAL_LINK_GET_FAILURE');

export const getSocialLink = (id) => async (dispatch) => {
  dispatch(getSocialLinkRequest());

  try {
    const { data } = await axios.get(routes.sectionTwoLinksSocialOperation(id), {
      headers: generateHeaders(localStorage.token, localStorage.lang, localStorage.orgId),
    });
    dispatch(getSocialLinkSuccess(data));
  } catch (e) {
    dispatch(setSocialPagesError(e?.response));
    dispatch(getSocialLinkFailure());
  }
};
