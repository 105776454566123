import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Dashboard from 'components/Dashboard';
import { getRDeadline } from 'actions/regions';

function Layout({ children }) {
  const { location } = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!location.pathname.includes('organization')) {
      dispatch(getRDeadline());
    }
  }, [location])
  return (
    <Dashboard>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className="p2">{children}</Paper>
        </Grid>
      </Grid>
    </Dashboard>
  );
}

export default Layout;
